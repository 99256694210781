import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Password} from 'primereact/password';
import {Message} from 'primereact/message';
import { ldb, api, log, sock, go, is_local_server, set_header,
	set_cluster_search_buf,
	growl, local_storage_get_login_info, } from './Lib';

// Note: See State Diagram way below for Login Page 
//	to see how these components interact

class LoginForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email : '',
			password: '',
			error : '',
			logged_in: false,
			working: false,
		};
	}

	componentDidMount() {
		sock.connect();
		var ele = this.nameInput || 
			(this.emailInput && this.emailInput.inputEl);
		ele && ele.focus();

		this.auto_login();
	}

	auto_login = () => {
		if (!is_local_server())
			return;
		
		let {email, password} = local_storage_get_login_info();
		if ((!email) || (!password))
			return;
		
		growl('Auto Login');
		this.setState({
			email:email,
			password:password
		});
		setTimeout(this.submit, 1000);
	}

	componentDidUpdate() {
	}

	change = (event) => {
		const ele = event.target;
		var item = {};
		item[ele.dataset.name] = ele.value;
		this.setState(item);
	}

	submit = (event) => {
		if (event)
			event.preventDefault();

		// For pages rendered on the server through Django,
		//	we need to know the timezone. (mail settings/logs...)
		//	Send our timezone here to store it in Django Session
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		
		const {email, password} = this.state;
		const cmd = 'login';
		const relogin = false;
		const args = {cmd, email, password, relogin, timezone};

		log('login', 'apic', cmd, email);

		api( args, this.api_callback, this.api_callback);

		this.setState({working:true, error: ''});
	}

	api_callback = (error, db) => {
		const logged_in = true;

		log('login', error);
		
		if ((db) && (db.redirect)) {
			window.location.href = db.redirect;
		}

		this.setState({ error });

		if (error) {
			this.setState({working:false});
			return;
		}

		const {email, password} = this.state;
		sock.save_for_relogin(email, password);

		ldb.post_login();

		this.props.par.setState({mode: 'load_contacts'});
	}

	keyDown = e => {
		if (e.keyCode == 13) {
			e.preventDefault();
			return false;
		}
	}

	render() {
		const {logged_in, email, password, error} = this.state;
		if (logged_in)
			return null;

		// log('login', 'LOGIN LOGIN', window.location.hash);
		
		if (window.location.hash.includes('/public/'))
			return null;

		const err = this.state.error ? 
			<div><b>Error</b>: {error}</div> : null;
		
		return (
		<form onSubmit={this.submit}>
			<div>Email</div>
			<InputText id="login_email"
				ref={inp => {this.emailInput = inp}}
				value={email}
		onChange={e => this.setState({email:e.target.value})}
			style={{width:'20em'}}
			onKeyDown={this.keyDown}
				/>

			<br/><br/>

			<div>
				Password
			</div>
			<Password id="login_password"
				feedback={false}
				value={password}
				toggleMask={true}
		onChange={e => this.setState({password:e.target.value})}
				inputStyle={{width:'20em'}}
				/>
			{' '}
			<p/>
			<Button label="Login" />
			<br/>
			<br/>
			<br/>
			<br/>
		{this.state.working && <ProgressSpinner/>}
		</form>
		);
	}
}

// Loads contacts : persons + clusters
//	on Success, sets state to 'ms_login'
//	on Failure, displays error and blocks

class LoadContacts extends Component {
	constructor(props) {
		super(props);
		this.state = { error : '', working: false, };
	}

	componentDidMount() {
		this.get_contacts();
	}

	get_contacts  = () => {
		if (this.state.working)
			return;

		const args = {cmd: 'get_contacts'};
		log('login', 'get_contacts');

		api( args, this.got_contacts, this.got_contacts);
		
		this.setState({working:true});

	}

	got_contacts = (err2, db) => {
		const error = db.meta.error;
		log('login', 'got_contacts', error);
		this.setState({working:false, error});

		if (error) 
			return;

		set_cluster_search_buf();
		ldb.data.contacts._flags.fetched = true;

		let mode = window.is_mobile ? 'done' : 'ms_login';

		// skip mail server password/login check to speed up
		//	local development and repeated refresh.
		if (is_local_server())
			mode = 'done';

		// We catch mail server password failure in other cases.
		//	Skip mail server login for production also.
		mode = 'done';

		this.props.par.setState({mode});
	}

	on_error = () => <dl>
		<dt>

			Error
		</dt>
		<dd>
			Failed to load contacts
		</dd>
	</dl>

	render() {
		return (
	<div>
		<h5>Loading contacts...</h5>
		{this.state.error && this.on_error() }
		<p/>
		{this.state.working && <ProgressSpinner/>}
	</div>
		);
	}
}


// Logs in to Mail Server. 
//	on Success, sets state to 'done'
//	on Failure, sets state to 'ms_pw',

class MailServerLogin extends Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 1,
			error : '',
			working: false,
		};
	}

	componentDidMount() {
		this.mail_server_login();
	}

	mail_server_login  = () => {
		if (this.state.working)
			return;

		const args = {cmd: 'mail_server', op: 'connect'};
		log('login', 'mail_server_connect', );

		api( args, this.post_mail_server_login, 
			this.post_mail_server_login);
		
		this.setState({working:true});

	}

	post_mail_server_login = (err2, db) => {
		const error = db.meta.error;

		if (!error) {
			this.props.par.setState({mode: 'done'});
			return;
		}

		log('login', 'mail server login', error);
		const count = this.state.count + 1;

		this.setState({working:false, error, count});
	}

	goto_mspw = () => this.props.par.setState({mode: 'ms_pw'});

	on_error = () => <dl>
		<dt>

			Your ID
		</dt>
		<dd>
			{ldb.data.me.name} ({ldb.data.me.email})
		</dd>

		<dt>
			Login to TagInbox
		</dt>

		<dd>
			Success
		</dd>

		<dt>
			Login to your mail server
		</dt>

		<dd>
			<Message severity="error" 
		text={'Failed to connect to your mail server: (' +
				ldb.data.mailbox.in_server + ')'}
			/>
		</dd>

		<dt>
			Likely cause
		</dt>

		<dd>
			You changed your email password, 
			but haven't updated TagInbox with it.
		</dd>

		<dt>
			If so,
		</dt>

		<dd>
			<Button label="Update Mail Server Password"
				onClick={this.goto_mspw} />
		</dd>

		<dt>
			If not,
		</dt>
		
		<dd>
	{this.state.count < 4 && 
		<div className="p-col-8">
			<Button label={"Retry #"  + this.state.count}
				onClick={this.mail_server_login} />
			{' '}
			(You get 3 tries)
		</div>
	}
	{this.state.count >= 4 &&
		<div className="p-col-8">
			Sorry, too many failed retries.
		</div>
	}
		</dd>
	</dl>

	render() {
		return (
	<div>
		<h5>Connecting to mail server...</h5>
		{this.state.error && this.on_error() }
		<p/>
		{this.state.working && <ProgressSpinner/>}
	</div>
		);
	}
}

class ChangeMailServerPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			error : '',
			working: false,
		};
	}

	componentDidMount() {
		var ele = this.pwInput && this.pwInput.inputEl;
		ele && ele.focus();
	}

	change = (event) => {
		const ele = event.target;
		var item = {};
		item[ele.dataset.name] = ele.value;
		this.setState(item);
	}

	submit = (event) => {
		if (event)
			event.preventDefault();
		
		const {password} = this.state;
		const args = {cmd: 'mail_server', op: 'update', password};
		log('login', 'mail_server: update password', );

		api( args, this.api_callback, this.api_callback);
		
		this.setState({working:true, error: ''});
	}

	api_callback = (err2, db) => {
		const error = db.meta.error;

		if (!error) {
			growl('Email password updated successfully');
			this.props.par.setState({mode: 'done'});
		}

		log('login', 'mail server change password', error);
		this.setState({working:false, error});
	}

	render() {
		const err = this.state.error ? 
			<div><b>Error</b>: {this.state.error}</div> : null;

		return (
		<form onSubmit={this.submit}>
			<div className="p-grid">
				<div className="p-col-2 colname">
					You:
				</div>
				<div className="p-col-10">
					{ldb.data.me.name}
				</div>
				<div className="p-col-2 colname">
					Username:
				</div>
				<div className="p-col-10">
					{ldb.data.mailbox.username}
				</div>
				<div className="p-col-2 colname">
					Mail Server:
				</div>
				<div className="p-col-10">
					{ldb.data.mailbox.in_server}
				</div>
			</div>

			<br/>

			<div>Mail Server Password</div>
			<Password id="login_password"
				ref={inp => {this.pwInput = inp}}
				feedback={false}
				value={this.state.password}
		onChange={e => this.setState({password:e.target.value})}
				style={{width:'20em'}}
				/>
			<p/>
			<Button label="Update" onClick={this.submit} />
			<br/>
			<br/>
			{err}
			<br/>
			<br/>
		{this.state.working && <ProgressSpinner/>}
		</form>
		);
	}
}
	
class LoginDone extends Component {
	componentDidMount() {
		// Most likely, App.js intercepted executing current URL and 
		// 	sent us to LoginPage. Now we need to return to 
		//	whereever we were. (/ or /#/room/4/summary or /#/bulk/folders ..

		/*
		if (window.is_mobile) {
			log('login', 'Go Mobile');
			go('/');
			return;
		}
		*/

		// Tell App.js to use full Routes, and not limited routes to Login
		// 	and go to current URL
		window.g_app.refresh();

		// Note: if URL is /, App.js would send it to Dashboard, which
		//	sends it to myrooms


		// One exception, if URL was /#/login though, we're done logging in
		//	go to myrooms
		if (window.location.href.indexOf('login') >= 0) {
			go('myrooms');
		}
	}

	render() {
		log('login', '+++Login Done+++');

		
		return null;
	}
}

// Main Login Page
// State transition
//	Login => load_contacts
//	load_contacts => ms_login 		ms = mail server
//	ms_login => done | ms_pw
//	ms_pw => ms_pw | done
//	done

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mode: 'login',
		};
	}
	render() {
		switch(this.state.mode) {
		case 'login' : 
			return <LoginForm par={this} />
		case 'load_contacts' :
			return <LoadContacts par={this} />
		case 'ms_login' :
			return <MailServerLogin par={this} />
		case 'ms_pw':
			return <ChangeMailServerPassword par={this} />
		case 'done':
			ldb.post_login2();
			return <LoginDone par={this} />
		}
	}
}

export default LoginPage;
