import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {RadioButton} from 'primereact/radiobutton';
import {TabView, TabPanel} from 'primereact/tabview';
import {ProgressSpinner} from 'primereact/progressspinner';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Paginator} from 'primereact/paginator';
import {Dropdown} from 'primereact/dropdown';
import {ListBox} from 'primereact/listbox';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';

import {Focusable, Spacer, ModalCommand, lazyFocus} from './Utils';
import {EditPerson} from './EditPerson';
import {ldb, log, api, go, edate, edate3, map_list, go_url, pid2person,
		validate_email, set_order, resort, get_connection, pint,
		growl, show_is_new, get_room, get_staff, em, copy_to_clipboard, 
		gen_xid, get_room_options, get_client_options} from './Lib';

//------------------ Persons  ------------------------

/*---- Connections Tab ----*/

function SaveConnection(props) {
	const {rid, cid, name, role, comment, email, 
			setEdit, setSave} = props;
	const cmd = 'mod_connection';
	const done = () => {
		draft_connection(null);
		setEdit(0);
	}

	if (!validate_email(email)) {
		growl('Error', 'Invalid email', 'warn');
		setSave(0);
		return null;
	}

	log('person', 'SaveConnection', cid);

	const args = {cmd, rid, cid, name, role, comment, email};
	api( args, done );

	return <ProgressSpinner />;
}

function RemoveConnection(props) {
	const {rid, cid, name, role, comment, email, setEdit} = props;
	const cmd = 'mod_connection';
	const done = () => {
		draft_connection(null);
		setEdit(0);
	}

	// negative cid means remove
	const args = {cmd, rid, cid: (-cid), name, role, comment, email};
	api( args, done );
	
	log('person', 'RemoveConnection', args);

	return <ProgressSpinner />;
}

function draft_connection(draft) {
	// draft can be undefined, null (reset), or initial value;
	if (draft === undefined)
		draft = ldb.data.contacts.connections.draft;
	if (!draft)
		draft = {name: '', email: '', role: '', comment: '', id:0};
	ldb.data.contacts.connections.draft = draft;
	return draft;
}

function EditConnection(props) {
	const {cid, setEdit} = props;
	const icid = pint(cid);
	const conn = icid ? get_connection(icid) : draft_connection();
	const header = (icid ? 'Edit' : 'Create') + ' Connection';
	const action = icid ? 'Save' : 'Create';

	const [name, setName] = React.useState(conn.name);
	const [email, setEmail] = React.useState(conn.email);
	const [role, setRole] = React.useState(conn.role);
	const [comment, setComment] = React.useState(conn.comment);
	const [save, setSave] = React.useState(false);
	const [remove, setRemove] = React.useState(false);

	const cancel = e => {
		draft_connection({name, email, role, comment, id:0});
		setEdit(0);
	};

	return (
	<ModalCommand header={header}
		onHide={cancel} 
		className="modal-35-command"
		extra={{maximizable:false}}
	>
	<div className="p-grid edit-connection">

	<div className="p-col-2 label">
		Name:
	</div>
	<div className="p-col-10">
		<InputText placeholder="Name" ref={lazyFocus}
			value={name} onChange={e=>setName(e.target.value)} />
	</div>

	<div className="p-col-2 label">
		Email:
	</div>
	<div className="p-col-10">
		{icid > 0 ? 
			conn.email :
		<InputText placeholder="Email" 
			value={email} onChange={e=>setEmail(e.target.value)} />
		}
	</div>

	<div className="p-col-2 label">
		Role:
	</div>
	<div className="p-col-10">
		<InputText placeholder="Comment"
		    value={role} onChange={e=>setRole(e.target.value)} />
	</div>

	<div className="p-col-2 label">
		Comment:
	</div>
	<div className="p-col-10">
		<InputText placeholder="Comment"
		    value={comment} onChange={e=>setComment(e.target.value)} />
	</div>

	<div className="p-col-2">
	</div>
	<div className="p-col-10">
		<Button label={action} icon="pi pi-fw pi-check"
			onClick={e=>setSave(true)} />

		{icid > 0 && 
		  <Button label="Remove from Room" icon="pi pi-fw pi-times"
		     className='p-button p-button-secondary remove-connection'
			onClick={e=>setRemove(true)} />}
	</div>

	</div>
	{save > 0 && <SaveConnection name={name} role={role} comment={comment}
			setSave={setSave} email={email} {...props} />}
	{remove && <RemoveConnection name={name} role={role} comment={comment}
				email={conn.email} {...props} />}
	</ModalCommand>
	);
}

function AddAsClient(props) {
	const {cid, setEdit} = props;
	let {rid} = props;
	const icid = pint(cid);
	const person = ldb.data.contacts.persons._items[cid];
	
	let show_no_person_warning = false;
	if (!person) {
		show_no_person_warning = true;
	}
	
	//console.log('CID', cid, icid, person)

	let room = get_room(rid);
	if (room.is_mailbox) {
		rid = '';
		room = null;
	}

	const [name, setName] = React.useState(person.name);
	const [email, setEmail] = React.useState(person.email);
	const [target_rid, setTargetRid] = React.useState(rid);
	const [target_cid, setTargetCid] = React.useState('');
	const [save, setSave] = React.useState(false);
	const [working, setWorking] = React.useState(false);
	const [type_client_add, setTypeClientAdd] = React.useState('new');
	const [no_person_warning, setNoPersonWarning] = React.useState(show_no_person_warning);

	const room_options = get_room_options();

	const target_room = target_rid ? get_room(target_rid) : null;
	const target_person = target_cid ? target_room.clients._items[target_cid] : null;

	const client_options = target_room ? get_client_options(target_room) : [];
	
	const has_clients_options = (client_options.length > 1) ? true : false;
	
	const cancel = e => {
		setEdit(0);
	};

	const update = () => {
		if (working) {
			growl('Operating in progress. Please wait');
			return;
		}

		if (!target_rid) {
			growl('Please select a room');
			return;
		}

		const is_new_client = (type_client_add == 'new') ? true : false;
		
		if (!is_new_client) {
			if (!target_cid) {
				growl('Please select an existing client');
				return;
			}
		}

		const cmd = is_new_client ? 'mod_client' : 'client_email_ids';
		
		let args = {};
		if (!is_new_client) {
			const troom = get_room(target_rid);
			const target_person = troom.clients._items[target_cid];
			const target_person_name = target_person.name;
			
			args = {
				cmd,
				rid: target_rid,
				cuid: target_cid,
				items: [{id:0, email, priority:1, comment:'test'}],
				name: target_person_name,
			}
		} else {
			args = {
				cmd, 
				rid: target_rid,
				add: [{name, 
					emails: [{email, priority:1}],
					tels: [],
				}],
				remove: [],
			}
		}
		
		console.log(args);
		api( args, (error, db) => {setWorking(false);cancel();} );
		setWorking(true);
	}

	if (no_person_warning) {
		return (
		<ModalCommand header="Add as Client"
			onHide={cancel} 
			className="medium-modal-command"
			extra={{maximizable:false}}
		>
			<p>We are unable to add this person as a client through this feature at the moment.</p>
			<p>Please go the "About" tab of the room you would like to add this person as a client to, and click the "Add Client" button to add them instead.</p>
		</ModalCommand>
		);
	}

	return (
	<ModalCommand header="Add as Client"
		onHide={cancel} 
		className="medium-modal-command"
		extra={{maximizable:false}}
	>
	<div className="p-grid">

	<div className="p-col-2 label">
		1. Select:
	</div>
	<div className="p-col-10">
	</div>
	
	</div>
	
	<div className="p-grid">

	<div className="p-col-1">
	</div>
	<div className="p-col-2 label">
		A. Room:
	</div>
	<div className="p-col-9">
		<ListBox value={target_rid} options={room_options} onChange={(e) => setTargetRid(e.value)} style={{height: '270px'}} listStyle={{height: '217px'}} filter />
	</div>
	
	</div>

	{target_rid && <div className="p-grid">

	<div className="p-col-1"></div>
	<div className="p-col-2 label">
		B. Client:
	</div>
	<div className="p-col-9">

			<div>
			<RadioButton id="type_client_add_new" 
			name="type_client_add" value="new"
			onChange={e => setTypeClientAdd(e.value)}
			checked={type_client_add === 'new'}
			/>
			<label htmlFor="type_client_add_new"
				className="p-radiobutton-label">
				{em(0.4)}
				New Client Person in this Room
			</label>
			</div>
			
			<div style={{'margin-top': '10px', 'margin-bottom': '10px'}}>
			<RadioButton id="type_client_add_existing" 
				name="type_client_add" value="existing"
			onChange={e => setTypeClientAdd(e.value)}
			checked={type_client_add === 'existing'}
			disabled={(!has_clients_options)}
			/>
			<label htmlFor="type_client_add_existing" 
				className="p-radiobutton-label">
				{em(0.4)}
				Additional email to Existing Client Person
				<br/>
				{(!has_clients_options) && <span>{em(1.5)}(There are no existing clients for this room)</span>}
			</label>
			</div>
		
		{has_clients_options && (type_client_add == 'existing') && <div className="p-grid">
		
		<div className="p-col-7">
		<Dropdown value={target_cid}
			placeholder="Choose Client"
			options={client_options}
			onChange={ e => setTargetCid(e.value) }
			style={{'width': '90%'}}
		/>
		</div>
		{target_person && <div className="p-col-5">
			<ScrollPanel style={{'width': '100%', 'max-height': '100px'}}>
			<div>
				<b>Current emails for {target_person.name}:</b>
			</div>
			{target_person.emails._idlist.map((value, index) => {
				return <div>{target_person.emails._items[value].email}</div>
			})}
			</ScrollPanel>
		</div>}

		</div>}

	</div>
	
	</div>}
	
	{(!target_rid) && <div className="p-grid">
	<div className="p-col-12" style={{height:'99px'}}>
	</div>
	</div>}

	<div className="p-grid">
	
	<div className="p-col-2 label">
		2. Add:
	</div>
	<div className="p-col-9">
		<InputText value={email}
			disabled={true}
			style={{'width': '80%'}}
		/>
	</div>
	
	</div>

	<div className="p-grid">
	
	<div className="p-col-2">
	</div>
	<div className="p-col-10">
		<br/>
		{(!working) && <Button label="Add as Client" icon="pi pi-fw pi-check"
			onClick={e=>update()} />}
		{working && <ProgressSpinner/>}
	</div>
	
	</div>

	</ModalCommand>
	);
}

function ShowConnection(props) {
	const {cid, rid} = props;
	const conn = get_connection(cid);

	return (
	<div className="p-col-4" 
	 onClick={e=>go('room', rid, 'contact', 0, 'edit_connection', cid)} >
	  <div className="p-grid person">
		<div className="p-col-1">
			<i className="fa fa-fw fa-user-o"></i>
		</div>
		<div className="p-col-11">
	   <div className="name" title={conn.name}>
			{conn.name}
		{!conn.name &&
		  <i className="person-name-not-set">Name Not Set</i>}
	   </div>
	   <div className="email" title={conn.email}>
		{conn.email}
	    </div>
	   <div className="role" title={conn.rol}>
		{conn.role && <span>({conn.role})</span>}
	    </div>
	   <div className="comment" title={conn.comment}>
		{conn.comment}
	    </div>
		</div>
	  </div>
	</div>
	);
}

function Connections(props) {
	const {rid} = props;
	const room = get_room(rid);
	const cidarr = [...room.cids].sort();
	const [edit, setEdit] = React.useState(0);

	// log('person', 'Connections', props);

	return (
	<div className="p-grid">
		<div className="p-col-12 right" >
			<Button label="Add Connection"
				icon="pi pi-fw pi-plus"
				onClick={e=>go('room', rid, 'contact', 0,
					'edit_connection', 0)}
				/>
		</div>
		{cidarr.map(x => <ShowConnection cid={x} key={x} rid={rid} />)}
		{cidarr.length == 0 && <div>
			<br/><br/>
			None yet.
			<br/><br/>
			Please choose from Contacts to add a connection.
			<br/><br/>
			Or Add one with the button above.
		</div>}
	</div>
	);
}

/*---- Contact / Add Contact Tab ----*/

const show_kind = person => {
		let name = '';
		if (person.kind == 'v')
			name = 'Ext Contact';
		else if (person.kind == 's')
			name = 'Staff';
		else if (person.kind == 'c')
			name = 'Client';
		if (!name)
			return null;
		return <div className={'person-kind person-' + person.kind}>
			{name}
		</div>;
	}

const is_person_available = person => {
		// cimr_update
		
		let icon, klass;
		if (person.kind == 's') {
			icon = 'pi-user-minus';
			klass = 'person-na';
		}
		else {
			icon = 'pi-user-plus';
			klass = 'person-available';
		}

		return <div className={'p-col-1 ' + klass} >
			<i className={'pi pi-fw ' + icon}></i>
		</div>
	}

function PersonCard(props) {
	// cimr_update
	
	const {pid, rid, setPid} = props;
	const person = pid2person(pid);
	let klass = 'p-grid person ' + ((person.kind == 's') ? 'ptaken' : 'pfree');
	let title = (person.kind == 's') ? 'Unavailable' : '';
	return (
		<div className="p-col-4" onClick={e => setPid(pid)} >
		  <div className={klass} title={title} >
			<div className="p-col-2">
				{is_person_available(person)}
			</div>
			<div className="p-col-10">
		   <div className="name" title={person.name}>
				{person.name}
		   	{!person.name &&
			  <i className="person-name-not-set">Name Not Set</i>}
		   </div>
		   <div className="email" title={person.email}>
		   	{person.email}
		    </div>
			     {show_kind(person)}
			</div>
		  </div>
		</div>
	);
}

function Persons(props) {
	const [first, setFirst] = React.useState(0);

	const root = ldb.data.contacts.persons;
	const order = root._order;
	const total = order.length;
	const perpage = 12;

	const {rid, setPid} = props;
	const pids = order.slice(first, first+perpage);

	return (
	<React.Fragment>
		<div className="p-col-6">
		<Paginator first={first} rows={perpage} totalRecords={total}
			onPageChange={ e => setFirst(e.first) }
		></Paginator>
		</div>

		{pids.map( pid => 
			<PersonCard pid={pid} key={pid} {...props} /> )}
	</React.Fragment>
	)
}

function FilteredPersons(props) {
	const root = ldb.data.contacts.persons;
	const flags = root._flags;
	const [search, setSearch] = React.useState(flags._search_text);

	flags._search_text = search;
	set_order(root);
	resort(root);

	const keyDown = e => (e.key === 'Escape' && setSearch(''))
	
	return (
	<div className="p-grid">
		<div className="p-col-4">

	<InputText value={search} ref={lazyFocus}
		className="person-search" placeholder="Search"
		onChange={e => setSearch(e.target.value)}
		onKeyDown={keyDown}
			/>

		{search && <Button label='' icon='pi pi-fw pi-times'
				onClick={e=>setSearch('')} />}
		<i className="pi pi-fw pi-search"></i>

		</div>
		<div className="p-col-2 persons-legend">
			{props.legend}
		</div>

		<Persons {...props} />
	</div>
	);
}

function addConnection(pid, rid) {
	const person = pid2person(pid);

	const args = {
		cmd: 'mod_connection', 
		rid, cid:0,
		email:person.email, name:person.name,
		role: '', comment:''
	};
	api( args );
}

function Confirm(props) {
	const {onHide, pid, rid} = props;
	if (!pid)
		return null;

	const person = pid2person(pid);
	const room = get_room(rid);
	const header = <span>
			<i className="fa fa-fw fa-address-book-o"></i> 
			Add Connection to 
			<i>
				{room.name}
			</i>
	</span>;
	
	return (
	<ModalCommand header={header}
		onHide={onHide} className="mini-modal-command"
		extra={{maximizable:false}}
	>
		Are you sure you want to 
		add <b>{person.name}</b> (<i>{person.email}</i>) as
		a Contact for this Room?
		<br/><br/>
		<Button label="Add"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				addConnection(pid, rid);
				onHide();
			}} />
	</ModalCommand>
	);
}

function AddContact(props) {
	const [pid, setPid] = React.useState(0);
	const legend = 'Click Contact to Add to Connections';

	return (
	<div>
		<Confirm pid={pid} rid={props.rid} onHide={e=>setPid(0)}/>
		<FilteredPersons {...props} setPid={setPid} 
			legend={legend} />
	</div>
	);
}

function goback() {
	window.history.back();
}

function contact_command(props) {
	const {command, rid, tab, iid, cid} = props;

	if (command)
		log('person', 'Contacts: Command', command, props);

	if (command == 'edit_connection')
		return <EditConnection cid={cid} setEdit={goback} rid={rid} />;

	if (command == 'add_client')
		return <AddAsClient cid={cid} setEdit={goback} rid={rid} />;

	return null;
}

function Contacts(props) {
	const [index, setIndex] = React.useState(0);

	log('person', 'Contacts', props);

	return (
	<React.Fragment>
	{contact_command(props)}
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} >
		<TabPanel header="Contacts" leftIcon="fa fa-fw fa-users">
			<AddContact {...props} />
		</TabPanel>
	</TabView>
	</React.Fragment>
	);
}

/*---- Smart Names ---*/

function env_names(elist) {
	if (!elist.length)
		return null;

	return elist.map( item => email2name(item) ).join(', ');
}

function email2name(item) {
	// Handle names with empty space. That or blank, use email.
	if (item.name.trim())
		return item.name;

	let email = item.email.toLowerCase();
	const client = ldb.data.contacts.clients[ email ];
	const name = client ? client.name.trim(): '';

	item.name = name || item.email;

	// TBD: check connections and persons for name.

	return item.name;
}

function email2name_full(item, i, elist, env, rid) {
	return <SmartName key={i} item={item} elist={elist} env={env} 
				rid={rid} />
}

function pid2names(env, kind) {
	// log('lib', 'pid2names****', env, kind);
	const pidbuf = env[kind + '_pids'];
	const pids = pidbuf.split(',').filter(x => x.length).map(num => parseInt(num,10));

	const root = ldb.data.contacts.persons._items;
	const elist = pids.map(pid => root[pid]);

	return elist;
}

function set_client_user(person) {
	const room = get_room(person.rid);
	if (!room)
		return {id:0, error: 'No access'};
	const client = ldb.data.contacts.clients[ person.email ];
	if (!client)
		return {id:0, error: 'Missing Client Info'};

	person.client_user = room.clients._items[ client.cuid ];
	person.client_email_id = person.client_user.emails._items[client.ceid];
}

function set_client_user_rids_csv(person) {
	// cimr_update
	
	let client = ldb.data.contacts.clients[ person.email ];
	if (!client)
		return {id:0, error: 'Missing Client Info'};
	
	let rid = null;
	let room = null;
		
	let rid_list = person.rids_csv.split(',').slice(1, -1);
	
	for (let i=0; i<rid_list.length; i++) {
		rid = rid_list[i];

		room = get_room(rid);
		if (!room)
			continue;
		try {
			person.client_user = room.clients._items[ client.cuid ];
			person.client_email_id = person.client_user.emails._items[client.ceid];

			return {id:0, error: ''};
		} catch(error) {
		}
	}
	
	return {id:0, error: 'Missing Client Info'};
}

function email2person(email, name) {
	// not efficient.. that's ok for now.
	name = name || '';
	const root = ldb.data.contacts.persons;
	let sel = {email, name, id:0, kind:'u', cx: null};

	const idl = root._idlist;
	for (let i=0, l=idl.length; i<l; i++) {
		let person = root._items[ idl[i] ];
		if (person.email == email) {
			if (person.cid && !person.cx) 
				person.cx = get_connection( person.cid );
			else if (person.kind == 's' && !person.staff)
				person.staff = get_staff(person.objid);
			else if (person.kind == 'c' && !person.client_user) {
				// cimr_update
				if (person.rids_csv.split(',').length > 3) {
					set_client_user_rids_csv(person);
				} else {
					set_client_user(person);
				}
			}
			sel = person;
			break;
		}
	}
	// log('lib', 'email2person', email, sel.id, sel);
	return sel;
}

function email2name_span(item, index) {
	const name = email2name(item);
	return (
		<span key={index + item.email} title={item.email}>
			{name}, </span>
	);
}

function env_name_spans(env, kind, full, rid) {
	const elist = env.is_common ?  env.pinfo[kind] : pid2names(env, kind);

	// log('lib', 'env_name_spans', env, kind, elist);
	
	if (!elist)
		return null;
	
	if (!elist.length)
		return null;

	const fn = full ? email2name_full : email2name_span;

	return <span>
		{elist.map( (item, i) => fn(item, i, elist, env, rid) )}
	</span>;
}

function env_list_kind(env, kind) {
	let elist;

	if (env.pinfo)
		elist =  env.pinfo[kind];
	else {
		const pidbuf = env[kind + '_pids'];
		const pids = pidbuf.split(',').filter(x => x.length).map(
				x => parseInt(x, 10));
		elist = pids.map(pid2person).filter(x => x !== undefined);
	}
	return elist;
}

function env_names_kind(env, kind) {
	const elist = env_list_kind(env, kind);

	return env_names(elist);
}

function getxy(el) {
	window.g_sn = el;
	if (el) {
		log('email', 'getxy+++++++++', el.offsetLeft);
	}
}

function smart_kind(kind) {
	let name = {c: 'Client', s: 'Staff', x: 'Connection'}[kind];
	if (name === undefined)
		name = 'Contact';
	return <div className="skind">
		{name}
	</div>;
}

function person_klass(person) {
	let kind = person.kind;
	if (!kind) {
		if (person.cx)
			kind = 'x';	// connection
		else
			kind = 'u';
	}
	return kind + '-kind';
}

function SmartStaff(props) {
	const {staff, person} = props;

	return <div>
		<div className="skind">
			Staff
		</div>
		{staff.smstel && <div>
			{staff.smstel}
		</div>}
	</div>;
}

function ifdiv(val) {
	return val ? <div>{val}</div> : null;
}

function SmartClient(props) {
	const {client_user, person} = props;
	const url = go_url('room', person.rid, 'summary', 0, 'EditClient',
				client_user.id);
	const ceid = person.client_email_id;

	return <div>
		<div className="skind">
			Client
		</div>
		{ifdiv( client_user.smstel )}
		{ifdiv( ceid.comment )}
		<div className="sn-edit">
			<Link to={url}>
				<i className="pi pi-fw pi-pencil"></i>
				Edit
			</Link>
		</div>
		<div className="sn-edit">
			<Link to="#" onClick={function() {
				copy_to_clipboard(person.email);
				growl('Copied ' + person.email + ' to clipboard');
				return false;
			}}>
				<i className="fa fa-fw fa-clipboard"></i>
				{em(0.25)}
				Copy
			</Link>
		</div>
	</div>;
}

function SmartConnection(props) {
	const {connection, person, rid} = props;
	const url = go_url('room', rid, 'contact', 0, 
				'edit_connection', connection.id);

	return <div>
		<div className="skind">
			Connection
		</div>
		{ifdiv( connection.role )}
		{ifdiv( connection.comment )}
		<div className="sn-edit">
			<Link to={url}>
				<i className="pi pi-fw pi-pencil"></i>
				Edit
			</Link>
		</div>
	</div>;
}

function set_mkroom_clients(env) {
	const {clients} = ldb.data.rooms.draft;
	const emails = new Set(clients.map(item => item.email));

    ['from','to','cc','bcc'].forEach(kind => {

	const elist = env.is_common ?  env.pinfo[kind] : pid2names(env, kind);

	elist.forEach(item => {
		const person = email2person(item.email, item.name);
		log('person', 'set_mkroom_clients', person);
		if (person && 
			(person.kind == 'u' || person.kind == '') && 
				!emails.has(item.email)) {
			clients.push({name: person.name, emails: [person.email],
				csel: '', xid: gen_xid(), pids: [person.id] });
			person.kind = 'c';
		}
	});
    });
}

function SmartContact(props) {
	const {person, elist, env, rid} = props;

	return <div>
		<div className="skind">
			Contact
		</div>
		<div className="sn-edit">
			<Link to='/add/create_room'
				onClick={e=>set_mkroom_clients(env)} >
				<i className="pi pi-fw pi-plus"></i>
				Create Room
			</Link>
		</div>
		{ldb.data.me.is_org_admin && <div className="sn-edit">
			<Link to={go_url('room',rid,'contact',0,
					'add_client',person.id)}
				onClick={e=>console.log('Add Client')}>
				<i className="pi pi-fw pi-plus"></i>
				Add as Client
			</Link>
		</div>}
	</div>;
}

function person_detail(person, elist, env, rid) {
	if (person.staff)
		return <SmartStaff staff={person.staff} 
				person={person} />;

	if (person.client_user)
		return <SmartClient client_user={person.client_user} 
				person={person} />;

	if (person.cx)
		return <SmartConnection connection={person.cx} 
				person={person} rid={rid} />;
	
	return <SmartContact person={person} elist={elist} env={env} 
				rid={rid} />;
}

function SmartName(props) {
	const {item, elist, env, rid} = props;
	const name = email2name(item);
	const person = email2person(item.email, item.name);
	const [left, setLeft] = React.useState(0);

	let klass = left < 200 ? 'smart-right' : 'smart-left';
	klass += ' ' + person_klass(person);

	const detail = person_detail(person, elist, env, rid);

	return (
	<div className="smart-name" 
		ref={el => {if (el) setLeft(el.offsetLeft)}}
	>
		{name}
		<div className={"smart-hover " + klass}>
			<div className="sname">
				{name}
			</div>
			<div className="semail">
				{item.email}
			</div>
			<div className="smart-detail">
				{detail}
			</div>
		</div>
	</div>
	);
}

export {Contacts, FilteredPersons,
	env_names_kind, env_list_kind, pid2names,
	env_names, email2person, env_name_spans,
	set_mkroom_clients,
};

