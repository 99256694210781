import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';

import {deleteDraft} from './NewEmail';
import {vrecover_draft, vrm_draft} from './UnsavedDrafts';

import {ldb, log, go, go_url, get_room, api, set_current_list_view,
	get_task, show_is_new, list_next_id, is_in_viewport,
	fulldate, fulldatetime, fulldatetimehuman, durhuman,
	resort, edate, edate2, edate3, get_tag, url2room,
	set_order, is_within_one_day, has_passed,
	durhuman_with_title, get_my_most_recent_activity,
	url2iid, cap, map_list, display_readable_bytes} from './Lib';



function show_recent_room(rid, i) {
	const room = get_room(rid);
	if (!room)
		return null;
	
	let klass = i % 2 ? ' oddrow' : ' evenrow';
	
	const recent_activity = get_my_most_recent_activity(room);
	if (!recent_activity)
		return null;
	
	const last_visited = durhuman_with_title(recent_activity.dt_last_read);	
	const last_tab = cap(recent_activity.tab);

	return (<div className={"p-grid recent-room " + klass} key={"recent_room_" + i}
		onClick={e => go('room', rid)}
		>
		<div className="p-col-6">
			{room.name}
		</div>
		<div className="p-col-3">
			{last_tab}
		</div>
		<div className="p-col-3">
			{last_visited}
		</div>
	</div>);
}

function RecentRooms(props) {
	const ROOMS_SHOWN = 10;

	const root = JSON.parse(JSON.stringify(ldb.data.rooms));
	root._flags.sortby = 'visited';
	
	set_order(root);
	resort(root, 'room');
	
	if (root.length == 0) {
		return <div className="card">
			You have no recently visited rooms
		</div>
	}
	
	return (<div className="card">
		<div className="p-grid recent-room-header">
			<div className="p-col-6 hdr">
				Room
			</div>
			<div className="p-col-3 hdr">
				Last Tab
			</div>
			<div className="p-col-3 hdr">
				Last Visited
			</div>
		</div>
		{root._order.slice(0, ROOMS_SHOWN).map( (rid, i) => show_recent_room(rid, i) )}
	</div>)
}

function get_recent_drafts() {
	const draft_list = [];

	ldb.data.rooms._order.forEach(function(rid) {
		const room = get_room(rid);
		for (let d in room.shared.drafts) {
			draft_list.push({'room': room, 'draft': room.shared.drafts[d]});
		}
	});
	
	return draft_list;
}

function show_recent_draft(draft_data, i, par_reload) {
	const rid = draft_data.rid;
	const iid = draft_data.iid;
	
	const room = get_room(rid);
	if (!room)
		return null;
	
	let klass = i % 2 ? ' oddrow' : ' evenrow';
	
	return (<div className={"p-grid recent-draft " + klass} key={"recent_draft_" + i}
		>
		<div className="p-col-4 recent-draft-room" onClick={e => go('room', rid, 'compose', iid)}
		>
			{room.name}
		</div>
		<div className="p-col-5" onClick={e => go('room', rid, 'compose', iid)}
		>
			{draft_data.subject}
		</div>
		<div className="p-col-2 recent-draft-date" onClick={e => go('room', rid, 'compose', iid)}
		>
			{durhuman_with_title(draft_data.dt_updated)}
		</div>
		<div className="p-col-1">
			{display_readable_bytes(draft_data.size, 0, true)}
			{' '}
			<Link to='#' 
				title="Delete Draft"
			onClick={e => deleteDraft(rid, iid, par_reload)}>
				<i className="fa fa-fw fa-times"></i>
			</Link>
		</div>
	</div>);
}

function show_recent_recovered_draft(draft, i, par_reload) {
	log('vault', 'RECENT', draft);
	if (!draft)
		return null;
	
	const rid = draft.rid;
	const iid = draft.iid;
	
	const room = get_room(rid);
	if (!room)
		return null;
	
	let klass = i % 2 ? ' oddrow' : ' evenrow';
	
	return (<div className={"p-grid recent-draft " + klass} key={"recent_recovered_draft_" + i}
		>
		<div className="p-col-4 recent-draft-room" onClick={e => vrecover_draft(draft)}
		>
			{room.name}
		</div>
		<div className="p-col-5" onClick={e => vrecover_draft(draft)}
		>
			{draft.subject}
		</div>
		<div className="p-col-2 recent-draft-date" onClick={e => vrecover_draft(draft)}
		>
			{durhuman_with_title(draft.vsave_time)}
		</div>
		<div className="p-col-1">
			{display_readable_bytes(draft.html.length, 0, true)}
			{' '}
			<Link to='#' 
				title="Delete Draft"
				onClick={() => {vrm_draft(draft);par_reload();}}>
				<i className="fa fa-fw fa-times"></i>
			</Link>
		</div>
	</div>);
}

function RecentRecoveredDrafts(props) {
	const [refresh, setRefresh] = React.useState(0);
	
	const root = window.g_dvault.get_all();
	
	if (root.length == 0) {
		return <div className="card">
			You have no recovered email drafts
		</div>
	}
	
	const reload = () => setRefresh(refresh+1);
	
	return (<div className="card">
		<div style={{marginBottom: '15px', fontSize: 'large'}}>Recovered Compose Emails</div>
		<div className="p-grid recent-draft-header">
			<div className="p-col-4 hdr">
				Room
			</div>
			<div className="p-col-5 hdr">
				Subject
			</div>
			<div className="p-col-2 hdr">
				Created
			</div>
			<div className="p-col-1 hdr">
				Size
			</div>
		</div>
		{root.map((draft, i) => show_recent_recovered_draft(draft, i, reload))}
	</div>);
}

function RecentDrafts(props) {
	const [refresh, setRefresh] = React.useState(0);
	const [loading, setLoading] = React.useState(true);

	if (loading) {
		const args = {
			cmd: 'get_recent_drafts', 
		}
		log('email', 'doGetRecentDrafts', args);
		ldb.data.recent_drafts = undefined;
		api( args, function() {
			setLoading(false);
		}, function() {
			setLoading(false);
		});

		return (<div className="card">Checking for recent drafts...<br/><ProgressSpinner /></div>);
	}

	const root = ldb.data.recent_drafts;
	//const flags = root._flags;
	//
	//flags._filter_fn = propSearch;
	//if (flags.sortby === undefined)
	//	flags.sortby = ldb.data.me.settings.rooms_sortby;
	
	set_order(root);
	resort(root, 'draftshell');

	if (root.length == 0) {
		return (<div>
			<div className="card">
			You have no drafts
			</div>
			{ldb.data.org.settings.save_emails_locally && <RecentRecoveredDrafts />}
		</div>);
	}

	const reload = () => setRefresh(refresh+1);

	return (<div>
		<div className="card">
		<div className="p-grid recent-draft-header">
			<div className="p-col-4 hdr">
				Room
			</div>
			<div className="p-col-5 hdr">
				Subject
			</div>
			<div className="p-col-2 hdr">
				Created
			</div>
			<div className="p-col-1 hdr">
				Size
			</div>
		</div>
		{map_list(root, (draft_data, i) => show_recent_draft(draft_data, i, reload) )}
		</div>
	
		{ldb.data.org.settings.save_emails_locally && <RecentRecoveredDrafts />}
	</div>);
}

export {RecentRooms, RecentDrafts};
