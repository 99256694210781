import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {Dropdown} from 'primereact/dropdown';

import UserList from './UserList';

import {ldb, log, get_staff, priorities, text2html, go_url, edate3, edate2,
	show_is_new, task_category_options, blogdate,
	get_first_name, edate, cap, growl, edate4, 
	is_within_one_day, has_passed} from './Lib';
import {Spacer} from './Utils';

function ids2names(ids) {
	return ids.map(sid => get_staff(sid).name).join(', ');
}

function show_name(sid, cls) {
	if (!sid)
		return null;

	const name = get_staff(sid).name;
	cls = cls || ''

	return <span title={name} key={sid} className={cls}>
		{get_first_name(name)}&nbsp;
		</span>;
}

const Staff = props => {
	const {sid, plain, me} = props;

	if (!sid)
		return null;
	
	const cn = plain ? '' : 'staff';

	const name = me == sid ? 'self' : get_staff(sid).name;

	return <span title={name} key={sid} className={cn} >
		{get_first_name(name)}&nbsp;
	</span>;
}

const ShowDate = props => <span className="date">{edate4(props.date)}</span>

function task_priority_name(pri) {
	let name = '';

	priorities.forEach(item => {if (item.value == pri) name = item.label});

	return name;
}

function task_priority(pri) {
	const name = task_priority_name(pri);
	if (!name)
		return null;

	return <span className={"task-pri-" + name}>{name}</span>;
}

const TaskStatus = props => props.task.dt_done ?
	<React.Fragment>
		<i className="pi pi-check"></i>
		<span className="task-status">Done</span>
	</React.Fragment>
		:
	<React.Fragment>
		<i className="fa fa-hourglass-half"></i>
		<span className="task-status">Active</span>
	</React.Fragment>

/* Task Note Display --->>> */

// quoted text.. slightly diff text, diff font ? 
const Qtext = props => <span className="qtext">"{props.text}{props.children}"</span>

function from_delta(sid, by) {
	if (!sid)
		return null;

	return <React.Fragment key='note_fd'>
		from <Staff sid={sid} plain={true} me={by} />
	</React.Fragment>
}

function to_delta(delta, note) {
	return <React.Fragment key='note_to'>
		assigned it {from_delta(delta.from, note.by)} to <Staff sid={delta.to} me={note.by} />
	</React.Fragment>
}

function by_delta(delta, note) {
	return <React.Fragment key='note_by'>
		assigned it {from_delta(delta.from, note.by)} to <Staff sid={delta.to} me={note.by} />
	</React.Fragment>
}

function name_delta(delta) {
	return <React.Fragment key='not_nd'>
		subject from
		<Qtext text={delta.from} />
		to 
		<Qtext text={delta.to} />
	</React.Fragment>;
}

function category_delta(delta) {
	return <React.Fragment key='note_cd'>
		category from <Qtext text={delta.from} /> to <Qtext text={delta.to} />
	</React.Fragment>;
}

function priority_delta(delta) {
	return <React.Fragment key='note_pd'>
		priority from
		<Qtext text={task_priority_name(delta.from)} />
		to
		<Qtext text={task_priority_name(delta.to)} />
	</React.Fragment>;
}

function dt_due_delta(delta) {
	return <React.Fragment key='note_ddd'>
		due Date from <Qtext><ShowDate date={delta.from} /></Qtext>
		{' '}
		to <Qtext><ShowDate date={delta.to} /></Qtext>
	</React.Fragment>
}

function main_note_action(note) {
	let action = note.action + ' the task';
	action += note.deltas.length ? ': ' : '.';
	return <React.Fragment key='note_mna'>
		<Staff sid={note.by} />
		{action}
	</React.Fragment>
}

function last_note_action(note) {
	let action = note.action + ' the task';
	const ndl = note.deltas.length;
	action += ndl ? ': ' : '.';
	if (note.action == 'updated' && !ndl) 
		action = 'added: ' + note.note.slice(0,24) + '...';
	return <React.Fragment key='note_lna'>
		<Staff sid={note.by} />
		{action}
	</React.Fragment>
}

const delta_handlers = {to : to_delta, by : by_delta, name: name_delta,
	category: category_delta, priority: priority_delta,
	dt_due: dt_due_delta};

const NoteAction = props => {
	const {note} = props;
	const deltas = note.deltas;
	// log("task", "note_action ", note, deltas);

	return <div className="note-action">
		{main_note_action(note)}
		{deltas.map(delta => delta_handlers[delta.prop](delta, note))}
	</div>
}

const LastNoteAction = props => {
	const {task} = props;
	const note = JSON.parse(task.notes)[0];
	if (!note.deltas)
		note.deltas = [];
	const deltas = note.deltas;
	// log("task", "note_action ", note, deltas);

	return <div className="note-action">
		{last_note_action(note)}
		{deltas.map(delta => delta_handlers[delta.prop](delta, note))}
	</div>
}

const ShowTaskNote = props => {
	const {note} = props;

	if (!note.note.trim())
		return null;

	return <div className="note-note">
		{text2html(note.note)}
	</div>;
}

function show_note(note, index, fullwidth) {
	let wdt = 'p-col-3';
	let wnote = 'p-col-9';
	if (fullwidth) {
		wdt = 'p-col-1';
		wnote = 'p-col-11';
	}

	if (!note.deltas)
		note.deltas = [];

	// log("task", "show_note ", note);

	return <div key={index} className="p-grid task-note">
		<div className={wdt + " p-col-align-start"}>
			{blogdate(note.date)}
		</div>

		<div className={wnote} >
			<NoteAction note={note} />
			<ShowTaskNote note={note} />
		</div>
	</div>
}



/* Task Note Display <<<--- */

const EmailTask = props => <div className="linked-tasks">
	<Link to={go_url('room', props.rid, 'shared', props.eid)} >
		Linked Email
		{' '}
	</Link>
</div>

const TaskPriCat = props => <div>
	{task_priority_name(props.task.priority) != 'Normal' &&
	<div>
		<span className="colname"> Priority: </span>
			{task_priority(props.task.priority)}
	</div>}
	</div>

const ActiveTask = props => <div className="p-grid">
		<div className="p-col-6">
	<span className="colname">Assigned To: </span>
			<span className="task-note-author">
	{show_name(props.task.to_sid)}
			</span>
		</div>
		<div className="p-col-6">
	<span className="colname">Due: </span>
		{edate4(props.task.dt_due)}

		<TaskPriCat {...props} />

		</div>
	</div>

const CompletedTask = props => <div className="p-grid">
		<div className="p-col-8">
			<span className="task-note-author">
				{show_name(props.task.to_sid)}
			</span>
			Completed
		</div>

		<div className="p-col-4">
	<span className="colname">On: </span>
		{edate4(props.task.dt_done)}
		<br/>
	<span className="colname">Due: </span>
		{edate4(props.task.dt_due)}

		<TaskPriCat {...props} />

		</div>
	</div>

class TaskDetailHeader extends Component {
	// One property
	//	task

	render = () => {
		const task = this.props.task;

		if (!task)
			return null;
		
		return (
	<div className="item-detail task task-detail-header">
		{task.dt_done ? 
			<CompletedTask task={task} />
			:
			<ActiveTask task={task} />}

		<div className="p-grid">
			<div className="p-col-2 colname">
				Subject: 
			</div>
			<div className="p-col-10">
				{task.name}
			</div>
		</div>

		<ShowFullTaskCategory cat={task.category} />

		<div className="p-grid ">
			<div className="p-col-12 colname center">
				{' '}
			</div>
		</div>
	</div>
		)
	}
}

class FullTask extends Component {
	// One property
	//	task
	
	constructor(props) {
		super(props);
	}
	
	render = () => {
		const {tab, task, par} = this.props;
		const notes = JSON.parse(task.notes);
		const options = task_category_options();

		task._seen = Date.now();

		log("room", "full_task ", task.id);

		return (
		<React.Fragment>
		{(task.eid > 0) && <EmailTask eid={task.eid} rid={task.rid} />}
	<div ref={el => (par.printEl = el)}>
	<TaskDetailHeader task={task} tab={tab} />
	<div className="item-detail task">
		{notes.map((note, index) => show_note(note, index))}

	</div>
	</div>
		</React.Fragment>
		)
	}
} 

const PriorTaskNotes = props => {
	const notes = JSON.parse(props.task.notes);

	return <div className="p-grid">
		<label className="p-col-2">Previous Notes</label>
		<div className="p-col-10 task-prior-notes">
			{notes.map((note, index) => show_note(note, index))}
		</div>
	</div>
}


const ShowFullTaskCategory = props => props.cat ? 
	<div className="p-grid">
		<div className="p-col-2 colname">
			Category:
		</div>
		<div className="p-col-10">
			{props.cat}
		</div>
	</div>
	: null

const ShowTaskCategory = props => props.cat ? 
	<span className="task-category"> [{props.cat}]</span> : null

// Badge showing colored circle to indicate status
const TaskDoneIcon = props => {
	const task = props.task;
	if (task.dt_done)
		return <span title="done" className="task-done" >
			&#10003;
		</span>

	let dot_class = 'task-not-done';
	if (has_passed(task.dt_due)) 
		dot_class = 'task-past-due';
	else if (is_within_one_day(task.dt_due)) 
		dot_class = 'task-almost-due';

	return <span className={dot_class} title={dot_class} >
		&#x2B24;
	</span>
}

class LinkedTaskHeader extends Component {
	// 3 properties:
	//	tid
	//	selected (true or false)
	//	onSelect (callback)  

	empty = () => {}

	render() { 
		const {task, is_new, is_mine, iid} = this.props;
		const sel = (iid == task.id);
		const onSelect = this.props.onSelect || this.empty;
		const last_sid = task.to_sid || task.by_sid;
		const bold = last_sid == ldb.data.me.id ? ' bold' : '';
		let klass = sel ? 'sel-list-item' : '';
		
		/*
		if ((is_mine) && (!task.dt_done)) {
			klass = 'task-my-header';
		klass = 'task-header ' + klass;
		*/
		
		const priClass = 'task-pri-' + 
			task_priority_name(task.priority);
		
		log("task", "show_task_header ", task, sel, task.id, iid);
		return (
		<div key={task.id} data-task_id={task.id}
			onClick={onSelect} 
			className={klass}
			>
	<div className="p-grid topline" >
		<div className="p-col-4">
			<span className="bold">
				{show_name(task.to_sid)}
			</span>
		</div>
		<div className={"p-col-4"} >
			{show_name(task.by_sid)}
		</div>
		<div className="p-col-3" >
			<TaskDoneIcon task={task} />
			{' '}
			{edate4(task.dt_due)}
			{" "}
			{show_is_new(is_new)}
		</div>
		<div className={"p-col-1 " + priClass} >
			{'#' + task.id + ' '}
		</div>
	</div>
	<div className="p-grid" >
		<div className="p-col-1">
		</div>
		<div className="p-col-11">
			{task.name}
			{' '}
			<ShowTaskCategory cat={task.category} />
		</div>
	</div>
		</div>
		)
	}
}

const TaskListLegend = () => (
		<div >
	<div className="p-grid topline" >
		<div className="p-col-4 bold">
			Assigned To
		</div>
		<div className="p-col-4" >
			Updated By
		</div>
		<div className="p-col-3" title="Date Due">
			Due Date
		</div>
		<div className="p-col-1" >
			#
		</div>
	</div>
	<div className="p-grid" >
		<div className="p-col-1">
		</div>
		<div className="p-col-11">
			Subject
			[Category]
		</div>
	</div>
		</div>
		)

export {FullTask, LinkedTaskHeader, TaskStatus, TaskListLegend, Staff,
	PriorTaskNotes, TaskDetailHeader, TaskDoneIcon,
	LastNoteAction, show_note, show_name, task_priority}; 
