import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import UpdateTask from './UpdateTask';
import DoneTask from './DoneTask';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Checkbox} from 'primereact/checkbox';
import {TabView, TabPanel} from 'primereact/tabview';
import {SelectButton} from 'primereact/selectbutton';

import {show_name, task_priority, TaskStatus, show_note, Staff,
	TaskDoneIcon, LastNoteAction} from './TaskUtil';

import {ldb, log, go, go_url, get_room, api, set_current_list_view,
	get_task, show_is_new, list_next_id, is_in_viewport,
	fulldate, fulldatetime, fulldatetimehuman, durhuman,
	resort, edate, edate2, edate3, get_tag, url2room,
	set_order, is_within_one_day, has_passed,
	get_task_by_me, url2iid, edate6, em} from './Lib';

function sort_arrow(field, sortby, reverse) {
	if (field != sortby)
		return null;
	if (reverse)
		return <span>&uArr;</span>;
	return <span>&dArr;</span>;
}

const recent_ms = 15 * 60 * 1000;	// 15 minutes in millisec

const TaskRecent = props  => {
	const {dt_updated} = props.task;

	const ms = window.g_moment().diff( dt_updated );

	if (ms > recent_ms)
		return null;
	
	const title = window.g_moment.duration(ms).humanize();
	
	return show_is_new(true, title, 'recent');
}

class MyTaskLegend extends Component {
	constructor(props) {
		super(props);
		this.state = {has_new: false};
		window.g_myTasksLegend = this;
	}

	got_new = () => {
		this.setState({has_new: true});
	}

	refresh = e => {
		this.setState({has_new:false});
		this.props.par.reload();
	}

	render() {

	const {par} = this.props;
	const pstate = par.state;
	const {set_sortby} = par;
	let {sortby, reverse} = par.state;

	return (
<div className="p-grid grey bold mytask-legend" >
	<div className="p-col-1">
		Assigned To
	</div>
	<div className="p-col-1">
		Last Updated By
	</div>
	<div className="p-col-1">
		Subject
		<div className="recent-note-action non-bold">
			Last Action
		</div>
	</div>
	<div className="p-col-2 small non-bold">
	  <div className="flags">
		<Checkbox checked={pstate.flag_pending} 
		    onChange={e => par.setState({flag_pending: e.checked})}
			/> Active <br/>
		<Checkbox checked={pstate.flag_completed} 
		    onChange={e => par.setState({flag_completed: e.checked})}
			/> Done <br/>
	 </div>
	</div>
	<div className="p-col-1">
		{this.state.has_new && <div className="center">
			<div className="xsmall non-bold">
				Got Updates 
			</div>
			<Button label="Refresh" 
				onClick={this.refresh}
			/>
			</div>
		}
	</div>
	<div className="p-col-2">
		Room
	</div>
	<div className="p-col-1">
		Category
	</div>
	<div className="p-col-1">
		Priority
	</div>
	<div className="p-col-1">
		<div>
			Due 
		</div>
	</div>
	<div className="p-col-1"
		style={{paddingLeft:'2em'}}>
		ID
	</div>
</div>
	)
	}
}

// TBD: Once we fetch my tasks at login, we dont' need to fetch them again
// 	since Task updates are multicasted to us.
// TBD: However, task_multicast listener is only active in tasks tab
// 	should be active all the time and update these tasks.

//	onClick={() => go('room', task.rid, 'task', task.id)}

const MyTaskHeader = props => {
	// 2 properties:
	//	task
	//	rname (room name)

	const {task, par} = props;
	const rname = get_room(task.rid).name;

	// log("task", "show_task_header ", task);
	
	let now = window.g_moment().valueOf();
	let klass = '';
	
	if ((task.dt_due) && (!task.dt_done)) {
		let dt_due = new Date(task.dt_due).getTime();
		let tdiff = now - dt_due;
		console.log('TASK', task.id, tdiff, now, dt_due);
		if (tdiff > 0) {
			klass = ' task-past-due-bg';
		} else if (tdiff > - (2 * 24 * 60 * 60 * 1000)) { 
			klass = ' task-almost-due-bg';
		}
	}
	
	return (
<div className={"mytask-header" + klass} >
	<div className="p-grid" >
		<div className="p-col-1" >
			<Staff sid={task.to_sid} />
		</div>
		<div className="p-col-1" >
			<Staff sid={task.by_sid} />
		</div>
		<div className="p-col-4" >
			{task.name}
			<div className="recent-note-action">
				<LastNoteAction task={task} />
			</div>
		</div>
		<div className="p-col-2">
			{rname}
		</div>
		<div className="p-col-1" >
			{task.category}
		</div>
		<div className="p-col-1 " >
			{task_priority(task.priority)}
		</div>
		<div className="p-col-1">
			<div title="Due Date">
				{edate6(task.dt_due)}
			</div>
		</div>
		<div className="p-col-1">
			<TaskDoneIcon task={task} />
			{' #' + task.id + ' '}
			<TaskRecent task={task} />
		</div>
	</div>
</div>
	)
}

const TaskDates = props => {
	const {task} = props;

	return <div>
	<div className="p-grid">
		<div className="p-col-1">
			Due
		</div>
		<div className="p-col-11">
			{fulldate(task.dt_due)}
		</div>
	</div>

	<div className="p-grid">
		<div className="p-col-1">
			Created
		</div>
		<div className="p-col-11">
			{fulldatetime(task.dt_added)}
			{durhuman(task.dt_added)}
		</div>
	</div>

	<div className="p-grid">
		<div className="p-col-1">
			Updated
		</div>
		<div className="p-col-11">
			{fulldatetime(task.dt_updated)}
			{durhuman(task.dt_updated)}
		</div>
	</div>

	{task.dt_done && <div className="p-grid">
		<div className="p-col-1">
			Completed
		</div>
		<div className="p-col-11">
			{fulldatetime(task.dt_done)}
			{durhuman(task.dt_done)}
		</div>
	</div>}


	</div>
}

const MyTaskDetail = props => {
	const {task} = props;

	log('mytasks', 'detail', task);

	const notes = JSON.parse(task.notes);
	return <div className="mytask-detail">
		<TaskDates task={task} />
		{notes.map((note, index) => 
			show_note(note, index, true))}
	</div>
}

function sp(e){ 
	e.stopPropagation(); 
	return true ;
}

const MyTaskMenu = props => {
	const {task, par} = props;

	return <div className="mytask-menu " >
		Actions: 

		<Button 
			label="Collapse" 
			icon="pi pi-fw pi-chevron-up" 
			className="p-button-secondary" 
			onClick={e => sp(e) && par.set_detail(null)}
		/>

		<Button 
			label="Update" 
			icon="pi pi-fw pi-pencil" 
			className="p-button-secondary" 
			onClick={e => sp(e) && par.set_detail('update')}
		/>

		{!task.dt_done && 
			<Button 
				label="Done" 
				icon="pi pi-fw pi-check" 
				className="p-button-secondary" 
				onClick={e => sp(e) && par.set_detail('done')}
			/>
		}

		<Button 
			label="See Task in Room" 
			icon="pi pi-fw pi-external-link" 
			className="p-button-secondary" 
		  onClick={e => sp(e) && go('room', task.rid, 'task', task.id)}
		/>
	</div>;
}

window.g_myTasks_save = {tid: 0, detail: null};

class TasksForMe extends Component {
	constructor(props) {
		super(props);
		this.state = {nrefresh:0, 
			sortby: props.sort_by,
			target: props.target,
			reverse: props.sort_desc, 
			tid:0, 		// selected task id, 0 otherwise
			ntid : 0,	// next tid, 
			detail: null,	// null | 'yes' | 'update' | 'done'
			flag_pending: true,
			flag_completed: false,
			search_text: '',
		};

		this.sel_el = null;	// to scroll into view
		Object.assign(this.state, window.g_myTasks_save);
		// this.state.tid = 104; 		// debug
		// this.state.detail = 'yes'; 	// debug
		window.g_myTasks = this;
		// window.g_myTasks_scroll_position = 0;
		
		const list = (this.state.target == 'by_me') ? ldb.data.tasks_by_me : ldb.data.tasks;
		list._flags.sortby = this.state.sortby;
		list._flags.reverse = this.state.reverse;
	}

	componentDidMount = () => {
		this._isMounted = true;
		const {tsubject} = this.props.match.params;
		if (tsubject)
			window.g_appTopbar.setSearch(tsubject);
		log('task', 'mytask ++Mount');
		this.fetch();
		set_current_list_view(this, {rid:0, tab:'mytasks'});

		// this.restore_scroll_position();
	}

	componentDidUpdate(prevProps) {
		set_current_list_view(this, {rid:0, tab:'mytasks'});

		if (this.sel_el)
			this.sel_el.scrollIntoView(true);
		
		// if (this.sel_el && !is_in_viewport(this.sel_el)) 
			// this.sel_el.scrollIntoView(true);

		// this.restore_scroll_position();

		if (prevProps.sort_by != this.props.sort_by) {
			const list = (this.state.target == 'by_me') ? ldb.data.tasks_by_me : ldb.data.tasks;
			list._flags.sortby = this.props.sort_by;
			this.setState({sortby: this.props.sort_by});
		}
		
		if (prevProps.sort_desc != this.props.sort_desc) {
			const list = (this.state.target == 'by_me') ? ldb.data.tasks_by_me : ldb.data.tasks;
			list._flags.reverse = this.props.sort_desc;
			this.setState({reverse: this.props.sort_desc});
		}
	}

	componetWillUnmount() {
		this._isMounted = false;
		set_current_list_view(null);
	}

	reload = () => {
		this.setState({nrefresh: this.state.nrefresh+1});
	}

	fetched = (error, db) => {
		this.setState({nrefresh: this.state.nrefresh+1});
	}

	fetch = () => {
		const args = {cmd: 'get_mytasks'};
		api( args, this.fetched );
	}

	set_sortby = field => {
		let list = (this.state.target == 'by_me') ? ldb.data.tasks_by_me : ldb.data.tasks;
		let {sortby, reverse} = this.state;
		if (sortby == field) {
			reverse = !reverse;
		}
		else {
			reverse = false;
			sortby = field;
		}
		list._flags.sortby = sortby;
		list._flags.reverse = reverse;
		resort(list);
		if (sortby == 'priority') {
			list = list.reverse();
		}
		log("task", "resort my tasks ", sortby, reverse);
		this.setState({sortby, reverse});
	}

	update_search = search_text => this.setState({search_text})

	save_scroll_position = () => 
		window.g_myTasks_scroll_position = this.el.scrollTop;

	restore_scroll_position = () => 
		this.el.scrollTop = window.g_myTasks_scroll_position || 0;

	toggle_tid = new_tid => {
		let {tid, detail} = this.state;
		if (tid != new_tid) {
			tid = new_tid;
			detail = 'yes';
		}
		else if (detail)
			detail = null;
		else
			detail = 'yes';

		window.g_myTasks_save = {tid, detail};
		this.setState({tid, detail});
		log("mytasks", 'switch', window.g_myTasks_save);
		return;
	}

	set_detail = detail => {
		window.g_myTasks_save.detail = detail;
		log("mytasks", 'switch', window.g_myTasks_save);
		this.setState({detail})
	}
	
	key_down = e => {
		let delta = 0;
		if (e.keyCode == 38)	// up arrow
			delta = -1;
		if (e.keyCode == 40)	// down arrow
			delta = 1;

		if (!delta)
			return;

		const root = (this.state.target == 'by_me') ? ldb.data.tasks_by_me : ldb.data.tasks;
		let {tid, detail} = this.state;

		tid = list_next_id(root, tid, delta);

		log('mytasks', 'arrow move', delta, tid);

		if (!tid)
			return;	// no valid next/prev

		if (!detail)
			detail = 'detail';

		this.setState({tid, detail});
	}

	close_update = () => this.setState({detail:'yes'})

	is_na = (id, task) => {		// is not applicable
		task = task || (this.state.target == 'by_me') ? get_task_by_me(id) : get_task(id);

		// Likely user completed task, or reassigned it.
		//	If it is no longer applicable, skip it.

		return !task || task.dt_done || task.to_sid != ldb.data.me.id;
	}

	show_task = (id, curtid, detail) => {
		const task = (this.state.target == 'by_me') ? get_task_by_me(id) : get_task(id);
		let sel = '';

		// if (this.is_na(id, task))
			// return null;

		if (id == curtid) 
			sel = 'sel';
		else
			detail = '';
		
		let klass = '';
		if (!task.dt_done) {
			klass = ' task-my-header';
		} else {
			klass = ' task-done-header';
		}
		
		if ((!task.dt_done) && (is_within_one_day(task.dt_due))) {
			klass = klass + ' task-header-almost-due';
		} else {
			if ((!task.dt_done) && (has_passed(task.dt_due))) {
				klass = klass + ' task-header-past-due';
			}
		}
		
		return <div className={'mytask ' + sel } key={id}
			>

		<div onClick={()=> this.toggle_tid(id)} 
				ref={el => {if (detail) this.sel_el = el}}
				tabIndex="0"
				onKeyDown={this.key_down}
			>


			{detail && 
				<MyTaskMenu task={task} par={this} />
			}

			<MyTaskHeader task={task} 
				par={this}
				className={sel}
			/>

			{detail && 
				<MyTaskDetail task={task} par={this} />
			}

		</div>

			{detail == 'update' && 
				<UpdateTask
					item={task}
					close={this.close_update}
				/>
			}

			{detail == 'done' && 
				<DoneTask
					item={task}
					close={this.close_update}
				/>
			}

		</div>
		
	}

	filter_tasks = task => {
		const {flag_pending, flag_completed} = this.state;

		if (flag_pending && flag_completed)
			return true;

		if (flag_pending)
			return !task.dt_done;

		if (flag_completed)
			return task.dt_done;

		return false;
	}

	render() {
		// let {tid} = this.props.match.params;
		let {tid, detail, search_text} = this.state;
		const {tsubject} = this.props.match.params;
		const root = (this.state.target == 'by_me') ? ldb.data.tasks_by_me : ldb.data.tasks;
		root._flags._search_text = search_text;
		root._flags._filter_fn = this.filter_tasks;

		set_order(root);
		resort(root);
		const order = root._order;
		this.sel_el = null;
		let title = 'Tasks for me (' + order.length + ')';
		if (tid)
			title += ' - #' + tid;

		// window.g_setTitle( title );
		// window.g_searchBar.set_target_comp(this);

		if (order.length == 0) {
			return <div className="card" >
				<MyTaskLegend par={this} />
				None.
			</div>
		}

		return <div className="card mytasks-wrapper tasktab">
			<MyTaskLegend par={this} />
			<div className="mytasks-list"
				ref={el => {this.el = el}}
				>
			{order.map(id => this.show_task(id, tid, detail))}
			</div>
		</div>
	}
}

class TasksByMe extends Component {
	constructor(props) {
		super(props);
		this.state = {nrefresh:0, 
			sortby: props.sort_by, reverse: false, 
			tid:0, 		// selected task id, 0 otherwise
			ntid : 0,	// next tid, 
			detail: null,	// null | 'yes' | 'update' | 'done'
			flag_pending: true,
			flag_completed: false,
			search_text: '',
		};
		this.sel_el = null;	// to scroll into view
		Object.assign(this.state, window.g_myTasks_save);
		// this.state.tid = 104; 		// debug
		// this.state.detail = 'yes'; 	// debug
		window.g_myTasks = this;
		// window.g_myTasks_scroll_position = 0;
			
		const list = ldb.data.tasks_by_me;
		list._flags.sortby = this.state.sortby;
	}

	componentDidMount = () => {
		this._isMounted = true;
		const {tsubject} = this.props.match.params;
		if (tsubject)
			window.g_appTopbar.setSearch(tsubject);
		log('task', 'mytask ++Mount');
		this.fetch();
		set_current_list_view(this, {rid:0, tab:'mytasks'});

		// this.restore_scroll_position();
	}

	componentDidUpdate() {
		set_current_list_view(this, {rid:0, tab:'mytasks'});

		if (this.sel_el)
			this.sel_el.scrollIntoView(true);

		// if (this.sel_el && !is_in_viewport(this.sel_el)) 
			// this.sel_el.scrollIntoView(true);

		// this.restore_scroll_position();
	}

	componetWillUnmount() {
		this._isMounted = false;
		set_current_list_view(null);
	}

	reload = () => {
		this.setState({nrefresh: this.state.nrefresh+1});
	}

	fetched = (error, db) => {
		this.setState({nrefresh: this.state.nrefresh+1});
	}

	fetch = () => {
		const args = {cmd: 'get_tasks_by_me'};
		api( args, this.fetched );
	}

	set_sortby = field => {
		const list = ldb.data.tasks_by_me;
		let {sortby, reverse} = this.state;
		if (sortby == field) {
			reverse = !reverse;
		}
		else {
			reverse = true;
			sortby = field;
		}
		list._flags.sortby = sortby;
		list._flags.reverse = reverse;
		resort(list);
		log("task", "resort my tasks ", sortby, reverse);
		this.setState({sortby, reverse});
	}

	update_search = search_text => this.setState({search_text})

	save_scroll_position = () => 
		window.g_myTasks_scroll_position = this.el.scrollTop;

	restore_scroll_position = () => 
		this.el.scrollTop = window.g_myTasks_scroll_position || 0;

	toggle_tid = new_tid => {
		let {tid, detail} = this.state;
		if (tid != new_tid) {
			tid = new_tid;
			detail = 'yes';
		}
		else if (detail)
			detail = null;
		else
			detail = 'yes';

		window.g_myTasks_save = {tid, detail};
		this.setState({tid, detail});
		log("mytasks", 'switch', window.g_myTasks_save);
		return;
	}

	set_detail = detail => {
		window.g_myTasks_save.detail = detail;
		log("mytasks", 'switch', window.g_myTasks_save);
		this.setState({detail})
	}
	
	key_down = e => {
		let delta = 0;
		if (e.keyCode == 38)	// up arrow
			delta = -1;
		if (e.keyCode == 40)	// down arrow
			delta = 1;

		if (!delta)
			return;

		const root = ldb.data.tasks_by_me;
		let {tid, detail} = this.state;

		tid = list_next_id(root, tid, delta);

		log('mytasks', 'arrow move', delta, tid);

		if (!tid)
			return;	// no valid next/prev

		if (!detail)
			detail = 'detail';

		this.setState({tid, detail});
	}

	close_update = () => this.setState({detail:'yes'})

	is_na = (id, task) => {		// is not applicable
		task = task || get_task_by_me(id);

		// Likely user completed task, or reassigned it.
		//	If it is no longer applicable, skip it.

		return !task || task.dt_done || task.to_sid != ldb.data.me.id;
	}

	show_task = (id, curtid, detail) => {
		const task = get_task_by_me(id);
		let sel = '';

		// if (this.is_na(id, task))
			// return null;

		if (id == curtid) 
			sel = 'sel';
		else
			detail = '';
		
		let klass = '';
		if (!task.dt_done) {
			klass = ' task-my-header';
		} else {
			klass = ' task-done-header';
		}
		
		if ((!task.dt_done) && (is_within_one_day(task.dt_due))) {
			klass = klass + ' task-header-almost-due';
		} else {
			if ((!task.dt_done) && (has_passed(task.dt_due))) {
				klass = klass + ' task-header-past-due';
			}
		}
		
		return <div className={'mytask ' + sel } key={id}
			>

		<div onClick={()=> this.toggle_tid(id)} 
				ref={el => {if (detail) this.sel_el = el}}
				tabIndex="0"
				onKeyDown={this.key_down}
			>


			{detail && 
				<MyTaskMenu task={task} par={this} />
			}

			<MyTaskHeader task={task} 
				par={this}
				className={sel}
			/>

			{detail && 
				<MyTaskDetail task={task} par={this} />
			}

		</div>

			{detail == 'update' && 
				<UpdateTask
					item={task}
					close={this.close_update}
				/>
			}

			{detail == 'done' && 
				<DoneTask
					item={task}
					close={this.close_update}
				/>
			}

		</div>
		
	}

	filter_tasks = task => {
		const {flag_pending, flag_completed} = this.state;

		if (flag_pending && flag_completed)
			return true;

		if (flag_pending)
			return !task.dt_done;

		if (flag_completed)
			return task.dt_done;

		return false;
	}

	render() {
		// let {tid} = this.props.match.params;
		let {tid, detail, search_text} = this.state;
		const {tsubject} = this.props.match.params;
		const root = ldb.data.tasks_by_me;
		root._flags._search_text = search_text;
		root._flags._filter_fn = this.filter_tasks;

		set_order(root);
		resort(root);
		const order = root._order;
		this.sel_el = null;
		let title = 'Tasks for me (' + order.length + ')';
		if (tid)
			title += ' - #' + tid;

		// window.g_setTitle( title );
		// window.g_searchBar.set_target_comp(this);

		if (order.length == 0) {
			return <div className="card" >
				<MyTaskLegend par={this} />
				None.
			</div>
		}

		return <div className="card mytasks-wrapper tasktab">
			<MyTaskLegend par={this} />
			<div className="mytasks-list"
				ref={el => {this.el = el}}
				>
			{order.map(id => this.show_task(id, tid, detail))}
			</div>
		</div>
	}
}

window.g_mytasks_sortby = 'id'; 
window.g_mytasks_sortdesc = true; 

function MyTasks(props) {
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);
	const [index, setIndex] = React.useState(0);
	const [subIndex, setSubIndex] = React.useState(0);
	const [sortBy, setSortBy] = React.useState(window.g_mytasks_sortby);
	const [sortDesc, setSortDesc] = React.useState(window.g_mytasks_sortdesc);
	
	const sort_by_options = [
		{'name': 'ID', 'value': 'id'},
		{'name': 'Date Due', 'value': 'dt_due'},
		{'name': 'Priority', 'value': 'priority'},
	];

	const sort_option_template = function(option) {
		return <div style={{fontSize:'small'}}>{option.name}</div>;
	}

	const order_by_options = [
		{'name': 'desc', 'value': true, 'icon': 'pi pi-fw pi-sort-alpha-down-alt'},
		{'name': 'asc', 'value': false, 'icon': 'pi pi-fw pi-sort-alpha-up-alt'},
	]
	
	const order_option_template = function(option) {
		return <div style={{fontSize:'small'}}><i style={{fontSize:'small'}} className={option.icon}></i></div>;
	}
	
	const buttons = (
	<div className="p-grid" style={{marginBottom:'1em'}}>
		<div className="p-col-12">
			{em(4)}
			<div style={{display:'inline-block', verticalAlign:'top', paddingTop:'7px', fontSize:'small', color:'#999'}}>
				Sort By: 
			</div>
			{em(1)}
			<div style={{display:'inline-block'}}>
				<SelectButton value={sortBy} options={sort_by_options} optionLabel="name" itemTemplate={sort_option_template} className="sort-by-select-button" onChange={e => {
					if (e.value) {
						window.g_mytasks_sortby = e.value;
						setSortBy(e.value);
					}
				}} />
			</div>
			{em(2)}
			<span style={{display:'inline-block', verticalAlign:'top', paddingTop:'7px', fontSize:'small', color:'#999'}}>
				Order: 
			</span>
			{em(1)}
			<div style={{display:'inline-block'}}>
				<SelectButton value={sortDesc} options={order_by_options} optionLabel="name" itemTemplate={order_option_template} className="sort-by-select-button" onChange={e => {
					if (e.value !== null) {
						window.g_mytasks_sortdesc = e.value;
						setSortDesc(e.value);
					}
				}} />
			</div>
		</div>
	</div>
	);
		
	if ((ldb.data.me.settings.show_tasks_help) && (ldb.data.tasks._idlist.length == 0) && (ldb.data.tasks_by_me._idlist.length == 0)) {
		return (
		<Card>
			<p>
				This page displays the tasks you have been assigned by other staff members, as well at the tasks you have assigned to others. You currently have no tasks assigned to you.
			</p>
			<p>
				Tasks are work items that staff can assign to each other. They can (optionally) be associated with a particular shared email. Once you have completed your portion of the work item, you can mark the task as complete or reassign it to another staff member with an on update on the status of the task.
			</p>
		</Card>
		);
	}

	return (
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} >
		<TabPanel header="Tasks for me" 
				leftIcon="fa fa-fw fa-level-down">
			{buttons}
			<TasksForMe {...props} reload={reload} sort_by={sortBy} sort_desc={sortDesc} target="for_me" />
		</TabPanel>
		<TabPanel header="Tasks by me" 
				leftIcon="fa fa-fw fa-level-up">
			{buttons}
			<TasksForMe {...props} reload={reload} sort_by={sortBy} sort_desc={sortDesc} target="by_me" />
		</TabPanel>
	</TabView>
	);
}

function minimytask(tid, i, sel) {
	const task = get_task(tid);
	if (task.dt_done)
		return null;
	const room = get_room(task.rid);
	const klass = sel == tid ? 'mini-sel' : '';
	return (
	<li key={i} >
		<Link to={go_url('room', task.rid, 'task', task.id)} 
					className={klass} >
			&#x25b8;
			{' '}
			{task.name}
			<div className="task-room">
				({room.name})
			</div>
		</Link>
	</li>
	);
}

class MiniMyTasks extends Component {
	componentDidUpdate() {
		const room = url2room();
		if (room)
			window.g_setTitle(room.name);	

		const sel = document.querySelector('.mini-sel');
		if (sel)
			sel.scrollIntoView(false);
	}

	render() {
		const root = ldb.data.tasks;
		root._flags._search_text = this.props.search;
		set_order(root);
		resort(root);
		const order = root._order;

		if (root._idlist.length == 0)
			return 'None';
	
		const sel = url2iid();
		console.log('MINITASK', sel);
		
		return (
		<ScrollPanel className="mini-list">
		<ul>
			{order.map((tid, i) => minimytask(tid, i, sel))}
		</ul>
		</ScrollPanel>
		);
	}
}

export {MyTasks, MiniMyTasks, MyTaskDetail};
