import React from 'react'; 
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import {Redirect} from 'react-router-dom';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {Checkbox} from 'primereact/checkbox';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {TabView, TabPanel} from 'primereact/tabview';

//**-------- SunEditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './suneditor-override.css';


import sanitizeHtml from 'sanitize-html';
import * as moment from 'moment';

import {TaskSubject, EditNote, EditBody, TaskCommand,} from './EditTask';
import EmailTo from './EmailTo';
import {decode_mime, map_inline_attachments} from './EmailUtil';
import {Attach, Spacer, ModalCommand, Focusable} from './Utils';
import {FilteredPersons} from './Persons';
import {vsave_draft, vsave_draft_body, vsave_draft_subject, vrm_draft, vget_draft} from './UnsavedDrafts';


import {ldb, log, get_room, get_list, growl, api, get_body_part, p2br, go,
	get_envdb_persons, regcomp, pid2person, validate_email, myatob,
	clean_email, ispin, get_item, drafts_tab, list_rm_ele, gen_xid,
	foreach_list, get_envdb_to_persons, get_envdb_cc_persons,
	get_envdb_from_person, get_first_name, em, email2person,
	go_url, map_list, set_order, env_names, edate, get_signature_of_room,
	html2plain, mbox_write_perm, get_room_team_email_display_name,
	display_readable_bytes, list_rm_item, mbox_read_perm,
	room_has_team_email, show_sandbox, org_admins_display,
	check_for_draft_shell, delete_draft_shell,
	get_default_editor_style_settings} from './Lib';
import {env_names_kind, env_list_kind, pid2names, } from './Persons';
import {lazyFocus} from './Utils';


const check_errors = draft => {
	const {subject, plain, html} = draft;

	if ( (subject.trim().length + plain.trim().length) == 0 ) {
		growl('Error', 
	'Please choose subject and/or message', 'error');
		return true;
	}

	// Attempt to prevent embedded images copy/pasted into the editor
	//if (html.includes('src="data:image/png;base64')) {
	//	growl('Error', 'You may have embedded images copy-pasted into this message. These may not display properly in client emails and should be removed', 'error');
	//	return true;
	//}	

	return false;
}

const confirm_no_recipients = draft => {
	//return window.confirm('You are about to send an email with no direct recipients');

	return true;
}

function clean_b64data(buf) {
	return buf.replace(/%0a/ig, '').replace(/%0d/ig, '');
}

function mod_img_src(imgsrc, inlines) {
	// src="data:image/jpeg;base64, /9jsdfsd...."
	const myre = /src="data:(.*);(.*),\s?([^"]*)"/im;
	const parts = myre.exec(imgsrc);

	// log('email', 'inlines', imgsrc, parts);

	if (!parts)
		return imgsrc;


	const cid = gen_xid() + '_' + inlines.length;
	const kind = parts[1];
	const b64data = clean_b64data(parts[3]);
	const ext = kind.split('/')[1] || '.data';
	const name = cid + '.' + ext;

	inlines.push({cid, kind, b64data, name});

	return 'src="cid:' + cid + '"';
}

function mod_inline_image(img, inlines) {
	if (img.slice(0, 4).toLowerCase() != '<img')
		return img;


	const exp = /(src="data:[^"]*")/mig; 	// " balance
	const parts = img.split(exp);

	// log('email', 'found.image', img, parts);

	img = parts.map(part => mod_img_src(part, inlines)).join('');

	return img;
}

function sep_inlines(ohtml) {
	const myre= /(<img[^>]*>)/mig;
	const parts = ohtml.split(myre);

	let html = '';
	let inlines = [];

	// log('email', 'inline.splits', parts);

	html = parts.map(part => mod_inline_image(part, inlines)).join('');

	return {html, inlines};
}



window.g_draft_subject_save_timer = null;
window.g_draft_body_save_timer = null;

function clear_draft_save_timers() {
	if (window.g_draft_subject_save_timer) {
		clearTimeout(window.g_draft_subject_save_timer);
		window.g_draft_subject_save_timer = null;
	}

	if (window.g_draft_body_save_timer) {
		clearTimeout(window.g_draft_body_save_timer);
		window.g_draft_body_save_timer = null;
	}
}

window.draft_cache = {};

function save_draft_in_cache(draft) {
	let {rid, iid} = draft;
	
	const draft_data = JSON.stringify(draft);
	
	if (!(rid in window.draft_cache)) {
		window.draft_cache[rid] = {}
	}
	
	window.draft_cache[rid][iid] = draft_data;
}

function delete_draft_in_cache(rid, iid) {
	//remove_draft_from_local_storage(rid, iid);
	
	if ((!(rid in window.draft_cache)) || (!(iid in window.draft_cache[rid])))  {
		return;
	}
	
	delete window.draft_cache[rid][iid];
}

function get_draft_data_from_cache(rid, iid) {
	if ((!(rid in window.draft_cache)) || (!(iid in window.draft_cache[rid])))  {
		return null;
	}
	
	return window.draft_cache[rid][iid];
}

function validate_draft_html(draft, context, html, plain) {
	const trunc = 1000;

	try {
		if ((plain.length > 0) && (html.length == 0)) { 
			log('email', '+++++++++++++', 'email', context + '_html_error', draft, html.slice(0, trunc), html.length, plain.slice(0, trunc), plain.length);
		} else {
			log('email', context + '_html', draft, html.slice(0, trunc), html.length, plain.slice(0, trunc), plain.length);
		}
	} catch(e) {
	}	
}

// Add a cache (by room ID and IID) to save the last
// json draft string. If it is the same, then don't
// send the save draft request.
const saveDraft = (draft, onSaveDraft, setAutosaveMsg, setAutosaveModal, setAutosaveModalError, manualSave) => {
	const write_perm = mbox_write_perm();
	
	if (!write_perm) {
		return;
	}
	
	const me = ldb.data.me;
	
	let {rid, iid, version_number} = draft;
	
	if (!version_number) {
		version_number = 1;
	}
	
	const old_data = get_draft_data_from_cache(rid, iid) 
	const draft_data = JSON.stringify(draft);
	if (draft_data == old_data) {
		return;
	}
	
	// If no content, don't save the draft
	//if (((draft.plain == '') || (draft.plain == ('\n\n' + me.plain_signature))) && (draft.subject == '') && (draft.files == []))  {
	//	return;
	//}
	
	validate_draft_html(draft, 'save_draft', draft.html, draft.plain);
	
	clear_draft_save_timers();

	draft.nsave++;
	// TBD: If draft is null, delete draft on server
	const args = {
		cmd: 'save_draft', 
		rid, iid, draft,
		version_number
	}
	log('email', 'doSaveDraft', args);
	api( args, onSaveDraft, onSaveDraft );
	
	save_draft_in_cache(draft);
	
	if (window.g_compose_refresh) {
		setAutosaveMsg('Saving draft...');
		if (manualSave) {
			setAutosaveModalError(false);
			setAutosaveModal(true);
		}
	}
}

const deleteDraft = (rid, iid, callback) => {
	const args = {
		cmd: 'delete_draft', 
		rid, iid,
	}
	log('email', 'doDeleteDraft', args);
	api( args, callback, callback);
	
	try {
		delete_draft_in_cache(rid, iid);
	} catch(e) {
	}
	
	try {
		delete_draft_shell(rid, iid)
	} catch(e) {
	}
	
	try {
		if (ldb.data.recent_drafts) {
			let draftid = null;
			foreach_list(ldb.data.recent_drafts, (item, id) => {
				if ((item.rid == rid) && (item.iid == iid)) {
					draftid = id;
				}
			});
			
			if (draftid) {
				list_rm_item(ldb.data.recent_drafts, draftid);
			}
		}
	} catch(e) {
	}
}

const autoGenerateResponse = (draft, autoGenerateResponseComplete, setGeneratingResponse) => {
	let {rid, iid} = draft;
	
	const args = {
		cmd: 'auto_generate_response', 
		rid, iid,
	}
	log('email', 'autoGenerateResponse', args);

	setGeneratingResponse(true);
	api( args, autoGenerateResponseComplete, autoGenerateResponseComplete );
}

const doSend = (draft, onSent) => {
	let {tos, ccs, bccs, subject, plain, html, creating, files, 
		rid, reply_to, references, high_importance, is_private} = draft;

	html = fix_sig(rid, html);

	const img_split = sep_inlines(html);
	html = img_split.html;
	let inlines = img_split.inlines;

	// Only send payload part of each file
	files = files.map(file => file.payload);
	
	validate_draft_html(draft, 'send_email', html, plain);
	
	const args = {
		cmd: 'send_email', 
		tos, ccs, bccs, subject, plain, html, rid, 
		reply_to, references, files, inlines, high_importance,
		is_private,
	}
	log('email', 'doSend', args);
	api( args, onSent, onSent );
}

const sent = (error, db, draft) => {
	if (error) {
		const message = "An error occurred: " + error + 
		". Please notify TagInbox. Some operations may have been partially successful. Retry may not be successful."
		alert(message);
		return;
	}

	log('email', 'sent+++', draft);

	const {rid, iid} = draft;
	const room = get_room(rid);
	//const tab = draft.etab ? draft.etab : drafts_tab(room);
	const tab = drafts_tab(room);
	const rtab = room[tab];
	const ntab = (draft.is_private || room.is_mailbox) ? 'unshared' : 'shared';
	
	vrm_draft(rtab.drafts[iid]);
	
	delete rtab.drafts[iid];
	list_rm_ele(rtab._flags.pending_draft_iids, iid);

	const latest = db.meta.new_id;
	const scroll_iid = iid || latest;
	if (iid)
		growl('Email is being sent');
	
	go('room', rid, ntab, scroll_iid);
}

function CcBccRows({draft, reload}) {
	return (
		<React.Fragment>
	<div className="p-grid">
	<div className="p-col-1">
		Cc:
	</div>
	<div className="p-col-11">
		{show_ccs_mini(draft, reload)}
	</div>
	</div>

	<div className="p-grid">
	<div className="p-col-1">
		Bcc:
	</div>
	<div className="p-col-11">
		{show_bccs_mini(draft, reload)}
	</div>
	</div>
		</React.Fragment>
	);
}

function to_summary_extra(draft, nrest) {
	if (nrest <= 0)
		return null;

	let buf = '';	// breakdown of To/Cc/Bcc. Only show if Cc|Bcc > 0
	if (draft.ccs.length) 
		buf += ', Cc: ' + draft.ccs.length;
	
	if (draft.bccs.length) 
		buf += ', Bcc: ' + draft.bccs.length;

	if (buf)
		buf = <span className="to-small-extra">
			{'(To: ' + draft.tos.length + buf + ')'}
		</span>;

	return <span> and <b>{nrest}</b> more. {buf}</span>;
}

function show_tos_summary(draft, reload, setToMode) {
	const all = [...draft.tos, ...draft.ccs, ...draft.bccs];
	const nmax = 3;
	/*
	if (all.length <= (nmax+1))
		return show_tos_mini(draft, reload);
		*/

	const names = all.slice(0,nmax);
	const nrest = all.length - names.length;

	return <div className="new-email-cc-div">
		{names.map((item, i) => <span key={i}
			className={"to-micro to-" + item.kind} 
			title={item.name + ' <' + item.email + '>'}
			>
				{get_first_name(item.name) || item.email }
				</span>)}
		{to_summary_extra(draft, nrest)}
		{em(2)}
		<Button icon="pi pi-fw pi-plus"
			onClick={e => setToMode('L')} />
	</div>;
}

function ToRows({draft, toMode, setToMode}) {
	// To Modes:
	//	s - one line summary (1 name, and 5 more recipients, ..)
	//	m - To Row only
	//    	l - To, Cc, Bcc
	//	xl - To, Cc, Bcc, no scroll bars for each, show all, 
	//		one scroll bar for entire section.

	/*
	const modes = ['s','m','L'].map(m => ({label: m, value:m}));
		<div className="new-email-cc-div">
			<SelectButton value={toMode} options={modes}
				onChange={e => setToMode(e.value)} />
		</div>
	*/

	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);

	const toggleMode = () => {
		// s -> m -> L -> s
		// Exception:
		//	s -> L -> s
		//	if, there are cc/bc
		let mode = '';
		if (toMode == 'm')
			mode = 'L';
		else if (toMode == 'L')
			mode = 's';
		else if (toMode == 's') {
			if (draft.ccs.length || draft.bccs.length)
				mode = 'L';
			else
				mode = 'm';
		}
		setToMode(mode);
	}

	return (
		<React.Fragment>
	<div className="p-grid">
	<div className="p-col-1 new-email-cc-div">
		<Button icon="fa fa-fw fa-expand" 
			tooltip="Contract/Expand Cc/Bcc"
			onClick={toggleMode}
		/>
		To:
	</div>
	<div className="p-col-11">
		{toMode == 's' ? 
			show_tos_summary(draft, reload, setToMode) :
			show_tos_mini(draft, reload)}
	</div>
	</div>

	{toMode == 'L' && <CcBccRows draft={draft} reload={reload} />}

		</React.Fragment>
	);
}

function EmailSubject({draft}) {
	const [subject, setSubject] = React.useState(draft.subject);
	
	React.useEffect(() => {
		setSubject(draft.subject);
	});

	return (
	<InputText value={subject} 
		ref={lazyFocus}
		onChange={e => {
			draft.subject = e.target.value;
			setSubject(draft.subject);
			
			if (!window.g_draft_subject_save_timer) {
				window.g_draft_subject_save_timer = setTimeout(function () {
					vsave_draft_subject(window.g_draft);
					window.g_draft_subject_save_timer = null;
				}, 5000)
			}
		}}
		onKeyDown={e => {
			if (e.key === 'Tab') {
				//alert('Tab');
				//e.preventDefault();
			}
		}}
	/>
	);
}

function useInterval(callback, delay) {
	const savedCallback = React.useRef();
	
	// Remember the latest callback.
	React.useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);
	
	// Set up the interval.
	React.useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

window.g_compose_refresh = null;

function sun_style_tweak(html) {
	// Apply table inline styles

	html = html.replaceAll('<table>', '<table style="border-collapse:collapse;">');
	html = html.replaceAll('<th>', '<th style="border:1px solid #999;">');
	html = html.replaceAll('<td>', '<td style="border:1px solid #999;">');

	return html;
}

function sun_style_exclude_existing_tables(html) {
	// Don't modify tables existing tables before we start editing
	//	So when we reply/forward/... someone else's tables,
	//	lets not put border around them. Makes them look wierd.
	// To distinguis them from our own tables, 
	//	add extra space for tags.

	html = html.replaceAll('<table>', '<table >');
	html = html.replaceAll('<th>', '<th >');
	html = html.replaceAll('<td>', '<td >');

	return html;
}

function Compose(props) {
	const {draft, par_reload, mobile, par} = props;
	const [refresh, setRefresh] = React.useState(0);
	const [working, setWorking] = React.useState(false);
	const [autosave_msg, setAutosaveMsg] = React.useState(null);
	const [autosave_modal, setAutosaveModal] = React.useState(false);
	const [autosave_modal_error, setAutosaveModalError] = React.useState(false);
	const [recovery_draft_attachments_modal, setRecoveryDraftAttachmentsModal] = React.useState(draft.alert_attach_items);
	const [local_storage_unavailable_modal, setLocalStorageUnavailableModal] = React.useState(ldb.data.local_storage_unavailable_alert);
	const [generating_response, setGeneratingResponse] = React.useState(false);
	const sunInstance = React.useRef();

	const reload = () => setRefresh(refresh+1);

	const onSaveDraft = (error, db) => {
		if (error) {
			setAutosaveModalError(true);
			return;
		}

		const m = 'Draft saved at ' + moment().format('h:mm a');
		
		let {iid, rid} = draft;
		
		vrm_draft(draft);
		//remove_draft_from_local_storage(rid, iid);

		if (window.g_compose_refresh) {
			setAutosaveMsg(m);
			setAutosaveModal(false);
			setAutosaveModalError(false);
		}
	}
	
	const autoGenerateResponseComplete = (error, db) => {
		console.log('autoGenerateResponseComplete', error, db);
		
		if (error) {
			growl('Error', 'Error generating response: ' + error, 'error');
			return;
		}

		growl('Generated response');

		let {iid, rid} = draft;
		const room = get_room(rid);
		const item_root = draft.is_private ? room['unshared'] : room[drafts_tab(room)];
		const item = iid ? item_root._items[iid] : null;
		
		draft.html = '';
		draft.plain = '';
		
		if (item) {
			inc_body(draft, item);
		}

		draft.html =  plain2html(db.response) + '<br/><br/>' + draft.html;
		draft.plain = db.response + '\n\n' + draft.plain;

		vsave_draft(draft);
		
		setGeneratingResponse(false);
		setRefresh(refresh+1);
	}
	
	React.useEffect(() => {
		validate_draft_html(draft, 'load_email', draft.html, draft.plain);
		
		return function cleanup() {
			window.g_compose_refresh = null;
			
			clear_draft_save_timers();
			
			if ((!working) && (!draft.sent) && (!draft.deleted)) {
				saveDraft(draft, onSaveDraft, setAutosaveMsg, setAutosaveModal, setAutosaveModalError);
			}
		};
	}, []);
	
	// Save the draft every three minutes
	useInterval(() => {
		saveDraft(props.draft, onSaveDraft, setAutosaveMsg, setAutosaveModal, setAutosaveModalError);
	}, 3 * 60 * 1000);

	window.g_compose_refresh = reload;

	const write_perm = mbox_write_perm();

	const sendicon = working ? 'pi-spin pi-spinner' : 'pi-envelope';
	const onBody = e => {
		draft.html = e.htmlValue;
		draft.plain = e.textValue;
		vsave_draft(draft);
		auto_to_mode();
		// reload();
	};
	const onSent = (error, db) => {
		setWorking(false);
		sent(error, db, draft);

		let {rid, iid} = draft;

		draft.sent = true;

		rm_draft(rid, iid);
		// close();
	}
	const onSend = e => {
		if (working)
			return;
		
		if (check_errors(draft))
			return;
		
		if (!confirm_no_recipients(draft))
			return;
		
		doSend(draft, onSent);
		setWorking(true);
	}
	const rm_draft = (rid, iid) => {
		const room = get_room(rid);
		//const tab = draft.etab ? draft.etab : drafts_tab(room);
		const tab = drafts_tab(room);
		const rtab = room[tab];
		const ntab = draft.is_private ? 'unshared' : 'shared';
		
		if (rtab.drafts[iid]) {
			vrm_draft(rtab.drafts[iid]);
		}

		delete rtab.drafts[iid];
		list_rm_ele(rtab._flags.pending_draft_iids, iid);
		
		deleteDraft(rid, iid);
		return ntab;
	}
	const onCancel = e => {
		if (working)
			return;

		draft.deleted = true;
		
		log('email', 'cancel', draft);
		let {rid, iid} = draft;
		const tab = rm_draft(rid, iid);
		setToMode( initToMode() );
		go('room', rid, tab, iid);
	}
	const onReset = e => {
		if (working)
			return;
		
		draft.deleted = true;
	
		log('email', 'reset', draft);
		let {rid, iid} = draft;
		rm_draft(rid, iid);
		setToMode( initToMode() );
		if (draft.is_reply_all) {
			go('room', rid, 'compose', iid, 'reply_all');
		} else {
			go('room', rid, 'compose', iid);
		}
		reload();
		par_reload();
	}
	const acknowledgeRecoveryAttachments = e => {
		draft.alert_attach_items = false;
		draft.saved_attach_items = draft.attach_items;
		draft.attach_items = [];
		vsave_draft(draft);
		
		setRecoveryDraftAttachmentsModal(false);
	}
	const acknowledgeLocalStorageUnavailable = e => {
		ldb.data.local_storage_unavailable_alert = false;
		
		setLocalStorageUnavailableModal(false);
	}
	const onManualDraftSave = e => {
		if (working)
			return;
		
		saveDraft(props.draft, onSaveDraft, setAutosaveMsg, setAutosaveModal, setAutosaveModalError, true);
	}
	const onAutoGenerateResponse = e => {
		if (working)
			return;
		
		autoGenerateResponse(props.draft, autoGenerateResponseComplete, setGeneratingResponse);
	}
	const initToMode = () => {
		let mode = draft.to_mode;
		if (mode === undefined) {
			if (draft.ccs.length || draft.bccs.length)
				mode = 'L';
			else
				mode = 'm';
			draft.to_mode = mode;
		}
		return mode;
	}
	const [toMode, rawSetToMode] = React.useState(initToMode);

	const setToMode = mode => {
		draft.to_mode = mode;
		rawSetToMode(mode);
	}

	const auto_to_mode = () => {
		// When user starts typing in Subject or Body,
		//	set to-mode to small, for mini laptops, to give 
		//	more room in editor window.
		if (window.is_mini_laptop && toMode != 's')
			setToMode('s');
	}

	window.g_Compose = props;

	let label = 'Send';
	let can_cancel = true;

	if (draft.is_forward)
		label = 'Forward';
	else if (draft.is_reply)
		label = 'Send Reply';
	else
		can_cancel = false;
	
	let team_email_display_name = '';
	const show_from_row = ((!mbox_read_perm()) && room_has_team_email(get_room(draft.rid)));
	if (show_from_row) {
		team_email_display_name = get_room_team_email_display_name(get_room(draft.rid));
	}
	
	if (mobile) {
		// No Send, Reset button row. Mobile header has those buttons.
		// 	hook par.action.handler, to get OnSend.
		// Also, No Cc/Bcc.  And row heights change. 
		par.action.handler = onSend;

		return <div className="p-grid mobile-compose">
		{show_from_row && <React.Fragment>
		<div className="p-col-2">
			From:
		</div>
		<div className="p-col-10">
			{team_email_display_name}
		</div>
		</React.Fragment>}

		<div className="p-col-2">
			To:
		</div>
		<div className="p-col-10">
			{show_tos_mini(draft, reload, true)}
		</div>
		
		<div className="p-col-2">
			Subject:
		</div>
		<div className="p-col-10 wide-input">
			<InputText value={draft.subject} 
				ref={lazyFocus}
			  onChange={e => {
				draft.subject = e.target.value;
				vsave_draft_subject(draft);
				reload();
				}} />
		</div>

		<div className="p-col-12 wide-input">
			<EditBody value={draft.html} mobile={true}
				onTextChange={onBody} />
		</div>
			
		</div>;
	}

	const editor_klass = toMode == 'L' ? 'edit-compressed' : null;
	const cc_icon = toMode == 'L' ? 'fa-compress' : 'fa-expand';

	let sun_height = {
		s : "calc(100vh - 400px)",
		m : "calc(100vh - 450px)",
		L : "calc(100vh - 566px)",
	}
	// If showing the from row, we need different editor height calculations
	if (show_from_row) {
		sun_height = {
			s : "calc(100vh - 437px)",
			m : "calc(100vh - 487px)",
			L : "calc(100vh - 605px)",
		}
	}

		// <EditBody value={draft.html} onTextChange={onBody} 
			// editor_klass={'to-mode-' + toMode} />

	// log('email', 'compose', draft.html);



	let left_button_options = [];
	let right_button_options = []

	
	//if (ldb.data.org.use_ai_features && ldb.data.me.settings.show_ai_features && (draft.iid != 0)) {
	//	left_button_options.push({icon: 'fa fa-fw fa-magic', value: 'AI Draft'});
	//}
		
	if (write_perm) {
		left_button_options.push({icon: 'fa fa-fw fa-floppy-o', value: 'Save'});	
	}
	
	right_button_options.push({icon: 'pi pi-fw pi-refresh', value: 'Reset'});	
	
	if (can_cancel) {
		right_button_options.push({icon: 'pi pi-fw pi-times', value: 'Cancel'});
	}
	
	const button_template = (option) => {
		return <React.Fragment><i className={option.icon}></i>{em(0.5)}{option.value}</React.Fragment>;
	}
	
	const buttonPress = e => {
		if (e.value == 'Cancel') {
			onCancel(e);
		}
		if (e.value == 'AI Draft') {
			onAutoGenerateResponse(e);
		}
		if (e.value == 'Save') {
			onManualDraftSave(e);
		}
		if (e.value == 'Reset') {
			onReset(e);
		}
	} 
	
	const default_editor_style_settings = get_default_editor_style_settings();
	console.log('DEFAULT EDITOR', default_editor_style_settings); 
	
	// PC Version
	return (
<div className="compose">
	{show_from_row && <div className="p-grid">
	<div className="p-col-1">
		From:
	</div>
	<div className="p-col-11">
		{team_email_display_name}
	</div>
	</div>}
	
	<ToRows draft={draft} toMode={toMode} setToMode={setToMode} />

	{false && <div className="p-grid">
	<div className="p-col-1">
		High Importance:
	</div>
	<div className="p-col-11">
		<Checkbox onChange={e=> {
				draft.high_importance = (!draft.high_importance);
				vsave_draft(draft);
				reload();
			}}
			checked={draft.high_importance}
		/>
	</div>
	</div>}

	{ldb.data.org.settings.allow_sending_private_emails && <div className="p-grid">
	<div className="p-col-1">
		Private Email:
	</div>
	<div className="p-col-11">
		<Checkbox onChange={e=> {
				draft.is_private = (!draft.is_private);
				vsave_draft(draft);
				reload();
			}}
			checked={draft.is_private}
		/>
	</div>
	</div>}
	
	<div className="p-grid">
	<div className="p-col-1">
		Subject:
	</div>
	<div className="p-col-11 wide-input">
		<EmailSubject draft={draft} />
	</div>
	</div>

	<div className="p-grid">
	<div className="p-col-1">
		Note:
	</div>
	<div className="p-col-11 wide-input">
		<SunEditor 
			key={refresh}
			height={sun_height[toMode]}
			setDefaultStyle={default_editor_style_settings}
			setContents={
	sun_style_exclude_existing_tables( draft.html )
			} 
			onChange={ content => {
				draft.html = sun_style_tweak(content);
				// draft.plain = e.textValue;
				// if (sunInstance && sunInstance.current) {
					// draft.plain = sunInstance.current.getText();
				// }
				let plain = html2plain(draft.html);
				plain = plain.replace(/\[data:image\/.*?\]/gim, '');
				draft.plain = plain;
				
				window.g_draft = draft;
				auto_to_mode();
				
				if (!window.g_draft_body_save_timer) {
					window.g_draft_body_save_timer = setTimeout(function () {
						vsave_draft_body(window.g_draft);
						window.g_draft_body_save_timer = null;
					}, 5000)
				}
			}}
			//onClick={auto_to_mode}
			getSunEditorInstance={ editor_instance => {
				sunInstance.current = editor_instance;
				// window.g_sunInstance = sunInstance;
			}}
			
			setOptions={{
			  buttonList: [
			['bold', 'underline', 'italic', 'list', 'outdent', 'indent', 'image', 'table', 'link'],
                        ['undo', 'redo'],
                        [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                        [':e-More Line-default.more_horizontal', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'lineHeight'],
                        [':r-More Rich-default.more_plus', 'table', 'link', 'image', 'video', 'audio', 'imageGallery'],
                        ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', ],
                			  ]
			}}
			/>
	</div>
	</div>

	<div className="p-grid">
	<div className="p-col-1">
	</div>
	<div className="p-col-5 wide-input iconsep">
		<Button icon={"pi pi-fw " + sendicon} 
			label={label}
			onClick={onSend} />

		{autosave_msg && <span className="draft-save-message">
			{autosave_msg}
		</span>}
	</div>
	<div className="p-col-6 wide-input iconsep email-buttons">

		{(right_button_options.length > 0) && <SelectButton value={''} onChange={(e) => buttonPress(e)} itemTemplate={button_template} optionLabel="value" options={right_button_options} />}
		
		{em(1)}
		
		{(left_button_options.length > 0) && <SelectButton value={''} onChange={(e) => buttonPress(e)} itemTemplate={button_template} optionLabel="value" options={left_button_options} />}
		
	</div>
	</div>

	<Spacer />

	{autosave_modal && <ModalCommand onHide="true"
			className="small-modal-command"
			header="Saving Draft">	
		<div className="p-grid">
			<div className="p-col-12">
			{autosave_modal_error && <div>
				There was an error saving your draft. It is possible your connection to the server has been lost, and you may need to log back in. Please close this popup and copy and paste your email content into a notepad or Word document to preserve it.
			</div>}
			{(!autosave_modal_error) && <div>
				Saving draft... please wait one moment.
			</div>}
			</div>
		</div>
	</ModalCommand>}
	
	{generating_response && <ModalCommand onHide="true"
			className="small-modal-command"
			header="Generating Response">	
		<div className="p-grid">
			<div className="p-col-12">
				Auto generating response...
			</div>
		</div>
	</ModalCommand>}
	
	{recovery_draft_attachments_modal && <ModalCommand onHide={acknowledgeRecoveryAttachments}
			className="small-modal-command"
			header="Recovered Draft">	
		<div className="p-grid">
			<div className="p-col-12">
				This in-progress email draft has been recovered.
				<br/><br/>
				However, the following files that were previously attached will need to be re-attached:
				<br/><br/>
				{draft.attach_items.map((fitem => {
					return (<div><b>{fitem.name}</b></div>);
				}))}
			</div>
		</div>
	</ModalCommand>}

	{local_storage_unavailable_modal && <ModalCommand onHide={acknowledgeLocalStorageUnavailable}
			className="small-modal-command"
			header="Local Storage Unavailable">	
		<div className="p-grid">
			<div className="p-col-12">
				Your organization is set to use local browser storage for real-time autosaving while composing emails.<br/><br/>
				However, your browser is not set to allow local browser storage. Autosaving of email drafts will still occur when manually initiated and automatically every three minutes in the background.<br/><br/>
				To turn off the local browser storage autosave feature, have an admin go to the organization settings page and update that setting for your organization.
			</div>
		</div>
	</ModalCommand>}

</div>
	);
}

const fix_sig = (rid, html) => {
	const signature = get_signature_of_room(rid);
	const html_signature = signature ? signature.html : ldb.data.me.html_signature;
	
	const psig = html_signature;
	const brsig = p2br(psig);
	
	// In replies, editor messes up signatures..
	// this at least fixes our own signature.
	//  other's signatures may not be preserved.
	//  TBD TBD.
	// return html.replace(new RegExp(psig, 'g'), brsig);

	try {
		const new_html = html.replace(new RegExp(psig.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')), brsig);
		return new_html;
	} 
	catch(e) {
		log('email', 'fix_sig failed', e);
		return html;
	}

}

const get_orig_recipients = env => {
	if (!env)
		return []

	const pinfo = env.pinfo;
	const orig = [...pinfo.to, ...pinfo.cc];

	// if the sender is not a staff member, add them to the list
	// if staff member, they'll get the email anyway. 
	//	no need to include them here.`
	const addr = pinfo.from[0];
	if (addr) {
		addr.email = addr.email.toLowerCase();
		const room = get_room(this.props.rid);
		set_order(room.staffs);
		let guest = true;
		map_list(room.staffs, staff => {
			if (staff.email.toLowerCase() == addr.email)
				guest = false;
		});
		if (guest)
			orig.push(addr);
	}

	orig.forEach(item => item.email = item.email.toLowerCase());

	return orig;
}

const init_tos = env => {
	const tos = [];
	const orig = get_orig_recipients(env);

	this.add_clients(tos, orig);
	this.add_staff(tos, orig);
	this.add_ext(tos, orig);
	add_blank(tos, orig);

	return tos;
}
const email_in_orig = (orig, email) => {
	const lemail = email.toLowerCase();
	for (let i=0; i<orig.length; i++)
		if (orig[i].email == lemail) {
			orig[i].used = true;
			return true;
		}
	return false;
}

const add_ext = (items, orig) => {
	orig.map(addr => {
		if (!addr.used) {
			items.push({
				id : 0,
				kind: 'Ext',
				name: addr.name,
				email: addr.email,
				comment: '',
				checked: true,
			});
			addr.used = true;
		}
	});
}

const add_clients = (items, orig) => {
	const room = get_room(this.props.rid);
	set_order(room.clients);
	map_list(room.clients, cuser => {
		set_order(cuser.emails);
		map_list(cuser.emails, cemail => {
			if (cemail.priority < 0)
				return;
			const checked = this.email_in_orig(orig, 
				cemail.email) || cemail.priority > 0;
			items.push({
				id : cemail.id,
				kind: 'Client',
				name: cuser.name,
				email: cemail.email,
				comment: cemail.comment,
				checked,
			});
		});
	});
}

const add_staff = (items, orig) => {
	const room = get_room(this.props.rid);
	set_order(room.staffs);
	map_list(room.staffs, staff => {
		const checked = email_in_orig(orig, staff.email);
		items.push({
			id : staff.id,
			kind: 'Staff',
			name: staff.name,
			email: staff.email,
			comment: '',
			checked,
		});
	});
}

const add_blank = (items) => {
	items.push({
		id : 0,
		kind: 'Ext',
		name: '',
		email: '',
		comment: '',
		checked: false,
	});
}

// Draft: prepare -------

const plain2html = plain => plain.split('\n').join('<br/>')

function show_kind(env, kind) {
	const lkind = kind.toLowerCase();
	const elist = env.is_common ? env.pinfo[lkind] : pid2names(env, lkind);
	const namelist = elist.map(item => {
		let buf = '';
		if (item.name)
			buf += '"' + item.name + '" ';
		buf += '&lt;' + item.email + '&gt;';
		return buf;
	});
	if (!namelist.length)
		return '';
	return kind + ': ' + namelist.join(', ') + '\n';
}

function ltgt2plain(buf) {
	buf = buf.replace('&lt;', '<');
	buf = buf.replace('&gt;', '>');
	return buf;
}

const body_prefix = (draft, env) => {
	const prefix = {};
	const from = env_names_kind(env, 'from');
	const date = edate(env.dt_sent);

	if (draft.is_reply && !draft.is_pretend_forward) {
		prefix.plain_sep = '\n\n\n---- ' + from + 
			' wrote on ' + date + ':\n\n';

		prefix.html_sep = '<br/><br/><hr><div>---- <b>' + from + 
				'</b> wrote on ' + date + 
				':</div><br/>';
		return prefix;
	}

	let buf = '';
	if (draft.is_pretend_forward) {
		const me = ldb.data.me;
		const people = get_people(env);
		let recip = null;
		foreach_list(ldb.data.staffs, staff => {
			people.forEach(peep => {
				if (peep.email == staff.email)
					recip = staff;
			});
		});

		buf += '----Forwarded Message----\n';
		if (recip) {
			buf += 'From: "' + recip.name + '" &lt;' + 
				recip.email + '&gt;\n';
		}
		buf += 'To: "' + me.name + '" &lt;' + me.email + '&gt;\n';
		buf += 'Subject: ' + env.subject + '\n\n';
	}

	buf += '----Forwarded Message----\n';
	['From', 'To', 'Cc'].forEach(kind => (buf += show_kind(env, kind)));
	buf += 'Date: ' + date + '\n';
	buf += 'Subject: ' + env.subject + '\n\n';

	prefix.plain_sep = '\n\n\n' + ltgt2plain(buf);
	prefix.html_sep = '<br/><br/><hr/>' + plain2html(buf);

	return prefix;
}

const inc_body = (draft, env, display_linked_images=false, enable_links=false) => {
	const me = ldb.data.me;

	const signature = get_signature_of_room(draft.rid);
	const html_signature = signature ? signature.html : me.html_signature;
	const plain_signature = signature ? signature.plain : me.plain_signature;

	draft.plain = '\n\n' + plain_signature;
	let html = '<p><br/></p><p><br/></p>' + html_signature;

	const oplain = get_body_part(env.body, 'text/plain');
	const ohtml = get_body_part(env.body, 'text/html') 
		|| plain2html(oplain);

	const prefix = body_prefix(draft, env); 

	const options = map_inline_attachments(env.body, display_linked_images, enable_links);	

	draft.plain += prefix.plain_sep + oplain;
	let shtml = prefix.html_sep + '<div>' + ohtml + '</div>\n';

	let fixed_shtml = sanitizeHtml(shtml, options)
	
	//log('email', 'inc.body_2', shtml, fixed_shtml);

	html += fixed_shtml;

	log('email', 'inc.body', {draft, env, prefix, html, oplain, ohtml});

	//log('email', 'inc.body2', html);
	
	//console.log('PREEEEEEEEEEEEEE', html);
	draft.html = html;
	//console.log('POOOOOOOOOOOOOST', draft.html);

	//log('email', 'inc.body3', draft.html);

	vsave_draft_body(draft);
	
	return draft;
}

const get_current_draft_content = (draft) => {
	let plain = draft.plain;

	plain = plain.split('--------------------------------------------------------------------------------', 1)[0];

	let content = plain;

	if (ldb.data.me.plain_signature) {
		content = content.replace(ldb.data.me.plain_signature, '');
	}
	
	content = content.replace(/\n\n\n\n/g, '\n\n');
	content = content.trim();

	console.log('GET_CURRENT_DRAFT_CONTENT', content);

	return content;
}

const get_current_draft_context = (draft) => {
	let {iid, rid} = draft;
	const room = get_room(rid);
	const item_root = draft.is_private ? room['unshared'] : room[drafts_tab(room)];
	const item = iid ? item_root._items[iid] : null;
	
	let context = {};
	
	let plain = draft.plain;
	let parts = plain.split('--------------------------------------------------------------------------------');
	
	plain = parts[0];
	
	let thread = '';
	if (parts.length > 1) {
		thread = parts.slice(1).join('--------------------------------------------------------------------------------');
	}
	
	context.thread = thread;
	
	let attachmentinfo = '';
	if (item) {
		attachmentinfo = item.attachmentinfo;
	}
	
	context.attachmentinfo = attachmentinfo;

	context.to_emails = draft.to_emails;

	console.log('GET_CURRENT_DRAFT_CONTEXT', context);
	
	return context;
}

const update_draft = (draft, new_plain, set_index) => {
	let {iid, rid} = draft;
	const room = get_room(rid);
	const item_root = draft.is_private ? room['unshared'] : room[drafts_tab(room)];
	const item = iid ? item_root._items[iid] : null;
	
	draft.html = '';
	draft.plain = '';
	
	if (item) {
		inc_body(draft, item);
	}
	
	draft.html =  plain2html(new_plain) + '<br/><br/>' + draft.html;
	draft.plain = new_plain + '\n\n' + draft.plain;

	vsave_draft_body(draft);
	
	set_index(1);
}

const pinfo_from = (draft, env) => {
	const pinfo = env.pinfo;

	if (pinfo.from.length == 0) {
		draft.tos = [];
		return;
	}
	
	let recip = pinfo.from[0];
	if (recip.email == ldb.data.me.email) {
		const newtos = pinfo.to.filter(item => item.email != ldb.data.me.email);
		if (newtos.length > 0) {
			recip = newtos[0];
		}
	}
	draft.tos = [recip];
}

function set_kind(recips) {
	recips.forEach(item => {
		if (item.kind === undefined) {
			const person = email2person(item.email);
			item.kind = abbr2kind( person.kind );
		}
	});
}

const pinfo_tos = (draft, env) => {
	const pinfo = env.pinfo;
	draft.tos = [...pinfo.from, ...pinfo.to];
	set_kind(draft.tos);
}

const pinfo_ccs = (draft, env) => {
	const pinfo = env.pinfo;
	draft.ccs = [...pinfo.cc];
	set_kind(draft.ccs);
}

const pid_tos = (draft, env) => {
	draft.tos = [...get_envdb_to_persons(env)];
}

const pid_ccs = (draft, env) => {
	draft.ccs = [...get_envdb_cc_persons(env)];
}

const pid_from = (draft, env) => {
	draft.tos = [...get_envdb_from_person(env)];
}

const exclude_staffs = draft => {
	const room = get_room(draft.rid);
	set_order(room.staffs);
	const email2staff = {};
	map_list(room.staffs, item => (email2staff[item.email] = item));

	const newtos = draft.tos.filter(item => 
				email2staff[item.email] === undefined);
	draft.tos = newtos;
}

const exclude_me = draft => {
	const newtos = draft.tos.filter(item => item.email != ldb.data.me.email);
	draft.tos = newtos;
}

const reply_tos = (draft, env, reply_all=false) => {
	if (env.pinfo !== undefined) {
		// shared tab
		if (reply_all) {
			pinfo_tos(draft, env);
			pinfo_ccs(draft, env);
			exclude_me(draft);
		} else {
			pinfo_from(draft, env);
		}
		//if (!draft.is_pretend_forward)
		//	exclude_staffs(draft);
	}
	else {
		// unshared tab
		if (reply_all) {
			pid_tos(draft, env);
			pid_ccs(draft, env);
			exclude_me(draft);
		} else {
			pid_from(draft, env);
		}
	}
}

const get_people = env => {
	const pinfo = env.pinfo;
	return pinfo ? 
		[...pinfo.from, ...pinfo.to, ...pinfo.cc] :
		[...get_envdb_persons(env)];
}

// If I am replying to an email from a client to another staff
//   and I wasn't copied on the original email, client may be spooked.
// Make it look like other staff forwarded that email to me,
//  and I'm responding to it, by also copying this other staff.
//  is_pretend_forward flags that case
function get_pretend_forward(draft, env) {
	if (! (draft.is_reply || draft.is_forward) )
		return false;

	const people = get_people(env);
	const me = people.filter(item => ((item.email == ldb.data.me.email) || (item.email == ldb.data.me.email_alias)));

	if (me.length)
		return false;	// I was on the email.

	return true;
}

const reply_orig = (draft, env, reply_all=false) => {
	// returns {tos, subject, plain, html};

	log('email', 'reply_orig.1111', draft, env);

	reply_tos(draft, env, reply_all);

	draft = inc_body(draft, env);
	console.log('INC BODY', draft);

	const fw = draft.is_pretend_forward ? 'FW: ' : '';

	let subject = env.subject.trim();
	if (subject.substring(0,4) !== 'Re: ')
		subject = 'Re: ' + fw + subject;
	// Sometimes original subject has \r\n in it. 
	//	Causes failures in Send Mail. Remove that.
	draft.subject = subject.replace(/(\r\n|\n|\r)/gm, '');

	draft.reply_to = env.message_id;

	// Save original attachments,for "include original attachments" feature
	draft.orig_files = attach_files(env);

	draft.references = env.message_id;
	if (env.references)
		draft.references += ' ' + env.references;
	log('email', 'reply_orig.2222', draft, env);
}

const attach_files = env => env.body.filter(part => part.is_attachment).map(
    part => {
	const ab = decode_mime(part);
	const blob = new Blob([ab], {type: part.content_type});
	const url = URL.createObjectURL(blob);
	
	return {
		url,
		payload: {
			name: part.file_name,
			size: part.data.length,
			kind: part.content_type,
			b64data: part.data,
		},
	};
})

const forward_orig = (draft, env) => {
	const me = ldb.data.me;

	draft.subject = 'Fw: ' + env.subject;
	if (draft.is_pretend_forward)
		draft.subject = 'Re: ' + draft.subject;

	draft.files = attach_files(env);

	draft.references = env.message_id;

	inc_body(draft, env, true, true);
}

const to_clients = (draft, env) => {
	const room = get_room(draft.rid);
	
	if (room.default_email_recipients == 'n') {
		return;
	}
	
	set_order(room.clients);
	map_list(room.clients, cuser => {
		set_order(cuser.emails);
		map_list(cuser.emails, cemail => {
			if (cemail.priority > 0)
				draft.tos.push({
					name: cuser.name,
					email: cemail.email,
					kind: 'Client',
				});
		});
	});
}

const set_emails = draft => {
	draft.to_emails = draft.tos.map(item => item.email);
	draft.cc_emails = draft.ccs.map(item => item.email);
	draft.bcc_emails = draft.bccs.map(item => item.email);
}



const init_draft = props => {
	// let {rid, tab, item, iid, is_reply, is_forward} = props;
	const {rid, tab, iid, command} = props;
	const room = get_room(rid);
	let etab = null;
	//if (iid) {
	//	const uitem = get_item(rid, 'unshared', iid);
	//	const sitem = get_item(rid, 'shared', iid);
	//	if ((uitem) && (!sitem)) {
	//		etab = 'unshared';
	//	}
	//}
	//etab = etab ? etab : drafts_tab(room);
	//const root = room[etab];
	const root = room[drafts_tab(room)];
	const is_private = ((command == 'private_reply') | (command == 'private_reply_all') | (command == 'private_forward'));
	const item_root = is_private ? room['unshared'] : room[drafts_tab(room)];
	const gtab = is_private ? 'unshared' : drafts_tab(room);
	const item = iid ? item_root._items[iid] : null;
	const is_reply = item && iid != 0;
	const is_reply_all = item && iid != 0 && ((command == 'reply_all') || (command == 'private_reply_all'));
	const is_forward = item && iid != 0 && ((command == 'forward') | (command == 'private_forward'));
	const pending_draft_iids = root._flags.pending_draft_iids || [];
	
	// let iid = is_reply ? props.iid : 0;
		
	log('email', 'init_draft.11111', rid, props, item, 
			is_reply, is_reply_all, is_forward, command);

	if (pending_draft_iids.indexOf(iid) < 0) {
		pending_draft_iids.unshift(iid);
		root._flags.pending_draft_iids = [...pending_draft_iids]; //copy
	}
	
	const created_dt = moment();

	let draft = root.drafts[iid]; 
	if (draft) 	// Draft already in progress
		return draft;
		
	const me = ldb.data.me;

	const signature = get_signature_of_room(rid);
	const html_signature = signature ? signature.html : me.html_signature;
	const plain_signature = signature ? signature.plain : me.plain_signature;

	draft = {tos:[], ccs:[], bccs:[], subject:'', 
		plain: '\n\n' + plain_signature,
		html: '<p><br/></p><p><br/></p>' + html_signature,
		files:[], orig_files: [],
			references: '', reply_to: '',
			is_mailbox : room.is_mailbox,
			is_reply, is_reply_all, is_forward,
			is_private,
			command, created_dt, etab,
			rid, iid, tab, nsave: 0, version_number: 1}; // save these in draft, 
					// so props not needed

	if ((iid) && (!item)) {
		go('room', rid, gtab, iid);
		return null;
	}
	
	draft.is_pretend_forward = get_pretend_forward(draft, item);
	reset_look(draft);

	root.drafts[iid] = draft;

	log('email', 'init_draft.2222', rid, props);

	if (is_reply) {	// reply or forward.  for new, iid==0
		if (is_forward)
			forward_orig(draft, item);
		else if (is_reply_all)
			reply_orig(draft, item, true);
		else
			reply_orig(draft, item);
	}
	else {
		to_clients(draft);	// New email
	}
	set_emails(draft);

	log('email', 'init_draft.3333', props, draft);

	return draft;
}

function show_name(recip, kind) {
	// kind: choice: (show more), chosen (in To: only show kind)
	return (
	<div className="p-col-9">
		<div className="rname">
			{recip.name}
		</div>
		<div className="remail">
			{recip.email}
		</div>
		{kind == 'chosen' && 
			<div className="rkind tkind">
				{recip.kind}
			</div>
		}
		{kind == 'choice' && 
			<div className="rrole">
				{recip.role}
			</div>
		}
		{kind == 'choice' && 
			<div className="rcomment">
				{recip.comment}
			</div>
		}
	</div>
	);
}


function show_recip(i, draft, modtos) {
	const recip = draft.tos[i];

	return (
	<div key={i} className={"recip to-" + recip.kind}>
	<div className="p-grid">
		<div className="p-col-3">
			<Button 
				icon="pi pi-times" 
				className="p-button-secondary"
				onClick={()=>modtos(i)}
			/>
		</div>
		{show_name(recip, 'chosen')}
	</div>
	</div>
	);

}

function show_tos(draft, modtos) {
	if (draft.tos.length == 0)
		return 'None';

	return (
	<div className="p-grid tos">
		{draft.to_emails.map( (email, i) => show_recip(
					i, draft, modtos) )}
	</div>
	);
}

function show_to_name_mini(item, i, draft, reload) {
	return (
	<span key={i} className={"to-mini to-" + item.kind}
				onClick={e=> {
					draft.tos.splice(i, 1);
					draft.to_emails.splice(i, 1);
					reload();
				}}
	>
		<div className="tname" >
			{item.name}
			<div className="tremove"
			>
				x
			</div>
		</div>
		<div className='temail'>
			{item.email}
		</div>
		<div className='tkind'>
			{item.kind}
		</div>
	</span>
	);
}

function show_cc_name_mini(item, i, draft, reload, kind) {
	return (
	<span key={i} className={"to-mini to-" + item.kind}
				onClick={e=> {
					draft.ccs.splice(i, 1);
					draft.cc_emails.splice(i, 1);
					reload();
				}}
	>
		<div className="tname" >
			{item.name}
			<div className="tremove"
			>
				x
			</div>
		</div>
		<div className='temail'>
			{item.email}
		</div>
		<div className='tkind'>
			{item.kind}
		</div>
	</span>
	);
}

function show_bcc_name_mini(item, i, draft, reload, kind) {
	return (
	<span key={i} className={"to-mini to-" + item.kind}
				onClick={e=> {
					draft.bccs.splice(i, 1);
					draft.bcc_emails.splice(i, 1);
					reload();
				}}
	>
		<div className="tname" >
			{item.name}
			<div className="tremove"
			>
				x
			</div>
		</div>
		<div className='temail'>
			{item.email}
		</div>
		<div className='tkind'>
			{item.kind}
		</div>
	</span>
	);
}

function remove_all_tos(draft, reload) {
	return <span className="to-mini tremove-all" 
		onClick={e=> {
			draft.tos = [];
			draft.to_emails = [];
			reload();
		}}
	>
		<div className='tname'>
			<div className="tremove" >
				x
			</div>
		</div>
		<div className='temail'>
			All
		</div>
	</span>
}

function remove_all_ccs(draft, reload) {
	return <span className="to-mini tremove-all" 
		onClick={e=> {
			draft.ccs = [];
			draft.cc_emails = [];
			reload();
		}}
	>
		<div className='tname'>
			<div className="tremove" >
				x
			</div>
		</div>
		<div className='temail'>
			All
		</div>
	</span>
}

function remove_all_bccs(draft, reload) {
	return <span className="to-mini tremove-all" 
		onClick={e=> {
			draft.bccs = [];
			draft.bcc_emails = [];
			reload();
		}}
	>
		<div className='tname'>
			<div className="tremove" >
				x
			</div>
		</div>
		<div className='temail'>
			All
		</div>
	</span>
}


// Person search optimization. Reset Lookahead search cache.
//	pids = list of Person Ids still to be searched.
//	We modify with each search text update. So copy idlist, than modify.
function reset_look(draft) {
	draft.to_look = {text: '', 
			pids_left: ldb.data.contacts.persons._idlist};
	return draft.to_look;
}

function abbr2kind(abbr) {
	let kind = {s: 'Staff', m: 'Staff', c: 'Client', }[abbr] 
			|| 'Contact';
	return kind;
}

function p2to(person) {
	let kind = abbr2kind(person.kind);
	return {name: person.name, email: person.email, kind};
}

// Searching all contacts can be slow. with 10,000+ contacts.
//	Optimize.
// In Primary, we find all matching entries
// For Contacts, we stop if total choices are 6.
//	NOTE: Choices in dropdown are not necessarily Complete set of matches.
function search_ahead_contacts(draft, recip, pri_choices) {
	const choices = [];

	// If there are >= 6 choices already, skip searching contacts.
	let nleft = 6 - pri_choices.length;
	if (nleft <= 0)
		return choices;

	let la = draft.to_look;	// lookahead

	// User is adding to search text: ascending.  Mar -> Mary
	//  So if a Person failed to match earlier, it'll continue to fail now
	//  So we can search only in the subset of matched / unsearched 
	// 	records from earlier.
	// If descending/modified search:  Mar -> Ma,  or Mar -> Maar
	//	reset
	if (recip.slice(0, la.text.length) != la.text) 
		la = reset_look(draft);
	
	// No persons match
	if (la.recip && la.pids_left.length == 0)
		return choices;

	let i = 0;
	const root = ldb.data.contacts.persons;
	const mpids = [];	// matching pids.

	for (i=0; i < la.pids_left.length && nleft > 0; i++) {
		const pid = la.pids_left[i];
		const person = root._items[pid];

		if (draft.to_emails.indexOf(person.email) < 0 &&
			(person.name.indexOf(recip) >= 0 ||
				person.email.indexOf(recip) >= 0)) {

			choices.push( p2to(person) );
			nleft--;
			mpids.push( pid );
		}
	}

	// New set = matching + unsearched
	la.pids_left = mpids.concat( la.pids_left.slice(i) );
	la.text = recip;

	return choices;
}

function ToDropdown({choices, add}) {
	return (
	  <div className="freeform-choices">
	  {choices.map((item, index) => {
		const klass = (index == 0) ? 
			'first-to-mini to-mini' : 
			('to-mini to-' + item.kind);
		
		return (<div key={index} className={klass}
			onClick={e => add(item)}
		>
			<div className="tname">
				{item.name}
			</div>
			<div className="temail">
				{item.email}
			</div>
		</div>)
	  })}
	  <div className="help">
		  Help:
		  <br/>
		  1. Type email address and hit Enter or Tab
		  <br/>
		  2. Hit Enter or Tab to choose first choice.
		  <br/>
		  3. ESC to clear
		  <br/>
		  4. Use Recipients tab to search all Contacts.
	  </div>
	  </div>
	);
}

function AddTo(props) {
	const {draft, reload, kind} = props;
	const [recip, setRecip] = React.useState('');
	
	let add = null;
	if (kind == 'to') {
		add = item => {
			draft.tos.push(item);
			draft.to_emails.push(item.email);
			vsave_draft(draft);
			setRecip('');
			reload();
		}
	} else if (kind == 'cc') {
		add = item => {
			draft.ccs.push(item);
			draft.cc_emails.push(item.email);
			vsave_draft(draft);
			setRecip('');
			reload();
		}
	} else if (kind == 'bcc') {
		add = item => {
			draft.bccs.push(item);
			draft.bcc_emails.push(item.email);
			vsave_draft(draft);
			setRecip('');
			reload();
		}
	}
	if (!add)
		return null;
	
	if (draft.choices === undefined)
		set_choices(draft);

	// valid choices = not already chosen & matches name or email.
	// Choices are : clients, connections and staff. Excludes Contacts.
	//	fast and higher priority.
	const pri_choices = draft.to_choices.filter(item => (
			draft.to_emails.indexOf(item.email) < 0 &&
		  	(item.name.indexOf(recip) >= 0 ||
					item.email.indexOf(recip) >= 0)));
	// 
	const sec_choices = search_ahead_contacts(draft, recip, pri_choices);

	const choices = [...pri_choices, ...sec_choices];
	// log('email', 'to_choices', choices);

	return (
	<div className="freeform-to">
		<InputText value={recip}
			onChange={e => setRecip(e.target.value)}
			onKeyDown={e => {
				reload();
				if ((e.key === 'Enter') || (e.key === 'Tab')) {
					if (validate_email(recip)) {
						add({name: '', 
						email: recip, 
						kind: 'Manual'});
					}
					else if (choices.length && (recip.trim() != ''))
						add(choices[0]);

					if (e.key === 'Tab' && recip)
						 e.preventDefault();
				}
				else if (e.key === 'Escape') 
					setRecip('');
			}}
		/>
		{recip.length > 0  && <ToDropdown choices={choices} 
					add={add} />}
	</div>
	);
}

function show_tos_mini(draft, reload, mobile=false) {
	const kind = 'to';
	
	if (draft.tos.length == 0)
		return <div className="p-grid tos">
		<div className="to-mini">
				<div className="tname">
					None
				</div>
				<div className="temail">
					(Room Staff)
				</div>
		</div>
		<AddTo draft={draft} reload={reload} kind={kind} />
		</div>;

	const content = <div className="p-grid tos">
		{draft.tos.map(
		    (item, i) => show_to_name_mini(item, i, draft, reload)
		    )}
		<AddTo draft={draft} reload={reload} kind={kind} />
		{remove_all_tos(draft, reload)}
	</div>;

	if (mobile)
		return content;

	return (
		<ScrollPanel className="tos-row" >
			{content}
		</ScrollPanel>
	);
}

function show_ccs_mini(draft, reload) {
	const kind = 'cc';
	
	if (draft.ccs.length == 0)
		return <div className="p-grid tos">
		<div className="to-mini cc-mini">
		</div>
		<AddTo draft={draft} reload={reload} kind={kind} />
		</div>;

	return (
	<ScrollPanel className="tos-row" >
	<div className="p-grid tos">
		{draft.ccs.map(
		    (item, i) => show_cc_name_mini(item, i, draft, reload)
		    )}
		<AddTo draft={draft} reload={reload} kind={kind} />
		{remove_all_ccs(draft, reload)}
	</div>
	</ScrollPanel>
	);
}

function show_bccs_mini(draft, reload) {
	const kind = 'bcc';
	
	if (draft.bccs.length == 0)
		return <div className="p-grid tos">
		<div className="to-mini cc-mini">
		</div>
		<AddTo draft={draft} reload={reload} kind={kind} />
		</div>;

	return (
	<ScrollPanel className="tos-row" >
	<div className="p-grid tos">
		{draft.bccs.map(
		    (item, i) => show_bcc_name_mini(item, i, draft, reload)
		    )}
		<AddTo draft={draft} reload={reload} kind={kind} />
		{remove_all_bccs(draft, reload)}
	</div>
	</ScrollPanel>
	);
}

function set_choices(draft) {
	const room = get_room(draft.rid);
	draft.to_choices = [];

	const emails_used = [];

	// Add Clients
	set_order(room.clients);
	map_list(room.clients, cuser => {
		set_order(cuser.emails);
		map_list(cuser.emails, cemail => {
			if (cemail.priority >= 0) {
				draft.to_choices.push({
					name: cuser.name,
					email: cemail.email,
					kind: 'Client',
					checked: false,
				});
				
				emails_used.push(cemail.email);
			}
		});
	});

	// Add Staff
	set_order(room.staffs);
	map_list(room.staffs, staff => {
		draft.to_choices.push({
			id : staff.id,
			kind: 'Staff',
			name: staff.name,
			email: staff.email,
			comment: '',
			checked: false,
		});

		emails_used.push(staff.email);
	});

	// Add Connections (Room Contacts)
	const cidarr = [...room.cids].sort();
	cidarr.map(cid => {
		const conn = ldb.data.contacts.connections._items[cid];
		draft.to_choices.push({
			id : cid,
			kind: 'Connection',
			name: conn.name,
			email: conn.email,
			comment: conn.comment,
			role: conn.role,
			checked: false,
		});

		emails_used.push(conn.email);
	});

	ldb.data.contacts.connections._idlist.map(cid => {
		const conn = ldb.data.contacts.connections._items[cid];
		
		if (emails_used.indexOf(conn.email) > -1)
			return;
		
		draft.to_choices.push({
			id : cid,
			kind: 'Connection',
			name: conn.name,
			email: conn.email,
			comment: conn.comment,
			role: conn.role,
			checked: false,
		});

		emails_used.push(conn.email);		
	});
}

function addrm(checked, item, i, modtos, draft) {
	if (!checked)
		return modtos(i);
	
	draft.tos.push(item);
	modtos(-1);
}

function show_choice(item, draft, modtos) {
	const i = draft.to_emails.indexOf(item.email);
	const toggle = e => {
		if (i<0) {
			draft.tos.push(item);
			modtos(-1);
		}
		else {
			modtos(i);
		}
	};
	return (
	<div key={item.email} className="recip" onClick={toggle} >
	<div className="p-grid">
		<div className="p-col-3" >
			<Checkbox checked={i>=0} />
		</div>
		{show_name(item, 'choice')}
	</div>
	</div>
	);
}

function choose_to(draft, kind, modtos) {
	const choices = draft.to_choices.filter(item => (item.kind == kind));

	if (choices.length == 0)
		return 'None';

	return (
	<div className="p-grid">
		{choices.map((item,i)=>show_choice(item, draft, modtos))}
	</div>
	);
}

const add_person = (pid, draft, modtos) => {
	const person = pid2person(pid);
	const i = draft.to_emails.indexOf(person.email);
	if (i<0) {
		draft.tos.push({
			name: person.name,
			email: person.email,
			kind: 'Contact',
		});
		modtos(-1);
	}
	// TBD: Toggle or just add??
	// else
		// modtos(i);
}

const ManualAdd = props => {
	const {draft, modtos} = props;
	const [email, setEmail] = React.useState('');
	const [name, setName] = React.useState('');
	const add = () => {
	    const parts = email.split(',');

	    parts.forEach(part => {
		const lemail = clean_email(part);
		if (!validate_email(lemail)) {
			growl('Error', 'Email is invalid', 'warn');
			return;
		}
		if (draft.to_emails.indexOf(lemail) < 0) {
			draft.tos.push({email:lemail, name, kind:'Manual'});
			modtos(-1);
			setEmail('');
			setName('');
		}
	    });
	};

	return (
	<div className="p-grid manual-to">
		<div className="p-col-1">
			Email
		</div>
		<div className="p-col-4 wide-input">
			<InputText value={email}
				ref={lazyFocus}
				onChange={e => setEmail(e.target.value)}
			/>
		</div>
		<div className="p-col-7">
		</div>

		{email.indexOf(',')<0 && <React.Fragment>

		<div className="p-col-1">
			Name:
		</div>
		<div className="p-col-4 wide-input">
			<InputText value={name} 
				placeholder="Optional"
				onChange={e => setName(e.target.value)}
			/>
		</div>
		<div className="p-col-7">
		</div>

		</React.Fragment>
		}

		<div className="p-col-1">
		</div>
		<div className="p-col-3">
			<Button icon="pi pi-plus" label="Add" onClick={add} />
		</div>
		<div className="p-col-8">
		</div>
	</div>
	);
}

function Recipients(props) {
	const {draft, setRefresh} = props;
	const [index, setIndex] = React.useState(1);
	const [recips, setRecips] = React.useState(draft.to_emails);
	const allc_legend = 'Click to add Recipient'
	const modtos = i => {
		if (i>=0)
			draft.tos.splice(i, 1);
		set_emails(draft); 
		setRecips(draft.to_emails);
		props.reload();
	}
	set_choices(draft);

	const is_common = !get_room(draft.rid).is_mailbox;

	return (
	<div>
		{show_tos(draft, modtos)}

		{is_common ? 

	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} >
		<TabPanel header="Choose:" leftIcon="fa fa-fw fa-user" 
			disabled={true} >
		</TabPanel>
		<TabPanel header="Clients" leftIcon="fa fa-fw fa-user">
			{choose_to(draft, 'Client', modtos)}
		</TabPanel>
		<TabPanel header="Staff" leftIcon="fa fa-fw fa-user">
			{choose_to(draft, 'Staff', modtos)}
		</TabPanel>
		<TabPanel header="Connections" leftIcon="fa fa-fw fa-user">
			{choose_to(draft, 'Connection', modtos)}
		</TabPanel>
		<TabPanel header="Contacts" leftIcon="fa fa-fw fa-user">
			<FilteredPersons 
			  setPid={pid => add_person(pid, draft, modtos)} />
		</TabPanel>
		<TabPanel header="Manual" leftIcon="fa fa-fw fa-user">
			<ManualAdd draft={draft} modtos={modtos} />
		</TabPanel>
	</TabView>
			:
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} >
		<TabPanel header="Choose:" leftIcon="fa fa-fw fa-user" 
			disabled={true} >
		</TabPanel>
		<TabPanel header="Contacts" leftIcon="fa fa-fw fa-user">
			<FilteredPersons 
			  setPid={pid => add_person(pid, draft, modtos)} />
		</TabPanel>
		<TabPanel header="Manual" leftIcon="fa fa-fw fa-user">
			<ManualAdd draft={draft} modtos={modtos} />
		</TabPanel>
	</TabView>
		}
	</div>
	);
}


/*
 * Attach
 */

function show_attachment(item, i, draft, reload) {
	if (item.url === undefined)
		item.url = URL.createObjectURL(item.file);
	const remove = e => {
		draft.files.splice(i, 1);
		vsave_draft(draft);
		reload();
	}

	const {payload} = item;

	return (
	<div className="p-grid attach-item" key={i}>
		<div className="p-col-8 at-name">
			<i className="pi pi-fw pi-paperclip"></i>
			{' '}
			<a href={item.url} target="_blank">
				{payload.name} 
			</a>
		</div>
		<div className="p-col-3 at-size">
			{payload.size} bytes
		</div>
		<div className="p-col-1 at-rm">
			<Button label="" 
				onClick={remove}
				icon="pi pi-times"
				className="p-button p-button-secondary"
			/>
		</div>
	</div>
	);
}

function Attachments(props) {
	const [refresh, setRefresh] = React.useState(0);
	const myreload = ()=>setRefresh(refresh+1);
	
	const [incOrigAttachments, setIncOrigAttachments] = React.useState(false);
	
	const {draft, reload} = props;
	let _dzref = null;

	const onDrop = (acceptedFiles, rejectedFiles) => {
		log('email', 'Attach', acceptedFiles, rejectedFiles);

		acceptedFiles.forEach(file => {
			const reader = new FileReader();

			reader.onload = () => {
				const bin = reader.result; // binary
				const item = {
					file, // save for local preview
					payload: { // for sending to server
						name: file.name,
						size: file.size,
						kind: file.type,
						// base64 string
						// b64data: btoa(bin), 
						bindata: bin,
					},
				};
				draft.files.push(item);
				vsave_draft(draft);
				reload();
			};
		reader.onabort = () => growl('file reading was aborted');
		reader.onerror = () => growl('file reading has failed');

			reader.readAsBinaryString(file);
		});
	}

	const inc_orig_files = e => {
		while (draft.orig_files.length > 0) {
			const item = draft.orig_files.shift();
			draft.files.push(item);
			vsave_draft(draft);
		}
		myreload();
		
		log('email', 'Include Original Attachments', draft.files);
	}

	// this._dzref.open();
 
	return (
	<div className="p-grid">
		{draft.saved_attach_items && (draft.saved_attach_items.length > 0) && <div className="p-col-12">
			This in-progress email draft has been recovered. However, the following files that were previously attached will need to be re-attached:
			<br/><br/>
			{draft.saved_attach_items.map((fitem => {
				return (<div><b>{fitem.name}</b></div>);
			}))}
		</div>}
		<div className="p-col-5">
<Dropzone onDrop={onDrop}
	ref={ele => _dzref = ele}
		>
{({getRootProps, getInputProps, isDragActive}) => {
	const msg = isDragActive ?
	  <p>
	  	Drop files here...
	  </p> 
	:
	  <p>
	  	Try dropping some files here, or
		  <br/>
		  click Attach to select files to upload.
	  </p>;

  return (
    <div
      {...getRootProps()}
      className={classNames({'dropzone' : true}, 
      		{'dropzone--isActive': isDragActive})}
    >
      <input {...getInputProps()} />
      {msg}
    </div>
  )
}}
</Dropzone>
		</div>

		<div className="p-col-7">
			<Button label="Attach:" 
				onClick={e => _dzref.open()}
				iconPos="right" icon="pi pi-fw pi-paperclip"
				className="p-button-secondary"
				/>
			{em(4)}
			{draft.orig_files.length > 0 && 
			<Button 
			label={"Include original attachments (" +
					draft.orig_files.length + ")"}
				onClick={inc_orig_files}
				icon="fa fa-fw fa-files-o"
				className="p-button-secondary"
				/>}

			<div className="show-attach">
			  {draft.files.map((item,i) => 
				show_attachment(item, i, draft, reload))}
			</div>
		</div>

	</div>
	);
}

function AIAssist(props) {
	const [refresh, setRefresh] = React.useState(0);
	const [hint, setHint] = React.useState('');
	const [content, setContent] = React.useState('');
	const [context, setContext] = React.useState({});
	const [working, setWorking] = React.useState(false);
	const [generated, setGenerated] = React.useState('');
	const [help, setHelp] = React.useState(false);
	const [credithelp, setCredithelp] = React.useState(false);
	const [tos, setTos] = React.useState(false);
	const myreload = () => setRefresh(refresh+1);
	
	const {draft, reload, par_set_index} = props;
	
	const {iid, rid} = draft;
	
	React.useEffect(() => {
		let cur_content = get_current_draft_content(draft);
		let cur_context = get_current_draft_context(draft);

		if (draft.ai_assist_hint) {
			setHint(draft.ai_assist_hint);
		}
		if (draft.ai_assist_generated) {
			setGenerated(draft.ai_assist_generated);
		}
		
		setContent(cur_content);
		setContext(cur_context);
	}, []);
	
	const assist_request = () => {
		let json_content = JSON.stringify(content);
		let json_context = JSON.stringify(context);

		const args = {
			cmd: 'ai_draft_assist', 
			content: json_content,
			context: json_context,
			hint,
			rid,
			eid: iid,
		}
		
		api( args, assist_request_sent, assist_request_sent);
		
		setWorking(true);
	}

	const assist_request_sent = (error, db) => {
		if (error) {
			setWorking(false);
		}
	}
	
	const handle_assist = (rid, eid, output) => {
		draft.ai_assist_generated = output;
		
		setGenerated(output);
		setWorking(false);
	}
	
	const accept = () => {
		update_draft(draft, generated, par_set_index);
		update_hint('');
		update_generated('');
	}

	const cancel = () => {
		par_set_index(1);
		update_hint('');
		update_generated('');
	}
	
	const update_hint = (new_hint) => {
		draft.ai_assist_hint = new_hint;
		
		setHint(new_hint);
	}

	const update_generated = (new_generated) => {
		draft.ai_assist_generated = new_generated;
		
		setGenerated(new_generated);
	}

	const open_help = () => {
		setHelp(true);
	}

	const close_help = () => {
		setHelp(false);
	}

	const leave_disabled = () => {
		par_set_index(1);
	}

	const enable_ai = () => {
		const args = {
			cmd: 'ai_features_setting', 
			new_setting: 'enabled',
			tos: tos,
		}
		
		api( args, enable_ai_completed, enable_ai_completed);
	}

	const enable_ai_completed = () => {
		myreload();
	}
	
	const open_credit_help = () => {
		setCredithelp(true);
	}

	const close_credit_help = () => {
		setCredithelp(false);
	}
	
	const button_template = (option) => {
		return <React.Fragment><i className={option.icon}></i>{em(0.5)}{option.value}</React.Fragment>;
	}

	const cancel_button = [{icon: 'pi pi-fw pi-times', value: 'Cancel'}];
	const help_button = [{icon: 'fa fa-fw fa-question', value: 'Help'}];

	window.g_ai_assist = handle_assist;
	
	let show_regular_disabled = false;
	let show_admin_disabled = false;
	let show_tos = false;

	if (!ldb.data.org.ai_features_enabled) {
		if (ldb.data.me.is_org_admin) {
			show_admin_disabled = true;
			if (!ldb.data.org.ai_features_accepted_tos) {
				show_tos = true;
			}
		} else {
			show_regular_disabled = true;
		}
	}
	
	let org_admins = org_admins_display();
	
	return (
	<div>
	
	{show_admin_disabled && <ModalCommand onHide={leave_disabled}
			className="modal-35-command"
			header="Enable AI Features"
			style={{maxWidth: '500px'}}>
<div>
		
		{show_tos && <div>

		<div style={{width:'100%', height:'250px', border:'1px solid #999', padding:'10px', fontSize:'small', overflowY:'auto'}}>
		<span style={{fontSize:'24px'}}>
			<b>AI Features Terms of Service</b>
		</span>
		<p>
			TagInbox uses OpenAI APIs to generate email replies.
		</p>
		<p>
			Please review OpenAI <a href="https://openai.com/policies/api-data-usage-policies" target="_blank">here</a>
		</p>
		<p>
			TagInbox has <b>not</b> opted in to share data. OpenAI claims that data we provide via the APIs will not be used to train the AI models.
		</p>
		<p>
			You initially get 500 AI credits. Each response generation uses 1 credit.  You can buy additional credits from TagInbox. We use OpenAI to generate responses, and OpenAI charges us for such usage.
		</p>
		<p>
			By enabling the AI Assist feature for your organization, you acknowledge the data policies above.
		</p>
		
		</div>
		
		<div style={{marginTop: '10px'}}>
			{em(0.25)}
			<Checkbox onChange={e=> {
					setTos((!tos));
				}}
				checked={tos}
			/>
			{em(0.5)}
			Accept AI Features Terms of Service
		</div>

		</div>}
		
		<div style={{marginTop: '10px'}}>
			<Button label="Enable AI Features" 
				icon="fa fa-fw fa-check"
				className="p-button"
				disabled={(show_tos && (!tos))}
				onClick={enable_ai}
			/>
			
			{em(1)}
			
			<Button label="Cancel"
				icon="pi pi-times" 
				className="p-button-secondary"
				onClick={leave_disabled}
			/>
		</div>

		</div>
	</ModalCommand>}

	{show_regular_disabled && <ModalCommand onHide={leave_disabled}
			className="modal-35-command"
			header="AI Features Disabled"
			style={{maxWidth: '500px'}}>
<div>

		<p>
			AI features are currently disabled for your organization.
		</p>
		<p>
			Please contact your TagInbox administrator ({org_admins}) to enable this feature.
		</p>

		</div>
	</ModalCommand>}

	{help && <ModalCommand onHide={close_help}
			className="modal-35-command"
			header="About AI Assist"
			style={{maxWidth: '500px'}}>
<div>

		<p>You can use the "AI Assist" feature to come up with various versions of an email you are drafting and select a version thats acceptable to you.  Enter an (optional) hint asking "AI Assist" to create a specific tone or include specific details and click the "Generate" button. In a few seconds an "After" version will show up above your original "Before" version. You can accept the new version, and it will take you back to the email compose tab with the new content replacing the old one. You can then edit it before sending it out.</p>
		
		<p>You can always cancel to return to your original version, or generate a different version by updating the hint and generating it again.</p>

		<p>Here are some examples of potential hints you can use:</p>
		
		<ul>
			<li>"I need these work items completed ASAP"</li>
			<li>"Be apologetic about not responding sooner"</li>
			<li>"Include a list of the action items gathered from Dave's email"</li>
		</ul>
		
		</div>
	</ModalCommand>}

	{credithelp && <ModalCommand onHide={close_credit_help}
			className="modal-35-command"
			header="About AI Credits"
			style={{maxWidth: '500px'}}>
<div>
		<p>
			Each use of this feature takes one AI credit. AI credits are shared among all the staff of your organization.
		</p>
		<p>
			If you would like to obtain more AI credits, please contact your administrator. They can purchase additional AI credits from TagInbox.
		</p>
		</div>
	</ModalCommand>}
	
	<div className="p-grid" style={{marginTop: '0px', marginBottom: '0px', padding: '0px', height: 'calc(100vh - 255px)'}}>
		<div className="p-col-12">
		
		<div className="p-grid">
		<div className="p-col-1 ai-gen-label" >
			Before:
		</div>
		<div className="p-col-11">
			<InputTextarea className="ai-assist-textarea" 
			placeholder="<No current draft. AI will generate a new draft>"
			value={content} 
			/>
		</div>
		</div>
		
		<div className="p-grid">
		<div className="p-col-1 ai-gen-label" >
			After:
		</div>
		<div className="p-col-11">
			<InputTextarea className="ai-assist-textarea" 
			placeholder="<AI generated response will be placed here.  Please click Generate to create the response>"
			value={generated} 
		/>
		</div>
		</div>

		</div>
	</div>
	
	<div className="p-grid" style={{ background:'' }}>
		<div className="p-col-2 ">
			<Button label="Generate" 
				icon={working? 
					"pi-spin pi-spinner" :
					"fa fa-fw fa-magic"
				}
				className="p-button"
				onClick={assist_request}
			/>
		</div>
		<div className="p-col-4 wide-input">
			<InputText value={hint}
				placeholder="Hint (optional)"
				className="ai-gen-hint"
				onChange={e => {
					update_hint(e.target.value);
				}}
			/>
		</div>
		<div className="p-col-2 ">
			{(generated) && <Button label="Accept" 
				className="p-button"
				icon="fa fa-fw fa-check"
				onClick={accept}
			/>}
		</div>
		<div className="p-col-2 ">
			<Button label="Cancel" 
				icon="pi pi-fw pi-times"
				outlined
				className="p-button"
				onClick={cancel}
			/>
		</div>
		<div className="p-col-2 ">
			<Button label="?" 
				outlined
				className="p-button"
				onClick={open_help}
			/>
			{em(2)}
			<Button label={"Credits\n" + ldb.data.org.ai_credits}
				style={{fontSize: 'xx-small', width: '60px', height: '37px', wordBreak: 'keep-all', textAlign: 'center'}}
				outlined
				className="p-button"
				onClick={open_credit_help}
			/>
		</div>
	</div>
	
	</div>
	);
}

function reply_not_fetched(props) {
	const {is_reply, item, rid, tab} = props;

	if (is_reply && (item === undefined || item.id === undefined)) {
		// item is not fetched yet.
		go('room', rid, tab, 0);
		return true;
	}
	return false;
}

function find_later_recovery_draft(rid, iid, draft_shell) {
	const tdraft = {rid, iid};
	const rdraft = vget_draft(tdraft);
	
	let ldraft = null;
	
	if (rdraft) {
		log('vault', 'find_later_recovery_draft', rdraft, draft_shell);
		
		if ((draft_shell && (rdraft.version_number >= draft_shell.version_number)) || (!draft_shell)) {
			rdraft.vsave_state = 'recovery';
			vsave_draft(rdraft, false);
			
			ldraft = rdraft;

			const room = get_room(rid);
			const root = room[drafts_tab(room)];
			
			root.drafts[iid] = ldraft;
			
			log('vault', 'loading_recovery_draft', ldraft);
		}
	}
	
	return ldraft;
}

function get_recovery_draft(rid, iid) {
	const tdraft = {rid, iid};
	const rdraft = vget_draft(tdraft);
	log('vault', 'get_recovery_draft', rdraft);
	if (rdraft && rdraft.vsave_state == 'recovery') {
		return rdraft;
	}
	
	return null;
}

function NewEmail(props) {
	const [index, setIndex] = React.useState(1);
	const [refresh, setRefresh] = React.useState(0);
	const [mode, setMode] = React.useState('uninit');
		
	log('email', 'NewEmail', mode, props);

	const {rid, iid} = props;
	
	const read_perm = mbox_read_perm();
	const write_perm = mbox_write_perm();

	if ((!mbox_read_perm()) && (!room_has_team_email(get_room(rid)))) {
		go('room', rid, 'shared');
		return null;
	}

	if (mode != 'ready') {
		if (get_recovery_draft(rid, iid)) {
			log('vault', 'Setting mode to ready');
			
			setMode('ready');
			return null;
		}
	}

	let draft_shell = null;
	let rdraft = null;
	let room = null;
	let root = null;
	
	if (mode != 'ready') {
		room = get_room(rid);
		root = room[drafts_tab(room)];
		
		log('email', 'Checking for cached draft');
		if (iid in root.drafts) {
			log('email', 'Found cached draft', root.drafts[iid]);
			setMode('ready');
			return null;
		}

		draft_shell = check_for_draft_shell(rid, iid);
		
		let ldraft = find_later_recovery_draft(rid, iid, draft_shell);
		
		log('vault', 'Found Recovery Draft', ldraft, draft_shell);

		if (ldraft) {
			setMode('ready');
			return null;	
		}
		
		if (!draft_shell) {
			setMode('ready');
			return null;
		}
	}

	if (mode == 'uninit') {
		if (!write_perm) {
			try {
				delete_draft_shell(rid, iid)
			} catch(e) {
			}
			setMode('ready');
			return null;
		}
		
		const args = {
			cmd: 'get_draft', 
			rid, iid,
		}
		log('email', 'doGetDraft', args);
		api( args, function() {
				delete_draft_shell(rid, iid);
				setMode('ready');
			}, function() {
				setMode('ready');
		});
		setMode('loading');
		return null;
	}

	if (mode == 'loading') {
		const draft_size = display_readable_bytes(draft_shell.size, 0);
		const draft_warning = (draft_shell.size > 4000000) ? 
			<span>
		This may take a few moments due to the size of the draft<br/>
			</span> : '';
		
		return (
		<div className="new-email">
			Loading draft ({draft_size})...<br/>
			{draft_warning} 
			<ProgressSpinner />
		</div>
		);
	}
	
	// if (reply_not_fetched(props))
		// return null;
	
	let draft = null;
	rdraft = get_recovery_draft(rid, iid);
	if (rdraft) {
		draft = rdraft;
		draft.vsave_state = 'unsaved';
		vsave_draft(draft);
		
		room = get_room(rid);
		root = room[drafts_tab(room)];
		
		root.drafts[iid] = draft;
		
		draft = init_draft(props);
	} else {
		draft = init_draft(props);
		vsave_draft(draft);
		//vsave_draft_body(draft);
		//vsave_draft_subject(draft);
	}

	if (!draft) {
		return null;
	}
	
	console.log('NEW EMAIL', draft, draft.subject);

	const nr = draft.tos.length;
	const nf = draft.files.length;
	const reload = ()=>setRefresh(refresh+1);

	if (props.mobile) 
		return <Compose draft={draft} {...props} par_reload={reload} />
	
	return (
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)} 
			className="new-email" >
		<TabPanel header={"Recipients (" + nr + ")"}
			leftIcon="fa fa-fw fa-users">
			<Recipients draft={draft} reload={reload} />
		</TabPanel>
		<TabPanel header="Compose" 
				leftIcon="fa fa-fw fa-pencil-square-o">
			<Compose draft={draft} {...props} par_reload={reload} />
		</TabPanel>
		<TabPanel header={"Attachments (" + nf + ")"} 
			leftIcon="pi pi-fw pi-paperclip">
			<Attachments draft={draft} reload={reload} />
		</TabPanel>
		<TabPanel header="AI Assist" 
			leftIcon="fa fa-fw fa-magic">
			<AIAssist draft={draft} reload={reload} par_set_index={setIndex} />
		</TabPanel>
	</TabView>
	);
}

// defunct.. no longer used in Mobile.js
const ModalNewEmail= props => (
	<ModalCommand header={props.title}
		onHide={props.close}
		className='modal-command new-email2'
			>
		<NewEmail {...props} />
	</ModalCommand>
	)

export {NewEmail, ModalNewEmail, deleteDraft, CcBccRows, init_draft};
