import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Checkbox} from 'primereact/checkbox';

import sanitizeHtml from 'sanitize-html';

//**-------- SunEditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './suneditor-override.css';

import {EditBody} from './EditTask';
import {map_inline_attachments} from './EmailUtil';
import {ModalCommand, Spacer, Focusable, lazyFocus, HelpInfo} from './Utils';
import {CcBccRows} from './NewEmail';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v, get_room_options, is_staff_a_recipient_of_shared_email,
	map_list, filter_list, gen_xid, get_item, em, escape_reg_exp,
	get_body_part, bulk_email_list_options, get_bulk_email_list, 
	html2plain, get_bulk_email_list_data,
	} from './Lib';



function sun_style_tweak(html) {
	// Apply table inline styles

	html = html.replaceAll('<table>', '<table style="border-collapse:collapse;">');
	html = html.replaceAll('<th>', '<th style="border:1px solid #999;">');
	html = html.replaceAll('<td>', '<td style="border:1px solid #999;">');

	return html;
}

function sun_style_exclude_existing_tables(html) {
	// Don't modify tables existing tables before we start editing
	//	So when we reply/forward/... someone else's tables,
	//	lets not put border around them. Makes them look wierd.
	// To distinguis them from our own tables, 
	//	add extra space for tags.

	html = html.replaceAll('<table>', '<table >');
	html = html.replaceAll('<th>', '<th >');
	html = html.replaceAll('<td>', '<td >');

	return html;
}

const BulkEmailHelp = () => {
	const text1 = "{{ FieldName:DefaultValue }}";
	const text2 = "{{ State:None }}";

	return (
	<HelpInfo header="Bulk Email Usage">
	<dl>
	<dt>
		About
		<br/>
		<br/>
	</dt>
	<dd>
		The bulk email feature allows staff to use a shared email in any room in their organization as a template for a bulk email to be sent out to any number of rooms and clients in their organization.
	</dd>
	<dt>
		How
		<br/>
	</dt>
	<dd>
		<ol>
		<li>Find and view the shared email you would like to use as a template.
		</li>
		<li>In the detail pane for that email, click the button in the header with three horizontal lines to open an option menu. Select "Bulk Email" to bring up a popup.
		</li>
		<li>The subject line of the bulk email to be sent out is fixed, and the same as the shared email you are using as a template. The same goes for the body of the email, though room field substitution can be used to customize the email for each room (see below).
		</li>
		<li>In the "To" field you can type space-separated search criteria. Each word you enter will be checked against the room's name to determine if the room should be included in the bulk email recipient list. For example, if you enter "ben andy" in the field it will show a list of all rooms with both "ben" and "andy" (case insensitive) in the room name. You can also search by room fields. The format is "FieldName:FieldValue". So if you search for "State:WA" it will include all firms with the field "State" set to "WA". Searching for "State:WA ben" will return all rooms with the field "State" set to "WA" and "ben" in the name.
		</li>
		<li>Within the search results you may manually deselect any rooms you would like excluded from the bulk email batch.
		</li>
		<li>Rooms for you org that are EXCLUDED from the search results are displayed below the search results.
		</li>
		<li>Below the excluded rooms, you may see red warning text letting you know how many (if any) rooms do not have any value set for the fields you are searching for. This does not apply if you are not searching by room fields.
		</li>
		<li>Once you click "Send" you will see a confirmation dialogue letting you know how many firms will receive the bulk email. If you confirm, the emails will begin sending in the background. You can use the progress circle in the upper-right of the screen to track the bulk email sending.
		</li>
		</ol>
	</dd>
	<dt>
		Room Field Substitution
		<br/>
		<br/>
	</dt>
	<dd>
		Room field substitution may be used in the body of the bulk email. For now, it cannot be used in the subject line.
		<br/><br/>
		The format for room field substitution is "{text1}". So if you want the email to say "Your company is located in STATENAME" you would use "Your company is located in {text2}". For a state with the "State" field set to "WA" this would show up as "Your company is located in WA". For a state without the field set it would show up as "Your company is located in None". For the moment, the default value must not have any spaces in it. This may change in the future.
	</dd>
	</dl>
</HelpInfo>
)
}

function BulkEmailStep1Confirm(props) {
	const {onHide, onConfirm, subject, rids, par} = props;
	
	return (
	<ModalCommand header="Confirm Bulk Email Rooms"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>
		<div className="p-grid">
			<div className="p-col-12">
				<b>Step 2 of 4</b>
				<br/><br/>
			</div>
		</div>
		
		Are you sure you want to send a copy of the email
		"{subject}" to {rids.length} room(s)? 
		<br/><br/>
		<div className="p-grid">
		<div className="p-col-2">These rooms will receive a copy:</div>
		<div className="p-col-10">
			<div className="bulk-email-room-list-div">
		<ScrollPanel className="bulk-email-room-list">
			{rids.map( rid => {
				const room = get_room(rid);
				if (!room)
					return null;
				
				return (<div className="bulk-email-room-item p-grid" key={"bulk_room_key_" + rid}>
						<div className="p-col-1">
						</div>
						<div className="p-col-11 bulk-email-room-name">
							{room.name}
						</div>
					</div>);
			})}
		</ScrollPanel>
			</div>
		</div>
		</div>
		<br/><br/>
		<Button label="Confirm Rooms"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />
	
	</ModalCommand>
	);
}

function BulkEmailStep2Confirm(props) {
	const {onHide, onConfirm, subject, rids, par} = props;
	
	const num_rooms = rids.length;
	
	const be_list = get_bulk_email_list(par.state.bulk_email_list);
	
	return (
	<ModalCommand header="Confirm Bulk Email Content"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>

		<div className="p-grid">
		<div className="p-col-12">
			<b>Step 3 of 4</b>
		</div>
		</div>
		
		<div className="p-grid">
		<div className="p-col-12">
			Select the bulk email subject and text you would like to send to {num_rooms} room(s)
		</div>
		</div>

		<div className="p-grid">
		<div className="p-col-1" style={{paddingTop: '16px'}}>Subject:</div>
		<div className="p-col-11 wide-input">
			<InputText value={par.state.subject} 
				ref={lazyFocus}
		  		onChange={e => {
		  			par.setState({subject: e.target.value});
				}}
				className="bulk-email-input"
			/>
		</div>
		</div>
		
		<div className="p-grid">
		<div className="p-col-1" style={{paddingTop: '16px'}}>Cc:</div>
		<div className="p-col-11 wide-input">
			<InputText value={be_list.data.ccs_list} 
				disabled={true}
				className="bulk-email-input"
			/>
		</div>
		</div>

		<div className="p-grid">
		<div className="p-col-1" style={{paddingTop: '16px'}}>Bcc:</div>
		<div className="p-col-11 wide-input">
			<InputText value={be_list.data.bccs_list}  
				disabled={true}
				className="bulk-email-input"
			/>
		</div>
		</div>

		<div className="p-grid">
		<div className="p-col-12 wide-input">
			<SunEditor 
			//key={refresh}
			height="calc(100vh - 450px)"
			setDefaultStyle="font-family:Arial"
			setContents={
	sun_style_exclude_existing_tables( par.state.html )
			} 
			onChange={ content => {
				const html = sun_style_tweak(content);
				const plain = html2plain( html );
				par.setState({html: html, plain: plain});
			}}
			
			setOptions={{
			  buttonList: [
			['bold', 'underline', 'italic', 'list', 'outdent', 'indent', 'image', 'table', 'link'],
                        ['undo', 'redo'],
                        [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                        [':e-More Line-default.more_horizontal', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'lineHeight'],
                        [':r-More Rich-default.more_plus', 'table', 'link', 'image', 'video', 'audio', 'imageGallery'],
                        ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', ],
                			  ]
			}}
			/>
		</div>
		</div>

		<br/>
		<Button label="Confirm Email Subject and Text"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />
	
	</ModalCommand>
	);
}

function BulkEmailStep3Confirm(props) {
	const {onHide, onConfirm, subject, rids, par} = props;
	
	const num_rooms = rids.length;

	return (
	<ModalCommand header="Confirm Bulk Email"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>
		
		<div className="p-grid">
		<div className="p-col-12">
			<b>Step 4 of 4</b>
			<br/><br/>
		</div>
		</div>
		
		<div className="p-grid">
		<div className="p-col-12">
			<p>
				Ready to send bulk emails to {num_rooms} rooms? Once you confirm then this action cannot be undone.
			</p>
			<p>
				Once you initiate this process, emails will begin going out to clients. There is a slight delay between each email being sent out to prevent email blacklisting. You do not need to wait for this process to complete to perform other actions on the system. You may even log out before the process is complete, and it will continue in the background. You can use the progress circle in the upper-right of the screen to track the bulk email sending.

			</p>
		</div>
		</div>
		
		<br/><br/>
		<Button label="Send Bulk Emails"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />

	</ModalCommand>
	);
}

const plain2html = plain => plain.split('\n').join('<br/>')

class BulkEmail extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, par} = this.props;
		
		const eid = item.id;
		
		const room = get_room(rid);
		const working = false;
		const show_step1_confirm = false;
		const show_step2_confirm = false;
		const show_step3_confirm = false;
		const input_csv = false;
		const [rid_options, excluded_rids, error_buf] = this.get_rid_options('');
		const rids = [...rid_options];
		const subject = item.subject;
		const search_text = '';
		const csv_data = '';
		const ccs = '';
		const ccs_list = [];
		const bccs = '';
		const bccs_list = [];
		const step = 1;
		const bulk_email_list = null;
		
		if (!eid) {
			this.props.close();
		}

		const env = get_item(rid, 'shared', eid);
		if (!env) {
			this.props.close();
		}
		
		let plain = '';
		let html = '';
		
		if (env) {
			plain = get_body_part(env.body, 'text/plain');
			html = get_body_part(env.body, 'text/html') || plain2html(plain);
			const options = map_inline_attachments(env.body);
			html = sanitizeHtml(html, options);
		}

		this.state = {html, plain, rid:0, step, rids, rid_options, excluded_rids, error_buf, subject, search_text, ccs, ccs_list, bccs, bccs_list, show_step1_confirm, show_step2_confirm, show_step3_confirm, working, input_csv};
		
		window.g_bulkEmail = this;
	}
	
	on_body = e => {
		this.state.html = e.htmlValue;
		this.state.plain = e.textValue;
	}
	
	has_errors = () => {
		if (!this.props.item) {
			growl('Error', 'No email selected. Please close the popup', 'error');
			return true;
		}

		if (!this.state.bulk_email_list) {
			growl('Error', 'Please select a bulk email list', 'error');
			return true;
		}

		if (this.state.rids.length == 0) {
			growl('Error', 'Please choose at least one room', 'error');
			return true;
		}

		const be_list = get_bulk_email_list(this.state.bulk_email_list);
		const rids = be_list.data.rids;
		if ((!rids) || (rids.length == 0)) {
			growl('Error', 'Please choose a list with at least one room', 'error');
			return true;	
		}
		
		return false;
	}
	
	display_help = () => {
		
	}

	cancel = () => {
		this.props.close();	
	}
	
	prep_rooms_selected = () => {
		if (this.has_errors())
			return;
		
		const be_list = get_bulk_email_list(this.state.bulk_email_list);
		const rids = be_list.data.rids;
		
		this.setState({rids: rids, show_step1_confirm: true});
	}

	rooms_selected = () => {
		this.setState({show_step1_confirm: false, step: 2});

		this.prep_content_selected();
	}
	
	prep_content_selected = () => {
		if (this.has_errors())
			return;
		
		this.setState({show_step2_confirm: true});
	}

	content_selected = () => {
		this.setState({show_step2_confirm: false, step: 3});
		
		this.prep_send_emails();
	}
	
	prep_send_emails = () => {
		if (this.has_errors())
			return;
		
		this.setState({show_step3_confirm: true});
	}

	send_emails = () => {
		if (this.has_errors())
			return;
		
		const {subject, plain, html, rids, bulk_email_list} = this.state;
		const {item} = this.props;
		
		const eid = item.id;
		
		const be_list = get_bulk_email_list(this.state.bulk_email_list);

		this.setState({show_step3_confirm: false, working: true});
		
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {
			cmd: 'bulk_email', 
			bulk_email_list_id: be_list.id,
			eid, subject, plain, html, 
			bid, search_terms: this.state.search_text,
		}
		api( args, this.send_emails_complete );
		
		window.g_progCircle.add(bid, 
			"Sending Bulk Email(s)", rids.length);
	}

	send_emails_complete = (error) => {
		if (!error)
			growl("Sending Bulk Email(s)");
		
		this.props.close();
	}
	
	generate_search_params = (search_text) => {
		let name_params = [];
		let category_params = [];
		let field_params = [];
		
		search_text = search_text.trim();
		
		const components = search_text.split(' ');
		components.forEach( component => {
			if (!component.trim())
				return;

			let match = component.match(/(\S+?):(\S+)/g);
			if (match) {
				const parts = component.split(":");
				const key = parts[0];
				const fvalue = parts[1];
				
				if (key.toLowerCase() == 'category') {
					category_params.push(fvalue);
				} else {
					field_params.push([key, fvalue]);
				}
			} else {
				name_params.push(component);
			}
		});
		
		const params = {'name': name_params, 'category': category_params, 'field': field_params};
		
		return params;
	}
	
	get_rid_options = (value) => {
		const params = this.generate_search_params(value);

		let rid_options = [];
		let excluded_rids = [];
		
		let rooms_without_fields = {};
		let error_buf = '';

		params['field'].forEach( field_check => {
			const key = field_check[0];
			rooms_without_fields[key] = 0;
		})

		ldb.data.rooms._idlist.forEach( rid => {
			const room = ldb.data.rooms._items[rid];
			if (room.is_mailbox)
				return;
			
			let include = true;
			
			params['field'].forEach( field_check => {
				const key = field_check[0];
				const fvalue = field_check[1];
				
				// Check if this is a checkbox object
				// TBD Add syntax and support for checking if checkboxes are false
				if ((room.info[key]) && (room.info[key].value.constructor == Object)) {
					if (!room.info[key].value[fvalue]) {
						include = false;
					}
				} else if ((!room.info[key]) || (room.info[key].value != fvalue)) {
					include = false;
				}
				
				if (!room.info[key]) {
					rooms_without_fields[key] = rooms_without_fields[key] + 1;
				}
			});

			params['name'].forEach( name_check => {
				const re = RegExp(escape_reg_exp(name_check), 'i');
				const res = re.test(room.name) 
				if (!res)
					include = false;
			});

			params['category'].forEach( category_check => {
				const re = RegExp(escape_reg_exp(category_check), 'i');
				const res = re.test(room.category) 
				if (!res)
					include = false;
			});
						
			if (include)
				rid_options.push(room.id);
			else
				excluded_rids.push(room.id);
		});

		params['field'].forEach( field_check => {
			const key = field_check[0];
			const num_rooms_without_field = rooms_without_fields[key];
			if (num_rooms_without_field  > 0) {
				error_buf = error_buf + num_rooms_without_field + ' room(s) do not have the field "' + key + '" set\n';
			}
		})
		
		return [rid_options, excluded_rids, error_buf];
	}

	search_rooms = (value) => {
		const [rid_options, excluded_rids, error_buf] = this.get_rid_options(value);
		
		this.setState({search_text: value, rid_options, excluded_rids, error_buf, rids: [...rid_options]});
	}

	parse_csv_data = (value) => {
		const csv_data = value.trim();
		let emails = [];

		const components = csv_data.split(',');
		components.forEach( component => {
			const email = component.trim();
			if (!email)
				return;
			
			if (emails.indexOf(email) < 0) {
				emails.push(email);
			}
		});
		
		let rid_options = [];
		let excluded_rids = [];
		let error_buf = '';

		ldb.data.rooms._idlist.forEach( rid => {
			const room = ldb.data.rooms._items[rid];
			if (room.is_mailbox)
				return;

			let include = false;
			
			room.clients._idlist.forEach( cid => {
				const client = room.clients._items[cid];

				client.emails._idlist.forEach( eid => {
					const email = client.emails._items[eid].email;
					
					console.log('COMPARING EMAIL', email);
					if (emails.indexOf(email) > -1) {
						include = true;
					}
				});
			});
			
			if (include)
				rid_options.push(room.id);
			else
				excluded_rids.push(room.id);
		});
		
		this.setState({csv_data, rid_options, excluded_rids, error_buf, rids: [...rid_options]});
	}

	parse_cc_data = (value) => {
		const cc_data = value.trim();
		let emails = [];
		
		const components = cc_data.split(',');
		components.forEach(component => {
			const email = component.trim();
			if (!email)
				return;
			
			if (emails.indexOf(email) < 0) {
				emails.push(email);
			}
		});
		
		this.setState({ccs: value, ccs_list: emails});
	}

	parse_bcc_data = (value) => {
		const bcc_data = value.trim();
		let emails = [];
		
		const components = bcc_data.split(',');
		components.forEach(component => {
			const email = component.trim();
			if (!email)
				return;
			
			if (emails.indexOf(email) < 0) {
				emails.push(email);
			}
		});
		
		this.setState({bccs: value, bccs_list: emails});
	}

	room_selected = (rid) => {
		return this.state.rids.includes(rid);
	}

	toggle_select_room = (rid) => {
		let rids = this.state.rids;
		
		if (this.room_selected(rid)) {
			rids.splice(rids.indexOf(rid), 1);
		} else {
			rids.push(rid);
		}

		this.setState({rids});
	}

	render() {
		const {item} = this.props;
		
		const bulk_email_options = bulk_email_list_options();
	 	
		return (
		<ModalCommand par={this}
			className="medium-modal-command"
			header="Bulk Email">
	
	<div className="p-grid">
		<div className="p-col-12">
			<b>Step 1 of 4</b>
			<br/><br/>
		</div>
	</div>
	
	<div className="p-grid">
		<div className="p-col-2">Subject:</div>
		<div className="p-col-10">
			<InputText value={this.state.subject} 
				ref={lazyFocus}
				disabled={true}
		  		onChange={e => {
		  			this.setState({subject: e.target.value});
				}}
				className="bulk-email-input"
			/>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-2">Bulk Email List:</div>
		<div className="p-col-10">
			<Dropdown value={this.state.bulk_email_list}
					placeholder="Choose"
					options={bulk_email_options}
					onChange={ e => this.setState({bulk_email_list: e.value}) } 
				/>
		</div>
	</div>

	{this.state.error_buf && <div className="p-grid">
		<div className="p-col-2">
		</div>
		<div className="p-col-10 bulk-email-error-div">
			{this.state.error_buf}
		</div>
	</div>}
	{!this.state.working && <div className="p-grid">
		<div className="p-col-2">
			{this.state.show_step1_confirm && <div><BulkEmailStep1Confirm par={this} subject={this.state.subject} rids={this.state.rids} onConfirm={this.rooms_selected} onHide={e=>this.setState({show_step1_confirm: false})} /></div>}
			{this.state.show_step2_confirm && <div><BulkEmailStep2Confirm par={this} subject={this.state.subject} rids={this.state.rids} onConfirm={this.content_selected} onHide={e=>this.setState({show_step2_confirm: false})} /></div>}
			{this.state.show_step3_confirm && <div><BulkEmailStep3Confirm par={this} subject={this.state.subject} rids={this.state.rids} onConfirm={this.send_emails} onHide={e=>this.setState({show_step3_confirm: false})} /></div>}
		</div>
		<div className="p-col-10">
			<br/>
			<Button label="Prepare to Send"
				icon="pi pi-directions"
				onClick={this.prep_rooms_selected}
			/>
			{em(2)}
			<Button label="Cancel"
				className="bulk-email-secondary-button"
				icon="pi pi-times"
				onClick={this.cancel}
			/>
		</div>
	</div>}
	<div className="p-grid">
		<div className="p-col-12" style={{marginTop: '20px'}}>
			<span className="small">
				A copy of the email with the following subject will be sent to the firms in the bulk email list you select below:
			</span>
			{em(1)}
			<BulkEmailHelp />
			<br/>
			<br/>
		</div>
	</div>
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
		)
	}
}

export default BulkEmail;
