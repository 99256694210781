import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {InputTextarea} from 'primereact/inputtextarea';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {show_name} from './TaskUtil';
import {ModalCommand, Spacer, Focusable} from './Utils';

import {ldb, log, gen_email_summary, task_category_default, regcomp,
	get_first_name, get_staff, get_room, set_order, resort,
	map_list,
	priorities, get_tag, pri_v2n, edate, env_tags, rel_url,
	task_due_date_delta_default, growl, api, pri_n2v} from './Lib';


const getname = (who) => {
	let name = ( !who.is_client && who.id == ldb.data.me.id ? 'Me' : get_first_name(who.name) );
	if (!name) {
		try {
			name = who.emails._items[who.emails._idlist[0]].email;
		} catch(error) {
		}
	}
	
	return name;
}

function mysig() {
	return ' -' + 
		get_first_name(ldb.data.me.name) + '/' + 
		ldb.data.org.name.slice(0,24);
}

function SmsRecip(props) {
	const {whoall, par} = props;
	// whoall could be staff and clientusers

	return (
	<div className="new-sms-to">
		{whoall.map((who,i) => <Button key={i}
			label={getname(who)}
			onClick={e => {
				e.preventDefault();
				par.toggle(i);
			}}
	className={who._sms_sel ? 'p-button-primary' : 'p-button-secondary'}
		/>)}
		<div className="new-sms-tels">
		{whoall.map( (who, i) => <span key={i}>
				{who._sms_sel ? who.smstel : ''}
				</span> )}
		</div>
	</div>
	)
}

function Presets(props) {
	const my_presets = ldb.data.me.settings.sms_presets || [];
	
	const choicebuf = "Please respond to my email. : Sent you email. Please review and respond. : Please respond to emails quickly. : I'm on it and will get back to you shortly. ";
	const choices = choicebuf.split(':').map(x => x.trim()).concat(my_presets);

	return (
	<React.Fragment>
		{choices.map((choice,i) => <span className='preset' key={i}
		onClick={()=>props.par.setState({note:choice + mysig()})}
				>
					{choice}
			</span>)}
	</React.Fragment>
	);
}

class NewSms extends Focusable {
	constructor(props) {
		super(props);
		const note = 'Please respond to my email.' + mysig();
		this.state = {note, error: '',
			whoall: this.get_whoall(props.rid)};

		regcomp(this, 'newSMS');
	}

	get_whoall = rid => {
		const whoall = [];
		const room = get_room(rid);
		set_order(room.staffs);
		resort(room.staffs);

		log('email', 'newSms', room, this.props);
		map_list(room.clients, cuser => {
			if (cuser.sms_email)
				whoall.push(cuser);
		});
		map_list(room.staffs, staff => {
			if (staff.sms_email)
				whoall.push(staff);
		});
		whoall.map(item => (item._sms_sel = false));
		return whoall;
	}

	toggle = i => {
		const {whoall} = this.state;
		whoall[i]._sms_sel = !whoall[i]._sms_sel;
		this.setState({whoall});
	}

	onSend = () => {
		const {whoall, note} = this.state;
		const {rid, env, close} = this.props;
		const tids = whoall.filter(who => who._sms_sel).map(
				who => who.smstelid).join(',');
		if (!tids) {
			growl('Error', 'Please choose at least one recipient',
				'warn');
			return;
		}
		if (note.length < 6) {
			growl('Error', 'SMS Message is too short', 'warn');
			return;
		}
			
		const args = { cmd: 'send_sms', tids, note, rid, };
		api( args, this.sent, this.sent );
		
		close();
	}

	sent = (error) => {
		this.props.close();
	}

	render() {
		const {par, rid, mobile, close} = this.props;
		const {whoall} = this.state;

		if (mobile)
			par.action.handler = this.onSend;

		if (!whoall.length)
			return (
			<div>
		<br/>
		<br/>
		Cell phone numbers are not set for 
		any of the clients or staff.
		<p>
		Please set them before sending an SMS message to them.
		</p>
		<br/>
		<br/>
		<Button label="Cancel" icon="pi pi-times" 
			onClick={close}
			className="p-button-secondary"/>
		<br/>
		<br/>
			</div>
			);

		return (
	<Card className="new-sms">
<div className="p-grid">

	<label className="p-col-2 desc">
		To:
	</label>
	<div className="p-col-10">
		<SmsRecip par={this} whoall={whoall} />
	</div>

	<label className="p-col-2 desc">
		SMS:
	</label>
	<InputTextarea className="p-col-10"
		rows={2}
		ref={inp =>  (this._focusEle = inp)}
		value={this.state.note} 
		placeholder=""
		onChange={e => this.setState({note: e.target.value})}
			/>
	<label className="p-col-2 desc">
		Presets:
	</label>
	<div className="p-col-10">
		<Presets par={this} />
	</div>	

	{!mobile && <React.Fragment>

	<div className="p-col-12">
		&nbsp;
	</div>	

	<label className="p-col-2">
		
	</label>
	<div className="p-col-6">
		<Button label="Send" icon="pi pi-check" 
			onClick={this.onSend}
			style={{marginRight: '.25em'}}/>
		<Button label="Cancel" icon="pi pi-times" 
			onClick={close}
			className="p-button-secondary"/>
	</div>
	</React.Fragment>}
</div>
	{this.state.creating && <ProgressSpinner/>}
	</Card>
		);

	}
}

export {NewSms};
