import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {Calendar} from 'primereact/calendar';
import {lazyFocus} from './Utils'

import {ModalCommand, Spacer, Focusable} from './Utils';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v} from './Lib';

// ref = {el => (window.g_cal_el = el)}
const ThresholdDate = props => <div className="p-grid" style={{"marginTop":"320px"}}>
	<label className="p-col-2">Mark Before:</label>
	<div className="p-col-10 priority-buttons">
		<Calendar value={props.par.state.dt_due}
		inputRef={lazyFocus}
		onChange={ e => props.par.setState({ dt_mark_before: e.value }) }
		style={{zIndex:5000}}
		></Calendar>
	</div>
	</div>


class MarkTagsNotNeeded extends Focusable {
	constructor(props) {
		super(props);
		const {rid} = this.props;
		const dt_mark_before = window.g_moment().toDate();
		const room = get_room(rid);
		const working = false;

		this.state = {dt_mark_before, working};

		window.g_markTagsNotNeeded = this;
	}

	has_errors = () => {
		if (!this.state.dt_mark_before) {
			growl('Error', 'Please choose a date', 'error');
			return true;
		}

		return false;
	}

	update = () => {
		if (this.has_errors())
			return;

		const {dt_mark_before} = this.state;
		const {rid} = this.props;
		
		this.setState({working: true});

		const args = {
			cmd: 'mark_tags_not_needed', 
			rid, dt_mark_before,
		}
		api( args, this.updated );
	}

	updated = (error) => {
		if (!error)
			growl("Emails Marked");
		this.props.close();
	}

	render() {
		const {rid} = this.props;
	 	return (
		<ModalCommand par={this}
			className="modal-35-command"
			header="Mark Emails Before a Certain Date as Not Needing Tags">
	<p>
		Select a date below to mark all shared emails for this room
		before that date as not needing tags. 
	</p>
	<Spacer />
  	<ThresholdDate par={this} rid={rid} />
	<Spacer />
	{!this.state.working && <div className="p-grid">
		<div className="p-col-2"></div>
		<div className="p-col-10">
			<Button label="Update"
				icon="pi pi-plus"
				onClick={this.update}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
		)
	}
}

export default MarkTagsNotNeeded;
