import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Toolbar} from 'primereact/toolbar';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import {Dropdown} from 'primereact/dropdown';
import {SelectButton} from 'primereact/selectbutton';
import {Editor} from 'primereact/editor';
import Quill from 'quill';
import MagicUrl from 'quill-magic-url';

//**-------- SunEditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './suneditor-override.css';

/*
import {Editor} from 'slate-react';
import {TrixEditor} from 'react-trix';
*/

import {Spacer} from './Utils';

import {ldb, log, map_list, priorities, get_first_name, 
	list_rm_ele, task_category_options, em,
	get_room, get_staff, set_order, resort,
	html2plain, } from './Lib';

import {env_name_spans, } from './Persons';

Quill.register('modules/magicUrl', MagicUrl);
// ------- for future use, if we use editor ------->>
// ../../node_modules/primereact/components/editor/Editor.js has the full list
     // These two don't work.
     // <button className="ql-color" aria-label="Color"></button>
     // <button className="ql-background" aria-label="Background"></button>
const editorToolbar = (
<span className="ql-formats" style={{fontSize:'x-small'}}>
     <button className="ql-bold" aria-label="Bold"></button>
     <button className="ql-bold" aria-label="Bold"></button>
     <button className="ql-italic" aria-label="Italic"></button>
     <button className="ql-underline" aria-label="Underline"></button>


     <button className="ql-list" value='ordered' aria-label="Ordered List"></button>
     <button className="ql-list" value='bullet' aria-label="Unordered List"></button>
     {' '}
     <button className="ql-link" aria-label="Insert Link"></button>
</span>
);

// ------- for future use, if we use edior -------<<


//-------------------------

// TaskSubject
//	Legacy History: After typing one letter in Subject, it was losing
//		focus. User had to click again.
//	Solution: make it a component, keep subject in local state
//		and push it up to parent, only when it loses focus / Blur

class TaskSubject extends Component {
	constructor(props) {
		super(props);
		window.g_taskSubject = this;
		const subject = props.par.state.subject;
		this.state = {subject, orig: subject};
	}

	static getDerivedStateFromProps(props, state) {
		const subject = props.par.state.subject;
		if (state.orig != subject)
			return {orig: subject, subject};
		return null;
	}

	pushup = () => {
		const {subject} = this.state;

		this.props.par._focusEle = null;
		this.props.par.setState({subject});
	}

	onChange = e => {
		this.props.par._focusEle = null;
		this.setState({subject: e.target.value});
	}

	render() {
		const {label, autofocus, par} = this.props;
		return (
	<div className="p-grid">
	<label className="p-col-2">{label || 'Subject'}:</label>
	<InputText className="p-col-10"
		ref={inp =>  {
			if (autofocus)
				par._focusEle = inp;
		}}
		value={this.state.subject} 
		placeholder=""
		onChange={this.onChange}
	   	onBlur={this.pushup}
			/>
	</div>
		)
	}
}

const TaskCategory = props => {
	const options = task_category_options();
	if (options === null)
		return null;

	const par = props.par;

	return <div>
	<Spacer/>
	<div className="p-grid">
	<label className="p-col-2">Category:</label>
		<Dropdown value={par.state.category}
			placeholder="Choose"
			options={options}
			onChange={ e => par.setState({category: e.value}) } 
		/>
	</div>
	</div>;
	}

const TaskDone = props => <div className="p-grid">
	<label className="p-col-2">Done:</label>
	<Checkbox 
		onChange={ e => props.par.setState({done: e.checked}) }
		checked={props.par.state.done}>
	</Checkbox>
	</div>

const TaskCommand = props => <div className="p-grid">
	<div className="p-col-2"></div>
	<div className="p-col-10">
		<Button label={props.label} 
			icon={"pi " + (props.icon || "pi-plus")}
			onClick={props.onClick}
		/>
	</div>
  </div>

const TaskAttach = props => {
	if (!props.email.uid)
		return null;
	log('email', 'Attach', props.email);
	const env = props.email;
	return (
	<div className="p-grid">
		<label className="p-col-2">Email:</label>
		<div className="p-col-10">
			<b>From:</b> {env_name_spans(env, 'from')} 
			{' '}
			<b>Subject:</b> {env.subject}
		</div>
	</div>
	)
}

class EditNote extends Component {
	constructor(props) {
		super(props);
		window.g_editNote = this;
	}

	componentDidMount() {
		// Users want to tab from Subject and go into Message Body.
		//	Tooblar interferes with that.
		// Editor includes the toolbar buttons for Bold, Italic etc.
		//	in the bTab Order.
		// Remove all buttons in the Editor Toolbar from the tab order.
		//this._el.toolbarElement.querySelectorAll('button').forEach(
		//	btn => {btn.tabIndex = -1}
		//);
	}

	render() {
		const props = this.props;
		const cls = props.className || 'task-edit-note';

		const extra = props.fullMenu ? {} : 
				{headerTemplate:editorToolbar};

	return <div className="p-grid">
	<label className="p-col-2">{props.label || 'Note:'}</label>

	<div className="p-col-10">
		<SunEditor 
			//key={refresh}
			height="calc(100vh - 450px)"
			setDefaultStyle="font-family:Arial"
			setContents={props.par.state.html} 
			onChange={ content => {
				const html = content;
				const plain = html2plain( html );
				props.par.setState({html: html, plain: plain});
			}}
			
			setOptions={{
			  buttonList: [
			['bold', 'underline', 'italic', 'list', 'outdent', 'indent', 'image', 'table', 'link'],
                        ['undo', 'redo'],
                        [':p-More Paragraph-default.more_paragraph', 'font', 'fontSize', 'formatBlock', 'paragraphStyle', 'blockquote'],
                        [':t-More Text-default.more_text', 'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript', 'fontColor', 'hiliteColor', 'textStyle', 'removeFormat'],
                        [':e-More Line-default.more_horizontal', 'outdent', 'indent', 'align', 'horizontalRule', 'list', 'lineHeight'],
                        [':r-More Rich-default.more_plus', 'table', 'link', 'image', 'video', 'audio', 'imageGallery'],
                        ['-right', ':i-More Misc-default.more_vertical', 'fullScreen', 'showBlocks', 'codeView', ],
                			  ]
			}}
		/>
	</div>
			
	</div>
	}
}

// Light wrapper for Editor. Make its toolbar not tab-able;

function untabEditorToolbar() {
	const el = document.querySelector('div.p-editor-toolbar');
	//log('email', 'EditBody.untab', el);

	if (el)
		el.querySelectorAll(
			'button, select, span'
		).forEach( btn => {
			//log('email', 'EditBody.untab.button', btn);
			btn.tabIndex = -1;
		} );
}

function EditBody(props) {
	const untab = el => {
		//log('email', 'EditBody.untab', el);
		if (el)
			setTimeout(untabEditorToolbar, 500);
			
		// some elements are added after toolbar is created.
		//	wait a few few min before resetting it.
		/*
		return el && el.toolbarElement.querySelectorAll(
		'button, select, span[role="button"]'
			).forEach( btn => {
				log('email', 'EditBody.untab.button', btn);
				btn.tabIndex = -1;
			} );
		*/
	}
var toolbarOptions = [
  [{ 'font': [] }],
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  [{ 'size': ['normal', 'small', 'large', 'huge'] }],  // custom dropdown
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  // ['blockquote', 'code-block'],

  // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  // [{ 'direction': 'rtl' }],                         // text direction

  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

  [{ 'align': [] }],
  ['link', 'image'],

  ['clean']                                         // remove formatting button
];


	let klass = 'edit-body';
	if (props.editor_klass)
		klass += ' ' + props.editor_klass;

	// TBD: Add drag and drop

	const newprops = props.mobile ? 
		{...props, headerTemplate: editorToolbar}:
		props;
	
	// editorToolbar is display=none in App.css for Mobile
	return <Editor className={klass} ref={untab} 
		{...newprops}
		modules={{magicUrl:true}}
	/>;
		// modules={{magicUrl:true, toolbar: toolbarOptions}}
}

/*
const SlateEditNote = props => {
	return <div className="p-grid">
	<label className="p-col-2">{props.label || 'Note:'}</label>
	<Editor style={{height:'8em'}} className="p-col-10"
	    	value={props.par.state.html}
	    	onChange={html => props.par.setState({html,
			plain: html})} />
	</div>
}


class EditNote extends Component {
	ready = editor => editor.insertString(this.props.par.state.html)
	
	change = (html, text) => {
		this.props.par.setState({html, plain:text});
	}

	render() {
	return <div className="p-grid">
	<label className="p-col-2">{this.props.label || 'Note:'}</label>
	<TrixEditor onChange={this.change} 
				onEditorReady={this.ready} 
			style={{height:'8em', width:'10em'}} className="p-col-10"
			/>
	</div>
	}
}
*/


const TaskNote = props => <div className="p-grid p-align-stretch">
	<label className="p-col-2">Note:</label>
	<InputTextarea className="p-col-10 task-edit-note" 
		autoResize={true}
	    	value={props.par.state.note} 
		ref={inp =>  {
			if (props.autofocus)
				props.par._focusEle = inp;
		}}
		onChange={ e => props.par.setState({note: e.target.value}) }
		/>
	</div>



const TaskPriority = props => {
	const unchosen = props.par.state.priority === null;
	
	return <div className="p-grid">
	<label className="p-col-2">
		Priority: {unchosen && <span className="task-to-choose">Choose</span>}
	</label>
	<div className="p-col-10 priority-buttons">
		<SelectButton value={props.par.state.priority}
	    		options={priorities}
			onChange={ e => props.par.setState({ priority: e.value }) }
				></SelectButton>
	</div>
	</div>
	}

const TaskDueDate = props => <div className="p-grid">
	<label className="p-col-2">Due:</label>
	<div className="p-col-10 priority-buttons">
		<Calendar value={props.par.state.dt_due}
		onChange={ e => props.par.setState({ dt_due: e.value,
				dt_due_mod: true}) }
		style={{zIndex:5000}}
				></Calendar>
		{props.show_done && 
		    <span>
			{em(2)}
			<Checkbox 
				onChange={ e => props.par.setState(
					{done: e.checked}) }
				checked={props.par.state.done}>
			</Checkbox>
			{em(0.5)}
			Done
		    </span>
		}
	</div>
	</div>

const getname = sid => ( sid == ldb.data.me.id ? 'Me' :
				get_first_name(get_staff(sid).name) )

const TaskSelectStaff = props => {
	const {par, rid, exclude_me, nobody, multiple, label} = props;
	const room = get_room(rid);
	set_order(room.staffs);
	resort(room.staffs);
	const order = room.staffs._order.slice(); // clone
	if (exclude_me)
		list_rm_ele(order, ldb.data.me.id);
	const staffs = order.map(sid => ({label : getname(sid), value: sid} ));

	const unchosen = par.state.to_sid === 0 && !nobody;

	if (nobody)
		staffs.push({label: 'Nobody', value: 0});
	
	return <div>
		<div className="p-grid">
	<label className="p-col-2">
		{label || 'To'}:
		{unchosen && <span className="task-to-choose">Choose</span>}
	</label>
	<div className="p-col-10 priority-buttons">
		<SelectButton value={par.state.to_sid}
	    		options={staffs}
			multiple={multiple ? true : false}
			onChange={ 
				e => par.setState({ to_sid: e.value }) 
			}
		></SelectButton>
	</div>
		</div>
		{multiple && <div className="p-grid">
			<label className="p-col-2"></label>
			<div className="p-col-10 helpinfo">
				Creates a separate task for each selected staff.
			</div>
			</div>}
		</div>
}

const NudgeSubjectSelect = props => {
	const par = props.par;

	const subjects = props.par.state.nudge_subject_options;

	return <div>
		<div className="p-grid">
			<label className="p-col-2">
				Subject:
			</label>
			<div className="p-col-10 priority-buttons">
				<Dropdown value={props.par.state.nudge_subject} options={subjects} onChange={e => props.par.setState({ nudge_subject: e.value })} />
			</div>
		</div>
		</div>
}



export {TaskSubject, TaskAttach, TaskNote, EditNote, EditBody,
		TaskPriority, TaskSelectStaff, TaskCategory,
		TaskDueDate, TaskDone, TaskCommand,
		NudgeSubjectSelect, editorToolbar,} 

