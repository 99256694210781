import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {Focusable, Spacer} from './Utils';
import {ldb, log, api, go, edate, edate3, map_list, go_url, regcomp,
	set_order, resort, get_prev_room, get_next_room, 
	get_first_name, show_is_new, get_room, get_staff, } from './Lib';

//------------------ Onlines  ------------------------


// "bulb" = dot representing status of a given staff member
//	green - online, yellow - idle - grey - off
// An 24x24 icon with 4x4 dots with 3 padding gives 3x3 bulbs = 9 staff
//	3x3 dots with 2 padding = 4x4 bulbs = 16 staff
//	2x2 dots with 2 padding = 5x5 bulbs = 25 staff

class SVG {
	constructor(size) {
		size = size || 24;	// 24x24 icon

		this.size = size;
		this.reset();
		this.tail = '</svg>';
	}

	reset () {
		const size = this.size;

		this.parts = [

		'data:image/svg+xml;utf8,',

		'<?xml version="1.0" standalone="no"?>',

	`<svg width="${size}" height="${size}" version="1.1" xmlns="http://www.w3.org/2000/svg">`,

	`<rect x="0" y="0" width="${size}" height="${size}" stroke="black" stroke-width="1" fill="white" />`,	// black border, white fill

		];
	}

	url () {
		return this.parts.join('') + this.tail;
	}

	rect (x,y,color, side) {// 4x4 dot of chosen color at chosen loc
		const part = `<rect x="${x}" y="${y}" width="${side}" height="${side}" stroke="${color}" stroke-width="1" fill="${color}" />`
		this.parts.push( part );
	}
}

class BulbSet {
	constructor(max) {
		max = max || 9;

		let side, pad, count;
		if (max <= 9) {
			side = 4; pad = 3; count=9;
		}
		else if (max <= 16) {
			side = 3; pad = 2; count=16;
		}
		else {
			side = 2; pad = 2; count=25;
		}
		this.side = side; 
		this.pad = pad;
		this.max = max;
		this.count = count;

		this.svg = new SVG();

		this.colors = {'online' : 'green', 
			'idle' : 'blue', 
			'offline' : 'brown'};
	}


	offset (unit) {
		return this.pad + unit * (this.pad + this.side);
	}

	set (row, col, color) {
		const y = this.offset( row );
		const x = this.offset( col );
		
		this.svg.rect( x, y, color, this.side );
	}

	gen (arr) {
		this.svg.reset();

		let index = 0;
		for (let r=0; r<3; r++) {
			for (let c=0; c<3; c++) {
				let item = arr[index++];
				if (item === undefined)
					continue;
				const color = this.colors[item.mode];
				this.set(r, c, color);
			}
		}

		return this.svg.url();
	}
}

function get_online_data() {
	const root = ldb.data.staffs;
	set_order(root);
	resort(root);

	const data = map_list(root, staff => {
		let name = staff.name, online = false, idle = true;
		const oids = [...staff.onlines];	// set to array
		oids.map(oid => {
			const info = ldb.data.onlines._items[oid];
			if (!info.dt_ended && info.is_recent) {
				online = true;
				if (!info.dt_idle)
					idle = false;
			}
			//log('online', 'data', name, online, idle, info, staff);
		});
		const mode = online ? (idle ? 'idle' : 'online') : 'offline';
		return {name, mode};
	});

	return data;
}

function OnlinePanel(props) {
	const {ncols, data} = props;
	const klass = 'p-col-' + Math.round(12/ncols) + ' online-item-';

	return (
	<div className={"online-panel online-cols-" + ncols}>
	<div className="p-grid">
		{data.map((item,i) => (
			<div className={klass + item.mode} key={i}>
				{get_first_name(item.name)}
			</div>))}
	</div>
	<div className="online-legend">
		Staff Online Status
	</div>
	</div>
	);
}

function RoomPanel(props) {
	const {count} = props;
	
	const prev_room = get_prev_room();
	const next_room = get_next_room();

	return (
	<span className="online-icon layout-topbar-icon animated swing">
	<i className="layout-topbar-myrooms-icon fa fa-th-large"></i>
	<div className={"online-panel online-cols-3"}>
	<div className="p-grid">
		<div className="p-col-12" style={{textAlign:'center'}}>
			<Link to={go_url('myrooms')} style={{textAlign:'center', marginLeft:'0px', color:'inherit'}}>
				Go to My Rooms
			</Link>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-1">
			{prev_room && <div onClick={e => {go('room', prev_room.id)}}>
			<i className="fa fa-fw fa-chevron-left small pointer"
				title="Previous Room"
			>
			</i>
			</div>}
		</div>
		<div className="p-col-5" style={{overflowWrap:'anywhere'}}>
			{prev_room && <div onClick={e => {go('room', prev_room.id)}}>
				{prev_room.name}
			</div>}
		</div>
		<div className="p-col-5" style={{overflowWrap:'anywhere'}}>
			{next_room && <div onClick={e => {go('room', next_room.id)}} style={{textAlign:'right'}}>
				{next_room.name}
			</div>}
		</div>
		<div className="p-col-1">
			{next_room && <div onClick={e => {go('room', next_room.id)}} style={{paddingRight:'5px'}}>
			<i className="fa fa-fw fa-chevron-right small pointer"
				title="Next Room"
			>
			</i>
			</div>}
		</div>
	</div>
	</div>
	</span>
	);
}

function RecentRoomsPanel(props) {
	const {} = props;
	
	const prev_room = get_prev_room();
	const next_room = get_next_room();

	return (
	<span className="online-icon layout-topbar-icon animated swing">
	<i className="layout-topbar-myrooms-icon fa fa-th-large"></i>
	<div className={"online-panel online-cols-3"}>
	<div className="p-grid">
		<div className="p-col-12" style={{textAlign:'center'}}>
			<Link to={go_url('myrooms')} style={{textAlign:'center', marginLeft:'0px', color:'inherit'}}>
				Go to My Rooms
			</Link>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-1">
			{prev_room && <div onClick={e => {go('room', prev_room.id)}}>
			<i className="fa fa-fw fa-chevron-left small pointer"
				title="Previous Room"
			>
			</i>
			</div>}
		</div>
		<div className="p-col-5" style={{overflowWrap:'anywhere'}}>
			{prev_room && <div onClick={e => {go('room', prev_room.id)}}>
				{prev_room.name}
			</div>}
		</div>
		<div className="p-col-5" style={{overflowWrap:'anywhere'}}>
			{next_room && <div onClick={e => {go('room', next_room.id)}} style={{textAlign:'right'}}>
				{next_room.name}
			</div>}
		</div>
		<div className="p-col-1">
			{next_room && <div onClick={e => {go('room', next_room.id)}} style={{paddingRight:'5px'}}>
			<i className="fa fa-fw fa-chevron-right small pointer"
				title="Next Room"
			>
			</i>
			</div>}
		</div>
	</div>
	</div>
	</span>
	);
}


window.g_onlineRefresh = () => null;

function Onlines(props) {
	const ncols = 3;

	const data = get_online_data();
	const bulbs = new BulbSet( ncols*ncols );
	const url = bulbs.gen(data);
	const [refresh, setRefresh] = React.useState(0);

	window.g_onlineRefresh = () => setRefresh(refresh+1);

	// log('online', 'svg', url);

	return <span className="online-icon"
			onClick={()=>go('settings', 'staff')} >
		<img className="online-icon" src={url} />
		<OnlinePanel ncols={ncols} data={data} />
	</span>;
}

export {Onlines, RoomPanel, RecentRoomsPanel};
