import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppMenu} from './AppMenu';
import {AppInlineProfile} from './AppInlineProfile';
import {Route, Redirect, useHistory} from 'react-router-dom';
import IdleTimer from 'react-idle-timer'
import {ScrollPanel} from 'primereact/scrollpanel';
import {Toast} from 'primereact/toast';
import 'primereact/resources/themes/nova/theme.css';
// import 'primereact/resources/themes/nova-light/theme.css';
// import 'primereact/resources/themes/fluent-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'fullcalendar/main.css';
import 'font-awesome/css/font-awesome.css';
import './layout/layout.css';
import './App.css';

import {Dashboard} from './cw/Dashboard';	// modified copy
import ViewRoom from './cw/ViewRoom';
import {MyTags} from './cw/Tag';
import {FindEmails} from './cw/FindEmails';
import {MyTasks} from './cw/MyTasks';
import {Rooms} from './cw/Rooms';
import {CreateRoom} from './cw/CreateRoom';
import StaffList from './cw/StaffList';
import LoginPage from './cw/LoginPage';
import ForgotPassword from './cw/ForgotPassword';
import Reconnect from './cw/Reconnect';
import {Documentation, LatestFeatures} from "./cw/Documentation";
import {Profile} from "./cw/Profile";
import {OrgProfile} from "./cw/Org";
import {Diag} from "./cw/Diag";
import {Logs} from "./cw/Logs";
import {NotificationLogs} from "./cw/NotificationLogs";
import {Chat} from "./cw/Chat";
import {Contacts} from "./cw/Persons";
import {MobileApp} from "./cw/Mobile";
import {loginMenu, clientWinMenu} from './cw/Menu';
import {FeedbackList, FeedbackSubmit, FeedbackView} from "./cw/Feedback";
import {Sandbox} from "./cw/Sandbox";
import {Calendar} from "./cw/Calendar";
import {RecentRooms, RecentDrafts} from './cw/Recent';
import {ModalCommand} from './cw/Utils';

import {ldb, log, set_order, map_list, go, get_room, api,
	idleTracker, compare_items, sort_room_by_activity,
	is_test_server, settings_url, go_to_settings_url} from './cw/Lib';

class BadPassword extends Component {
	constructor(props) {
		super(props);
		
		const {shared} = props;

		this.state = {
        		working: false,
			shared: shared,
		};
	}

	get_nonce = e => {
		if (!this.state.working) {
			this.setState({working: true});
			
			const args = { cmd: 'nonce', }
			api( args, this.got_nonce );
		}
		return false;
	}

	got_nonce = error => {
		if (this.state.shared) {
			go_to_settings_url('org_mailbox');
		} else {
			go_to_settings_url('staff_mailbox');
		}
	}

	empty () {
	}

	render() {
		if (this.state.shared) {
			if (ldb.data.shared_mailbox && ldb.data.shared_mailbox.use_oauth) {
			return (<ModalCommand
			onHide={this.empty}
			header="OAuth Reauthorization Required"
			extra={{width:"30%", minY: 70, className: ''}}
			>
				TagInbox is unable to connect to the shared mailbox for your organization. Please reauthorize.
				<br/><br/>
			    <a href="#" 
				onClick={this.get_nonce}
			    >
			    <i className="pi pi-fw pi-cog"></i>
				    Reauthorize TagInbox
			    </a>
			</ModalCommand>);	
			} else {
			return (<ModalCommand
			onHide={this.empty}
			header="Incorrect Email Password"
			extra={{width:"30%", minY: 70, className: ''}}
			>
				The shared email account password settings for your organization do not appear to be correct.
			        <br/><br/>
			        Please click the button below to go update them.
				<br/><br/>
		    	<a href="#" 
				onClick={this.get_nonce}
		    	>
		    	<i className="pi pi-fw pi-cog"></i>
				    Launch Mail Settings
		    	</a>
			</ModalCommand>);
			}
		} else {
			if (ldb.data.mailbox && ldb.data.mailbox.use_oauth) {
			return (<ModalCommand
			onHide={this.empty}
			header="OAuth Reauthorization Required"
			extra={{width:"30%", minY: 70, className: ''}}
			>
				TagInbox is unable to connect to your mailbox. Please reauthorize.
				<br/><br/>
			    <a href="#" 
				onClick={this.get_nonce}
			    >
			    <i className="pi pi-fw pi-cog"></i>
				    Reauthorize TagInbox
			    </a>
			</ModalCommand>);	
			} else {
			return (<ModalCommand
			onHide={this.empty}
			header="Incorrect Email Password"
			extra={{width:"30%", minY: 70, className: ''}}
			>
				Your email account password settings do not appear to be correct.
			        <br/><br/>
			        Please click the button below to go update them.
				<br/><br/>
			    <a href="#" 
				onClick={this.get_nonce}
			    >
			    <i className="pi pi-fw pi-cog"></i>
				    Launch Mail Settings
			    </a>
			</ModalCommand>);
			}
		}
	}
}

/*
 BackMon : Tracks user pressing back and forward buttons in the browser.
 	PrimeReact does NOT update Menu selections and title automatically.
	Previous versions used to do some work, but they don't now.
	We explicitly hook useHistory to track them.

	Each time it changes, we refresh AppMenu, which will highlight
		the currently chosen menu and update Title.

	I was tracking paths/forwards to get this working,
		but it may no longer be needed.

	I was trying to isolate Back/Forward and separate it from
		clicking to go to new address. So we refresh only then.
		So started tracking paths/forwards.
		But now, we *always* refresh menu, because
		all addresses come as POP and we can't distinguish.
 */
function BackMon() {
	const [ paths, setPaths ] = React.useState([]);	 	// not needed?
	const [ forwards, setForwards ] = React.useState([]);	// not needed?

	const history = useHistory();

	const onChange = (loc, action) => {

		const path  = loc.pathname;
		let kind = '';	// new | back | forward
		let cur = '';

		if (action == 'REPLACE') 
			kind = 'new';
		else if (action == 'POP') {
			if (paths[1] == path) {
				kind = 'back';

				cur = paths.shift();

				forwards.unshift(cur);
				setForwards(forwards);
			}
			else if (forwards[0] == path) {
				kind = 'forward';

				paths.unshift(path);
				setPaths(paths);

				forwards.shift();
			}
			else 
				kind = 'new';
		}

		if (kind == 'new') {
			paths.unshift(path);
			forwards.length = 0;
			// forwards.splice(0, forwards.length); alt
		}
		/*
		if (kind == 'back' || kind == 'forward' || paths.length == 1) {
			if (window.g_appMenu_reload) 
				window.g_appMenu_reload();
		}
		*/
		if (window.g_appMenu_reload) 
			window.g_appMenu_reload();

		setPaths(paths);
		setForwards(forwards);

		// g_appMenuTab.refresh();

		// log('lib', 'BackMon-------', kind, loc, action, path, 
				// paths, forwards);
	};

	React.useEffect(() => history.listen(onChange, [ paths, ]))

	// log('lib', '222--BackMon-------', paths, forwards);

	return null;
}

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
	    count: 0,
        };

	window.is_mobile = !this.isDesktop();
	// In Email Compose - switch to-mode to 's' when user goes to subject
	//	to give more room in editor window on mini laptops.
	//	One client's screen height was around 730px.
	window.is_mini_laptop = !window.is_mobile && 
		((window.screen.availHeight || window.screen.height) < 1080); 


        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
	window.g_app = this;

	this.idleTimer = null;
	this.onAction = this._onAction.bind(this);
	this.onActive = this._onActive.bind(this);
	this.onIdle = this._onIdle.bind(this);
    }

    refresh = (url) => {
        const count = this.state.count + 1;
	log('app', 'refresh', count, url);
	// window.g_appMenu.refreshMenu();
	this.setState({count})

	if (url !== undefined)
		window.location.href = url;
	
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
        //setTimeout(() => {this.layoutMenuScroller.moveBar(); }, 500);
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    /** Menu Generation << **/

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 620;
        // return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    componentDidMount() {
	/*
    	const history = useHistory();
    	this.backListener = history.listen( (loc, action) => {
		log('app', 'browserBack', loc, action);
	});
	*/
    }
	

    /* idle timer >> */
	_onAction(e) {
		// console.log('user did something', e)
	}

	_onActive(e) {
		// console.log('user is active', e)
		// console.log('time remaining', this.idleTimer.getRemainingTime())
		idleTracker.onActive();
	}

	_onIdle(e) {
		// console.log('user is idle', e)
		// console.log('last active', this.idleTimer.getLastActiveTime())
		idleTracker.onIdle(this.idleTimer.getLastActiveTime());
	}

    /* idle timer << */


    render() {
    	
	if (window.is_mobile && ldb.data.me != null)
		return <MobileApp />;

	if (ldb.data.bad_shared_password) {
		return <BadPassword shared={true} />;
	}
	
	if (ldb.data.bad_password) {
		return <BadPassword shared={false} />;
	}
	
	const test_server = is_test_server();

        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive,
	    'layout-test-server' : test_server,
        });
        let sidebarClassName = classNames("layout-sidebar", {'layout-sidebar-dark': this.state.layoutColorMode === 'dark'});

	this.menu = ldb.is_logged_in() ? clientWinMenu : loginMenu;

	log('app', 'render', window.location.href);

	// RAJA : removed this line for Wrapper Class
	// 	onClick={this.onWrapperClick} 

	// Idle Timer default: 15 minutes: timeout={1000 * 60 * 15} />
        return (
            <div className={wrapperClass} >
		<IdleTimer 
			ref={ref => {this.idleTimer = ref}}
			element={document}
			onActive={this.onActive}
			onIdle={this.onIdle}
			onAction={this.onAction}
			debounce={250}
			timeout={idleTracker.idle_timeout_seconds * 1000} />
                <AppTopbar onToggleMenu={this.onToggleMenu} />
		<BackMon />
		<Toast ref={ el => {
			window.g_growl = el;
			// log('lib', '****Toast', el);
		}}
		></Toast>

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

                    <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{height:'100%'}}>
                        <div className="layout-sidebar-scroll-content" >
                            <AppInlineProfile />
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                        </div>
                    </ScrollPanel>
                </div>

		{/* IMPORTANT: On initial startup, return limited
			Routes, / matches everything except forgotpw
			So we log user in, before going to that URL
		*/}
			
		{
		ldb.data.me == null ?
			<div className="layout-main">
			    <Route path="/" component={LoginPage} />
			    <Route path="/public/forgotpw" 
			    		component={ForgotPassword} />
			</div>
			:
                <div className="layout-main">
		    <Route path="/" exact component={Dashboard} />

 <Route path="/room/:rid(\d+)/:tab?/:iid([\-]?\d+)?/:command(\w+)?/:cid(\d+)?" 
				component={ViewRoom} />
                    <Route path="/mytags/:tsubject(.+)?" component={MyTags} />
                    <Route path="/task/:tsubject(.+)?" component={MyTasks} />
                    <Route path="/find_emails" component={FindEmails} />
 <Route path="/myrooms/:rid(\d+)?/:command(\w+)?/" component={Rooms} />
                    <Route path="/add/create_room" component={CreateRoom} />

                    <Route path="/login" component={LoginPage} />

                    <Route path="/reconnect/:reason/:arg?" 
		    			component={Reconnect} />

                    <Route path="/settings/staff" component={StaffList} />
                    <Route path="/settings/org/:command(.+)?" 
		    			component={OrgProfile} />
                    <Route path="/settings/profile/:command(.+)?" 
		    			component={Profile} />
                    <Route path="/settings/diag" component={Diag} />
                    <Route path="/settings/logs" component={Logs} />
                    <Route path="/settings/notification_logs" exact
		    			component={NotificationLogs} />

                    <Route path="/recent/rooms" component={RecentRooms} />
                    <Route path="/recent/drafts" component={RecentDrafts} />

		    <Route path="/feedback/submit" component={FeedbackSubmit} />
		    <Route path="/feedback/list" component={FeedbackList} />
	 	    <Route path="/feedback/view/:fid(\d+)" component={FeedbackView} />

	 	    <Route path="/sandbox" component={Sandbox} />
                    <Route path="/documentation" component={Documentation} />
                    <Route path="/latest_features" component={LatestFeatures} />
                    <Route path="/contact" component={Contacts} />
                    <Route path="/chat" component={Chat} />
                    <Route path="/calendar" component={Calendar} />

                </div>
		}

                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default App;
