import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';

import {EmailHeader, FullEmail} from './EmailUtil';
import ListView from './ListView';

import {ldb, log, idleTracker, set_next_fetch_time, init_tab_db,
	get_room, init_list, reset_page,
	resort, set_order, em, } from './Lib';

const page_size_options = [
	{label: '25', value: 25},
	{label: '50', value: 50},
	{label: '100', value: 100},
	{label: '200', value: 200},
	{label: '500', value: 500},
	{label: 'All', value: 10000},
];

//-------------- Base Emails, common to inbox/shared ---------------
// Input Props:
// 	rid, tab, iid

class BaseEmails extends ListView {
	show_item_header = (env, is_new) => <EmailHeader 
			env={env} 
			is_new={is_new}
			tab={this.props.tab} 
			rid={this.props.rid} 
		/>
	
	show_item_detail = env => <FullEmail 
			env={env} 
			tab={this.props.tab} 
			rid={this.props.rid}
			par={this}
			command={this.props.command}
		/>

	fetch_older = () => {
		this.setState({busy:true});
		return this.fetch({older:true})
	}

	fetched_older = () => {
		const list = this.get_item_list();
		resort(list);
		set_order(list);
	}

	page_size = page => {
		log('unshared', 'page.size', page.size);
		
		//const room = get_room(this.props.rid);
		const size_options = page_size_options;
		
		//if (!room.is_mailbox) {
		//	size_options.push({label: 'All', value: 1000000});
		//}

		return (
		<Dropdown value={page.size}
			className="toolbar-dropdown page-options"
	    		options={size_options}
			onChange={e => {
				page.size = e.value;
				this.fetch_page();
			}}
		/>
		);
	}

	reset = (kind, search) => {
		// Erase currently fetched data for the tab.
		//	preserve kind, search. Toggle kind if needed.
		// Reload/refresh with new id.
		const {rid, tab} = this.props;
		const room = get_room(rid);
		const page = room[tab]._flags.page;

		const okind = page.kind;
		const osearch = page.search;
		const osize = page.size;

		if (kind == -1)
			kind = (okind == 'client_emails') ? 
					'inbox_emails' : 'client_emails';
		kind = kind === undefined ? okind : kind;
		search = search === undefined ? osearch : search;

		init_tab_db(room, tab);
		room[tab]._flags.page.kind = kind;
		room[tab]._flags.page.search = search;
		room[tab]._flags.page.size = osize;
		this.setState({search_mod:false});
		this.refresh();
		this.redir_iid(-1);
	}

	setSearchText = (text) => {
		this.get_page().search = text;
		this.setState({search_mod:true});
		this.refresh();
		this.doSearch();
	}

	onSearchText = e => {
		this.get_page().search = e.target.value;
		this.setState({search_mod:true});
		this.refresh();
	}

	doSearch = e => this.reset()
	clearSearch = e => this.reset(undefined, '')

	keyDown = e => {
		if (e.key === 'Enter') 
			this.doSearch();
		else if (e.key === 'Escape') 
			this.clearSearch();
	}

	search_bar = () => {
		let search = this.get_page().search;
		
		let search_button = (this.state.search_mod && search) ? true : false;
		let clear_button = (search) ? true : false;
				
		return <React.Fragment>
		<i className="pi pi-fw pi-search"></i>
		{em(0.5)}
	<InputText value={search} 
		placeholder="Search Emails"
		onChange={this.onSearchText}
		onKeyDown={this.keyDown}
			/>
		{search_button && <Button label='' icon='pi pi-fw pi-check'
				onClick={this.doSearch} />}
		{(!search_button) && em(2)}
		{clear_button && <Button label='' icon='pi pi-fw pi-times'
				onClick={this.clearSearch} />}
		{(!clear_button) && em(2)}
		</React.Fragment>
	}
}

export default BaseEmails;
