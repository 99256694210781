import React, {Component} from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {RadioButton} from 'primereact/radiobutton';
import {InputText} from 'primereact/inputtext';
import {Toolbar} from 'primereact/toolbar';

import ListView from './ListView';
import UpdateTask from './UpdateTask';
import {TaskCommand} from './EditTask';
import {ModalCommand, MoreMenu, Spacer, Focusable} from './Utils';

import {ldb, log, set_order, map_list, filter_list, my_assign_id,
	get_room, growl, api} from './Lib';

//-------------- Utils ---------------

class FilterTasks extends Focusable {
	constructor(props) {
		super(props);
		const list = props.par.get_item_list();
		if (list._flags._show_all === undefined) {
			list._flags._show_all = false;
			list._flags._fetched_done_tasks = false;
		}
		this.state = {show: list._flags._show_all ? 'all' : 'active'}
	}

	filter_active_only = (task, list) => task.to_sid > 0

	onSet = () => {
		const list = this.props.par.get_item_list();
		list._flags._show_all = this.state.show == 'all';

		list._flags._filter_fn = list._flags._show_all ?
				null : this.filter_active_only;

		if (list._flags._show_all && !list._flags._fetched_done_tasks)
			this.get_done_tasks();
		else {
			set_order(list);
			this.props.close();
		}
	}

	get_done_tasks = () => {
		const {rid, tab} = this.props;
		const args = {cmd: 'get_list', tab, rid, active: false,};
		api( args, this.got_done_tasks );
	}

	got_done_tasks = () => {
		this.props.close();
	}

	render() {
		return <ModalCommand par={this} header="Filter Tasks">
	<Spacer />
	<div className="p-grid">
		<div className="p-col-2">
			Show
		</div>
		<div className="p-col-10">
			<RadioButton id="rb_ft_active" 
				name="task_show" value="active"
			onChange={e => this.setState({show: e.value})}
			checked={this.state.show === 'active'}
			/>
			<label htmlFor="rb_ft_active" 
				className="p-radiobutton-label">
				Active Tasks Only
			</label>

			<br/>

			<RadioButton id="rb_ft_all" 
				name="task_show" value="all"
			onChange={e => this.setState({show: e.value})}
			checked={this.state.show === 'all'}
			/>
			<label htmlFor="rb_ft_all" 
				className="p-radiobutton-label">
				All Tasks (Active and Done)
			</label>

		</div>
	</div>
	<Spacer />
  	<TaskCommand onClick={this.onSet} label="Set" icon="pi-check" />

	<Spacer />
		</ModalCommand>
	}
}

export default FilterTasks;
