import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';

import {ModalCommand, Spacer, Focusable, lazyFocus} from './Utils';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v, get_room_options, is_staff_a_recipient_of_shared_email,
	map_list, filter_list, gen_xid, get_item, icontains,
	} from './Lib';


function ChooseRoom(props) {
	// props = {rid, frid, onChoose}.  frid = from room id, rid = chosen rid
	const [rid, setRid] = React.useState(props.rid);
	const croom = rid ? get_room(rid) : null;
	const [name, setName] = React.useState(croom ? croom.name : '');
	const choose = item => {
		setRid(item.id);
		setName(item.name);
		props.onChoose(item.id);
	};

	log('email', 'ChooseRoom', rid, croom, name);

	// valid choices = matches name or email.
	const choices = filter_list(ldb.data.rooms, room => (
		!room.is_mailbox && 
		props.frid != room.id &&
		icontains(room.name, name)
		// room.name.search(RegExp(name, 'i')) >= 0
		)
	);
		
	return (
	<div className="choose-room">
		<InputText value={name}
			ref={lazyFocus}
			onChange={e => setName(e.target.value)}
			onKeyDown={e => {
				if (e.key === 'Enter') {
					if (choices.length)
						choose(choices[0]);
				}
				else if (e.key === 'Escape') 
					setName('');
			}}
		/>
		{' '}
		<Button label="" icon="pi pi-times"
			className="p-button p-button-secondary"
			onClick={e => setName('')} />

		<div className="choices">
		  {choices.map(room => (
			<div key={room.id} className="item"
				onClick={e => choose(room)}
			>
				<div>
					{room.name}
				</div>
			</div>
		  ))}
		</div>
	</div>
	);
}

class ShareTo extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, bulk_move, par} = this.props;
		
		const eids = bulk_move ? par.get_checked_ids({'mark_as_moved': false}) : [item.id];

		const room = get_room(rid);
		const working = false;
		const different_staff = false;
		
		this.state = {rid:0, working};
		
		window.g_shareTo = this;
	}

	has_errors = () => {
		if (!this.state.rid) {
			growl('Error', 'Please choose a room', 'error');
			return true;
		}
		
		return false;
	}

	share_to = () => {
		if (this.has_errors())
			return;
		
		if (this.props.bulk_move) {
			return this.share_to_multiple_emails();
		}
		
		const {rid} = this.state;
		const {item} = this.props

		const ids = [item.id];

		this.setState({working: true});
		item._being_moved = true;
		
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {
			cmd: 'transfer_email', 
			ids, rid, bid,
		}
		api( args, this.shared );
		
		window.g_progCircle.add(bid, 
			"Email(s) shared to " + get_room(rid).name,
				ids.length);
	}

	share_to_multiple_emails = () => {
		const {rid} = this.state;
		
		const ids = this.props.par.get_checked_ids();
		if (!ids.length)
			return growl("Please select some items");
				
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {cmd: 'transfer_email', ids, rid, bid};
		this.setState({working: true});
		api( args, this.shared );
		
		window.g_progCircle.add(bid, 
			"Email(s) shared to " + get_room(rid).name,
				ids.length);
	}

	shared = (error) => {
		if (!error)
			growl("Sharing Email(s)");
		
		this.props.close();
	}

	updated_room = (rid) => {
		this.setState({rid: rid});
	}
	
	render() {
		const {item, rid, bulk_move} = this.props;

		//if (!bulk_move) {
		//	if (!item.pinfo) {
		//		// refresh, email is not fetched yet.
		//		this.props.par.select_command('');
		//		return null;
		//	}
		//}

		const room_options = get_room_options();
		
	 	return (
		<ModalCommand par={this}
			className="modal-35-command"
			header="Share Emails to a Room">
	
	<div className="p-grid">
		<div className="p-col-2">Share To:</div>
		<div className="p-col-10">
			<ChooseRoom rid={this.state.rid}
				frid={rid}
				onChoose={this.updated_room} />
		</div>
	</div>
	{!this.state.working && <div className="p-grid" >
		<div className="p-col-2">
		</div>
		<div className="p-col-10">
			<Button label="Share Email(s)"
				icon="pi pi-directions"
				onClick={this.share_to}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
		)
	}
}

export default ShareTo;
