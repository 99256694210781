import React, { Component } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {Link} from 'react-router-dom';

import {Focusable, Spacer, ModalCommand} from './Utils';
import {ldb, log, api, go, edate, edate5, map_list, go_url, growl, em, 
		show_is_new, get_room, get_staff, get_my_feedback, time_diff} from './Lib';
import {editorToolbar} from './EditTask';



//------------------ Sandbox  ------------------------

class AIEcho extends Component {
	constructor(props) {
		super(props);

		const msg = '';
		const history = ldb.data.ai_test_output ? ldb.data.ai_test_output : [];

		this.state = {
			nrefresh: 0,
			working: false,
			msg,
			history
		};

		window.g_aitest = this;
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentDidUpdate() {
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	refresh = extra => {
		if (extra === undefined) {
			extra = {};
		}
				
		if (this._isMounted) {
			this.setState({nrefresh: this.state.nrefresh + 1, ...extra})
		}
	}	

	onSubmit = () => {
		let {msg} = this.state;
		
		log('ai', 'Submit AI Echo', msg);
		
		const args = {
			cmd: 'ai_req',
			ai_cmd: 'ai_test',
			msg};
		api( args, this.submitted, this.submitted);
		
		this.setState({working: true});
	}
	
	submitted = (error, db, resp) => {
		log('ai', 'AI Echo Response', error, db, resp);
		
		if (resp.error) {
			this.setState({working: false});
		}
	}
	
	render() {
		
		return (
<Panel header="AI Echo" className="ai-test-panel">
	Input/Output History:
	<div className="ai-test-panel-output">
		{this.state.history.toReversed().map((item, i) => {
			return (
			<div key={i} className="ai-test-panel-output-item">
				<div className="p-grid">
				<div className="p-col-1 ai-test-panel-output-item-light">Input:</div>
				<div className="p-col-9 prewrap">{item.input}</div>
				<div className="p-col-2 ai-test-panel-output-item-light right">{edate5(item.start_time)}</div>
				</div>

				<div className="p-grid">
				<div className="p-col-1 ai-test-panel-output-item-light">Output:</div>
				<div className="p-col-9 prewrap">{item.output}</div>
				<div className="p-col-2 ai-test-panel-output-item-light right">{edate5(item.end_time)}</div>
				</div>
			</div>
			);
		})}
	</div>
	
	<br/><br/>
	
	{this.state.working && <ProgressSpinner />}

	{(!this.state.working) && <div className="p-grid">
		<div className="p-col-9">
			<InputTextarea className="ai-test-input" placeholder="Enter Input Here" value={this.state.msg} onChange={(e) => this.setState({'msg': e.target.value})} rows={2} />
		</div>
		<div className="p-col-3" className="ai-test-input-button">
			<Button label="Submit AI Echo" onClick={this.onSubmit} />
		</div>
	</div>}
</Panel>
		)
	}
}

class AIPrediction extends Component {
	constructor(props) {
		super(props);

		const msg = '';
		const history = ldb.data.ai_prediction_history ? ldb.data.ai_prediction_history : [];

		this.state = {
			nrefresh: 0,
			msg,
			history
		};

		window.g_aiprediction = this;
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentDidUpdate() {
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	refresh = extra => {
		if (extra === undefined) {
			extra = {};
		}
				
		if (this._isMounted) {
			this.setState({nrefresh: this.state.nrefresh + 1, ...extra})
		}
	}	

	onSubmit = () => {
		let {msg, history} = this.state;
		
		log('ai', 'Submit AI Prediction', msg);
		
		const cid = history.length;
		
		const args = {
			cmd: 'ai_req', 
			ai_cmd: 'ai_predict',
			msg,
			cid
		};
		api( args, this.submitted, this.submitted );

		history.push({'input': msg, 'output': null, 'start_time': null, 'end_time': null, 'working': true});
		
		ldb.data.ai_prediction_history = history;

		this.setState({msg: '', history})
	}
	
	submitted = (error, db, resp) => {
		log('ai', 'AI Prediction Response', error, db, resp);

		if (resp.error) {
			//this.setState({working: false});
		}
	}

	update_history = (cid, data) => {
		let {history} = this.state;
		
		history[cid] = data;
		
		ldb.data.ai_prediction_history = history;

		this.setState({history});
	}
	
	render() {
		
		return (
<Panel header="AI Completion" className="ai-test-panel">
	<div className="ai-test-panel-output">
		{this.state.history.toReversed().map((item, i) => {
			return (
			<div key={i} className="ai-test-panel-output-item">
				<div className="p-grid">
				<div className="p-col-1 ai-test-panel-output-item-light">Input:</div>
				<div className="p-col-9 prewrap">{item.input}</div>
				<div className="p-col-2 ai-test-panel-output-item-light right">{item.start_time && <span>{time_diff(item.start_time, item.end_time, 2)} Seconds</span>}</div>
				</div>

				<div className="p-grid">
				<div className="p-col-1 ai-test-panel-output-item-light">Output:</div>
				<div className="p-col-9 prewrap">{(item.output === null) ? <span className="ai-test-progress-spinner"><ProgressSpinner /></span> : item.output}</div>
				<div className="p-col-2 ai-test-panel-output-item-light right"></div>
				</div>
			</div>
			);
		})}
	</div>
	
	<br/><br/>
	
	<div className="p-grid">
		<div className="p-col-12">
			AI Credits: {ldb.data.org.ai_credits}
		</div>
	</div>

	<div className="p-grid">
		<div className="p-col-9">
			<InputTextarea className="ai-test-input" placeholder="Enter Input Here" value={this.state.msg} onChange={(e) => this.setState({'msg': e.target.value})} rows={2} />
		</div>
		<div className="p-col-3" className="ai-test-input-button">
			<Button label="Submit" onClick={this.onSubmit} />
		</div>
	</div>
</Panel>
		)
	}
}




class Sandbox extends Component {
	constructor(props) {
		super(props);
		
		this.state = {show_handle_local_storage_draft: false};
		
		window.g_sandbox = this;
	}
	
	componentDidMount() {
	}
	
	componentDidUpdate() {
	}
	
	componentWillUnmount() {
	}
	
	render() {
		
		const attachment_list = [];
		
		return (
<div>
	{false && <div>
		<Button label="Handle Unsaved Draft" onClick={(e) => this.setState({show_handle_local_storage_draft: true})} />
		<br/><br/>
	</div>}

	{this.state.show_handle_local_storage_draft && <ModalCommand onHide="true"
			className="small-modal-command"
			header="Recover Unsaved Draft">	
		<div className="p-grid">
			<div className="p-col-12">
			<div>
				You have an email draft in progress that was interrupted. Would you like to discard it or continue editing it?
				<br/><br/>
			</div>
			<div>
				Attachments: {attachment_list}
				<br/><br/>
			</div>
			<div>
				<Button label="Recover Draft" onClick={recover_unsaved_local_storage_draft} />
				{em(2)}
				<Button label="Discard Draft" className="p-button-secondary" onClick={(e) => {remove_draft_from_local_storage(); this.setState({'show_handle_local_storage_draft': false});}} />
				{em(2)}
				<Button label="Delay Decision" className="p-button-secondary" onClick={(e) => {this.setState({'show_handle_local_storage_draft': false});}} />
			</div>
			</div>
		</div>
	</ModalCommand>}

	
	<AIPrediction />
</div>
		)
	}
}

export {Sandbox};
