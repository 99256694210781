import React, {Component} from 'react';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Password} from 'primereact/password';
import { ldb, api, log, sock, go, is_local_server,
	growl, abs_url} from './Lib';

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email : '',
			error : '',
			working: false,
			done: false,
		};
	}

	componentDidMount() {
		sock.connect();
	}

	change = (event) => {
		const ele = event.target;
		var item = {};
		item[ele.dataset.name] = ele.value;
		this.setState(item);
	}

	submit = (event) => {
		if (event)
			event.preventDefault();
		
		const {email} = this.state;

		const base_url = abs_url('');

		const cmd = 'forgot_password';
		const args = {cmd, email, base_url};

		log('login', 'forgot_password', cmd, email);

		api( args, this.api_callback, this.api_callback);

		this.setState({working:true, error: ''});
	}

	api_callback = (error) => {
		if (error) {
			this.setState({working:false});
			return;
		}
		const done = true;
		this.setState({done});
	}

	render() {
		const err = this.state.error ? 
			<div><b>Error</b>: {this.state.error}</div> : null;

		if (this.state.done)
			return <div>
				<p/>
				Please check your email.
				<p/>
				If <i>{this.state.email}</i> has a valid
				account,
				<br/>
				a password reset link would have been
				sent to that address.
				<p/>
				Please check your junk mail folder
				<br/>
				to make sure it didn't get stuckthere.
				<p/>
				Sorry, we can't say whether this email
				is valid because,
				<br/>
				saying so, would enable bots and hackers
				to guess valid user accounts.
			</div>

		return (
		<form onSubmit={this.submit}>
		<p/>
	<div>
	No problem. We'll send you a <i>Reset Password</i> link
	<br/>
	to the email below, if you have an account with TagInbox.
	</div>
		<br/>
		<br/>
			<div>Email</div>
			<InputText id="login_email"
				ref={inp => {this.emailInput = inp}}
				value={this.state.email}
		onChange={e => this.setState({email:e.target.value})}
			style={{width:'20em'}}
				/>

			<p/>
			<Button label="Send Reset Password Link" />
			<br/>
			<br/>
			{err}
			<br/>
			<br/>
		{this.state.working && <ProgressSpinner/>}
		</form>
		);
	}
}


export default ForgotPassword;
