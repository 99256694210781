import React, { Component } from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
import {ProgressSpinner} from 'primereact/progressspinner';
import {InputTextarea} from 'primereact/inputtextarea';
import {Dropdown} from 'primereact/dropdown';
import {Link} from 'react-router-dom';

import {Focusable, Spacer} from './Utils';
import {ldb, log, api, go, edate, edate5, map_list, go_url, growl, 
		show_is_new, get_room, get_staff, get_my_feedback, time_diff} from './Lib';
import {editorToolbar} from './EditTask';



//------------------ Calendar  ------------------------

class Calendar extends Component {
	constructor(props) {
		super(props);
		
		this.state = {};
		
		window.g_calendar = this;
	}
	
	componentDidMount() {
	}
	
	componentDidUpdate() {
	}
	
	componentWillUnmount() {
	}
	
	render() {

		return (
<div>
	<a target="_blank" href="https://outlook.office.com/calendar/view/month?actSwt=true">Open Calendar</a>
</div>
		)
	}
}

export {Calendar};
