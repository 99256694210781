import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Dialog} from 'primereact/dialog';

import { ldb, api, log, go, get_room,
	growl, } from './Lib';



function seconds_to_hms(d) {
	d = Number(d);
	let h = Math.floor(d / 3600);
	let m = Math.floor(d % 3600 / 60);
	let s = Math.floor(d % 3600 % 60);
	
	let h_display = h > 0 ? h + (h == 1 ? ' hour, ' : ' hours, ') : '';
	let m_display = m > 0 ? m + (m == 1 ? ' minute' : ' minutes') : '';
	let full_display = h_display + m_display;

	if (full_display == '') {
		full_display = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
	}
	
	return full_display;
}

function idledur() {
	// Find out how many seconds elapsed since our last 
	//	completed API request, that wasn't idle
	let last = new Date();

	for (let i=window.g_api_index -1; i >= 0; i--) {
		let al = window.g_api_log[i];
		if (al.dt_end && al.request && al.request.cmd != 'idle') {
			last = al.dt_end;
			break;
		}
	}

	const msec = new Date() - last;
	const sec = Math.round(msec / 1000);
	const display_time = seconds_to_hms(sec);
	
	return display_time;
}

const Reconnect = props => {
	const {reason, arg} = props.match.params;
	let header, message, room;
	let cur = window.g_sock.live_url;

	switch(reason) {
	case 'lost':
		header = 'Disconnected';
		message = 'Lost connection to the server';
		message += ' (Active ' + idledur() + ' ago)';
		break;

	case 'room_deleted':
		room = get_room(arg);
		header ='Reload - Room Deleted';
		message = '"' + room.name + '" was deleted. Please reconnect to update the data.';
		cur = '';
		break;
	}

	// Don't take them back to the reconnect page on reconnect (potential loop)
	if (cur.includes('reconnect')) {
		cur = '#/myrooms';
	}
	
	const ecur = btoa(cur);
	
	const url = `/onboard/reconnect/${ldb.data.me.id}/${ecur}`;

	log('lib', 'Reconnect', reason, arg, url, cur);

	return <Dialog header={header} modal={true}
			visible={true} width="350px" height="120px"
			onHide={()=> {window.location.replace(url)}}
		>
		<p>
		{message}
		</p>
		<a href={url}>Reconnect?</a>
	</Dialog>
}

export default Reconnect;
