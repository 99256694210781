import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Checkbox} from 'primereact/checkbox';

import sanitizeHtml from 'sanitize-html';

import {EditBody} from './EditTask';
import {map_inline_attachments} from './EmailUtil';
import {ModalCommand, Spacer, Focusable, lazyFocus, HelpInfo} from './Utils';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v, get_room_options, is_staff_a_recipient_of_shared_email,
	map_list, filter_list, gen_xid, get_item, em, escape_reg_exp,
	get_body_part, 
	} from './Lib';



const BulkArchiveHelp = () => {
	const text1 = "{{ FieldName:DefaultValue }}";
	const text2 = "{{ State:None }}";

	return (
	<HelpInfo header="Bulk Archive Email Usage">
	<dl>
	<dt>
		About
		<br/>
		<br/>
	</dt>
	<dd>
		The bulk archive email feature allows staff to move over shared emails covering a certain date range from multiple rooms into separate archive rooms that are less prominently displayed (though still accessible). So if you select 3 rooms using this feature and select the date range of 2/1/2018 - 3/2/2019, then 3 archive rooms will be created (one for each room). Each archive room will have the shared email that were sent between 2/1/2018 - 3/2/2019 moved into that room.
	</dd>
	<dt>
		How
		<br/>
	</dt>
	<dd>
		<ol>
		<li>In the "To" field you can type space-separated search criteria. Each word you enter will be checked against the room's name to determine if the room should be included in the bulk email recipient list. For example, if you enter "ben andy" in the field it will show a list of all rooms with both "ben" and "andy" (case insensitive) in the room name. You can also search by room fields. The format is "FieldName:FieldValue". So if you search for "State:WA" it will include all firms with the field "State" set to "WA". Searching for "State:WA ben" will return all rooms with the field "State" set to "WA" and "ben" in the name.
		</li>
		<li>Within the search results you may manually deselect any rooms you would like excluded from the bulk archive email process.
		</li>
		<li>Rooms for you org that are EXCLUDED from the search results are displayed below the search results.
		</li>
		<li>Below the excluded rooms, you may see red warning text letting you know how many (if any) rooms do not have any value set for the fields you are searching for. This does not apply if you are not searching by room fields.
		</li>
		<li>Once you click "Continue to Next Step" you will see a confirmation dialogue letting you know how many firms will have an archive room created for them.
		</li>
		<li>If you confirm, then you will next select the date range of emails you would like to move over to the archive room for each client room you selected.
		</li>
		<li>The system will then show you a list of the archive rooms that will be created, as well as how many emails meet the date range criteria and will be moved over to the archive room.
		</li>
		<li>If you confirm, then you will be given a final summary message and confirmation button before the system will begin creating the archive rooms. Once you initiate this process, archive rooms will begin being created. You do not need to wait for this process to complete to perform other actions on the system. You may even log out before the process is complete, and it will continue in the background. You can use the progress circle in the upper-right of the screen to track the progress of the archives. A confirmation email will be sent out when the process is complete.
		</li>
		<li>Email archive rooms do not show up in the "My Rooms" list by default. To access a room's archive rooms, go to the "About" tab for that room. In the "Settings" section there will be an "Archive Rooms" list for rooms that have had archive rooms created. This will list the archive rooms, as well as their visibility in the "My Rooms" list. To view the archived emails, click the "Go to Archive" link next to an archive room.
		</li>
		<li>Archived emails will show up in the "Shared" tab of the archive room. To make the archive room visible or hidden in the "My Rooms" list, go to the "About" tab. In the top section there will be a "Change Visibility" link that will allow you to alter this setting.
		</li>
		<li>Archive rooms, by default, are created with the same staff access as their counterpart room. The staff access for archive rooms can be independently altered.
		</li>
		</ol>
	</dd>
	</dl>
</HelpInfo>
)
}

function BulkArchiveStep1Confirm(props) {
	const {onHide, onConfirm, subject, rids, par} = props;
	
	return (
	<ModalCommand header="Step 2 of 4: Confirm Bulk Email Archive Rooms"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>
		Are you sure you create email archive rooms for {rids.length} room(s)? 
		<br/><br/>
		<div className="p-grid">
		<div className="p-col-2">These rooms will have an archive created:</div>
		<div className="p-col-10">
			<div className="bulk-email-room-list-div">
		<ScrollPanel className="bulk-email-room-list">
			{rids.map( rid => {
				const room = get_room(rid);
				if (!room)
					return null;
				
				return (<div className="bulk-email-room-item p-grid" key={"bulk_room_key_" + rid}>
						<div className="p-col-1">
						</div>
						<div className="p-col-11 bulk-email-room-name">
							{room.name}
						</div>
					</div>);
			})}
		</ScrollPanel>
			</div>
		</div>
		</div>
		<br/><br/>
		<Button label="Confirm Rooms"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />
	
	</ModalCommand>
	);
}

function BulkArchiveStep2Confirm(props) {
	const {onHide, onConfirm, subject, rids, par} = props;
	
	const num_rooms = rids.length;

	return (
	<ModalCommand header="Step 3 of 4: Select Email Archive Date Range"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>
		{par.state.working && <ProgressSpinner/>}

		{(!par.state.working) && <React.Fragment>
		{(!par.state.data) && <React.Fragment>
		<div className="p-grid">
		<div className="p-col-12">
			Select the sent date range of shared emails you would like to archive for {num_rooms} room(s)
		</div>
		</div>
		<Spacer />
		<div className="p-grid" style={{"marginBottom":"320px"}}>
		<label className="p-col-3">Archive Date Range:</label>
		<div className="p-col-9 priority-buttons">
			<Calendar value={par.state.dt_archive_from}
			onChange={ e => par.setState({ dt_archive_from: e.value }) }
			style={{zIndex:5000}}
			></Calendar>
			<span style={{marginLeft:'10px', marginRight:'10px'}}>-</span> 
			<Calendar value={par.state.dt_archive_till}
			onChange={ e => par.setState({ dt_archive_till: e.value }) }
			style={{zIndex:5000}}
			></Calendar>
		</div>
		</div>
		</React.Fragment>}
		
		{(par.state.data) && <React.Fragment>
		
		<p>
		The following archive rooms will be created:
		</p>
		<Spacer />
		
		{par.state.data.map(function(ar, index) {
			const is_hidden = ar.hidden ? 'Hidden' : 'Visible';
			
                        return (<div className="p-grid" key={"archive_room_" + index}>
				<div className="p-col-12">
					<b>{ar.name}</b>
					<br/>
					{ar.eids.length} email(s) will be moved to this archive room
				</div>
				</div>);
		})}
		
		</React.Fragment>}
		
		<br/><br/>
		{(!par.state.data) && <Button label="Check Archive Date Range"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				par.fetch_email_info();
			}} />}
		{par.state.data && <Button label="Confirm Archive Date Range"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />}
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />
		</React.Fragment>}
	
	</ModalCommand>
	);
}

function BulkArchiveStep3Confirm(props) {
	const {onHide, onConfirm, subject, rids, par} = props;
	
	const num_rooms = rids.length;

	return (
	<ModalCommand header="Step 4 of 4: Confirm Email Archives"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>
		<div className="p-grid">
		<div className="p-col-12">
			<p>
				Ready to generate {num_rooms} archive rooms? Once you confirm then this action cannot be undone.
			</p>
			<p>
				Once you initiate this process, archive rooms will begin being created. You do not need to wait for this process to complete to perform other actions on the system. You may even log out before the process is complete, and it will continue in the background. You can use the progress circle in the upper-right of the screen to track the progress of the archives. A confirmation email will be sent out when the process is complete.

			</p>
		</div>
		</div>
		
		<br/><br/>
		<Button label="Create Archives"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />

	</ModalCommand>
	);
}

const plain2html = plain => plain.split('\n').join('<br/>')

class BulkArchive extends Focusable {
	constructor(props) {
		super(props);
		
		const working = false;
		const done = false;
		const show_step1_confirm = false;
		const show_step2_confirm = false;
		const show_step3_confirm = false;
		const input_csv = false;
		const [rid_options, excluded_rids, error_buf] = this.get_rid_options('');
		const rids = [...rid_options];
		const search_text = '';
		const csv_data = '';
		const step = 1;
		const cur_date = window.g_moment().toDate();
		const dt_archive_from = new Date(cur_date.getFullYear(), cur_date.getMonth() - 6, cur_date.getDate());
		const dt_archive_till = window.g_moment().toDate();
		const data = null;
		
		this.state = {step, rids, rid_options, excluded_rids, error_buf, search_text, show_step1_confirm, show_step2_confirm, show_step3_confirm, working, done, input_csv, dt_archive_from, dt_archive_till, data};
		
		window.g_bulkEmail = this;
	}
	
	has_errors = () => {
		if (this.state.rids.length == 0) {
			growl('Error', 'Please choose at least one room', 'error');
			return true;
		}
		
		return false;
	}
	
	display_help = () => {
		
	}

	cancel = () => {
		this.props.close();	
	}
	
	prep_rooms_selected = () => {
		if (this.has_errors())
			return;
		
		this.setState({show_step1_confirm: true});
	}

	rooms_selected = () => {
		this.setState({show_step1_confirm: false, step: 2});

		this.prep_date_range_selected();
	}
	
	prep_date_range_selected = () => {
		if (this.has_errors())
			return;
		
		this.setState({show_step2_confirm: true, data: null});
	}

	date_range_selected = () => {
		this.setState({show_step2_confirm: false, step: 3});
		
		this.prep_create_archives();
	}

	fetch_email_info = () => {
		const {rids, dt_archive_from, dt_archive_till} = this.state;
		const op = 'get_data';
		const args = {cmd: 'bulk_archive_email', op, rids, dt_archive_from, dt_archive_till};
		api( args, this.fetched_email_info );
		this.setState({working: true});
	}

	fetched_email_info = (error, db, resp) => {
		const data = db.archive_email_data;
		
		this.setState({working: false, data: data});
	}

	prep_create_archives = () => {
		if (this.has_errors())
			return;
		
		this.setState({show_step3_confirm: true});
	}

	create_archives = () => {
		if (this.has_errors())
			return;
		
		const {rids, dt_archive_from, dt_archive_till} = this.state;
		const op = 'archive';
		
		this.setState({show_step3_confirm: false, working: true});
		
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {cmd: 'bulk_archive_email', op, rids, dt_archive_from, dt_archive_till, bid};
		api( args, this.create_archives_complete );
		
		window.g_progCircle.add(bid, 
			"Creating Archive(s)", rids.length);
	}

	create_archives_complete = (error) => {
		if (!error)
			growl("Creating Archive(s)");
		
		this.setState({done: true});
		
		//this.props.close();
	}
	
	generate_search_params = (search_text) => {
		let name_params = [];
		let category_params = [];
		let field_params = [];
		
		search_text = search_text.trim();
		
		const components = search_text.split(' ');
		components.forEach( component => {
			if (!component.trim())
				return;

			let match = component.match(/(\S+?):(\S+)/g);
			if (match) {
				const parts = component.split(":");
				const key = parts[0];
				const fvalue = parts[1];
				
				if (key.toLowerCase() == 'category') {
					category_params.push(fvalue);
				} else {
					field_params.push([key, fvalue]);
				}
			} else {
				name_params.push(component);
			}
		});
		
		const params = {'name': name_params, 'category': category_params, 'field': field_params};
		
		return params;
	}
	
	get_rid_options = (value) => {
		const params = this.generate_search_params(value);

		let rid_options = [];
		let excluded_rids = [];
		
		let rooms_without_fields = {};
		let error_buf = '';

		params['field'].forEach( field_check => {
			const key = field_check[0];
			rooms_without_fields[key] = 0;
		})

		ldb.data.rooms._idlist.forEach( rid => {
			const room = ldb.data.rooms._items[rid];
			if (room.is_mailbox)
				return;
			
			let include = true;
			
			params['field'].forEach( field_check => {
				const key = field_check[0];
				const fvalue = field_check[1];
				
				// Check if this is a checkbox object
				// TBD Add syntax and support for checking if checkboxes are false
				if ((room.info[key]) && (room.info[key].value.constructor == Object)) {
					if (!room.info[key].value[fvalue]) {
						include = false;
					}
				} else if ((!room.info[key]) || (room.info[key].value != fvalue)) {
					include = false;
				}
				
				if (!room.info[key]) {
					rooms_without_fields[key] = rooms_without_fields[key] + 1;
				}
			});

			params['name'].forEach( name_check => {
				const re = RegExp(escape_reg_exp(name_check), 'i');
				const res = re.test(room.name) 
				if (!res)
					include = false;
			});

			params['category'].forEach( category_check => {
				const re = RegExp(escape_reg_exp(category_check), 'i');
				const res = re.test(room.category) 
				if (!res)
					include = false;
			});
						
			if (include)
				rid_options.push(room.id);
			else
				excluded_rids.push(room.id);
		});

		params['field'].forEach( field_check => {
			const key = field_check[0];
			const num_rooms_without_field = rooms_without_fields[key];
			if (num_rooms_without_field  > 0) {
				error_buf = error_buf + num_rooms_without_field + ' room(s) do not have the field "' + key + '" set\n';
			}
		})
		
		return [rid_options, excluded_rids, error_buf];
	}

	search_rooms = (value) => {
		const [rid_options, excluded_rids, error_buf] = this.get_rid_options(value);
		
		this.setState({search_text: value, rid_options, excluded_rids, error_buf, rids: [...rid_options]});
	}

	parse_csv_data = (value) => {
		const csv_data = value.trim();
		let emails = [];

		const components = csv_data.split(',');
		components.forEach( component => {
			const email = component.trim();
			if (!email)
				return;
			
			if (emails.indexOf(email) < 0) {
				emails.push(email);
			}
		});
		
		let rid_options = [];
		let excluded_rids = [];
		let error_buf = '';

		ldb.data.rooms._idlist.forEach( rid => {
			const room = ldb.data.rooms._items[rid];
			if (room.is_mailbox)
				return;

			let include = false;
			
			room.clients._idlist.forEach( cid => {
				const client = room.clients._items[cid];

				client.emails._idlist.forEach( eid => {
					const email = client.emails._items[eid].email;
					
					console.log('COMPARING EMAIL', email);
					if (emails.indexOf(email) > -1) {
						include = true;
					}
				});
			});
			
			if (include)
				rid_options.push(room.id);
			else
				excluded_rids.push(room.id);
		});
		
		this.setState({csv_data, rid_options, excluded_rids, error_buf, rids: [...rid_options]});
	}

	room_selected = (rid) => {
		return this.state.rids.includes(rid);
	}

	toggle_select_room = (rid) => {
		let rids = this.state.rids;
		
		if (this.room_selected(rid)) {
			rids.splice(rids.indexOf(rid), 1);
		} else {
			rids.push(rid);
		}

		this.setState({rids});
	}

	render() {
		const {item} = this.props;
		
		if (this.state.done) {
			return (
			<div className="bulk-org">
			<h3 className="p-grid">
			<div className="p-col-2 back">
				<Link to="/settings/org" >
					&lt; Manage
				</Link>
			</div>
			
			<div className="p-col-3">
				Completed: Bulk Archive Emails
			</div>
				
			<div className="p-col-7">
			</div>
			</h3>

			<div className="bulk-sep"></div>
			
			<p>
			The bulk archive emails selection is complete, and the process of creating the archive rooms has begun. You do not need to wait for this process to complete to perform other actions on the system. You may even log out before the process is complete, and it will continue in the background. You can use the progress circle in the upper-right of the screen to track the progress of the archives. A confirmation email will be sent out when the process is complete.
			</p>
			
			</div>
			);
		}

	 	return (
		<div className="bulk-org">
			<h3 className="p-grid">
			<div className="p-col-2 back">
				<Link to="/settings/org" >
					&lt; Manage
				</Link>
			</div>
			
			<div className="p-col-3">
				Step 1 of 4: Bulk Archive Emails
			</div>
				
			<div className="p-col-7">
			</div>
			</h3>

			<div className="bulk-sep"></div>
	
	<div className="p-grid">
		<div className="p-col-12">
			<span className="small">
				Select the firms you would like to create an email archive room for:
			</span>
			{em(1)}
			<BulkArchiveHelp />
			<br/>
			<br/>
		</div>
	</div>
	{(!this.state.input_csv) && <div className="p-grid">
		<div className="p-col-2">To:</div>
		<div className="p-col-10">
			<InputText type="text" placeholder="Search"
				value={this.state.search_text} 
				onChange={e => {
					this.search_rooms(e.target.value);
				}}
				className="bulk-email-input-small"
			/>
			{em(0.5)}
			<span className="pi pi-search"/>
			{em(0.5)}
			<Button label="CSV Data"
				className="p-button-secondary"
				onClick={e => {
					this.setState({input_csv: true, search_text: ''});
				}}
				/>
		</div>
	</div>}
	{this.state.input_csv && <div className="p-grid">
		<div className="p-col-2">CSV Email Data:</div>
		<div className="p-col-10">
			<InputText type="text" placeholder="CSV Email Data"
				value={this.state.csv_data} 
				onChange={e => {
					this.parse_csv_data(e.target.value);
				}}
				className="bulk-email-input-small"
			/>
			{em(0.5)}
			<Button label="Room Search"
				className="p-button-secondary"
				onClick={e => {
					this.setState({input_csv: false, csv_data: ''});
				}}
				/>
		</div>
	</div>}
	<div className="p-grid">
		<div className="p-col-2">
			{this.state.rids.length + " Selected Room(s)"}
			<br/>
			<br/>
			<Button label="Select All"
				icon="pi pi-fw pi-copy"
				className="p-button-secondary"
				onClick={e => {
					const rids = this.state.rid_options;
					this.setState({rids});
				}}
				/>
			<br/>
			<br/>
			<Button label="Clear All"
				icon="pi pi-fw pi-times"
				className="p-button-secondary"
				onClick={e => {
					const rids = [];
					this.setState({rids});
				}}
				/>
		</div>
		<div className="p-col-10">
			<div className="bulk-email-room-list-div">
			<ScrollPanel className="bulk-email-room-list">
				{(this.state.rid_options.length > 0) && this.state.rid_options.map( rid => {
					const room = get_room(rid);
					if (!room)
						return null;
					
					return (<div className="bulk-email-room-item p-grid" key={"bulk_room_key_" + rid}>
							<div className="p-col-1">
								<Checkbox onChange={e => {this.toggle_select_room(room.id);}} checked={this.room_selected(room.id)} />
							</div>
							<div className="p-col-11 bulk-email-room-name">
								{room.name}
							</div>
						</div>);
				})}
				{(this.state.rid_options.length == 0) && <div>No Matching Rooms</div>}
			</ScrollPanel>
			</div>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-2">{this.state.excluded_rids.length + " Excluded Room(s)"}</div>
		<div className="p-col-10">
			<div className="bulk-email-room-list-div">
			<ScrollPanel className="bulk-email-room-list">
				{(this.state.excluded_rids.length > 0) && this.state.excluded_rids.map( rid => {
					const room = get_room(rid);
					if (!room)
						return null;
					
					return (<div className="bulk-email-room-item p-grid" key={"excluded_bulk_room_key_" + rid}>
							<div className="p-col-1">
							</div>
							<div className="p-col-11 excluded-bulk-email-room-name">
								{room.name}
							</div>
						</div>);
				})}
				{(this.state.rid_options.length == 0) && <div>No Rooms Excluded</div>}
			</ScrollPanel>
			</div>
		</div>
	</div>
	{this.state.error_buf && <div className="p-grid">
		<div className="p-col-2">
		</div>
		<div className="p-col-10 bulk-email-error-div">
			{this.state.error_buf}
		</div>
	</div>}
	{!this.state.working && <div className="p-grid">
		<div className="p-col-2">
			{this.state.show_step1_confirm && <div><BulkArchiveStep1Confirm par={this} subject={this.state.subject} rids={this.state.rids} onConfirm={this.rooms_selected} onHide={e=>this.setState({show_step1_confirm: false})} /></div>}
			{this.state.show_step2_confirm && <div><BulkArchiveStep2Confirm par={this} subject={this.state.subject} rids={this.state.rids} onConfirm={this.date_range_selected} onHide={e=>this.setState({show_step2_confirm: false})} /></div>}
			{this.state.show_step3_confirm && <div><BulkArchiveStep3Confirm par={this} subject={this.state.subject} rids={this.state.rids} onConfirm={this.create_archives} onHide={e=>this.setState({show_step3_confirm: false})} /></div>}
		</div>
		<div className="p-col-10">
			<br/>
			<Button label="Continue to Next Step"
				icon="pi pi-directions"
				onClick={this.prep_rooms_selected}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</div>
		)
	}
}

export default BulkArchive;
