import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
		TaskCommand, TaskSelectStaff,} from './EditTask';
import {ModalCommand, Spacer, Focusable} from './Utils';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v} from './Lib';

class ReadEmailTask extends Focusable {
	constructor(props) {
		super(props);
		const {rid, item} = this.props;
		const priority = pri_n2v('Normal');
		const note = 'Please read this email.';
		const dt_due = window.g_moment().add(3, 'days').toDate();
		let subject = "Please read this email";

		if (item.env)
			subject += ' : ' + item.env.subject;

		// Default: select all staff in the room, except me.
		const room = get_room(rid);
		const to_sid = room.staffs._idlist.slice();
		list_rm_ele(to_sid, ldb.data.me.id);

		this.state = {subject, to_sid, priority, note, dt_due};

		window.g_readEmailTask = this;
	}

	has_errors = () => {
		if (this.state.to_sid === 0) {
			growl('Error', 
		'Please choose staff for To:', 'error');
			return true;
		}

		return false;
	}

	onCreate = () => {
		if (this.has_errors())
			return;

		const {to_sid, subject, note, priority,} = this.state;
		const {rid, item} = this.props;
		const email = item;
		let uid = 0; 
		let email_summary = '';
		if (email.uid) {	// has attached email
			uid = email.uid;
			email_summary = gen_email_summary(email);
		}

		const args = {
			cmd: 'create_task', 
			subject, note, to_sid, 
			priority, rid, uid, email_summary,
		}
		api( args, this.created );
	}

	created = (error) => {
		/*
		if (!error)
			growl("Created Task");
		*/
		this.props.close();
	}

	render() {
		const {rid} = this.props;
	 	return (
		<ModalCommand par={this} header="Create Tasks to Remind Staff to Read this Email">
	<Spacer />
  	<TaskSubject par={this} autofocus={true} />
	<Spacer />
  	<TaskSelectStaff par={this} rid={rid} 
		multiple={true} exclude_me={true} />
	<Spacer />
  	<TaskNote par={this} />
	<Spacer />
  	<TaskPriority par={this} />
	<Spacer />
  	<TaskCommand onClick={this.onCreate} label="Create" />
	{this.state.creating && <ProgressSpinner/>}
	<TaskAttach par={this} email={this.props.item} />
		</ModalCommand>
	
		)
	}
}

export default ReadEmailTask;
