import React, {Component} from 'react';
import {Route, Redirect, Link, Switch} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Toast} from 'primereact/toast';
import {Menu} from 'primereact/menu';
import {ProgressSpinner} from 'primereact/progressspinner';

import {Dashboard} from './Dashboard';	// modified copy
import ViewRoom from './ViewRoom';
import UserList from './UserList';
import MyTasks from './MyTasks';
import CreateRoom from './CreateRoom';
import StaffList from './StaffList';
import LoginPage from './LoginPage';
import ForgotPassword from './ForgotPassword';
import Reconnect from './Reconnect';
import {Documentation} from "./Documentation";
import {Profile} from "./Profile";
import {Diag} from "./Diag";
import {Logs} from "./Logs";
import {NotificationLogs} from "./NotificationLogs";
import {FeedbackList, FeedbackSubmit, FeedbackView} from "./Feedback";

import {EmailHeader, FullEmail} from './EmailUtil';
import {share_one} from './UnsharedEmails';
import {LinkedTaskHeader, FullTask} from './TaskUtil';
import {FakeLink} from './Utils';
import {ChatMessage, ChatWrite} from './Chat';
import {NewEmail} from './NewEmail';
import {NewSms} from './NewSms';
import {AddTag} from './Tag';
import {RoomSearch, MyRoomsMenuBar, TabFlags, propSearch} from './Rooms';

import {ldb, log, go, go_url, get_room, get_list, get_item, list_rm_item, 
	api, get_first_id, set_next_fetch_time, set_new_activity_count,
	list_has_ele, list_rm_ele, regcomp, em, pint,cap,
	foreach_list, growl, set_current_list_view,
	set_order, resort, remove_flag, server_remove_flag,
	show_new_flag, get_room_activity} from './Lib';

/* utils */

const fa_icon = name => <i className={'fa fa-fw ' + name}></i>
const pi_icon = name => <i className={'pi pi-fw ' + name}></i>

// name = 'pi-circle' or 'fa-circle' or '.' or  ..
function show_icon(name) {
	if (!name)
		return null;

	const prefix = name.slice(0,2);

	if (prefix == 'pi')
		return pi_icon(name);
	
	if (prefix == 'fa')
		return fa_icon(name);

	return name;
}

const empty = () => <div className="mitem">Empty</div>

//----------------- Mobile Frame Framework -------------------
// Each Kard is a Mobile Frame: Header, Toolbar, Body

class MobileFrame extends Component {
	constructor(props) {
		super(props);
		this.state = {nrefresh:0};
		this.init();
		this.update(true);
		regcomp(this, 'mframe');
	}
	componentDidMount() {
		this.update(true);
		this.hook_current();
		this._isMounted = true;
		log('mobile', 'mobileFrame.mounted', this.title, this.props);
	}
	componentDidUpdate() {
		this.update(false);
		this.hook_current();
		log('mobile', 'mobileFrame.updated', this.title, this.props);
	}
	componentWillUnmount() {
		this.update(false);
		this._isMounted = false;
		log('mobile', 'mobileFrame.unmount', this.title, this.props);
	}

	refresh = () => this.setState({nrefresh: this.state.nrefresh+1})

	hook_current = e => {
		const {rid, tab, iid, command} = this.props;
		set_current_list_view(this, {rid, tab, iid, command});
	}

	debug_line = e => {
		// Useful separator in debug window
		e.preventDefault();
		const c = window._debug_line_count || 0;
		window._debug_line_count = c+1;
		log('mobile', c, '-----------------------------------------');
	}

	modal_close = () => {
		this.modal = null;
		this.refresh();
	}


	// virtual functions:  placeholders  : overridden by subclases
	title = {name: 'Title', icon: ''}
	init = () => null
	update = () => null
	body = () => 'Body'
	lmenu = () => null
	cmenu = () => null
	rmenu = () => null
	error = ''
	modal = null
	menu = null

	// helpers
	stope = e => e && e.preventDefault && e.preventDefault()

	header = () => {
		const {backdata} = this.props;

		return (
	    <div className="mobile-header">
		<div className="mh-back">
			{ backdata && 
			    <a href={backdata.url} onClick={backdata.onClick} >
			    	{pi_icon('pi-chevron-left')}
				{backdata.name} 
			    </a> 
			 }
		</div>
		<div className="mh-title">
			{show_icon(this.title.icon)}
			{this.title.name}
		</div>
		<div className="mh-menu">
			{window.location.hash != '#/' && 
				<Link to="/" className="mobile-home"
					onClick={e => window.g_is_next=true}
					onContextMenu={this.debug_line}
					>{pi_icon('pi-home')}
				</Link>}
		</div>
	    </div>
	    	);
	}

	toolbar = () => <div className="mobile-toolbar">
		<div className="mt-left">
			{this.lmenu()}
		</div>
		<div className="mt-status">
			{this.cmenu()}
		</div>
		<div className="mt-right">
			{this.rmenu()}
		</div>
	    </div>

	// Main Render
	render() {

		log('mobile', 'mobileFrame', this.title, this.props);

		return (
	  <div className="mobile-frame">
	  	{this.header()}
		{this.toolbar()}
		<div className="mobile-error">{this.error}</div>
	    <div className="mobile-body">
	      <div className="mobile-body-inner">
	    	{this.body()}
		{this.modal}
	      </div>
	    </div>
	  </div>
	  	);
	}
}

//----------------- Mobile List -------------------

class MobileList extends MobileFrame {

	// virtual functions
	show_item = (item, i) => item.name
	get_list = () => []
	get_url = item => item.url
	dyn_icon = show_icon
	has_more = false
	fetching = false
	more_icon = this.fetching ? 'pi-spin pi-spinner':'pi-arrow-circle-down'

	def_icon = 'pi-caret-right'
	no_detail = false	// true, for chat. false for email/task/..

	// Helpers
	go = url => {
		if (url.slice(0,1) != '/') {
			// relative url

			let hash = window.location.hash;
			if (hash.slice(-1) != '/')
				hash += '/';		// add trailing /
			if (url.slice(-1) != '/')
				url += '/';		// add trailing /
			url = '/' + hash + url;
		}
		window.location = url;
	}

	// list view
	row = (item, i) => {
		// icon: 1) null: none 2) '' : default class icon 3) icon
		const icon = (item.icon === null) ? '' : 
				(item.icon || this.def_icon);

		let onClick = null;
		let caret = true;
		if (item.action)
			onClick = item.action;	// logout
		else if (item.url !== null && this.def_url !== null)
			// common case: shared/id, unshared/id, ...
			onClick = (e => this.go( this.get_url(item) ));
		else 	// chat tab, & spacer. def_url === null.
			caret = false;

		const ibody = this.show_item(item, i);
		if (ibody === null)
			return null;	// eg. My Mailbox.. skip some items.

		// log('mobile', 'row', icon, item);

		return <div className="mitem" key={i}>
			<div className="mileft">
				{this.dyn_icon(icon)}
			</div>
			<div className="mibody" onClick={onClick} >
				{ibody}
			</div>
			<div className="miright">
			    	{caret && pi_icon('pi-chevron-right')}
			</div>
		</div>
	}

	more_item = () => {
		if (!this.has_more)
			return null;
		return <div className="mitem" >
			<div className="mileft">
				{this.dyn_icon('pi-download')}
			</div>
			<div className="mibody center" 
				onClick={this.get_page}
			>
				{pi_icon(this.more_icon)}
				...Fetch More...
				{pi_icon(this.more_icon)}
			</div>
			<div className="miright">
				{this.dyn_icon('pi-download')}
			</div>
		</div>

	}

	body = () => {
		const list = this.get_list();
		log('mobile', 'mobilelist', list);
		if (!list)
			return null;

		if (list.length == 0)
			return empty();

		return (
	<div className='mlist'>
		{list.map(this.row)}
		{this.more_item()}
	</div>
		);
	} 
}

//----------------- Helpers -------------------

function PanelRow(props) {
	return <div className="m-panel-row">
		{props.children}
	</div>;
}

function MyRoomsMenu({reload, par}) {
	const [filter, setFilter] = React.useState(false);

	return <div>
	  <div className="mobile-toolbar">
		<FakeLink to="filter" onClick={e => setFilter(!filter)}>
			{fa_icon('fa-filter')} 
		</FakeLink>
		<RoomSearch mobile={true} reload={reload} />
	  </div>
	  {filter && <div className="mobile-toolbar">
	  		<MyRoomsMenuBar reload={reload} mobile={true} />
	  </div>}
	</div>
}

//----------------- Room Mobile Frames -------------------
// Components Built as Frames

class RoomRoot extends MobileList {
	title = {name: 'TagInbox'}
	myname = e => {
		const me = ldb.data.me;
		return (
		<div className="my-profile">
			<div className="name">
				{me.name}
			</div>
			<div className="email">
				{me.email}
			</div>
			<div className="org">
				{ldb.data.org.name}
			</div>
		</div>
		);
	}
	logout = e => window.location = '/'
	get_list = () => [
		{name: 'My Rooms', url: 'room', icon: 'pi-th-large'},
		//{name: 'Tags', url: '/#/tags/my', icon: 'pi-tags'},
		{name: 'Staff List', url: '/#/org/staff', icon: 'pi-users'},
		{name: '', url: null, icon: 'pi-th-large invisible' },
		{name: 'Logout', action: this.logout, icon: 'pi-sign-out'},
		{name: this.myname(), url: null, icon: null},
	]
}

class ShowRooms extends MobileList {
	title = {name: 'My Rooms', icon : 'pi-th-large'}
	def_icon = 'fa-folder-o'

	reorder = () => {
		const root = ldb.data.rooms;
		root._flags.sortby = 'category';
		root._flags._filter_fn = propSearch;
		set_order(root);
		resort(root);
	}

	reload = () => {
		this.reorder();
		this.refresh();
	};

	get_list = () => ldb.data.rooms._order
	show_item = id => {
		const room = get_room(id);
		if (room.is_mailbox)
			return null;	// My Mailbox needs special casing.TBD.

		return <React.Fragment>
			{room.name}
			<TabFlags room={room} mobile={true} />
			{em(1)}
			<span className="m-cat">
				{room.category}
			</span>
		</React.Fragment>
	}
	get_url = id => id.toString()
	toolbar = () => <MyRoomsMenu reload={this.reload} par={this} />
	update = (init) => {
		if (init) {
			this.reorder();
		}
	}
}

class ShowTabs extends MobileList {
	title = {name: get_room(this.props.rid).name, icon : 'fa-folder-o'}
	get_list = () => [
		{name: 'Shared Emails', url: 'shared', icon: 'fa-share-alt'},
		{name: 'Unshared Emails', url: 'unshared', icon: 'fa-inbox'},
		{name: 'Chat', url: 'chat', icon: 'fa-comments'},
		{name: 'Tasks', url: 'task', icon: 'fa-calendar-check-o'},
		//{name: 'Tags', url: 'tag', icon: 'pi-tags'},
		{name: 'About', url: 'about', icon: 'fa-info-circle'},
	]
}


class ShowList extends MobileList {
	// Network
	got_page = (error, db, resp) => {
		const {rid, tab} = this.props;
		const root = ldb.data.rooms._items[ rid ][ tab ];
		// TBD: handle error better
		this.error = error;
		if (error)
			alert(error);

		root._flags._new = false;

		const page = root._flags.page;
		const meta = db.meta;

		this.fetching = page._fetching_page = false;
		page.last_dt = meta.last_dt;
		if (meta.total >= 0)
			page.total = meta.total;
		this.has_more = page.has_more = meta.has_more;
		log('mobile', 'showList.has_more', this.has_more);

		// TBD: have server set this?
		// const count = db.meta.count;
		// const message = count ?  "Got " + count + " messages" :
					// "No messages";
		// growl(message);

		this.refresh();
	}
	get_page = () => {
		const {rid, tab} = this.props;
		const root = ldb.data.rooms._items[ rid ][ tab ];
		const page = root._flags.page;
		const last_dt = page.last_dt;
		if (page._fetching_page)
			return;

		this.fetching = page._fetching_page = true;
		const args = {cmd: 'get_list', tab, rid, last_dt};
		api( args, this.got_page, this.got_page );

	}
	// display
	title = {name: get_room(this.props.rid).name, 
		icon : {shared: 'fa-share-alt', unshared: 'fa-inbox',
			chat: 'fa-comments', task: 'fa-calendar-check-o',
			tag: 'pi-tags',		
			about: 'fa-info-circle'}[this.props.tab]}
	def_icon = ''
	show_item = id => {
		if (id == -1)
			return <div>
				{pi_icon('pi-spin pi-spinner')}
				...fetching...
				</div>;
		const {rid, tab} = this.props;
		const root = ldb.data.rooms._items[ rid ][ tab ];
		const item = root._items[id];
		return this.render_item(item);
	}
	get_url = id => id.toString()
	get_list = () => {
		const {rid, tab} = this.props;
		const root = ldb.data.rooms._items[ rid ][ tab ];
		if (root._flags._new) {
			this.get_page();
			return [-1];
		}
		return root._order;
	}

	// New Email
	unused_new_email = e => {
	/*
		this.stope(e);
		const {rid, tab} = this.props;
		this.modal = <ModalNewEmail rid={rid} 
			tab={tab} title='New Email'
			close={this.modal_close} is_reply={false} />
		this.refresh();
	*/
	}
	unused_rmenu = () => <Link to="new" onClick={this.new_email}>
		{fa_icon('fa-pencil-square-o')}
	</Link>
}

class ShowTagList extends MobileList {
	title = {name: get_room(this.props.rid).name, 
		icon : {shared: 'fa-share-alt', unshared: 'fa-inbox',
			chat: 'fa-comments', task: 'fa-calendar-check-o',
			tag: 'pi-tags',		
			about: 'fa-info-circle'}[this.props.tab]}
	show_item = id => {
		const {rid, tab} = this.props;
		const root = ldb.data.tags;
		const item = root._items[id];
		return this.render_item(item);
	}
	get_url = id => id.toString()
	get_list = () => {
		const {rid, tab} = this.props;
		const root = ldb.data.tags;
		return root._order;
	}
}

/*--- Commands ---*/

class MobileCommand extends MobileFrame {
	// action.handler is set to local onAdd/onSend etc. in commands
	//	such as AddTag, NewEmail if mobile is set.
	// In otherwords, they hook into handler.
	action = {name: 'Submit', handler: ()=>null, icon: ''}

	header = () => {
		const act = this.action;
		const {backdata} = this.props;

		return (
	    <div className="mobile-header">
		<div className="mh-back">
			<a href={backdata.url} onClick={backdata.onClick} >
			    	{pi_icon('pi-chevron-left')}
				Cancel
			</a> 
		</div>
		<div className="mh-title">
			{show_icon(this.title.icon)}
			{this.title.name}
		</div>
		<div className="mh-menu">
			<Button label={act.name} 
				icon={act.icon || "fa fa-fw fa-paper-plane"}
				onClick={act.handler} 
				className="p-button-secondary"
				/>
		</div>
	    </div>
	    	);
	}

}

class MobNewEmail extends MobileCommand {
	// Main
	title = {name: 'New Email', icon: 'pi-envelope'}
	action = {name: 'Send', handler : () => null}
	body = () => {
		return <NewEmail 
			{...this.props} 
			mobile={true} 
			par={this}
		/>;
	}
}

class MobNewSms extends MobileCommand {
	// Main
	title = {name: 'New SMS', icon: 'fa-commenting-o'}
	action = {name: 'Send', handler : () => null}
	body = () => <NewSms
			{...this.props} 
			mobile={true} 
			close={this.props.backdata.onClick}
			is_reply={false}
			par={this}
		/>
}

class MobAddTag extends MobileCommand {
	// Main
	title = {name: 'Add Tag', icon: 'fa-tag'}
	action = {name: 'Add', handler : () => null}
	body = () => {
		const {rid, tab, iid, backdata} = this.props;
		const env = ldb.data.rooms._items[ rid ][ tab ]._items[iid];
		return <AddTag
			{...this.props} 
			item={env}
			mobile={true} 
			close={backdata.onClick}
			par={this}
		/>;
	}
}

/*--- Show Item ---*/

window.g_is_next = false
window.g_is_prev = false;

class ShowItem extends MobileFrame {
	title = {name: get_room(this.props.rid).name,
		icon: {shared: 'fa-share-alt', unshared: 'fa-inbox',
			task: 'fa-task-alt', tag: 'pi-tags', 
			about: 'fa-info-circle'}[this.props.tab]}

	// Network
	get_detail = (rid, tab, iid) => {
		const args = {cmd:'get_detail', rid, tab, iid};
		api( args, this.got_detail, this.got_detail );
	}
	got_detail = (error, db, resp) => {
		this.refresh();
	}

	prev_next = () => {
		let {rid, tab, iid} = this.props;
		const order = ldb.data.rooms._items[ rid ][ tab ]._order;
		const i = order.indexOf(pint(iid));
		const prev = i > 0 ? order[i-1] : false;
		const next = i < (order.length -1) ? order[i+1] : false;
		
		return (
	<React.Fragment>
		{next ? <Link to={go_url('room', rid, tab, next)}
				disabled={true}
				onClick={e => window.g_is_next = true}
				>
			{pi_icon('pi-chevron-down')}
			</Link> 
		    : 
			pi_icon('pi-chevron-down disabled')
		}
		{prev ? <Link to={go_url('room', rid, tab, prev)}
				onClick={e => window.g_is_prev = true}
				>
			{pi_icon('pi-chevron-up')} 
			</Link>
		    :
			pi_icon('pi-chevron-up disabled') 
		}
	</React.Fragment>
		);
	}
}

class ShowEmail extends ShowItem {
	body = () => {
		const {rid, tab, iid} = this.props;
		if (!iid)
			return null;

		const root = ldb.data.rooms._items[ rid ][ tab ];
		const env = root._items[iid];
		if (!env || env.body === undefined) {
			this.get_detail(rid, tab, iid);
			return <div>
				{pi_icon('pi-spin pi-spinner')}
				...fetching...
				</div>;
		}
		return (
		<div className="mdetail" >
			<FullEmail env={env} tab={tab} rid={rid} par={this} 
				mobile={true} />
		</div>
		);
	}

	// New Email, Reply
	reply = e => {
		this.stope(e);
		const {rid, tab, iid} = this.props;
		const root = ldb.data.rooms._items[ rid ][ tab ];
		const env = root._items[iid];
		/*
		this.modal = <ModalNewEmail rid={rid} tab={tab} title='Reply'
			item={env} close={this.modal_close} is_reply={true} />
		*/
		this.refresh();
	}
	new_email = e => {
		this.stope(e);
		const {rid, tab, iid} = this.props;
		this.modal = /*
			<ModalNewEmail rid={rid} tab={tab} title='New Email'
			close={this.modal_close} is_reply={false} />
			*/
		this.refresh();
	}
	old_rmenu = () => <span>
		<Link to="reply" onClick={this.reply}>
			{fa_icon('fa-reply')}
		</Link>
		<Link to="new" onClick={this.new_email}>
			{fa_icon('fa-pencil-square-o')}
		</Link>
	</span>
}

class MobEmailShared extends ShowEmail {
	toolbar = () => (
	<div className="mobile-toolbar">
		{this.prev_next()}
		<Link to={go_url('room', this.props.rid, this.props.tab, 
					0, 'new_email')}>
			{fa_icon('fa-pencil-square-o')} 
		</Link>
		<Link to="new_email">
			{fa_icon('fa-reply')} 
		</Link>
		<Link to="add_tag">
			{fa_icon('fa-tag')} 
		</Link>
	</div>
		)
}

class MobEmailUnshared extends ShowEmail {
	onShare = e => {
		const {rid, iid, backdata} = this.props;
		share_one(rid, iid);
		backdata.onClick();
	}
	redir_iid = e => {}
	toolbar = () => (
	<div className="mobile-toolbar">
		{this.prev_next()}
		<FakeLink to="0/share" onClick={this.onShare} >
			{fa_icon('fa-share-square-o')} 
			Share
		</FakeLink>
	</div>
		)
}

class MobTask extends ShowItem {
	toolbar = () => (
	<div className="mobile-toolbar">
		{this.prev_next()}
	</div>
		)

	body = () => {
		const {rid, tab, iid} = this.props;
		if (!iid)
			return null;

		const root = ldb.data.rooms._items[ rid ][ tab ];
		const task = root._items[iid];

		if (!task) {
			this.get_detail(rid, tab, iid);
			return <div>
				{pi_icon('pi-spin pi-spinner')}
				...fetching...
				</div>;
		}

		return (
		<div className="mdetail" >
			<FullTask task={task} tab={tab} par={this} />
		</div>
		);
	}
}

class MobTag extends ShowItem {
	toolbar = () => (
	<div className="mobile-toolbar">
		{this.prev_next()}
	</div>
		)

	body = () => {
		const {rid, tab, iid} = this.props;
		if (!iid)
			return null;

		const root = ldb.data.tags;
		const tag = root._items[iid];

		if (!tag) {
			return <div>
				No tag data
				</div>;
		}

		return (
		<div className="mdetail" >
			ABC
		</div>
		);
	}
}

/*--- List Components: ---*/

// defunct
function MobModal({close}) {
	const toggle = () => null;

	return <div className="mobile-modal">
		<div className="mobile-frame">
	    <div className="mobile-header">
		<div className="mh-back">
		<Button label="" icon="pi pi-times" 
			onClick={close} />
		</div>
		<div className="mh-title">
			New Message
		</div>
		<div className="mh-menu">
		<Button label="Send" icon="pi pi-envelope" 
			className="p-button-secondary"
			onClick={close} />
		{em(1)}
		</div>
	    </div>
	    <div className="mobile-body">
	      <div className="mobile-body-inner">
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      		One <br/> One <br/> One <br/> One <br/> One <br/> One <br/>
	      </div>
	    </div>
		</div>
	</div>
}

// defunct
function SharedMenu({reload, par}) {
	const [cmd, setCmd] = React.useState('');
	const toggle = text => {
		if (text == cmd || !text) {
			setCmd('');
			par.modal = null;
		}
		else {
			setCmd(text);
			par.modal = <MobModal close={e=>toggle('')} />;
		}
		reload();
	};

	return <div className="mobile-menubar">
	  <div className="m-toolbar">
		<FakeLink onClick={e => toggle('new')}>
			{fa_icon('fa-pencil-square-o')} 
		</FakeLink>
		<FakeLink onClick={e => toggle('reply')}>
			{fa_icon('fa-reply')} 
		</FakeLink>
		<FakeLink onClick={e => toggle('tag')}>
			{fa_icon('fa-tag')} 
		</FakeLink>
	  </div>
	  {cmd && <div>{cmd}</div>}
	</div>
}

class MobShared extends ShowList {
	toolbar = () => <div className="mobile-toolbar">
		<Link to="0/new_email">
			{fa_icon('fa-pencil-square-o')} 
		</Link>
		<Link to="0/new_sms">
			{fa_icon('fa-commenting-o')} 
		</Link>
	</div>
	render_item = env => <EmailHeader env={env} />
}

class MobUnshared extends ShowList {
	render_item = env => <EmailHeader env={env} />
}

class MobTaskList extends ShowList {
	render_item = task => <LinkedTaskHeader task={task} />
}

class MobTagList extends ShowTagList {
	render_item = tag => <div>Blah</div>
}

class MobChat extends ShowList {
	def_url = null
	render_item = entry => <ChatMessage entry={entry} mobile={true} />
	toolbar = () => <div className="mobile-toolbar chat-write">
		<ChatWrite rid={this.props.rid} mobile={true} />
	</div>
}

class About extends MobileFrame {
	title = {name: get_room(this.props.rid).name, 
			icon: 'fa-info-circle'}
	body = () => {
		const {rid} = this.props;
		const room = ldb.data.rooms._items[ rid ];
		log('mobile', 'About', room);
		return (
		<div className="mdetail" >
			<UserList root={room.clients} 
				header={<h3>Clients</h3>} />
			<UserList root={room.staffs} 
				header={<h3>Staff</h3>} />
		</div>
		);
	}
}

class ShowStaff extends MobileFrame {
	title = {name: 'Staff List', icon: 'pi-users'}
	body = () => {
		return (
		<div className="mdetail" >
			<StaffList mobile={true} />
		</div>
		);
	}
}

class ShowTagsForMe extends MobileFrame {
	title = {name: 'Tags For Me', icon: 'pi-tags'}
	body = () => {
		return (
		<div className="mdetail" >
			<StaffList mobile={true} />
		</div>
		);
	}
}


const tab2comp = {shared : MobShared, unshared: MobUnshared, 
	task: MobTaskList, tag: MobTagList, chat: MobChat, about: About};
const cmd2comp = {new_email: MobNewEmail, add_tag: MobAddTag,
	new_sms: MobNewSms};
const detail2comp = {shared : MobEmailShared, unshared: MobEmailUnshared,
	task: MobTask, tag: MobTag, chat: MobChat};

function roomStack(args) {
	let url = '/room/';

	const stack = [ 
		{name:'Home', url:'/', comp : RoomRoot, args, },
		{name:'My Rooms', url:'/room', comp : ShowRooms, args, },
	];
	const {rid, tab, iid, command} = args;

	if (rid) {
		url += rid + '/';
		stack.push({name:'Room', url, args, comp : ShowTabs});
	}
	if (tab) {
		url += tab + '/';
		const comp = tab2comp[tab];
		stack.push({name:cap(tab), url, args, comp})
	}
	if (iid && pint(iid)) {
		url += iid + '/';
		const comp = detail2comp[tab];
		stack.push({name:tab+'_item', url, args, comp});
	}
	if (command) {
		url += command + '/';
		const comp = cmd2comp[command];
		stack.push({name:command, url, args, comp});
	}

	return stack;
}

function tagsStack(args) {
	let url = '/tags/';

	const stack = [ 
		{name:'Home', url:'/', comp : RoomRoot, args, },
		{name:'Tags', url:'/tags', comp : RoomRoot, args, },
	];
	const {command} = args;

	if (command == 'my') {
		url += command + '/';
		stack.push({name:'Tags For Me', url, args, comp : ShowTagsForMe});
	}

	return stack;
}

function orgStack(args) {
	let url = '/org/';

	const stack = [ 
		{name:'Home', url:'/', comp : RoomRoot, args, },
	];
	const {command} = args;

	if (command == 'staff') {
		url += command + '/';
		stack.push({name:'Staff List', url, args, comp : ShowStaff});
	}

	return stack;
}

function rootStack(params) {
	return [{name:'Home', url:'/', comp : RoomRoot}];
}


//----------------- Test Mobile Frames -------------------
// Components Built as Frames

class TestRoot extends MobileFrame {
	body = () => <div>
		<h3>Root</h3>
		<Link to='life/'>Life</Link>
		</div>
}

class Life extends MobileFrame {
	body = () => <div>
		<h3>Life</h3>
		<Link to='tree/'>Tree</Link>
	</div>
}

class Tree extends MobileFrame {
	title = {name: 'Tree'}
	body = () => <div>
		<h3>Tree</h3>
		<Link to='mango/'>Mango</Link>
	</div>
}

class Mango extends MobileFrame {
	title = {name: 'Mango'}
	body = () => <div>
		<h3>Mango</h3>
	</div>
}

const test_name2comp = {
	mango: Mango,
	tree: Tree,
	life: Life,
	root: TestRoot,
};

function testStack(params) {
	let url = '/';

	const stack = [ {name:'testroot', url:'/', comp:TestRoot, } ];

	'l1,l2,l3'.split(',').map(level => {
		const name = params[level];
		// log('mobile', 'level', level, name);
		if (name) {
			url += name + '/';
			const comp = test_name2comp[name];
			const item = {name, url, comp, };
			stack.push( item );
		}
	});

	return stack;
}

//----------------- Kard Stack -------------------
// App is a stack of Kards
//	eg. Root, Rooms, Room, Common, Message, ..

function Kard(props) {
	let {item, mode, rindex, backdata} = props;
	if (mode == 'kslidein') {
		if (window.g_is_next)
			mode = 'kslideup';
		else if (window.g_is_prev)
			mode = 'kslidedown';
		window.g_is_next = window.g_is_prev = false;
	}

	log('mobile', 'kard', '******render', rindex, item.name, mode); 
	const args = item.args || {};

	return (
	<div className={'kard ' + mode} >
		<item.comp backdata={backdata} {...args} />
	</div>
	);
}


// Builds a Kard Stack based on URL
class MobileApp extends Component {
	constructor(props) {
		super(props);
		regcomp(this, 'mapp');

		// Stage:
		//   k0 = top kard, k1 = top-1 kard, ... in stack
		//	k2, k3, .. are all knull
		//
		//  show  : k0=kshow, k1=knull, 	normal, keep refreshing
		//
		//  slidein: k0=kslidein, k1=kshow    new url: anim, do timeout
		//  
		//
		//  slideout: k0=kslideout, k1=kshow   back clicked, timeout
		// 


		this.state = { 
			nrefresh: 0, 
			stage: 'show',
			stack: [],
			pathname: '',
		};

		// two hooks
		this._backing = false;		
		this._pathname = '';

		log('mobile', 'app.init');
	}

	componentDidMount() {
		log('mobile', 'app.mount');
		this.update();
	}

	componentDidUpdate() {
		log('mobile', 'app.update');
		this.update();
	}

	componentWillUnmount() {
		log('mobile', 'app.unmount');
	}


	timer = () => {
		const msecs = 500;
		log('mobile', 'timer', msecs);
		setTimeout( this.timeout, msecs );
	}

	timeout = () => {
		let {stage} = this.state;
		const ostage = stage;

		switch(stage) {
		case 'slidein':
			stage = 'show';
			break;
		case 'slideout':
			stage = 'goback';
			break;
		}
		log('mobile', 'timeout', ostage, stage);
		this.setState({stage});
	}

	update = () => {
		let {stage, pathname} = this.state;
		if (this._pathname != pathname) {
			// New URL
			if (this._backing) {
				stage = 'show';
				this._backing = false;
			}
			else {
				stage = 'slidein';
				this.timer();
			}

			pathname = this._pathname;
			const stack = this._stack;

			this.setState({stage, pathname, stack});

			log('mobile', 'update:new url', pathname, stage);
			return;
		}
		else if (stage == 'goback')
			this.go_back();
	}

	refresh = () => this.setState({nrefresh: this.state.nrefresh + 1})

	start_back = e => {
		if (e)
			e.preventDefault();
		log('mobile', 'start_back');

		this.timer();
		this.setState({stage: 'slideout'});
	}

	go_back = () => {
		const {stack} = this.state;
		const prev = stack[ stack.length - 2 ];
		const url = '/#' + prev.url;

		log('mobile', 'goback', url);

		this._backing = true;
		window.location = url;
	}

	get_mode = (rindex, stage) => {

		let mode = 'knull';

		if (rindex > 1)
			return mode;

		switch(stage) {
		case 'show':
			mode = rindex ? 'knull' : 'kshow';
			break;

		case 'slidein':
			mode = rindex ? 'kshow' : 'kslidein';
			break;

		case 'slideout':
			mode = rindex ? 'kshow' : 'kslideout';
			break;

		case 'goback':
			mode = rindex ? 'kshow' : 'knull';
			break;
		};
		return mode;
	}

	show = () => {
		const {stack, stage} = this.state;
		const slen = stack.length;

		return (
	<div className="kard-stack">
		{ stack.map( (item, i) => {
			const rindex = slen - i - 1;	// r0 = top
			let mode = this.get_mode(rindex, stage);

			if (mode == 'khide' || mode == 'knull')
				return null;

			const prev = stack[i-1];
			const backdata = prev ? {name: prev.name, 
					url: prev.url, 
					onClick: this.start_back} : null;
		
			return <Kard 
				key={i}	
				item={item} 
				mode={mode}
				backdata={backdata}
				rindex={rindex}		// for debugging only
			/>
		})}
			
	</div>
		);
	}

	mkStack = (routeProps, stackBuilder) => {
		const pathname = routeProps.location.pathname;

		if (pathname == this._pathname)
			return null;

		const params = routeProps.match.params;

		const stack = stackBuilder(params);

		log('mobile', 'setup', pathname, stack, {routeProps});

		this._pathname = pathname;
		this._stack = stack;

		this.refresh();

		return null;
	}

	render() {

		log('mobile', 'app--------------', window.location.hash);

		return (
	<div className="mobile-wrap">
	  <Switch>
	    <Route path="/" exact render={rp =>
					this.mkStack(rp, rootStack)} />
	    <Route path="/room/:rid?/:tab?/:iid?/:command?" render={rp =>
					this.mkStack(rp, roomStack)} />
	    <Route path="/tags/" render={rp =>
					this.mkStack(rp, tagsStack)} />
	    <Route path="/org/:command?" render={rp =>
					this.mkStack(rp, orgStack)} />
	    <Route path="/test/:l1?/:l2?/:l3?" render={rp =>
					this.mkStack(rp, testStack)} />

	  </Switch>
	    {this.show()}
		<Toast ref={ el => window.g_growl = el}></Toast>
	</div>
		);
	}
}

// No progcircle for mobile for now.. defaults.
window.g_progCircle = {
	add: (bid, name) => log('mobile', 'progCircle.add', bid, name),
	done: (bid) => log('mobile', 'progCircle.done', bid),
};

export {MobileApp};
