import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Menubar} from 'primereact/menubar';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {InputText} from 'primereact/inputtext';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {PickList} from 'primereact/picklist';

import UserList from './UserList';
import AddStaff from './AddStaff';
import AddClient from './AddClient';
import EditClient from './EditClient';
import DeleteClient from './DeleteClient';
import {AutoMove, CategorizeRoom, RenameRoom, DeleteRoom} from './RenameRoom';
import {Commandable, lazyFocus} from './Utils';

import {ldb, log, go, get_room, go_url, get_first_id, growl, 
	list_has_ele, list_toggle_ele, foreach_list,
	filter_list, api, edate3, get_room_activity, 
	set_order, map_list, resort, get_staff,
	mark_tab_as_read, 
} from './Lib';
import {priority_options} from './EditPerson';

// -- Merge 

function CEmail(props) {
	const {ce} = props;
	
	return (
	<div>
		{ce.email}
	</div>
	);
}

function CUser(props) {
	// log('org', 'CUser', props);
	const {cu, cuids, onToggle} = props;
	const sel = list_has_ele(cuids, cu.id) ? ' sel ' : '';
	
	return (
	<React.Fragment>
	<div className={"p-col-3 cuser" + sel} onClick={e => onToggle(cu.id)} >
		<b> {cu.name} </b>
		{map_list(cu.emails, ce => <CEmail ce={ce} key={ce.id} />)}
	</div>
	<div className="p-col-1">
	</div>
	</React.Fragment>
	);
}

function MergeClients(props) {
	const {rid} = props;
	const room = get_room(rid);
	const [name, setName] = React.useState('');
	const [cuids, setCuids] = React.useState([]);
	const [ces, setCes] = React.useState([]);
	const [pris, setPris] = React.useState({}); // {ceid.id : priority}
	const [unmerge, setUnmerge] = React.useState(false);
	const [refresh, setRefresh] = React.useState(0);
	const reload =  () => setRefresh(refresh+1);
	const onToggle = cuid => {
		let nname = name;
		list_toggle_ele(cuids, cuid);
		setCuids(cuids);
		let nces = [];
		cuids.forEach(cuid => {
			const cuser = room.clients._items[cuid];
			foreach_list(cuser.emails, ce => {
				nces.push(ce);
				if (pris[ce.id] === undefined)
					pris[ce.id] = ce.priority;
			});
			if (!nname)
				nname = cuser.name;
		});
		if (!cuids.length)
			nname = '';
		setName(nname);
		setCes(nces);
		setPris(pris);
		reload();
	};

	const mapi = (args, msg) => {
		api( args, (error, db) => {
			setCuids([]);
			setCes([]);
			setName('');
			// Send NOP to let all multicasts come down and
			//	refresh Room and remove merged client users.
			api( {cmd: 'nop'}, () => {
				reload();
				growl(msg);
			});
		});
	};

	return (
<div className="merge-clients" >
	<div className="p-grid backbar">
		<Link to="`/room/${room.id}/summary/0`" className="p-col-1">
			&lt; Back
		</Link>

		<h3 className="p-col-3">Merge Clients</h3>
		<div className="info p-col-8">
			If the same person with alternate emails
			was added as separate clients, merge them here.
		</div>
	</div>

	<div className="p-grid">
		<div className="p-col-3">
			{unmerge && <div className="merged-client">
				<b>Un-merge Client</b>
				<div className="instr">
				This will split this client account
				into multiple, 
				<br/>
				one each for these emails:
				</div>

			{ces.map((ce, i) => <div key={i}>
					{ce.email} 
				</div>)}

				<br/>

		<Button label="Un-Merge"
			icon='fa fa-fw fa-merge'
			onClick={e => {
				const args = { cmd: 'mod_org', 
					op: 'unmerge_clients', 
						cuid:cuids[0], pris };
				mapi(args, 'un-merged');
			}}

		/>
			<br/><br/>
			</div>}
		</div>

		<div className="p-col-1">
		</div>

		<div className="p-col-4 merged-client">
			<b>Merged Client</b>
			<div className="instr">
			Click clients below to merge
			</div>

			<div>
			<InputText value={name} className="name"
				placeholder="Type Client Name Here"
				onChange={e => setName(e.value)} />
			</div>
			<div className="merge-emails">
			{ces.map((ce, i) => <div className="p-grid" key={i}>
				<div className="p-col-8">
					{ce.email} 
				</div>
				<div className="p-col-4">
				{cuids.length > 1 && 
				<Dropdown value={pris[ce.id]}
					placeholder="Usage"
					options={priority_options}
					onChange={ e => {
						pris[ce.id] = e.value;
						setPris(pris);
						reload();
					}}
	   			/>}
				</div>
				</div>)}
			</div>
		<Button label="Merge and Replace"
			icon='fa fa-fw fa-merge'
			className={cuids.length > 1 ? '' : 'invisible'}
			onClick={e => {
				const args = { cmd: 'mod_org', 
					op: 'merge_clients', 
					rid: room.id, name, cuids, pris };
				mapi(args, 'Merged');
			}}
		/>

		{cuids.length == 1 && ces.length > 1 && 
			<Link to="#" onClick={e => {
					e.preventDefault();
					setUnmerge( !unmerge ); 
				}} >
				Un-merge
			</Link>}
		</div>
		<div className="p-col-4">
		</div>
	</div>

	<br/><br/>
	<div className="p-grid">
		{map_list(room.clients, cu => 
			<CUser cu={cu} onToggle={onToggle} cuids={cuids}
				key={cu.id} />)}
	</div>
</div>
	);
}


export {MergeClients};
