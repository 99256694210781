import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {PickList} from 'primereact/picklist';
import {Dropdown} from 'primereact/dropdown';

import {Focusable, Spacer, ModalCommand, lazyFocus} from './Utils';
import {ldb, log, go, get_room, go_url, get_first_id, growl, map_list,
	set_order, filter_list, api, pid2person, em,
} from './Lib';
import {FilteredPersons} from './Persons';

//-------------- Choose Client ---------------

function ChooseClient(props) {
	const [pid, setPid] = React.useState(0);
	const legend = 'Click Contact to Add to Connections';

	const newPid = cpid => {
		const person = pid2person(cpid);
		props.chosen(person.email);
	};

	return (
	<div>
		<FilteredPersons {...props} setPid={newPid} 
			legend={legend} />
	</div>
	);
}

//-------------- Add Client ---------------


const priority_options = [
	{label: 'Primary', value: 1},
	{label: 'Optional', value: 0},
	{label: 'Old/Unused', value: -1},
];

class EditClient extends Component {
	constructor(props) {
		super(props);

		const {rid, cid} = this.props;
		const cuser = get_room(rid).clients._items[cid];
		const root = cuser.emails;
		const name = cuser.name;

		set_order(root);

		const items = map_list(root, cemail => ({
			id: cemail.id,
		      	email: cemail.email,
		      	priority: cemail.priority,
		      	comment: cemail.comment,
		}));
		let extra = items.length < 3 ? 5 - items.length : 2;
		const email='', priority= -2, comment='';
		for (let i=0; i<extra; i++)
			items.push({id: -1-i, email, priority, comment});

		this.state = {
			cuser,
			name,
			items,
			iTab:0,
		};

		window.g_editClient = this;
	}

	chosen = email => {
		const {items} = this.state;
		const dups = items.filter(item => (item.email == email));
		if (dups.length) {
			growl(email + ': duplicate');
			return;
		}
		for (let i=0; i<items.length; i++) {
			const item = items[i];
			if (!item.email) {
				item.email = email;
				item.priority = 1;
				growl('Added ' + email);
				break;
			}
		}
		this.setState({items, iTab:0});
	}

	save = e => {
		const {rid} = this.props;
		const {cuser, name, items} = this.state;

		const args = {
			cmd: 'client_email_ids', 
			rid,
			cuid: cuser.id,
			items,
			name,
		};
		api( args, this.saved );
	}

	saved = e => {
		// this.props.close(e);
		window.history.back();
	}
	
	delete_email = (item) => {
		const {rid} = this.props;
		const {cuser} = this.state;
		
		const ceid = item.id;
		
		const args = {
			cmd: 'delete_client_email_id', 
			rid,
			cuid: cuser.id,
			ceid,
		};
		api( args, this.deleted_email );
	}

	deleted_email = e => {
		growl('Deleted email address');

		//const {items} = this.state;
		//items[index][prop] = val;
		//this.setState({items});
	}
	
	setVal = (index, prop, val) => {
		const {items} = this.state;
		items[index][prop] = val;
		this.setState({items});
	}

	setName = (new_name) => {
		this.setState({'name': new_name});
	}

	edit_email_address = (item, index) => {
		return <div key={index} className="p-grid">
			<div className="p-col-3">
				<Dropdown value={item.priority}
					placeholder="Usage"
					options={priority_options}
	   onChange={ e => this.setVal(index, 'priority', e.value) } 
	   			/>
				<div style={{display:'none'}}>
				{em(1)}
				<Link to="#" 
					onClick={e => {
					e.preventDefault(); 
					this.delete_email(item);
					}} >
					Remove Email
				</Link>
				</div>
			</div>
			<div className="p-col-5">
				<InputText value={item.email} 
					style={{width:'100%'}}
					placeholder="user@domain"
	   onChange={ e => this.setVal(index, 'email', e.target.value) } 
				/>
			</div>
			<div className="p-col-4">
				<InputText value={item.comment} 
					style={{width:'100%'}}
					placeholder="Comment"
	   onChange={ e => this.setVal(index, 'comment', e.target.value) } 
				/>
			</div>
		</div>
	}

	email_addresses = () => {
		const {items} = this.state;

		return (
		<React.Fragment>
			{items.map(this.edit_email_address)}
		</React.Fragment>
		);
	}

	tels = () => {
		const {cuser} = this.state;
		return (
		<React.Fragment>
			SMS Tel: {cuser.smstel}
			<br/>
			<br/>
			To set or change, please use inplace editing for now.
		</React.Fragment>
		);
	}

	settings = () => {
		const {props} = this;

		return (
	<div>
	Deleting a client user is an impactful operation.
	<br/>
	Only org admins can do this.
	<br/>
	You'll be asked for confirmation again.
	<br/>
	<br/>
		<Link to="#" 
			onClick={e => {
			e.preventDefault(); 
		props.par.select_command('DeleteClient', props.cid);
				}} >
			Delete Client
		</Link>
	<br/>
	<br/>
	</div>
		);
	}

	render() {
		const {cuser, name, items, iTab} = this.state;

		log('room', 'edit.client', this.props);

		// onHide={this.props.close}

	 	return (
                    <Dialog header="Edit Client" 
		    visible={true}
		    draggable={true}
		    modal={true}
		    onHide={e => window.history.back()}
		    maximizable={true}
		    style={{width:'80vw', height:'85vh'}}
		    	>
		<div className="p-grid">
		<div className="p-col-1 p-col-align-center">
			Name:
		</div>
		<div className="p-col-11">
			<InputText value={name} 
					style={{width:'100%'}}
					placeholder="Name"
	   				onChange={ e => this.setName(e.target.value) } 
			/>
		</div>
		</div>
	<TabView activeIndex={iTab} 
			onTabChange={e=>this.setState({iTab: e.index})} >
		<TabPanel header="Email Addresses">
			{this.email_addresses()}
		</TabPanel>
		<TabPanel header="Phone">
			{this.tels()}
		</TabPanel>
		<TabPanel header="Delete"
			disabled={!ldb.data.me.is_org_admin}
			>
			{this.settings()}
		</TabPanel>
		<TabPanel header="Choose">
			<ChooseClient cuser={cuser} {...this.props} 
				chosen={this.chosen}
			/>
		</TabPanel>
	</TabView>
			<Button label="Save" onClick={this.save} />
                    </Dialog>
	
		)
	}
}

export default EditClient;
