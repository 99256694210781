import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Dialog} from 'primereact/dialog';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Card} from 'primereact/card';

import {Spacer2, LinkButton, lazyFocus} from './Utils';
import {get_staff_options} from './Org';

import {ldb, log, go, get_room, go_url, growl, fulldate,
	api, edate3, map_list, get_staff, edate, 
} from './Lib';

//-------------- Summary : Room Info ---------------
// Field Template is defined once, per org, in settings.
//	org.settings.room_info_fields.
//		{ key : {name, key, kind, choices, ...}, ... }
// Field Value is stored per room. (instance)
//	room.info
//		{ key : { value, by, when }, ... }

function get_fields(room) {
	const fields = {_caption: {name: 'Caption', kind: 'text'},
		_flag: {name: 'Flag', kind: 'text'},
		_notes: {name: 'Notes', kind: 'textarea'},
	};
	Object.assign(fields, (ldb.data.org.settings.room_info_fields || {}) );
	for (const fid in fields) {
		const item = fields[fid];
		item.key = fid;
		item.placeholder = item.placeholder || 'Click to set';
	}
	return fields;
}

function mdy(isodate) {
	if (!isodate)
		return '';
	return window.g_moment( isodate ).format('M/D/Y');
}

function EditKind(props) {
	let {field, value, room, setValue} = props;
	let kind = field.kind;
	let options = [];

	log('room', 'EditKind', {field, value});

	switch (kind) {
	case 'allstaff':
		options = get_staff_options(false);
		kind = 'dropdown';
		break;
	
	case 'staff':
		options = get_staff_options(false, room);
		kind = 'dropdown';
		break;
	
	case 'dropdown':
		options = field.choices.map(x => ({ label: x, value: x}));
		break;
	};


	switch (kind) {
	case '':
	case 'link':
	case 'text':
		return <InputText ref={lazyFocus}
				value={value}
				onChange={e => setValue(e.target.value)}
					/>;
		break;

	case '':
	case 'textarea':
		return <InputTextarea ref={lazyFocus}
				value={value}
				onChange={e => setValue(e.target.value)}
					/>;
		break;

	case '':
	case 'date':
		const dt = mdy(value);
		return <Calendar dateFormat="m/d/yy"
				value={dt} placeholder={dt}
				onChange={e => setValue(e.value)}
					/>;
		break;

	case 'dropdown':
		return <Dropdown ref={lazyFocus}
				value={value}
				options={options}
				onChange={e=>setValue(e.value)}
				/>;
		break;

	case 'checklist':
		return <div>
		{field.choices.map((choice, i) => <div key={i}>
			<Checkbox value={choice}
				onChange={e => {
					// uninited ? make it {}
					if (value == '')
						value = {};
					value[choice] = e.checked;
					setValue(value);
				}}
				checked={value[choice]}
				></Checkbox>
			<label className="p-checkbox-label">
				{choice}
			</label>
			</div>)}
		</div>
		break;
	
	default:
		return null;
		break;

	}
}

function EditField(props) {
	const {field, instance, room, save} = props;
	const [value, setValue] = React.useState(instance.value || '');
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);

	const update = value => {setValue(value); reload()}

	return (
	  <React.Fragment>
		<div className="p-col-7 wide-input">
			<EditKind field={field} value={value} 
				setValue={update} room={room} />
		</div>
		<div className="p-col-2">
			<LinkButton label="save" 
				onClick={e => save(value)} />
			{' '}
			<LinkButton label="cancel" 
				onClick={e => save(null)} />
		</div>
	  </React.Fragment>
	);
}

function show_kind(field, value) {
	switch (field.kind) {
	case 'text':
		return value;
		break;

	case 'link':
		return value ? 
			<a href={value} target="_blank">{value}</a> : '';
		break;

	case 'textarea':
		return value ? <pre className="room-settings-field">{value}</pre> : '';
		break;

	case 'allstaff':
	case 'staff':
		return value ? get_staff(value).name : '';
		break;

	case 'date':
		return mdy(value);
		break;
	
	case 'checklist':
		value = value || {};
		return <div>
		{field.choices.map((choice, i)=><div key={i}>
			<Checkbox checked={value[choice]} disabled={true} />
				{' '}
				{choice}
			</div>)}
		</div>;
		break;
	};

	return value;
}

function ShowField(props) {
	const {field, instance} = props;
	let tooltip = '';
	if (instance.by)
		tooltip += 'Set by ' + get_staff(instance.by).name + ' ';
	if (instance.when)
		tooltip += 'on ' + edate(instance.when);

	return (
	  <React.Fragment>
		<div className="p-col-7" title={tooltip} >
			{show_kind(field, instance.value)}
		</div>
		<div className="p-col-2">
		    <LinkButton label="edit" 
		    	onClick={e=>props.setEditing(true)} />
		</div>
	  </React.Fragment>
	);
}

function RoomField(props) {
	const {field, room} = props;
	const instance = room.info[field.key] || {};
	const [editing, setEditing] = React.useState(false);
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => setRefresh(refresh+1);

	const save = value => {
		if (value !== null) {
			const args = {
				cmd: 'mod_room', 
				rid: room.id,
				op: 'set_field',
				val: {field: field.key, value},
			}
			api( args, (error, db) => {
				log('email', 'set_field', field);
				// give time for room.db to merge.
				setTimeout(reload, 500);
			});

		}
		setEditing(false);
	};

	return (
	<div className="p-col-12 room-field">
	<div className="p-grid">
		<div className="p-col-3">
			<i className='fa fa-fw fa-file-text-o'></i>
			{' '}
			{field.name}
		</div>
	  {editing ? 
		<EditField field={field} instance={instance} save={save} 
			room={room} /> 
		:
		<ShowField field={field} instance={instance} 
				setEditing={setEditing} /> 
	  }
	</div>
	</div>
	);
}

function RoomInfo(props) {
	const {room} = props;
	const fields = get_fields(room);

	log('room', 'org settings: room.info.fields', fields);

	return (
	<React.Fragment>
	{Object.keys(fields).map(key => 
		<RoomField key={key} field={fields[key]} room={room} />
	)}
	</React.Fragment>
	);
}

export {RoomInfo};
