import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Cookies from 'js-cookie';

import {ldb, log, go_url, get_reconnect_url, growl, api, mbox_write_perm} from './Lib';

export class Dashboard extends Component {
	render() {
		// Temporarily hidden due to errors
		//let url = get_reconnect_url();
		let url = null;
		if (url) {
			// Remove leading '#'. go_url adds it.
			//	Otherwise /#/#/.. and won't work.
			if (url.charAt(0) == '#')
				url = url.slice(1);
		} else if (!mbox_write_perm()) {
			url = go_url('mailbox_monitoring_info');
		} else {
			url = go_url('myrooms');
		}
		log('login', 'Dashboard - Redirect ', url);
		return <Redirect to={url} />

		// Skip below for now..
		// >>>>>> IGNORED FO+R NOW

		return (
		<div className="dashboard">
		    <div className="p-grid">
			<h1>
				Welcome to TagInbox.
			</h1>
		    </div>
		    <div className="p-grid">
			<p>
				Please 
				use the links in the left sidebar to get start
				get started.
			</p>
		    </div>
		 </div>
		);
	}
}
