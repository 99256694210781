import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import {Focusable, Spacer} from './Utils';
import {ldb, log, api, go, edate, edate3, map_list, go_url, regcomp,
	set_order, resort,
	get_first_name, show_is_new, get_room, get_staff, } from './Lib';

//------------------ OtherHomeRooms  ------------------------

function get_other_home_rooms_data(env, rid) {
	const current_room = get_room(rid);
	
	const home_room_ids = env.home_room_data;
	if (!home_room_ids)
		return [];

	let data = home_room_ids.map(hrid => {
		if (hrid == rid)
			return null;
		
		const room = get_room(hrid);
		if (!room)
			return null;

		const name = room.name;
		return {name, 'rid': hrid};
	});
	
	data = data.filter(function (el) {
		return el != null;
	});
	
	return data;
}

function OtherHomeRoomsPanel(props) {
	const {env, data} = props;
	
	return (
	<div className="other-rooms-panel">
	<div className="p-grid">
		{(data.length == 0) && <div className="p-col-12">No other home rooms</div>}
		{(data.length > 0) && data.map((item, i) => (
		<div className="p-col-12" key={i}>
			<Link to={go_url('room', item.rid, 'unshared', env.id)}
				data-iid={env.id}>
				{item.name}
			</Link>
		</div>))}
	</div>
	<div className="other-rooms-legend">
		Other Home Rooms For This Email
	</div>
	</div>
	);
}

window.g_onlineRefresh = () => null;

function OtherHomeRooms(props) {
	const {env, rid} = props;
	
	const data = get_other_home_rooms_data(env, rid);
	if (data.length == 0)
		return null;
	
	return <span className="other-rooms-icon">
		<i className="fa fa-fw fa-th-large"></i>
		<OtherHomeRoomsPanel env={env} data={data} />
	</span>;
}

export {OtherHomeRooms};
