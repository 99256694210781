import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {PickList} from 'primereact/picklist';
import {Dropdown} from 'primereact/dropdown';

import {ldb, log, go, get_room, go_url, get_first_id, growl, map_list,
	pid2person, set_order, filter_list, api,
} from './Lib';

//-------------- Add Client ---------------


const priority_options = [
	{label: 'Primary', value: 1},
	{label: 'Optional', value: 0},
	{label: 'Unused/Old', value: -1},
];

class EditPerson extends Component {
	constructor(props) {
		super(props);
		const {rid, pid} = props;
		const person = pid2person(pid);
		this.state = {name: person.name, comment: ''};
		window.g_editPerson = this;
	}

	save = e => {
		const {rid} = this.props;
		const {cuser, name, items} = this.state;

		const args = {
			cmd: 'client_email_ids', 
			rid,
			cuid: cuser.id,
			items,
			name,
		};
		api( args, this.saved );
	}

	saved = e => {
		this.props.close(e);
	}

	setVal = (index, prop, val) => {
		const {items} = this.state;
		items[index][prop] = val;
		this.setState({items});
	}

	setName = (new_name) => {
		this.setState({'name': new_name});
	}

	edit_email_address = (item, index) => {
		return <div key={index} className="p-grid">
			<div className="p-col-3">
				<Dropdown value={item.priority}
					placeholder="Usage"
					options={priority_options}
	   onChange={ e => this.setVal(index, 'priority', e.value) } 
	   			/>
			</div>
			<div className="p-col-5">
				<InputText value={item.email} 
					style={{width:'100%'}}
					placeholder="user@domain"
	   onChange={ e => this.setVal(index, 'email', e.target.value) } 
				/>
			</div>
			<div className="p-col-4">
				<InputText value={item.comment} 
					style={{width:'100%'}}
					placeholder="Comment"
	   onChange={ e => this.setVal(index, 'comment', e.target.value) } 
				/>
			</div>
		</div>
	}

	email_addresses = () => {
		const {items} = this.state;

		return (
		<React.Fragment>
			{items.map(this.edit_email_address)}
		</React.Fragment>
		);
	}

	tels = () => {
		const {cuser} = this.state;
		return (
		<React.Fragment>
			SMS Tel: {cuser.smstel}
			<br/>
			<br/>
			To set or change, please use inplace editing for now.
		</React.Fragment>
		);
	}

	render() {
		const {rid, pid} = this.props;
		const person = pid2person(pid);

	 	return (
                    <Dialog header="Edit Contact" 
			    visible={true}
			    draggable={true}
			    modal={true}
			    onHide={e => go('room', rid, 'person', 0)}
			    maximizable={false}
			    className="edit-person"
		    	>
	<div className="p-grid">

	<div className="p-col-2 label">
		Email:
	</div>
	<div className="p-col-10">
		{person.email}
	</div>

	<div className="p-col-2 label">
		Name:
	</div>
	<div className="p-col-10">
		<InputText value={person.name} 
			style={{width:'100%'}}
			placeholder="Name"
	   		onChange={ e => this.setName(e.target.value) } 
		/>
	</div>

	<div className="p-col-2 label">
		Comment:
	</div>
	<div className="p-col-10">

	</div>

	<div className="p-col-2">
	</div>
	<div className="p-col-10">
		<Button label="Save" onClick={this.save} />
	</div>

	</div>

                    </Dialog>
	
		)
	}
}

export {EditPerson, priority_options};
