import React, {Component} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';

import {ModalCommand, Spacer, Focusable, lazyFocus} from './Utils';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v, get_room_options, is_staff_a_recipient_of_shared_email,
	map_list, filter_list, gen_xid, get_item, go_url, escape_reg_exp,
	em, go} from './Lib';


function ChooseRoom(props) {
	// props = {rid, frid, onChoose}.  frid = from room id, rid = chosen rid
	const {same_room} = props;

	const [rid, setRid] = React.useState(props.rid);
	const croom = rid ? get_room(rid) : null;
	const [name, setName] = React.useState(croom ? croom.name : '');
	const choose = item => {
		setRid(item.id);
		setName(item.name);
		props.onChoose(item.id);
	};

	log('email', 'ChooseRoom', rid, croom, name);

	// valid choices = matches name or email.
	const choices = filter_list(ldb.data.rooms, room => (
		!room.is_mailbox && 
		(same_room || (props.frid != room.id)) &&
		room.name.search(RegExp(escape_reg_exp(name), 'i')) >= 0)
	);
		
	return (
	<div className="choose-room">
		<InputText value={name}
			ref={lazyFocus}
			onChange={e => setName(e.target.value)}
			onKeyDown={e => {
				if (e.key === 'Enter') {
					if (choices.length)
						choose(choices[0]);
				}
				else if (e.key === 'Escape') 
					setName('');
			}}
		/>
		{' '}
		<Button label="" icon="pi pi-times"
			className="p-button p-button-secondary"
			onClick={e => setName('')} />

		<div className="choices">
		  {choices.map(room => (
			<div key={room.id} className="item"
				onClick={e => choose(room)}
			>
				<div>
					{room.name}
				</div>
			</div>
		  ))}
		</div>
	</div>
	);
}

class MoveEmail extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, bulk_move, par} = this.props;
		
		const eids = bulk_move ? par.get_checked_ids({'mark_as_moved': false}) : [item.id];

		const room = get_room(rid);
		const working = false;
		const completed = false;
		const different_staff = false;
		
		let is_recipient = true;
		
		if (!ldb.data.me.is_org_admin) {
			if (bulk_move) {
				eids.forEach(eid => {
					const env = get_item(rid, 'shared', eid);
					if (!env)
						return;
					
					if ((!env.pinfo) || (!is_staff_a_recipient_of_shared_email(env, ldb.data.me.email)))
						is_recipient = false;
				});
			} else {
				is_recipient = item.pinfo ? is_staff_a_recipient_of_shared_email(item, ldb.data.me.email) : false;
			}
		}

		this.state = {rid:0, completed, working, different_staff, is_recipient};
		
		window.g_moveEmail = this;
	}

	has_errors = () => {
		if (!this.state.rid) {
			growl('Error', 'Please choose a room', 'error');
			return true;
		}

		if (this.state.rid == this.props.rid) {
			growl('Error', 'The email is already in this room', 'error');
			return true;
		}

		return false;
	}

	move_email = (go_after_move) => {
		console.log('MOVE EMAIL', go_after_move);

		if (this.has_errors())
			return;
		
		if (this.props.bulk_move) {
			return this.move_multiple_emails(go_after_move);
		}

		const {rid} = this.state;
		const {item} = this.props;
		const crid = this.props.rid;
		
		const eids = [item.id];

		this.setState({working: true});
		item._being_moved = true;

		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {
			cmd: 'move_email', 
			rid, crid, eids, bid,
		}
		if (go_after_move === true) {
			api( args, this.moved_now_go );
		} else {
			api( args, this.moved );
		}
		
		window.g_progCircle.add(bid, 
			"Email(s) moved to " + get_room(rid).name,
				eids.length);
	}
	
	move_email_and_go = () => {
		return this.move_email(true);
	}
	
	move_multiple_emails = (go_after_move) => {
		console.log('MOVE MULTIPLE EMAILS', go_after_move);
		
		const {rid} = this.state;
		const crid = this.props.rid;
		
		const eids = this.props.par.get_checked_ids();
		if (!eids.length)
			return growl("Please select some items");
				
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {cmd: 'move_email', rid, crid, eids, bid};
		this.setState({working: true});
		if (go_after_move === true) {
			api( args, this.moved_now_go );
		} else {
			api( args, this.moved );
		}
				
		window.g_progCircle.add(bid, 
			"Email(s) moved to " + get_room(rid).name,
				eids.length);

	}

	moved = (error) => {
		if (!error)
			growl("Moving Email(s)");
		
		this.setState({completed: true});
		
		this.props.close();
	}

	moved_now_go = (error) => {
		const {rid} = this.state;
		
		if (!error)
			growl("Moving Email(s)");
		
		this.setState({completed: true});
		
		this.props.close();
		
		go('room', rid, 'shared');
	}

	updated_room = (rid) => {
		this.setState({rid: rid});
		
		if (!rid)
			return;

		const old_room = get_room(this.props.rid);
		const new_room = get_room(rid);
		const old_room_staff = old_room.staffs._idlist;
		const new_room_staff = new_room.staffs._idlist;
		let different_staff = false;
		if (old_room_staff != new_room_staff) {
			different_staff = true;
		}
		this.setState({different_staff});
	}

	different_staff_warning = () => {
		const different_staff_warning = this.state.different_staff
			? <div><div className="warn">
			This room has a different set of staff than the current
			room for this email
			</div><Spacer /></div> : null;

		return different_staff_warning;
	}
	
	render() {
		const {item, rid, bulk_move} = this.props;
		const new_rid = this.state.rid;

		const room = get_room(rid);
		const new_room = (new_rid > 0) ? get_room(new_rid) : null;

		if (this.state.completed) {
			return (
			<ModalCommand par={this}
			className="modal-35-command"
			header="Move Emails to a Different Room">
			Emails are being moved.
			<br/>
			<div>
			<Link to={go_url('room', new_rid, 'shared')}>
				Go to {new_room.name}
			</Link>
			</div>
			</ModalCommand>
			);
		}

		if (!bulk_move) {
			if (!item.pinfo) {
				// refresh, email is not fetched yet.
				this.props.par.select_command('');
				return null;
			}
		}
		
		const room_options = get_room_options();
		
		if (!this.state.is_recipient) {
			return (
			<ModalCommand par={this}
			className="modal-35-command"
			header="Move Emails to a Different Room">
			You cannot move an email that was not sent to you.
			</ModalCommand>
			);
		}
		
	 	return (
		<ModalCommand par={this}
			className="modal-35-command"
			header="Move Emails to a Different Room">
	
	{this.different_staff_warning()}
	
	<div className="p-grid">
		<div className="p-col-2">From:</div>
		<div className="p-col-10">
			{room.name}
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-2">To:</div>
		<div className="p-col-10">
			<ChooseRoom rid={this.state.rid}
				frid={rid}
				onChoose={this.updated_room} />
		</div>
	</div>
	{!this.state.working && <div className="p-grid" >
		<div className="p-col-2">
		</div>
		<div className="p-col-10">
			<Button label="Move Email(s)"
				icon="pi pi-directions"
				onClick={this.move_email}
			/>
			<br/><br/>
			<Button label="Move Email(s) and Go"
				icon="pi pi-directions"
				onClick={this.move_email_and_go}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
		)
	}
}

export {MoveEmail, ChooseRoom};
