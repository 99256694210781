import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {InputTextarea} from 'primereact/inputtextarea';
import {ProgressSpinner} from 'primereact/progressspinner';
import {jsPanel} from 'jspanel4/es6module/jspanel';
import 'jspanel4/dist/jspanel.min.css';
import 'primeflex/primeflex.css';

import {ldb, log, api, go, map_list,} from './Lib';
import {set_tag_count} from './Menu';

//------------------ Diag  ------------------------


class Diag extends Component {
	constructor() {
		super();
		this.state = {working:false, done: false,
			headerbuf: '',
			panels: {},
			};
	}
	
	get_diag = () => {
		const {headerbuf} = this.state;
		const args = {cmd: 'diag', headerbuf};
		api( args, this.got_diag );
		this.setState({working: true});
	}

	got_diag = () => {
		log('lib', 'Diag.Result', ldb.data.sys.diag.find_email);
		this.setState({working: false, done: true});
	}

	show_results = () => {
		const {done} = this.state;

		if (!done)
			return null;
		const {find_email} = ldb.data.sys.diag;
		if (!find_email)
			return <div>No Result</div>;

		return (
		<div>
			<h3>Results</h3>
		<pre>
			{find_email.result}
		</pre>
		</div>
		);
	}
	
	render() {

		log('lib', 'Diag');

		return (
		<div>
	<Card title="Where&apos;s My Email?" 
	subTitle="Copy and Paste Email Headers from an email in your Inbox" 
				footer="" >
		<InputTextarea 
			style={{height:'12em', width:'100%',}}
			autoResize={false}
			value={this.state.headerbuf} 
			onChange={ e => this.setState({headerbuf: 
						e.target.value }) }
			/>
	<Button label="Find Email" onClick={this.get_diag} />
	<br/>
	{this.show_results()}
	{this.state.working && <ProgressSpinner/>}
	</Card> 
		<dl>
		<dt>How to get email headers</dt>
		<dd>
		<ol>

		<li>
		Open up an email in a new window by double clicking on 
			email in outlook.
		</li>

		<li>
		Click on file on top, select properties.
		</li>

		<li>
		On the new windows called "Properties" that pop's up,  
			look for a section titled "Internet Headers".    	
		</li>

		</ol>
		</dd>
		</dl>

	<br/><br/>
	<Button label="Tag Count" className="hide"
		onClick={e => {
			const count = Math.round(Math.random() * 1000);
			set_tag_count(count);
			window.g_appMenuTab.refresh();
		}}
	/>
		</div>
		)
	}
}

//------------------ Old Diag  ------------------------
//	This displays Inbox UIDs.
//	Not used currently.

class old_Diag extends Component {
	constructor() {
		super();
		this.state = {working:false,};
	}
	
	get_diag = () => {
		const args = {cmd: 'diag'};
		api( args, this.got_diag );
		this.setState({working: true});
	}

	got_diag = () => {
		// skip_post_proc.. so order will be empty
		const root = ldb.data.sys.diag.unshared_emails;
		root._order = root._idlist;
		this.setState({working: false});
	}

	ue_legend = () => <div className="p-grid legend bold">
		<div className="p-col-1">
			id
		</div>
		<div className="p-col-1">
			UID
		</div>
		<div className="p-col-1">
			Shared
		</div>
		<div className="p-col-1">
			Auto
		</div>
		<div className="p-col-8">
			Env
		</div>
	</div>

	show_ues = () => {
		const ues = ldb.data.sys.diag.unshared_emails;
		return <div> <h4>Expected (Unshared) Emails</h4>
		{this.ue_legend()}
		{map_list(ues, ue => {
			return <div className="p-grid" key={ue.id} >
				<div className="p-col-1">
					{ue.id}
				</div>
				<div className="p-col-1">
					{ue.uid}
				</div>
				<div className="p-col-1">
					{ue.is_shared ? 'Yes' : 'No' }
				</div>
				<div className="p-col-1">
					{ue.auto_share ? 'Yes' : 'No' }
				</div>
				<div className="p-col-8">
					{ue.env_json}
				</div>
				<div className="p-col-12">
					{ue.msgid}
				</div>
			</div>
			})}
		<hr/>
		</div>
	}

	show_iuids = () => {
		const iu = ldb.data.sys.diag.inbox_uids;

		return <div>
			<h4>Inbox UIDs</h4>
	<div className="p-grid">
		<div className="p-col-2">
			UID Next:
		</div>
		<div className="p-col-9">
			{iu.uidnext}
		</div>

		<div className="p-col-2">
			UID From:
		</div>
		<div className="p-col-9">
			{iu.uidfrom}
		</div>

		<div className="p-col-2">
			UID Till:
		</div>
		<div className="p-col-9">
			{iu.uidtill}
		</div>

		<div className="p-col-2">
			Date From:
		</div>
		<div className="p-col-9">
			{iu.dt_from}
		</div>
		<div className="p-col-2">
			Date Till:
		</div>
		<div className="p-col-9">
			{iu.dt_till}
		</div>

		<div className="p-col-2">
			Select:
		</div>
		<div className="p-col-9">
			{iu.selret}
		</div>
	</div>
		<hr/>
		</div>
	}
	
	render() {

		log('lib', 'Diag');

		return (
<div>
	<Button label="Update" onClick={this.get_diag} />
	{this.state.working && <ProgressSpinner />}
	{/*  this.show_ues() */}
	{this.show_iuids()}
</div>
		)
	}
}

export {Diag};
