import React, {Component} from 'react';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {RadioButton} from 'primereact/radiobutton';
import {InputText} from 'primereact/inputtext';
import {Menu} from 'primereact/menu';
import {Dropdown} from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {Toolbar} from 'primereact/toolbar';

import ListView from './ListView';
import {LinkedTaskHeader, FullTask, TaskDetailHeader, TaskListLegend, } from './TaskUtil';
import UpdateTask from './UpdateTask';
import DoneTask from './DoneTask';
import UndoneTask from './UndoneTask';
import {CreateTask} from './CreateTask';
import {TaskCommand} from './EditTask';
import FilterTasks from './FilterTasks';
import {ModalCommand, MoreMenu, Spacer, Focusable} from './Utils';

import {ldb, log, set_order, map_list, filter_list, resort,
	get_room, growl, api} from './Lib';

//-------------- Tab: tasks ---------------

/*
 * Standardize "More..." button for dropdown menu
 * Requires caller to set:
 * 	model
 */

class FilterMenu extends Component {
	render() {
		return <span>
	<Menu model={this.props.model} key="m1" 
			popup={true} ref={el => this.list_menu=el}/>
	<Button label=""
		className="p-button-raised p-button-secondary"
		icon="pi pi-fw pi-filter"
		onClick={ev => this.list_menu.toggle(ev)}
	/>
		</span>
	}
}

class Tasks extends ListView {
	constructor(props) {
		super(props);
		this.state.show = this.init_show();
		this.state.sortby = this.init_sortby();
		this.state.show_nudges = true;
	}

	init_show = () => {
		const list = this.get_item_list();
		if (!list)
			return 'active';

		if (list._flags._show === undefined) {
			list._flags._show = 'active';
			list._flags._fetched_done_tasks = false;
			list._flags._filter_fn = this.filter_active_only;
		}
		return (list._flags._show == 'active') ? 'active' : 'all';
	}

	init_sortby = () => {
		const list = this.get_item_list();
		const initial_value = ldb.data.me.settings.tasks_sortby;
		if (list) {
			list._flags.sortby = initial_value;
			resort(list);
		}
		
		if (list && list._flags)
			return list._flags.sortby;
		return '';
	}
	
	set_sort_by = sortby => {
		const list = this.get_item_list();
		list._flags.sortby = sortby;
		resort(list);
		this.setState({sortby});
		this.save_sort_by_settings(sortby);
	}

	set_show = show => {
		const list = this.get_item_list();
		list._flags._show = show;
		if (show == 'active') {
			list._flags._filter_fn = this.filter_active_only;
		} else if (show == 'done') {
			list._flags._filter_fn = this.filter_done_only;
		} else {
			list._flags._filter_fn = null;
		}
		resort(list);
		this.setState({show});
	}
	
	save_sort_by_settings = sortby => {
		const new_settings = {'tasks_sortby': sortby};
		const args = {cmd: 'staff_settings_update', new_settings};
		api( args );
		ldb.data.me.settings.tasks_sortby = sortby;
	}

	/* ---------- done tasks / filter -----------*/

	filter_active_only = task => {
		//task.to_sid > 0
		if (task.dt_done) {
			return false;
		} else {
			return true;
		}
	}

	filter_done_only = task => {
		//task.to_sid > 0
		if (task.dt_done) {
			return true;
		} else {
			return false;
		}
	}
	
	get_done_tasks = () => {
		const {rid, tab} = this.props;
		const args = {cmd: 'get_list', tab, rid, active: false,};
		api( args, this.got_done_tasks );
	}

	got_done_tasks = () => {
		this.refresh();
	}
	
	//---- Assigned to the current user
	
	is_it_mine = (item) => {
		if (item.to_sid == ldb.data.me.id) {
			return true;
		} else {
			return false;
		}
	}

	/* ---------- done tasks / filter -----------*/

	no_items_text = () => {
		return <div>There are no tasks</div>
	}

	show_item_header = (task, is_new) => {
		const is_mine = this.is_it_mine(task);
		
		return <LinkedTaskHeader 
			task={task} 
			is_new={is_new}
			is_mine={is_mine}
			tab={this.props.tab} 
			iid={this.props.iid}
		/>
	}
	
	show_item_detail = task => <FullTask 
			task={task} 
			tab={this.props.tab} 
			par={this}
		/>

	list_toolbar = (list) => {
	const sortby = [
		{label: 'ID #', value:'id'},
		{label: 'Assigned To', value:'to'},
		{label: 'Last Modified', value:'dt_updated'},
		{label: 'Category', value:'category'},
		{label: 'Due Date', value:'dt_due'},
		{label: 'Priority', value:'priority'},
		{label: 'Subject', value:'name'},
	];
	
	const showoptions = [
		{label: 'All', value:'all'},
		{label: 'Only Active', value:'active'},
		{label: 'Only Done', value:'done'},
	];

	if (this.state.sortby)
		sortby.unshift({label: 'Sort By:', value:''});
	
	//const initial_value = ldb.data.me.settings.tasks_sortby;
	//console.log('INITIAL VALUE: ' + initial_value);
	//if (initial_value) {
		//this.state.sortby = initial_value;
	//	this.setState({'sortby': initial_value});
	//}
	
	const left = (
	<React.Fragment>
		<i className="fa fa-fw fa-list"></i> 
		{' '}
		<strong>List</strong>
		<Button label="New"
			className="p-button-raised p-button-outlined"
			icon="pi pi-plus-circle" 
			tooltip="New Task"
			onClick={() => this.select_command('create_task')}
		/>
	</React.Fragment>
	);

	const right = (
	<React.Fragment>
		<span className="task-filter-label">
		Show: 
		</span>
		<Dropdown value={this.state.show}
			options={showoptions}
			onChange={ e => this.set_show(e.value) }
			placeholder="---"
		/>
		
		<span className="task-filter-label">
		Sort By:
		</span>
		
		<Dropdown value={this.state.sortby}
			className='task-sortby'
			options={sortby}
			onChange={ e => this.set_sort_by(e.value) }
			placeholder="---"
		/>
	</React.Fragment>
	);
		return <Toolbar left={left} right={right} 
			className="task-toolbar" />;
	}

	detail_toolbar = task => {
		if (!task || !task.id)
			return <Toolbar />;
		
		let action_button = '';
		if (task.dt_done) {
			action_button = <Button label=""
			className="p-button-raised p-button-secondary"
			icon="pi pi-fw pi-replay"
			tooltip="Set Task to Not Done"
			onClick={() => this.select_command('undone_task')}
			/>
		} else {
			action_button = <Button label=""
			className="p-button-raised p-button-secondary"
			icon="pi pi-fw pi-check"
			tooltip="Task Done"
			onClick={() => this.select_command('done_task')}
			/>	
		}

		const items = [
			{
			label: 'Print Task',
			icon:"pi pi-print",
			command: () => this.setState({print:true}),
			},
		      ];
		
	const left = (
	<React.Fragment>
		<i className="fa fa-fw fa-calendar-check-o"></i> 
		{' '}
		<strong>Task #{task.id}</strong>
		{' '}
	</React.Fragment>
	);

	const right = (
	<React.Fragment>
		{action_button}
		<Button label="Update"
			className="p-button-raised p-button-text"
			icon="pi pi-pencil" 
			tooltip="Update Task"
			onClick={() => this.select_command('update_task')}
		/>
		{<MoreMenu model={items} />}
	</React.Fragment>
	);
		return <Toolbar left={left} right={right} />;
	}

	list_legend = args => <TaskListLegend {...args} />

	update_task_command = args => <UpdateTask {...args} />

	done_task_command = args => <DoneTask {...args} />
	
	undone_task_command = args => <UndoneTask {...args} />

	create_task_command = args => <CreateTask {...args} />

	filter_tasks_command = args => <FilterTasks {...args} />
}

export default Tasks;
