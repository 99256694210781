import React, { Component, useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {NavLink} from 'react-router-dom'
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';

import {TabView, TabPanel} from 'primereact/tabview';

import {log, ldb, url2room} from './cw/Lib';
import {url2menu} from './cw/Menu';
import {MiniRooms} from './cw/Rooms';
import {MiniTags} from './cw/Tag';
import {MiniMyTasks} from './cw/MyTasks';

// How AppMenu works:
//	Each sidebar item is a regular <a> tag, with URL like '#/mytags'
//	When user clicks on it, we remember the seq (to highlight selected menu)
//		and go that URL.
//	So first we refresh all of menu, when we save state with seq
//	Then go to that URL.
//	
//
//	Raja: we extended this to highlight menu even when we move back.
//
//	We also check each update to see if we're moving forward or backwards
//		and if back, change the highlight.
// Raja:
//	Original AppMenu works when it "leads"... user clicks on menu
//	It fails when it "folows".. user clicks "back" or code does go(...)
// To fix this,
//	We track forward clicks in global.
//	We check if we need to be in reverse mode, if location.hash is not
//		the same as forward link.
//	If so, we go in reverse, to reset Selected Submenus and 
//		selet the rigth Submenus, and modify tiutle.
let g_clickedItem = {}; // raja
window.g_clickedItem = g_clickedItem;
window.g_userClicked = false;

class AppSubmenu extends Component {

    static defaultProps = {
        className: null,
        items: null,
        onMenuItemClick: null,
	rev: null,
        root: false
    }

    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.array,
        rev: PropTypes.array,
        onMenuItemClick: PropTypes.func,
        root: PropTypes.bool
    }
    
    constructor(props) {
        super(props);
        this.state = {activeIndex: null, count: 0};
    }

    choose = (item, synth=false) => {	// raja
    	if (item.url) {
		g_clickedItem = item;
		window.g_clickedItem = g_clickedItem;
		window.g_currentRoom = null;
		window.g_setTitle(item.label);	
		if (!synth)
			window.g_userClicked = true;
		log('lib', 'menu.choose', item, synth);
	}
    }
    
    onMenuItemClick(event, item, index) {
        //avoid processing disabled items
        if(item.disabled) {
            event.preventDefault();
            return true;
        }

	this.choose(item);

	log('lib', 'App.Menu onClick', item, index, this.state.activeIndex);
                        
        //execute command
        if(item.command) {
            item.command({originalEvent: event, item: item});
        }

        if(index === this.state.activeIndex)
            this.setState({activeIndex: null});    
        else
            this.setState({activeIndex: index});

        if(this.props.onMenuItemClick) {
            this.props.onMenuItemClick({
                originalEvent: event,
                item: item
            });
        }
    }

	renderLinkContent(item) {
		let submenuIcon = item.items && <i className="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>;
		let badge = item.badge ? <span className="menuitem-badge" title={item.badge_tooltip} >{item.badge}</span> : null;

		return (
			<React.Fragment>
				<i className={item.icon}></i>
				<span>{item.label}</span>
				{submenuIcon}
				{badge}
			</React.Fragment>
		);
	}

	renderLink(item, i) {
		let content = this.renderLinkContent(item);

		if (item.to) {
			return (
				<NavLink activeClassName="active-route" to={item.to} onClick={(e) => this.onMenuItemClick(e, item, i)} exact target={item.target}>
                    {content}
                </NavLink>
			)
		}
		else {
			return (
				<a href={item.url} onClick={(e) => this.onMenuItemClick(e, item, i)} target={item.target}>
					{content}
				</a>
			);

		}
	}
    
    render() {
        let items = this.props.items && this.props.items.map((item, i) => {
            let active = this.state.activeIndex === i;
            let styleClass = classNames(item.badgeStyleClass, {'active-menuitem': active && !item.to}, item.className);

            return (
                <li className={styleClass} key={i}>
                    {item.items && this.props.root===true && <div className='arrow'></div>}
					{this.renderLink(item, i)}
                    <AppSubmenu items={item.items} onMenuItemClick={this.props.onMenuItemClick}/>
                </li>
            );
        });

	if (this.state.activeIndex)
		log('lib', 'AppSubMenu++++', this.state.activeIndex);

	// raja
	if (this.props.items) 
		this.props.items.forEach(item => item.comp = this);
        
        return items ? <ul className={this.props.className}>{items}</ul> : null;
    }
}

function clear(items) {
	items[0].comp.setState({activeIndex:null});
	items.forEach(item => item.items && clear(item.items));
}

// Reverse engineer setting index based on current url
function revsync(root, seq) {
	let item = null;
	seq.forEach(num => {
		item = root[num];
		item.comp.setState({activeIndex:num});
		root = item.items;
	});
	if (item)
		item.comp.choose(item, true);
}

class AppMenuTab extends Component {

    static defaultProps = {
        model: null,
        onMenuItemClick: null
    }

    static propTypes = {
        model: PropTypes.array,
        onMenuItemClick: PropTypes.func
    }
    
    constructor(props) {
        super(props);
        this.state = {count: 0};
	this._isMounted = false;
	window.g_appMenuTab = this;
    }

    refresh = () => {
    	if (this._isMounted)
		this.setState({count: this.state.count+1});
    }

    componentDidMount() {
	this._isMounted = true;
    }
    componentWillUnmount() {
	this._isMounted = false;
    }

    componentDidUpdate() {
    	if (!ldb.is_logged_in())
		return ;

	const hash = window.location.hash;
	log('lib', 'AppMenuTab: Updated', hash, g_clickedItem.url);
	// if (g_clickedItem.url == hash) {
	if (window.g_userClicked) {
		window.g_setTitle(g_clickedItem.label);
		window.g_userClicked = false;
		return ;
	}

	const seq = url2menu[hash];
	if (seq === undefined) {
		const room = url2room(hash);
		if (room) {
			window.g_currentRoom = room;
			window.g_setTitle(room.name);
		} else {
			window.g_currentRoom = null;
		}
		return ;
	}

	// return; /**** temporary ****/
	
	const items = this.props.model;
	log('lib', 'menu.Reverse', {g_clickedItem, hash, seq});
	
	clear(items);
	revsync(items, seq);
    }

    render() {
	log('lib', 'AppMenuTab----', );

        return (
            <div className="layout-menu-container">
                <AppSubmenu items={this.props.model} className="layout-menu" onMenuItemClick={this.props.onMenuItemClick} root={true} />
            </div>
        );
    }
}

function SearchBar(props) {
	const {search, setSearch, tab, url, hdr} = props;
	return (
<div className="mini-search-bar">
<div className="p-grid p-align-center">
	<div className="p-col-1">
		<i className="pi pi-fw pi-search"></i>
	</div>

	<div className="p-col-9">
	<InputText value={search} 
		placeholder={tab}
		onChange={e => setSearch(e.target.value)}
			/>
	{search && <Button label='' icon='pi pi-fw pi-times'
			onClick={e => setSearch('')} />}
	</div>
	
	<div className="p-col-1">
		<Link to={url} onClick={e=>window.g_setTitle(hdr)} >
			<i className="pi pi-fw pi-external-link"></i>
		</Link>
	</div>
	<div className="p-col-1">
	</div>
</div>
</div>
	);
}

/*
function ExtraMenu(props) {
	const [index, setIndex] = useState(0);
	const [search, setSearch] = useState('');

	return (
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)}
		className="menutabs" contentClassName="layout-menu-container"
	>
		<TabPanel header="" leftIcon="pi pi-fw pi-tags">
			<SearchBar search={search} setSearch={setSearch}
				tab="Tags for me" url='/mytags' hdr="Tags"/>
			<MiniTags search={search} />
		</TabPanel>
		<TabPanel header="" leftIcon="pi pi-fw pi-list">
			<SearchBar search={search} setSearch={setSearch}
				tab="Tasks for me" url='/task' hdr="Tasks"
				/>
			<MiniMyTasks search={search} />
		</TabPanel>
	</TabView>
	);
}
*/

function AppMenu(props) {
	const [index, setIndex] = useState(0);
	const [search, setSearch] = useState('');
	const [refresh, setRefresh] = useState(0);
	const reload = () => setRefresh(refresh+1);

	useEffect(() => {
		window.g_appMenu_reload = reload;

		return function cleanup() {
			window.g_appMenu_reload = null;
		};
	});
	

	if (!ldb.is_logged_in())
		return <AppMenuTab {...props} />;

		/*
		<TabPanel header="" leftIcon="pi pi-fw pi-ellipsis-v">
			<ExtraMenu {...props} />
		</TabPanel>
		*/

	return (
	<TabView activeIndex={index} onTabChange={e=>setIndex(e.index)}
		className="menutabs"
	>
		<TabPanel header="" leftIcon="pi pi-fw pi-bars">
			<AppMenuTab {...props} />
		</TabPanel>
		<TabPanel header="" leftIcon="fa fa-fw fa-folder-open narrow">
			<SearchBar search={search} setSearch={setSearch}
				tab="My Rooms" url='/myrooms' hdr="My Rooms" />
			<MiniRooms search={search} />
		</TabPanel>
		<TabPanel header="" leftIcon="pi pi-fw pi-tags narrow">
			<SearchBar search={search} setSearch={setSearch}
				tab="Tags for me" url='/mytags' hdr="Tags"/>
			<MiniTags search={search} />
		</TabPanel>
		<TabPanel header="" leftIcon="fa fa-fw fa-calendar-check-o narrow">
			<SearchBar search={search} setSearch={setSearch}
				tab="Tasks for me" url='/task' hdr="Tasks"
				/>
			<MiniMyTasks search={search} />
		</TabPanel>
	</TabView>
	);
}

export {AppMenu};
