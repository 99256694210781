import React, {Component, useState, useRef, useEffect} from 'react'; 
import {Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Calendar} from 'primereact/calendar';
import {Checkbox} from 'primereact/checkbox';
import {Menu} from 'primereact/menu';
import {confirmDialog} from 'primereact/confirmdialog';
import {OrderList} from 'primereact/orderlist';
import DnDList from 'react-dnd-list';
import {Toolbar} from 'primereact/toolbar';
import {Panel} from 'primereact/panel';
import {ScrollPanel} from 'primereact/scrollpanel';
import {InputText} from 'primereact/inputtext';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';
import {TabView, TabPanel} from 'primereact/tabview';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {show_name} from './TaskUtil';
import {ModalCommand, Spacer, Focusable, FakeLink, lazyFocus, 
	HelpInfo} from './Utils';

import {ldb, log, gen_email_summary, task_category_default, regcomp,
	go_url, url2room, url2iid, go, em, fulldatetime, gen_xid,
	get_first_name, get_staff, get_room, set_order, resort,
	priorities, get_tag, pri_v2n, edate, env_tags, rel_url,
	task_due_date_delta_default, growl, api, pri_n2v, edate2,
	edate3, get_tag_by_me, is_tag_read, is_my_tag_read, init_list,
	map_list, foreach_list, copy_to_clipboard,
	show_is_new, pid2person, get_mailbox_room} from './Lib';



class FindEmails extends Component {
	constructor(props) {
		super(props);
		
		let search_from = ldb.data.find_emails_search ? ldb.data.find_emails_search.search_from : '';
		let search_recipients = ldb.data.find_emails_search ? ldb.data.find_emails_search.search_recipients : '';
		let search_subject = ldb.data.find_emails_search ? ldb.data.find_emails_search.search_subject : '';
		let search_date_from = ldb.data.find_emails_search ? ldb.data.find_emails_search.search_date_from : '';
		let search_date_till = ldb.data.find_emails_search ? ldb.data.find_emails_search.search_date_till : '';
		let search_recent = ldb.data.find_emails_search ? ldb.data.find_emails_search.search_recent : '10';
		
		let result_envs = [];
		
		let working = false;
		let submitted = false;
		
		if (ldb.data.find_emails_results) {
			result_envs = ldb.data.find_emails_results.envs;

			submitted = true;
		}
		
		this.state = {working, submitted, search_from, search_recipients, search_subject, search_date_from, search_date_till, search_recent, result_envs};
		
		window.g_findEmails = this;
	}
	
	componentDidMount() {
		if (!this.state.submitted) {
			this.onSubmit();
		}
	}
	
	componentDidUpdate() {
	}
	
	componentWillUnmount() {
		this._isMounted = false;
	}
	
	saveSearch = () => {
		let {search_from, search_recipients, search_subject, search_date_from, search_date_till, search_recent} = this.state;
		
		ldb.data.find_emails_search = {search_from, search_recipients, search_subject, search_date_from, search_date_till, search_recent};
	}
	
	reset = () => {
		this.setState({working: false, submitted: false, search_from: '', search_recipients: '', search_subject: '', search_date_from: '', search_date_till: '', search_recent: '10', result_envs: []});
		
		this.saveSearch();
	}

	onSubmit = () => {
		let {search_from, search_recipients, search_subject, search_date_from, search_date_till, search_recent} = this.state;
		
		if ((search_date_from) && (search_date_till)) {
			if (search_date_from.getTime() > search_date_till.getTime()) {
				growl('Error', 'Date from cannot be later than date till', 'error');
				return;
			}
		}

		this.saveSearch();
				
		const args = {
			cmd: 'find_emails', 
			search_from,
			search_recipients,
			search_subject,
			search_date_from,
			search_date_till,
			search_recent
		};
		
		api(args, this.submitted);
		
		this.setState({working: true});
	}

	submitted = (error, db) => {
		if (error) {
			growl('Error', error, 'error');
			return;
		}
		
		let results = db.search_results;
		
		ldb.data.find_emails_results = results;

		this.setState({working: false, submitted: true, result_envs: results.envs});
	}
	
	updateDateFrom = e => {
		let new_date_from = e.value;
		
		this.setState({search_date_from: new_date_from});
		
		if (new_date_from) {
			let new_date_till = new Date(new_date_from.getTime());
			
			new_date_till.setDate(new_date_till.getDate() + 7);
			
			this.setState({search_date_till: new_date_till});
		}
	}
	
	keyDown = e => {
		if (e.key === 'Enter') {
			this.onSubmit();
		}
	}

	render() {
		const props = this.props;
		
		return (
	<div className="card find-emails-wrapper">
	<div>
	
	<div id="advanced-search-div">

	<div className="p-grid small">
		<div className="p-col-2 wide-input" >
			<div className="fe-label p-grid">
				<div className="p-col-4 find-emails-label">
					From
				</div>
				<div className="p-col-8 find-emails-label find-emails-help-text">
					Name/Email Fragment
				</div>
			</div>
		<InputText
			value={this.state.search_from} 
			placeholder=""
			onChange={ e => this.setState({search_from: e.target.value}) }
			onKeyDown={this.keyDown}
			className=""
		/>
		</div>

		<div className="p-col-2 wide-input" >
			<div className="fe-label p-grid">
				<div className="p-col-4 find-emails-label">
					To/Cc
				</div>
				<div className="p-col-8 find-emails-label find-emails-help-text">
					Name/Email Fragment
				</div>
			</div>
		<InputText
			value={this.state.search_recipients} 
			placeholder=""
			onChange={ e => this.setState({search_recipients: e.target.value}) }
			onKeyDown={this.keyDown}
			className=""
		/>
		</div>

		<div className="p-col-2 wide-input" >
			<div className="fe-label"> Subject </div>
		<InputText
			value={this.state.search_subject} 
			placeholder=""
			onChange={ e => this.setState({search_subject: e.target.value}) }
			onKeyDown={this.keyDown}
			className=""
		/>
		</div>

		<div className="p-col-1 wide-input" >
			<div className="fe-label"> Date From </div>
		<Calendar
			value={this.state.search_date_from} 
			onChange={this.updateDateFrom}
			onKeyDown={this.keyDown}
			className=""
		/>
		</div>

		<div className="p-col-1 wide-input" >
			<div className="fe-label"> Date Till </div>
		<Calendar
			value={this.state.search_date_till} 
			onChange={ e => this.setState({search_date_till: e.value}) }
			onKeyDown={this.keyDown}
			className=""
		/>
		</div>

		<div className="p-col-1 wide-input" >
			<div className="fe-label"> Recent </div>
		<Dropdown
			value={this.state.search_recent}
			options={[
				{label: '10', value: '10'},
				{label: '50', value: '50'},
				{label: '100', value: '100'},
				{label: '500', value: '500'}
			]}
			onChange={ e => this.setState({search_recent: e.value}) }
			className=""
		/>
		</div>
		<div className="p-col-2 wide-input fe-bottom">
			<Button 
				icon="fa fa-fw fa-search" 
				label="Find"
				onClick={this.onSubmit}
			/>
			{em(1)}
			<Button 
				icon='pi pi-fw pi-times'
				label=''
				onClick={this.reset}
				className="p-button-secondary"
			/>
		</div>

	</div>

	</div>

	
	<hr/>
	
	<div className="find-emails-results">
	
	{this.state.working && <ProgressSpinner />}
	
	{(this.state.submitted) && (!this.state.working) && <div>

	{(this.state.result_envs.length == 0) && <div>
		No matching emails found
	</div>}
	
	{(this.state.result_envs.length > 0) && <div className="p-grid find-emails-header-div">
		<div className="p-col-2 find-emails-header-col find-emails-header-col-left">From</div>
		<div className="p-col-3 find-emails-header-col">Subject</div>
		<div className="p-col-2 find-emails-header-col">Date</div>
		<div className="p-col-2 find-emails-header-col">Tab</div>
		<div className="p-col-3 find-emails-header-col">Rooms</div>
		{this.state.result_envs.map( (u, i) => {
			let fname = '';
			let rooms = [];
			let tab = '';
			let subject = '(No Subject)';

			if (u.rid) {
				let pinfo = JSON.parse(u.pinfo_json);
				if (pinfo.from[0].name) {
					fname = pinfo.from[0].name;
				} else {
					fname = pinfo.from[0].email;
				}
				
				rooms.push(u.rid);

				tab = 'Shared';
			} else {
				if (u.from_pids) {
					let fper = pid2person(u.from_pids);
					if (fper) {
						if (fper.name) {
							fname = fper.name;
						} else {
							fname = fper.email;
						}
					}
				}

				if (u.home_room_data) {
					rooms = u.home_room_data;
					
					if (rooms.length == 0) {
						let r = get_mailbox_room();
						rooms.push(r.id);
					} else {
						
					}
				}
				
				tab = 'Unshared';
			}
			
			if (u.subject) {
				subject = u.subject;
			}
			
			let klass = i % 2 ? 'oddrow' : 'evenrow';
			
			return (<React.Fragment key={"e_" + i}>
				<div className={"p-col-2 find-emails-env-col-ellipsis find-emails-header-col-left " + klass}>{fname}</div>
				<div className={"p-col-3 find-emails-env-col-ellipsis " + klass}>{subject}</div>
				<div className={"p-col-2 " + klass}>{edate3(u.dt_sent)}</div>
				<div className={"p-col-2 " + klass}>{tab}</div>
				{(rooms.length > 1) && <div className={"p-col-3 find-emails-env-col-ellipsis " + klass}>
						<ul className="find-emails-env-room-list">
						{rooms.map( (h, j) => {
						let r = get_room(h);
						if (!r) {
							return null;
						}
						return (<li key={"r_" + j}><Link to={go_url('room', h, tab.toLowerCase(), u.id)}>{r.name}</Link></li>)
						})}
						</ul>
				</div>}
				{(rooms.length == 1) && <div className={"p-col-3 find-emails-env-col-ellipsis " + klass}>{rooms.map( (h, j) => {
						let r = get_room(h);
						if (!r) {
							return null;
						}
						return (<Link key={"r_" + j} to={go_url('room', h, tab.toLowerCase(), u.id)}>{r.name}</Link>)
						})}
				</div>}
				</React.Fragment>);
		})}
	</div>}
	
	</div>}
	
	</div>
	
	</div>
	</div>
	)
	}
}

export {FindEmails};
