import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {InputText} from 'primereact/inputtext';
import {Menubar} from 'primereact/menubar';
import {Button} from 'primereact/button';
import {Message} from 'primereact/message';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';

import {ldb, log, set_order, map_list, resort, get_last_activity, edate3, 
	api, get_root_item, display_account_type, growl,
	get_all_client_rooms, get_room, go_url} from './Lib';

const PriorityIcon = props => {
	let icon, title;

	switch(props.pri) {
		case 1:
			icon = 'check-circle';
			title = 'Primary Email (Active)';
			break;
		case 0:
			icon = 'check';
			title = 'Secondary Email (Active)';
			break;
		case -1:
			icon = 'ban';
			title = 'Previous Email (Inactive)';
			break;
	}
	icon = 'pi pi-fw pi-' + icon;
	return <i className={icon} title={title} />;
}

const ShowClientEmail = cemail => {
	return <div key={cemail.id}>
		<PriorityIcon pri={cemail.priority} />
		{' '}
		{cemail.email}
		{' '}
		<Link 
			to="#" 
			style={{fontSize: 'x-small'}}
			onClick={e => {
				e.preventDefault(); 
				navigator.clipboard.writeText(cemail.email);
				growl('Copied "' + cemail.email + '" to the clipboard');
		}} >Copy
		<i className="fa fa-fw fa-clipboard small"></i>
		</Link>
	{cemail.comment && 
		<div className="cemail-comment">{cemail.comment}</div>}
	</div>
}

const ShowEmailIds = props => {
	const item = props.item;
	
	if (item.emails === undefined)	// staff
		return <div className="email">{item.email}</div>;

	// client
	const root = item.emails;
	set_order(root);

	return <div className="email">
		{map_list(root, ShowClientEmail)}
	</div>
}

class ClientOtherRooms extends Component {
	constructor(props) {
		super(props);
		const {item, par} = this.props;
				
		this.state = {expanded:false};
	}
	
	render() {
		const {item, par} = this.props;
		const {expanded} = this.state;
		
		if (item.emails === undefined)	// staff
			return null;
		
		// client
		
		const current_rid = par.props.rid;

		const room_list = get_all_client_rooms(item);
		
		if (room_list.length <= 1)
			return null;
				
		return (<div className="p-grid">
			<div className="p-col-12" style={{textAlign:'left', paddingLeft:'30px', backgroundColor: '#eef'}}>
				<div style={{cursor: 'pointer'}} onClick={e => {
					this.setState({expanded: (!expanded)});
				}}>
				<i style={{marginLeft: '3px'}} className="pi pi-fw pi-link">
				</i>
				{(!expanded) && <span>View Other Rooms</span>}
				{expanded && <span>Hide Other Rooms</span>}
				</div>
				{expanded && <div style={{paddingLeft:'24px'}}>
					{room_list.map((rid, i) => {
						if (current_rid == rid) {
							return null;
						}
						let room = get_room(rid);
						
						return (<div key={"o_r_" + i}>
							<Link to={go_url('room', room.id, 'unshared')}>
								{room.name}
							</Link>
							</div>);
					})}
				</div>}
			</div>
			</div>);
	}
}

const EditClient = props => {
	return <div>
		<Link key={props.item.id} 
		to="#" 
		onClick={e => {
			e.preventDefault(); 
			// Call EditClient in par(Summary)
			props.par.select_command('EditClient', props.item.id);
		}} >Edit</Link>
	</div>;
}

const IdleTime = props => {
	const online = props.online;
	const m = window.g_moment;

	if (!online.dt_idle)
		return null;
	
	const dur = m.duration(m().diff(m(online.dt_idle))).humanize();

	return <span><span className="idle"> Away</span>({dur})</span>;
}

const ShowOnlines = props => {
	const staff = props.staff;
	const onlines = [];

	staff.onlines.forEach(oid => {
		const online = get_root_item(ldb.data.onlines, oid );
		if (!online.dt_ended && online.is_recent)
			onlines.push(online);
	});

	return <div>
		{onlines.map((item, i) => <div key={i}>
			<span className="online">Online </span> 
			since {edate3(item.dt_added)}
			<IdleTime online={item} />
		</div>)}
	</div>
}

const ShowOfflines = props => {
	const staff = props.staff;
	let recent = null;
	let when = '';

	staff.offlines.forEach(oid => {
		const online = get_root_item(ldb.data.onlines, oid );
		if (online.dt_ended && online.is_recent)
			recent = online;
	});

	if (recent)
		when = '. Last online: ' + edate3(recent.dt_ended);

	return <span><span className="offline">Offline</span>{when}</span>;
}

const ShowOnOfflines = props => props.staff._online ? 
		<ShowOnlines staff={props.staff} /> :
		<ShowOfflines staff={props.staff} />

class SmsTel extends Component {
	constructor(props) {
		super(props);
		const {par, item} = this.props;
		this.state = {smstel: item.smstel, working:false, active:false};
	}

	setnum = e => {
		const smstel = e.target.value;
		this.setState({smstel});
		// log('email', 'SmsTel', smstel);
	}

	save = e => {
		e.preventDefault();
		const {par, item} = this.props;
		const {smstel} = this.state;
		const tcid = item.is_client ? item.id : 0;
		const tsid = item.is_client ? 0 : item.id;

		const args = {
			cmd: 'mod_tel', 
			rid: par ? par.props.rid : 0,
			tid: item.smstelid,
			number: smstel,
			is_sms: true,
			tcid, tsid,
		};
		api( args, this.saved, this.saved );
		this.setState({working:true});
	}

	saved = e => {
		this.setState({active:false, working:false});
	}


	render() {
		const {par, item} = this.props;
		const {smstel, working} = this.state;
		const can_edit = item.is_client || ldb.data.me.is_org_admin;

		let edit = 'Edit';
		let kls = '';
		let ekls = '';
		if (can_edit) {
			if (item.smstel) {
				edit = 'Edit';
				ekls = 'smstel-edit';
			}
			else {
				edit = 'Set';
				kls = 'no-smstel';
				ekls = 'smstel-set';
			}
			edit = <span className={ekls}>
					{edit} 
					<i className="pi pi-fw pi-pencil">
					</i>
				</span>;
		}

		// log('email', 'SmsTel', this, par, item);

		return (
		<Inplace active={this.state.active} 
			    onToggle={e => this.setState({active: e.value})}>
			<InplaceDisplay >
		<span className={kls}>
			SMS Tel: {item.smstel} {edit}
		</span>
			{item.error && <div>{item.error}</div>}
			{item.sms_email && <div className="sms_email">({item.sms_email})</div>}
			</InplaceDisplay>
			<InplaceContent>
		{working && <i className="pi pi-fw pi-spin pi-spinner"></i>}
		<InputText autoFocus
			value={smstel} 
			placeholder="Cell Phone Number"
			   onChange={this.setnum}
				/>
		<Button label=""
			icon="pi pi-fw pi-check"
			onClick={this.save}
			style={{margin:'0 2px'}}
			/>
		<Button label=""
			icon="pi pi-fw pi-times"
			onClick={e => this.setState({active:false})}
			style={{margin:'0 2px'}}
			/>
			<br/>
			<div className="smstel-help">
		Type 10 digit Cell Phone Number.
		<br/>
		Examples: 425 555 1212 
		<br/>
			(425)-555-1212 
		<br/>
			4255551212
		<br/>
		<br/>
		Spaces,()- are ok. All except digits are ignored.
		<br/>
		+1 prefix or 1 prefix is NOT needed.
		<br/>
		Only USA numbers can be used in auto SMS.
			</div>
			</InplaceContent>
		</Inplace>
		)
	}
}

class UserList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	show_item = (item) => {
		// This is convoluted... set type explicitly on the server
		// const is_client = item.emails !== undefined;
		// const is_contact = ((!is_client) && item.is_org_admin === undefined);
		// const is_staff = ((!is_client) && (!is_contact));

		const nm = !window.is_mobile;
		const edit_client = nm && item.is_client ? 
			<EditClient par={this.props.par} item={item} /> : null;
		const admin_class = item.is_org_admin ? 'user-admin' : '';
		const disabled_msg = item.is_staff && !item.is_active ? <div>Account Disabled</div> : '';

	return <li key={item.id}>
		<div className="p-grid">
		<div className="p-col-2">
			<i 
		title={item._online > 0 ? 'Online' : 'Offline'}
			className={"fa fa-fw fa-user" + 
				(item._online > 0 ? '' : '-o')} />
			{edit_client}
		</div>
		<div className="p-col-10 p-justify-end" >
			<div className={"name " + admin_class} >
				{item.name + (
		item.email === ldb.data.me.email ? ' (me)' : '')}
			</div>
			{item.is_org_admin && <span>Admin</span>}
			<div>{display_account_type(item)}</div>
			{disabled_msg}
			<ShowEmailIds par={this.props.par} item={item} />
			{(item.is_team_email) && <div>Team Email Account</div>}
			<SmsTel par={this.props.par} item={item} />
			{(item.is_staff) && <ShowOnOfflines staff={item} />}
			{item.retired && <div><Message severity="warn" text="Retired" /></div>}
			{(!item.retired) && item.paused && <div><Message severity="warn" text="Paused" /></div>}
		</div>
		</div>
		{<ClientOtherRooms par={this.props.par} item={item} />}
	</li>
		}

	render() {
		const {root, items} = this.props;
		const header = <Menubar model={items} style={{border:0}} />;

		set_order(root);
		resort(root);

		return (
		<div>
		{this.props.header}
	    	<ul className="user-list">
	    	{map_list(root, this.show_item)}
		</ul>
	    	</div>
		)
	}
}

export default UserList;
