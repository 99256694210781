const Version = [
	{
	number: 1023,
	when: 'Sunday, March 10, 2024',
	summary: 'v1.023',
	details: [
		{
		header: 'Find Emails Feature',
		sections: [
			{
			subheader: 'What is this feature?',
			content: '<p>This feature helps you answer the question of "where did my email go?".</p>'
			},
			{
			subheader: 'How do I use it?',
			content: '<p>In main sidebar, click on "Find Emails". Initially the 10 most recent emails processed by the system will show in the results. You can change the search criteria at the top to search for an email by part of a subject line, the recipient/sender, or the message ID. The results will be displayed below.</p><p>Both shared and unshared email results will be shown. You can click the links in the rightmost column for each email to view that email in the rooms where they are located.</p>'
			}
		]
		},
		{
		header: 'View Original Email Feature',
		sections: [
			{
			subheader: 'What is this feature?',
			content: '<p>This feature allows you to view newsletters and such in their original formatting.</p>'
			},
			{
			subheader: 'How do I use it?',
			content: '<p>When viewing an email, click on the "Launch Window" icon above the email. The icon is two offset squares. This will launch a new tab in your web browser where you can view the email in a larger interface.</p><p>At the top of the new tab there is a button to "Load Remote Content". Clicking this will fetch and display any remote content (images, styles, etc) that is normally restricted when viewing the email in TagInbox. The email should now display in its full original formatting.</p>'
			}
		]
		}
	]
	}
];

export {Version};
