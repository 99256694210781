import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {PickList} from 'primereact/picklist';
import {Dropdown} from 'primereact/dropdown';

import {ldb, log, go, get_room, go_url, get_first_id, growl, map_list,
	set_order, filter_list, api, gen_xid
} from './Lib';

//-------------- Delete Client ---------------

class DeleteClient extends Component {
	constructor(props) {
		super(props);

		const {rid, cid} = this.props;
		const cuser = get_room(rid).clients._items[cid];
		this.state = {
			cuser
		};

		window.g_deleteClient = this;
	}

	delete_client = e => {
		const {rid} = this.props;
		const {cuser} = this.state;
		const bid = gen_xid();	// batch id, used in progress bar.

		const args = {
			cmd: 'delete_client', 
			rid,
			cuid: cuser.id,
			bid
		};
		api( args, this.finished );
		
		window.g_progCircle.add(bid, 
			"Deleting client " + cuser.name + " from " + get_room(rid).name, 1);
	}

	finished = e => {
		this.props.close(e);
	}

	render() {
		const {cuser, items} = this.state;

		//const client_emails = cuser.emails;
		//set_order(client_emails);
		
	 	return (
                    <Dialog header="Delete Client" 
		    visible={true}
		    width="50%" minY={70} draggable={true}
			modal={true}
		    onHide={this.props.close}
		    maximizable={true}
		    	>
		<h1>
			{cuser.name}
		</h1>
			<br/>
			Are you sure you want to delete this client?
			<br/><br/>
			<Button label="Delete Client" onClick={this.delete_client} />
                    </Dialog>
	
		)
	}
}

export default DeleteClient;
