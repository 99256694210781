import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {ProgressSpinner} from 'primereact/progressspinner';
import {PickList} from 'primereact/picklist';

import {Focusable} from './Utils';
import {FilteredPersons} from './Persons';

import {ldb, log, go, get_room, go_url, get_first_id, growl,
	filter_list, api, pid2person,
} from './Lib';

//-------------- Add Client ---------------

class AddClient extends Focusable {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			error: '',
			working:false,
		};
		window.g_addClient = this;
	}

	save = e => {
		const {rid} = this.props;
		const room = get_room(rid);
		const {name, email} = this.state;

		log("room", "AddClient", name, email);

		const args = {
			cmd: 'mod_client', 
			rid,
			add: [{name, emails:[{email, priority:1}], 
					tels: []}],
			remove: [],
		};
		api( args, this.saved, this.saved );
		this.setState({working:true, error:''});
	}

	saved = (error, db) => {
		log("room", "AddClient Return", error, db);
		if (error) {
			this.setState({error, working:false});
			return;
		}
		this.props.close();
	}

	add_person = pid => {
		const person = pid2person(pid);
		this.setState({name: person.name, email: person.email});
	}

	clear = () => {
		this.setState({name: '', email: ''});
	}

	show_error = () => this.state.error ? <div>
		ERROR: {this.state.error} </div> : null

	// copied from CreateRoom.js.. keep them in sync.
	client_user_manual = () => (
		<div>
		<div className="p-grid">
		<div className="p-col-5">
			<br/>
		  	<div className="p-inputgroup p-grid">
			  <span className="p-inputgroup-addon p-col-2">
			    Name
			  </span>
			<InputText value={this.state.name} 
				className="p-col-10"
				placeholder="First Last"
	   onChange={ e => this.setState({name: e.target.value}) } 
				ref={inp => this._focusEle = inp}
		   	/>
			</div>

		  	<div className="p-inputgroup p-grid" >
			  <span className="p-inputgroup-addon p-col-2">
			    Email
			  </span>
			<InputText value={this.state.email} 
				className="p-col-10"
				placeholder="user@domain.com"
	   onChange={ e => this.setState({email: e.target.value}) } 
		   	/>
			</div>

			<br/>

			<div className="p-grid">
			<div className="p-col-2">
			</div>
			<div className="p-col-2">
				<Button label="Add" 
					icon="pi pi-fw pi-plus"
					onClick={this.save} />
			</div>
			<div className="p-col-1">
			</div>
			<div className="p-col-2">
				{(this.state.email || this.state.name) &&
				  <Button label="Clear" 
					className="p-button p-button-secondary"
					icon="pi pi-fw pi-times"
					onClick={this.clear} />}
			</div>
			<div className="p-col-5 helpinfo">
			</div>
			</div>

		</div>
		<div className="p-col-1">
		</div>
		<div className="p-col-6">
				<br/>
				Type in client name and email address,
				<br/>
				or select from your contacts below.
		</div>
		</div>

		<div className="add-client-contacts">
		<FilteredPersons setPid={this.add_person} />
		</div>

		</div>
	)


	render() {
	 	return (
                    <Dialog header="Add Client" 
				className="add-client"
			    visible={true}
			    draggable={true}
				modal={true}
			    onHide={this.props.close}
			    maximizable={true}
		    	>
		{this.client_user_manual()}
		{this.state.working && <ProgressSpinner/>}
		{this.show_error()}
                    </Dialog>
	
		)
	}
}

export default AddClient;
