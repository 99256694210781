import React, { Component } from 'react';
import {Checkbox} from 'primereact/checkbox';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {ldb, settings_url, is_local_server, local_storage_save_login_info,
	local_storage_get_login_info, local_storage_remove_login_info,
	get_first_name, is_test_server, log} from './cw/Lib';

function AutoLogin() {
	const {email} = local_storage_get_login_info();
	
	const sock = window.g_sock;
	const relogin_info = sock.get_relogin_info();
	const new_email = relogin_info.email;
	
	const auto_login_checked =  (email == new_email);

	const [auto, setAuto] = React.useState(auto_login_checked);

	const update = e => {
		const checked = e.checked;

		if (checked) 
			local_storage_save_login_info();
		else
			local_storage_remove_login_info();

		setAuto(checked);
	}
    
    	return (
	<div className="auto-login">	
		Test Server. Auto Login:
		{" "}
		<Checkbox onChange={update} checked={auto} />
	</div>
	);
}

export class AppInlineProfile extends Component {

    constructor() {
        super();
	
        this.state = {
            expanded: false,
        };
        this.onClick = this.onClick.bind(this);
	window.g_appInlineProfile = this;
    }

    onClick(event) {
	    // Defunct/ Unused... we take directly to User Profile..
	    // Same for expanded profile below.
        this.setState({expanded: !this.state.expanded});
        event.preventDefault();
    }

    debug_separator = e => {
	this._nsep = this._nsep || 0;
	this._nsep++;
	log('lib', this._nsep, '--------------------------------------------------------------------------------------------------------');
	e.preventDefault();
    }

    prelogin = () => {
	const name = is_test_server() ? 'CWtest' : null;
	const logo = is_test_server() ? null : 'assets/layout/images/tilogo.png';

    	return (
	<React.Fragment>
		<div className="layout-logo" >
			{logo && <img alt="Logo" src={logo} 
				className="layout-logo-icon"
			/>}
			{name && <span className="layout-logo-text"
				>
				{name}
			</span>}
		</div>
	</React.Fragment>
	)
    }

    render() {
    	if (!ldb.data.me)
		return this.prelogin();

	const paused_msg = ldb.data.org.paused ? <div>(Paused)</div> : '';
	const name = ldb.data.me.name || '';
	const tooltip = `${name} (${ldb.data.me.email})`;

	const company_name_color = ldb.data.me.settings.company_name_color || '';

        return  (
            <div className="profile">

		<div className="name">
			<Link to="/settings/profile" title={tooltip} 
				onContextMenu={this.debug_separator}
			>
				{get_first_name(name)}
			</Link>
		</div>
		<div className="org">
			<Link to="/settings/org">
			    <span style={{color: company_name_color}}>
			    {ldb.data.org.name}
			    {paused_msg}
			    </span>
			</Link>
		 </div>
		{is_local_server() && <AutoLogin />}

                <ul className={classNames({'profile-expanded': this.state.expanded})}>
                    <li className="hide"><a><i className="pi pi-fw pi-user"/><span>Account</span></a></li>
                    <li className="hide"><a><i className="pi pi-fw pi-inbox"/><span>Notifications</span><span className="menuitem-badge">2</span></a></li>
                    <li><a href={settings_url()} target="_blank" ><i className="pi pi-fw pi-cog"/><span>Settings</span></a></li>
                    <li><a href="/"><i className="pi pi-fw pi-power-off"/><span>Logout</span></a></li>
                </ul>
            </div>
        );
    }
}
