import React, {Component} from 'react'; 
import {Redirect, Link} from 'react-router-dom';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import Cookies from 'js-cookie';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';
import {FileUpload} from 'primereact/fileupload';
import {ldb, log, go, growl, set_order, set_current_list_view,
	resort,} from './Lib';

import sanitizeHtml from 'sanitize-html';

const Spacer = props => <div className="spacer"></div>
const Spacer2 = props => <div className="p-col-12 spacer2"> </div>

/*
 * Standardize Modal Dialog for commands
 * Requires caller to set:
 * 	par (this) or onHide
 * 	header 
 * 	minY (optional)
 */

const ModalCommand = props => {
	// eg. extra={{width:"30%", minY: 70, className: ''}}
	//	If specifying width etc, reset className, else that overrides
	const extra = props.extra || {};
	const cls = props.className || 'modal-command'

        return <Dialog header={props.header || 'title'}
		    className={cls}
		    visible={true}
		    draggable={true}
		    modal={true}
		    onHide={props.onHide || props.par.props.close}
		    maximizable={true}
		    {...extra}
		    	>
		{props.children}
         </Dialog>
}

function HelpInfo(props) {
	const [show, setShow] = React.useState(false);

	return (
	<span className={props.className}>
		<Link to='#' onClick={e => {
			setShow(!show);
			e.preventDefault();
		}}>
			<i className="pi pi-info-circle"></i>
			{props.label && ' '}
			{props.label}
		</Link>
		<Dialog header={props.header} visible={show}
				className="modal-35-command"
				onHide={e => setShow(false)} >
			{props.children}
		</Dialog>
	</span>
	);
}


function LinkButton(props) {
	return (
	<Link to="#" onClick={e => {
			props.onClick(e);
			e.preventDefault();
		}}
		tooltip={props.tooltip}
		style={props.style}
		>
		{props.icon && <i className={props.icon}></i>}
		{props.label}
	</Link>
	);
}

// Wrapper to Link, to prevent going to the actual link, just do onClick.
function FakeLink(props) {
	const {onClick, icon, children} = props;
	const newOnClick = e => {
		e.preventDefault();
		onClick(e);
		e.stopPropagation();
	}
	return <Link {...props} to='#' onClick={newOnClick} >
		{icon && <i className={icon}></i>}
		{icon && ' '}
		{children}
	</Link>;
}

function AreYouSure(props) {
	const {callback, message, close} = props;
	const [visible, setVisible] = React.useState(true);
	const hide = e => {
		close(e);
		setVisible(false);
	}
	const footer = (
		<div>
			<Button label="Proceed" icon="pi pi-check"
				onClick={e => {callback(e); hide(e);}} />
			<Button label="Cancel" icon="pi pi-times"
			className="p-button-raised p-button-secondary"
				onClick={close} />
		</div>
	);

	return (
	<Dialog header="Please Confirm" visible={visible} onHide={close} 
		footer={footer}
	>
		{message}
	</Dialog>
	)
}

//-------------------------------------------------------------
// Focusable: Base class for setting focus on an element on launch
//
// Requirement: One of the PrimreReact components used in Derived Class
// 	needs to be set as _focusEle for this.
// Typically Derived Class (NewEmail), sets autofocus={true} for
// 	one of the input elements. eg. TaskSubject
// 	which uses ref to set _focusEle on parents.
//
// In essence,
// 	1. NeWEmail sets autofocus on one of its 
// 		inputelementwrapper (TaskSubject)
// 	2. TaskSubject sets _inputEle on parent (newEmail)
// 	3. NewEmail uses the code here to set focus
//-------------------------------------------------------------

class Focusable extends Component {
	constructor(props) {
		super(props);
		this._focusEle = null;
		window.g_focusable = this;
	}

	componentDidUpdate() {
		// this.setFocus();
	}

	componentDidMount() {
		this.setFocus();
	}

	setFocus = () => {
		const primeEle = this._focusEle;
		let mod = false;
		if (primeEle) {
			const ele = primeEle.element || primeEle.inputEl;
			if (ele) {
				ele.focus();
				mod = true;
			}
		}
	}

}

// Sets focus on an input element... using ref
//   Usage: <InputText placeholder="Name" ref={lazyFocus}
function lazyFocus(el) {
	if (!el)
		return;

	setTimeout( () => {
		const ele = el.element || el.inputEl || el.inputElement || el;
		// log('lib', 'lazyfocus', el, ele);

		if (ele) 
			ele.focus();
		}, 200);
}

function selFocus(selector) {
	const sel = document.querySelector(selector);
	if (sel)
		sel.focus();
}

function stretchHeight(el) {
	if (!el)
		return;

	const ele = el.element || el.inputEl || el.inputElement;
	if (ele) 
		ele.style.height = ele.scrollHeight + 'px';
}

class Commandable extends Component {
	
	// Change URL to got to selected command.
	// This toggles and ends command if it is already selected.
	select_command = (new_command, cid) => {
		let {rid, tab, iid, command} = this.props;

		if (new_command == command)
			new_command = '';
		if (iid < 0)
			iid = 0;

		// Command arg, used in edit-client-user
		if (cid === undefined)	
			go('room', rid, tab, iid, new_command);
		else
			go('room', rid, tab, iid, new_command, cid);

	}
	
	show_command = item => {
		let command_output = null;
		const {command, rid, tab, iid, cid} = this.props;
		if (command) {
			// eg this.create_task_command
			const fn = this[command + '_command']; 
			const par = this;
			const args = {item, rid, par, tab, iid, cid,
					close: this.select_command};
			command_output = fn(args);
			log('listview', 'Command', command, command_output);
		}
		return command_output;
	}
}

/*
 * Page : Paginate display
 *	Assumes this._root has data root, and uses _idlist to get ids
 *	Creates a Page navigation toolbar
 */

class Page extends Component {
	constructor(props) {
		super(props);
		this.state = {ipage: 0, perpage: 10, search_text: '',
			expanded: new Set(), irefresh: 0};
		window.g_pages = this;
	}

	page_ids = () => {
		const {ipage, perpage} = this.state;
		const ifrom = ipage * perpage;
		const itill = ifrom + perpage;
		const root = this._root;

		return root._order.slice(ifrom, itill);
	}

	page_offset = () => {
		const {ipage, perpage} = this.state;
		return ipage*perpage;
	}

	page_bar = () => {
		const nitems = this._root._order.length;

		if (!nitems) 
			return <div>None</div>;

		const {ipage, perpage} = this.state;
		const npage = parseInt(nitems / perpage, 10);
		const can_prev = ipage > 0;
		const can_next = ipage < npage;

		return <div className="p-grid">
			<div className="p-col-3"> </div>
			<div className="p-col-2"> 
			    {can_prev &&
				<Button label="Previous"
			onClick={e=>this.setState({ipage: ipage-1})}
				/>}
			</div>
			<div className="p-col-2" style={{textAlign:'center'}} > 
				Page {ipage + 1} of {npage + 1}
			</div>
			<div className="p-col-2"> 
			    {can_next &&
				<Button label="Next"
			onClick={e=>this.setState({ipage: ipage+1})}
				/>}
			</div>
			<div className="p-col-3"> </div>
		</div>;
	}

	update_search = search_text => {
		log('email', 'search', search_text);
		const root = this._root;
		root._flags._search_text = search_text;
		set_order(root);
		resort(root);
		this.setState({search_text, ipage:0});
	}
}

/*
 * Standardize "More..." button for dropdown menu
 * Requires caller to set:
 * 	model
 */

class MoreMenu extends Component {
	render() {
		return <span>
	<Menu model={this.props.model} key="m1" 
			popup={true} ref={el => this.list_menu=el}/>
	<Button label=""
		className="p-button-raised p-button-secondary"
		icon="pi pi-bars"
		onClick={ev => this.list_menu.toggle(ev)}
	/>
		</span>
	}
}

/*
 * Attach
 */

class Attach extends Component {
	constructor(props) {
		super(props);
		this.state = {visible: false, xyz: " there"};
		this._dzref = null;
		window.g_attach = this;
	}
	onDrop = (acceptedFiles, rejectedFiles) => {
		log('email', 'Attach', acceptedFiles, rejectedFiles);
		const par = this.props.par;

		acceptedFiles.forEach(file => {
			const reader = new FileReader();

			reader.onload = () => {
				const bin = reader.result; // binary
				const item = {
					name: file.name,
					size: file.size,
					kind: file.type,
					b64data: btoa(bin), // base64 string
				};
				const files = par.state.files;
				files.push(item);
				par.setState({files});
			};
		reader.onabort = () => growl('file reading was aborted');
		reader.onerror = () => growl('file reading has failed');

			reader.readAsBinaryString(file);
		});
	}

	show = e => {
		this.setState({visible: true});
		this._dzref.open();
	}
 
	render() {
		return <div className="p-grid">
		<label className="p-col-2">
			<Button label="Attach:" onClick={this.show}
				iconPos="right"
				icon="pi pi-fw pi-paperclip"
				className="p-button-secondary"
				/>
		</label>
		<div className="p-col-5">
<Dropzone onDrop={this.onDrop}
	par={this}
	ref={ele => this._dzref = ele}
		>
{({getRootProps, getInputProps, isDragActive}) => {
	const msg = isDragActive ?
	  <p>Drop files here...</p> :
	  <p>Try dropping some files here, or<br/>click to select files to upload.</p>;

  return (
    <div
      {...getRootProps()}
      className={classNames({'dropzone' : this.state.visible}, {'dropzone--isActive': isDragActive})}
    >
      <input {...getInputProps()} />
      {this.state.visible && msg}
    </div>
  )
}}
</Dropzone>
		</div>
		<div className="p-col-1"></div>
		<div className="p-col-4">
		{this.props.par.state.files.map(item => (
			item.name + ' (' + item.size + ' bytes)'
			)).join(', ')}
		</div>
		</div>
	}
}

/*
 * Sanitize HTML - Render
 *	By default a bunch of tags are blocked by sanitizeHtml.
 *	Options can add/extend.
 */

function wrap_sanitize(dirty, options) {
	const clean_html = sanitizeHtml(dirty, options);
	// log('email', 'sanitizeHtml', dirty, options, clean_html);

	return {__html: clean_html};
}

const SanitizeHTML = ({ html, options }) => (
  <div dangerouslySetInnerHTML={wrap_sanitize(html, options)} />
);

export {Spacer, ModalCommand, Focusable, Commandable, MoreMenu, Attach, Page,
	lazyFocus, SanitizeHTML, Spacer2, LinkButton, HelpInfo, AreYouSure,
	stretchHeight, selFocus, FakeLink,
	};
