import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDone, TaskDueDate,
		TaskCommand, TaskSelectStaff,} from './EditTask';
import {PriorTaskNotes} from './TaskUtil';
import {ModalCommand, Spacer, Focusable} from './Utils';

import {ldb, log, growl, api} from './Lib';

class DoneTask extends Focusable {
	// props = {item, close}
	constructor(props) {
		super(props);
		const task = props.item;
		const note = '';
		this.state = {subject: task.name, note, };
		window.g_doneTask = this;
	}

	onUpdate = () => {
		let {note, subject,} = this.state;
		const task = this.props.item;
		const tid = task.id;
		const to_sid = 0;
		const args = {
			cmd: 'update_task', note, tid, subject, note, to_sid,
			category: task.category, priority: -1, dt_due: null,
			done: true,
		};
		api( args, this.updated );
	}

	updated = (error) => {
		this.props.close();
	}

	render() {
		const {item} = this.props;

		log('task', 'done');

		return (
		<ModalCommand par={this} header="Task Done" >

	<Spacer />
  	<TaskSubject par={this} />
	<Spacer />
  	<TaskNote par={this} autofocus={true} />
	<Spacer />
  	<PriorTaskNotes task={item} />
	<Spacer />
  	<TaskCommand onClick={this.onUpdate} 
			icon="pi-check" label="Task Done" />
		</ModalCommand>
		)
	}
}

export default DoneTask;
