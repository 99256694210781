import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';

import {TaskSubject, TaskAttach, TaskNote, EditNote, TaskPriority, 
		TaskCommand, TaskSelectStaff,} from './EditTask';
import {Attach, Spacer, ModalCommand, Focusable, lazyFocus} from './Utils';

import {ldb, log, get_room, get_list, growl, api, get_body_part, p2br,
		edate} from './Lib';

class EditSig extends Focusable {
	constructor(props) {
		super(props);
		const html = ldb.data.me.html_signature;
		this.state = {html, plain: '', creating: false,};
		window.g_editSig = this;
	}

	onSave = () => {
		let {plain, html} = this.state;
		const args = {
			cmd: 'profile_update', 
			op: 'signature',
			plain, html, 
		}
		api( args, this.saved );

		this.setState({creating:true});
	}

	saved = (error) => {
		this.setState({creating:false});

		if (!error) {
			growl("Modified Signature");
		}
		this.props.par.close();
	}

	render() {
	 	return (
                    <ModalCommand header="Edit Signature"
			    onHide={this.props.par.close}
		    	>

	<div className="p-grid">
	<label className="p-col-2">Name:</label>
	<div className="p-col-10">
		Default
	</div>
	</div>
	<Spacer />
  	<EditNote par={this} label="Signature:" fullMenu={true}
		className="signature-edit-note" />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
  	<TaskCommand onClick={this.onSave} label="Save" />
	{this.state.creating && <ProgressSpinner/>}

                    </ModalCommand>
	
		)
	}
}

class AddAltSig extends Focusable {
	constructor(props) {
		super(props);
		this.state = {name: '', html: '', plain: '', creating: false,};
		window.g_addSig = this;
	}

	onSave = () => {
		let {name, plain, html} = this.state;
		const args = {
			cmd: 'profile_update', 
			op: 'add_alt_signature',
			name, plain, html, 
		}
		api( args, this.saved );

		this.setState({creating:true});
	}

	saved = (error) => {
		this.setState({creating:false});

		if (!error) {
			growl("Added Signature");
		}
		this.props.par.close();
	}

	render() {
	 	return (
                    <ModalCommand header="Add Signature"
			    onHide={this.props.par.close}
		    	>
	
	<div className="p-grid">
	<label className="p-col-2">Name:</label>
	<InputText className={"p-col-10"}
		value={this.state.name} ref={lazyFocus} label="Name:"
		onChange={e => this.setState({name: e.target.value})}			
		/>
	</div>
	<Spacer />
  	<EditNote par={this} label="Signature:" fullMenu={true}
		className="signature-edit-note" />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
  	<TaskCommand onClick={this.onSave} label="Add" />
	{this.state.creating && <ProgressSpinner/>}

                    </ModalCommand>
	
		)
	}
}

class EditAltSig extends Focusable {
	constructor(props) {
		super(props);
		
		let {num} = this.props;
		
		const alt_sigs = ldb.data.me.settings.alt_sigs;
		const sig = alt_sigs[num];

		const name = sig.name;
		const html = sig.html;
		this.state = {num, name, html, plain: '', creating: false,};
		window.g_editAltSig = this;
	}

	onSave = () => {
		let {num, name, plain, html} = this.state;
		const args = {
			cmd: 'profile_update', 
			op: 'edit_alt_signature',
			num, name, plain, html, 
		}
		api( args, this.saved );

		this.setState({creating:true});
	}

	saved = (error) => {
		this.setState({creating:false});

		if (!error) {
			growl("Modified Signature");
		}
		this.props.par.close();
	}

	render() {
	 	return (
                    <ModalCommand header="Edit Signature"
			    onHide={this.props.par.close}
		    	>

	<div className="p-grid">
	<label className="p-col-2">Name:</label>
	<InputText className={"p-col-10"}
		value={this.state.name} ref={lazyFocus} label="Name:"
		onChange={e => this.setState({name: e.target.value})}			
		/>
	</div>
	<Spacer />
  	<EditNote par={this} label="Signature:" fullMenu={true}
		className="signature-edit-note" />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
  	<TaskCommand onClick={this.onSave} label="Save" />
	{this.state.creating && <ProgressSpinner/>}

                    </ModalCommand>
	
		)
	}
}

class DeleteAltSig extends Focusable {
	constructor(props) {
		super(props);
		
		let {num} = this.props;
		
		const alt_sigs = ldb.data.me.settings.alt_sigs;
		const sig = alt_sigs[num];

		const name = sig.name;
		const html = sig.html;
		this.state = {num, name, html, plain: '', creating: false,};
		window.g_deleteAltSig = this;
	}

	onDelete = () => {
		let {num, name, plain, html} = this.state;
		const args = {
			cmd: 'profile_update', 
			op: 'delete_alt_signature',
			num, 
		}
		api( args, this.deleted );

		this.setState({creating:true});
	}

	deleted = (error) => {
		this.setState({creating:false});

		if (!error) {
			growl("Deleted Signature");
		}
		this.props.par.close();
	}

	render() {
	 	return (
                    <ModalCommand header="Delete Signature"
			    onHide={this.props.par.close}
		    	>

	<div className="p-grid">
	<label className="p-col-2">Name:</label>
	<div className="p-col-10">
		{this.state.name}
	</div>
	</div>
	<Spacer />
	<div className="p-grid">
	<label className="p-col-2">Signature:</label>
	<div className="p-col-10" dangerouslySetInnerHTML={{__html: this.state.html}} />
	</div>
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
  	<TaskCommand onClick={this.onDelete} label="Delete" icon="pi-times" />
	{this.state.creating && <ProgressSpinner/>}

                    </ModalCommand>
	
		)
	}
}

export {EditSig, AddAltSig, EditAltSig, DeleteAltSig};
