import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {RadioButton} from 'primereact/radiobutton';
import {InputText} from 'primereact/inputtext';
import {Toolbar} from 'primereact/toolbar';
import {Dropdown} from 'primereact/dropdown';
import {SelectButton} from 'primereact/selectbutton';
import {Checkbox} from 'primereact/checkbox';
import {Calendar} from 'primereact/calendar';

import ListView from './ListView';
import UpdateTask from './UpdateTask';
import {TaskCommand} from './EditTask';
import {ModalCommand, MoreMenu, Spacer, Focusable, lazyFocus, HelpInfo} from './Utils';

import {ldb, log, set_order, map_list, filter_list, my_assign_id,
	go_url, get_signature_of_room, room_signature_options, 
	get_room, growl, api, room_category_options, em, get_staff,
	gen_xid, dv_default_email_recipients} from './Lib';

//-------------- Utils ---------------


class CategorizeRoom extends Focusable {
	constructor(props) {
		super(props);
		const name = get_room(this.props.rid).name;
		const room = get_room(this.props.rid);
		const category = room.category;

		this.state = {name, room, category, working: false};
	}

	categorize = () => {
		const {rid} = this.props;
		const {category} = this.state;
		const args = {cmd: 'categorize_room', rid, category};
		api( args, this.categorized );
		this.setState({working: true});
	}

	categorized = () => {
		this.setState({working: false});
		this.props.close();
	}

	render() {
		const options = room_category_options();

		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/> Categorize Room</span>}
			className="modal-35-command"
				>
		{this.state.working && <ProgressSpinner/>}
		{options == null ? 
			<div >
				<p>
				There are no categories yet.
				</p>
				Please define some category choices in 
				My Org: Org Settings
				<br/><br/>
				{ldb.data.me.is_org_admin ?
					<div>
					<Link to={go_url('settings', 'org')}
						>Org Settings</Link>
					<br/><br/>
					</div>
					:
					<div>
					Your organization adminstrator 
						can do this.
					</div>
				}
			</div>
			:
			<div className="p-grid">
	<label className="p-col-2">Category:</label>
	<div className="p-col-8">
		<Dropdown value={this.state.category}
			placeholder="Choose"
			options={options}
			onChange={ e => this.setState({category: e.value}) } 
		/>
	</div>
			<Spacer />
			<TaskCommand onClick={this.categorize} 
				label="Set" icon="pi-check" />

			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />
			<Spacer />	
			</div>
		}
		</ModalCommand>
	}
}

class SelectRoomSignature extends Focusable {
	constructor(props) {
		super(props);
		const name = get_room(this.props.rid).name;
		const room = get_room(this.props.rid);
		const signature = get_signature_of_room(this.props.rid);
		const num = signature ? signature.num : null;
		const html = signature ? signature.html : ldb.data.me.html_signature;
		
		this.state = {name, room, num, signature, html, working: false};
	}

	update = () => {
		const {rid} = this.props;
		const {num} = this.state;
		
		const args = {
			cmd: 'profile_update', 
			op: 'set_room_signature',
			num, rid,
		}
		api( args, this.updated );
		this.setState({working: true});
	}

	updated = () => {
		this.setState({working: false});
		this.props.close();
	}
	
	set_selected = (new_num) => {
		let new_sig = null
		let html = ldb.data.me.html_signature;
		
		if (new_num !== '') {
			new_sig = ldb.data.me.settings.alt_sigs[new_num];
			html = new_sig.html;
		}

		this.setState({num: new_num, html: html}); 	
	}

	help_popup = () => {
		return (<HelpInfo header="Room Signature" className="normal">
		<div>
		<p>
			To edit the content of this email signature you can go to your profile and look for the edit option for this item under the "Email Signatures" section.
		</p>
		</div>
		</HelpInfo>)
	}

	render() {
		const options = room_signature_options();

		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/> Select Room Signature</span>}
			className="modal-35-command"
				>
		{this.state.working && <ProgressSpinner/>}
	<div className="p-grid">
	<label className="p-col-2">Signature:</label>
	<div className="p-col-10">
		<Dropdown value={this.state.num}
			placeholder="Default"
			options={options}
			onChange={ e => this.set_selected(e.value) } 
		/>
		{em(2)}
		<Button label="Set"
			icon="pi pi-check"
			onClick={this.update} 
		/>
		
		<Spacer />
		<Spacer />
	</div>
	</div>
	<div className="p-grid">
	<div className="p-col-2"></div>
	<div className="p-col-9" style={{border:'1px solid #999'}} dangerouslySetInnerHTML={{__html: this.state.html}} />
	<div className="p-col-1">{this.help_popup()}</div>
	</div>
	
	</ModalCommand>
	}
}

class DefaultEmailRecipients extends Focusable {
	constructor(props) {
		super(props);
		const name = get_room(this.props.rid).name;
		const room = get_room(this.props.rid);
		const default_email_recipients = room.default_email_recipients;
		
		this.state = {name, room, default_email_recipients, working: false};
	}

	update = () => {
		const {rid} = this.props;
		const {default_email_recipients} = this.state;
		
		const args = {cmd: 'mod_room', rid, 
			op: 'set_default_email_recipients', val: default_email_recipients};
		
		api( args, this.updated );
		this.setState({working: true});
	}

	updated = () => {
		this.setState({working: false});
		this.props.close();
	}
	
	render() {
		const options = [{label:'All Clients', value:'a'},
				{label:'No Clients', value:'n'}];
		
		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/> Select Default Email Recipients</span>}
			className="modal-35-command"
				>
		{this.state.working && <ProgressSpinner/>}
	<div className="p-grid">
	<label className="p-col-4">Default Email Recipients:</label>
	<div className="p-col-8">
		<Dropdown value={this.state.default_email_recipients}
			options={options}
			onChange={ e => this.setState({default_email_recipients: e.value}) } 
		/>
		{em(2)}
		<Button label="Set"
			icon="pi pi-check"
			onClick={this.update} 
		/>
	</div>
	</div>
	
	</ModalCommand>
	}
}

class RenameRoom extends Focusable {
	constructor(props) {
		super(props);
		const name = get_room(this.props.rid).name;
		this.state = {name, working: false};
	}

	rename = () => {
		const {rid} = this.props;
		const {name} = this.state;
		const args = {cmd: 'rename_room', rid, name};
		api( args, this.renamed );
		this.setState({working: true});
	}

	renamed = () => {
		this.setState({working: false});
		this.props.close();
	}

	render() {
		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/> Rename Room</span>}
				>

		{this.state.working && <ProgressSpinner/>}
	<Spacer />
	<div className="p-grid">
		<div className="p-col-2">
			Name
		</div>
		<div className="p-col-10">
			<InputText value={this.state.name}
				ref={lazyFocus}
				style={{width:'90%'}}
		onChange={e => this.setState({name: e.target.value})} 
			/>
		</div>
	</div>
	<Spacer />
  	<TaskCommand onClick={this.rename} label="Set" icon="pi-check" />

	<Spacer />
		</ModalCommand>
	}
}

class AutoMove extends Focusable {
	constructor(props) {
		super(props);
		const name = get_room(this.props.rid).name;
		this.state = {name, working: false};
	}

	toggle = () => {
		const {rid} = this.props;
		const room = get_room(rid);
		const args = {cmd: 'mod_room', rid, 
			op: 'auto_move', val: !room.auto_move};
		api( args, this.toggled );
		this.setState({working: true});
	}

	toggled = () => {
		const {rid} = this.props;
		const room = get_room(rid);
		//room.auto_move = (!room.auto_move);

		this.setState({working: false});
		this.props.close();
	}

	render() {
		const room = get_room(this.props.rid);
		const val = room.auto_move ? 'On' : 'Off';
		const cmd = room.auto_move ? 
				'Reset Auto Share' : 'Set Auto Share';
		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/>Set Auto Share</span>}
			className="modal-35-command"
				>

		{this.state.working && <ProgressSpinner/>}
	<Spacer />
	<div className="p-grid">
		<div className="p-col-4">
			Auto Share
		</div>
		<div className="p-col-8">
			{val}
		</div>
	<Spacer />
		<div className="p-col-4">
		</div>
		<div className="p-col-8">
			<Button label={cmd}
				icon="pi pi-check"
				onClick={this.toggle}
			/>
		</div>

	<Spacer />
	</div>
		</ModalCommand>
	}
}

class ChangeVisibility extends Focusable {
	constructor(props) {
		super(props);
		const name = get_room(this.props.rid).name;
		this.state = {name, working: false};
	}

	toggle = () => {
		const {rid} = this.props;
		const room = get_room(rid);
		const args = {cmd: 'mod_room', rid, 
			op: 'set_hidden', val: !room.hidden};
		api( args, this.toggled );
		this.setState({working: true});
	}

	toggled = () => {
		const {rid} = this.props;
		const room = get_room(rid);
		
		this.setState({working: false});
		this.props.close();
	}

	render() {
		const room = get_room(this.props.rid);
		const val = room.hidden ? 'Hidden' : 'Visible';
		const cmd = room.hidden ? 
				'Set to Visible' : 'Set to Hidden';
		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/>Set Visiblity Status</span>}
			className="modal-35-command"
				>

		{this.state.working && <ProgressSpinner/>}
	<Spacer />
	<div className="p-grid">
		<div className="p-col-4">
			Visibility Status
		</div>
		<div className="p-col-8">
			{val}
		</div>
	<Spacer />
		<div className="p-col-4">
		</div>
		<div className="p-col-8">
			<Button label={cmd}
				icon="pi pi-check"
				onClick={this.toggle}
			/>
		</div>

	<Spacer />
	</div>
		</ModalCommand>
	}
}

class EmailNotifications extends Focusable {
	constructor(props) {
		super(props);
		
		const room = get_room(this.props.rid);

		const settings = JSON.parse(room.settings_json);
		const email_notification_recipients = settings.email_notification_recipients ? settings.email_notification_recipients : [];

		this.state = {working: false, email_notification_recipients};
	}

	toggle = () => {
		const {rid} = this.props;
		const room = get_room(rid);

		const settings = JSON.parse(room.settings_json);

		const args = {cmd: 'mod_room', rid, 
			op: 'room_settings_update', val: {email_notifications: !settings.email_notifications}};
		api( args, this.toggled );
		this.setState({working: true});
	}

	toggled = () => {
		const {rid} = this.props;
		const room = get_room(rid);
		
		this.setState({working: false});
		//this.props.close();
	}

	update_recipients = () => {
		const {rid} = this.props;
		const room = get_room(rid);

		const args = {cmd: 'mod_room', rid, 
			op: 'room_settings_update', val: {email_notification_recipients: this.state.email_notification_recipients}};
		api( args, this.updated_recipients );
		this.setState({working: true});
	}

	updated_recipients = () => {
		const {rid} = this.props;
		const room = get_room(rid);
		
		this.setState({working: false});
		//this.props.close();
	}

	render() {
		const room = get_room(this.props.rid);
		
		const settings = JSON.parse(room.settings_json);
		
		const val = settings.email_notifications ? 'On' : 'Off';
		const cmd = settings.email_notifications ? 'Turn Off Email Alerts' : 'Turn On Email Alerts';
		
		if (!room.staffs._order) {
			this.props.close();
			return null;
		}

		const staff_checkboxes = room.staffs._order.map((sid) => {
			const staff = get_staff(sid);
			if (!staff) {
				return null;
			}
			
			let email_notification_recipients = this.state.email_notification_recipients;
			
			let checked = (email_notification_recipients.indexOf(sid) !== -1) ? true : false;
			
			return (<div><Checkbox
					value={sid}
					onChange={e=> {					
					if (e.checked) {
						email_notification_recipients.push(e.target.value);
					} else {
						const index = email_notification_recipients.indexOf(e.target.value);
						if (index !== -1) {
							email_notification_recipients.splice(index, 1);
						}
					}
					this.setState({email_notification_recipients});
				}}
				checked={checked}
			/> {staff.name}</div>)
		})
		
		return <ModalCommand par={this} 
	header={<span><i className='fa fa-fw fa-exchange'/>Set Email Alerts</span>}
			className="modal-35-command"
				>

		{this.state.working && <ProgressSpinner/>}
	<Spacer />
	<div className="p-grid">
		<div className="p-col-4">
			Email Alerts
		</div>
		<div className="p-col-8">
			{val}
		</div>
	<Spacer />
		<div className="p-col-4">
		</div>
		<div className="p-col-8">
			<Button label={cmd}
				icon="pi pi-check"
				onClick={this.toggle}
			/>
		</div>
	<Spacer />
	</div>
	{settings.email_notifications && <div className="p-grid" style={{marginTop:"10px"}}>
		<div className="p-col-4">
			Email Alert Recipients
		</div>
		<div className="p-col-8">
			{staff_checkboxes}
		</div>
	<Spacer />
		<div className="p-col-4">
		</div>
		<div className="p-col-8">
			<Button label="Update Email Alert Recipients"
				icon="pi pi-check"
				onClick={this.update_recipients}
			/>
		</div>
	<Spacer />
	</div>}
		</ModalCommand>
	}
}

class ArchiveEmails extends Focusable {
	constructor(props) {
		super(props);
		const {rid} = this.props;
		const cur_date = window.g_moment().toDate();
		const dt_archive_from = new Date(cur_date.getFullYear(), cur_date.getMonth() - 6, cur_date.getDate());
		const dt_archive_till = window.g_moment().toDate();
		const room = get_room(rid);
		const data = null;
		
		this.state = {working: false, data, step: 1, dt_archive_from, dt_archive_till};
	}

	archive_emails = () => {
		const {rid} = this.props;
		const {dt_archive_from, dt_archive_till} = this.state;
		const bid = gen_xid();	// batch id, used in progress bar.
		const op = 'archive';
		const args = {cmd: 'archive_email', op, rid, dt_archive_from, dt_archive_till, bid};
		api( args, this.archived_emails );
		this.setState({working: true});
		
		window.g_progCircle.add(bid, 
			"Creating Archive Room", 1);
	}

	fetch_email_info = () => {
		const {rid} = this.props;
		const {dt_archive_from, dt_archive_till} = this.state;
		const op = 'get_data';
		const args = {cmd: 'archive_email', op, rid, dt_archive_from, dt_archive_till};
		api( args, this.fetched_email_info );
		this.setState({working: true});
	}

	fetched_email_info = (error, db, resp) => {
		const data = db.archive_email_data;
		
		this.setState({working: false, step: 2, data: data});
	}

	archived_emails = () => {
		this.setState({working: false});
		this.props.close();
	}

	return_to_step_1 = () => {
		this.setState({step: 1});
	}

	render() {
		return <ModalCommand par={this}
			className="modal-35-command"
			header="Create Archive Room">
  
	{(this.state.step == 1) && <React.Fragment>
	<p>
		Select a date range below to select which shared emails for this room
		should be archived. They will be stored in a hidden archive room. 
	</p>
	<Spacer />
	<div className="p-grid" style={{"marginBottom":"320px"}}>
	<label className="p-col-3">Archive Date Range:</label>
	<div className="p-col-9 priority-buttons">
		<Calendar value={this.state.dt_archive_from}
		onChange={ e => this.setState({ dt_archive_from: e.value }) }
		style={{zIndex:5000}}
		></Calendar>
		<span style={{marginLeft:'10px', marginRight:'10px'}}>-</span> 
		<Calendar value={this.state.dt_archive_till}
		onChange={ e => this.setState({ dt_archive_till: e.value }) }
		style={{zIndex:5000}}
		></Calendar>
	</div>
	</div>
	</React.Fragment>}

	{(this.state.step == 2) && <React.Fragment>
	<p>
		The following archive room will be created:
	</p>
	<Spacer />
	<div className="p-grid">
	<div className="p-col-12">
		<b>{this.state.data.name}</b>
		<br/>
		{this.state.data.eids.length} email(s) will be move to this archive room
	</div>
	</div>
	</React.Fragment>}

	<Spacer />
	{!this.state.working && <div className="p-grid">
		<div className="p-col-2"></div>
		<div className="p-col-10">
			{(this.state.step == 1) && <Button label="Next Step"
				icon="pi pi-plus"
				onClick={this.fetch_email_info}
			/>}
			{(this.state.step == 2) && <Button label="Create Archive Room"
				icon="pi pi-plus"
				onClick={this.archive_emails}
			/>}
			{(this.state.step == 2) && <Button label="Choose New Dates"
				className="p-button-secondary"
				icon="pi pi-times"
				style={{marginLeft:'2em'}}
				onClick={this.return_to_step_1}
			/>}
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
	}
}

class DeleteRoom extends Focusable {
	constructor(props) {
		super(props);
		this.state = {loading: true, working: false, num_shared_emails: null, num_tasks: null};
	}
	
	componentDidMount() {
		this.get_room_data();
	}

	get_room_data = () => {
		const {rid} = this.props;
		const args = {cmd: 'delete_room_prep', rid};
		api( args, this.parse_room_data );
		this.setState({loading: true});
	}

	parse_room_data = (data) => {
		this.setState({loading: false,
			num_shared_emails: ldb.data.delete_room_prep.num_shared_emails,
			num_tasks: ldb.data.delete_room_prep.num_tasks
		});
	}

	room_delete = () => {
		const {rid} = this.props;
		const args = {cmd: 'delete_room', rid};
		api( args, this.room_deleted );
		this.setState({working: true});
	}

	room_deleted = () => {
		this.setState({working: false});
		this.props.close();
	}

	render() {
		return <ModalCommand par={this} 
  header={<span><i className='fa fa-fw fa-trash-o'/> Delete This room</span>}
		>
		{(this.state.working || this.state.loading) && <ProgressSpinner/>}
		<Spacer />
		{(!this.state.loading) && (!this.state.working) && <div>
		{(this.state.num_shared_emails == 0) && <div>
			<div className="p-grid">
			<div className="p-col-12">
			<p>
				This room will be deleted and removed for all staff members.
			</p>
			{(this.state.num_tasks > 0) && <p>
				Tasks assigned in this room will be deleted.
			</p>}
			</div>
			</div>
			<Spacer />
  			<TaskCommand onClick={this.room_delete} label="Confirm Room Delete" icon="pi-check" />
			<Spacer />
		</div>}
		{(this.state.num_shared_emails > 0) && <div className="p-grid">
			<div className="p-col-12">
				<p>
					You cannot delete rooms with shared emails in them.
				</p>
				<p>
					If you would still like to delete this room, please undo transfer all shared emails in the room back to unshared emails first.
				</p>
			</div>
		</div>}
		</div>}
	</ModalCommand>
	}
}

class CloseTicket extends Focusable {
	constructor(props) {
		super(props);
		this.state = {working: false};
	}
	
	componentDidMount() {
	}

	ticket_close = () => {
		const {rid} = this.props;
		const args = {cmd: 'close_ticket', rid};
		api( args, this.ticket_closed );
		this.setState({working: true});
	}

	ticket_closed = () => {
		this.setState({working: false});
		this.props.close();
		go('myrooms');
	}
	
	render() {
		return <ModalCommand par={this} 
  header={<span><i className='fa fa-fw fa-ticket'/> Close Ticket</span>}
		>
		{(this.state.working) && <ProgressSpinner/>}
		<Spacer />
		{(!this.state.working) && <div>
			<div className="p-grid">
			<div className="p-col-12">
			<p>
				This ticket will be closed, and all staff and clients will be removed.
			</p>
			</div>
			</div>
			<Spacer />
  			<TaskCommand onClick={this.ticket_close} label="Confirm Ticket Close" icon="pi-check" />
			<Spacer />
		</div>}
	</ModalCommand>
	}
}

export {CategorizeRoom, SelectRoomSignature, DefaultEmailRecipients, RenameRoom, DeleteRoom, CloseTicket, ArchiveEmails, AutoMove, EmailNotifications, ChangeVisibility};
