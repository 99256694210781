import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {SelectButton} from 'primereact/selectbutton';
import {ProgressSpinner} from 'primereact/progressspinner';

import {ldb, log, api, go, edate, edate3, map_list, go_url,
		get_room, get_staff, foreach_dict, } from './Lib';

//------------------ Diag  ------------------------

/* This component shows Staff member's logs.
 * If staff user is org admin, a Select toggle lets user choose to show
 #	user's logs, or all users of the org's logs.
 * User's logs are in ldb.data.logs
 *	Org logs are in ldb.data.org_logs
 *	Design alternatives: 
 *	a. Keep all logs in same tree: ldb.data.logs.
 *	b. Keep data separate for org logs and user logs, despite duplication
 *	Opting for (b) to avoid edge cases of "refresh" to get latest logs 
 *	or earlier logs, and not have gaps.
 */

class Logs extends Component {
	constructor() {
		super();
		this.state = {working:false, 
			org_logs: false,	// my logs only
			log_scope: 'my'};	// my|org (org if org admin)
	}

	log_root = () => this.state.org_logs ? 
		ldb.data.org_logs : ldb.data.logs
	
	init_data = () => {
		const flags = this.log_root()._flags;
		if (flags._new) {
			flags._new = false;
			this.get_logs();
		}
	}

	componentDidMount() {
		this.init_data();
	}

	componentDidUpdate() {
		this.init_data();
	}
	
	get_logs = () => {
		const {log_scope} = this.state;
		const args = {cmd: 'get_logs', log_scope};
		api( args, this.got_logs );
		this.setState({working: true});
	}

	sort_by_id = (a,b) => a-b

	got_logs = () => {
		// skip_post_proc.. so order will be empty
		const root = this.log_root();
		root._order = root._idlist.sort(this.sort_by_id).reverse();
		this.setState({working: false});
	}

	get_room_link = rid => {
		if (rid == 0)
			return <span>0</span>;
		const room = get_room(rid) || {};
		if (room === undefined)
			return <span>{rid}</span>;
		
		return <Link to={go_url('room', room.id)}
			title={room.name}
			>
			{room.name}
		</Link>;
	}

	logs_legend = () => <div className="p-grid legend bold">
		<div className="p-col-1">
			ID
			{this.state.org_logs && '/Staff'}
		</div>
		<div className="p-col-2">
			Date
		</div>
		<div className="p-col-2">
			Room
		</div>
		<div className="p-col-7">
			Message
		</div>
	</div>

	get_extra = (data, room) => {
		const au = data.added_updated;
		if (au) {
			const ceids = au.split(', ').map(
					snum => parseInt(snum,10)
				);
			const auemails = [];

			foreach_dict(room.clients, client => {
				foreach_dict(client.emails, email => {
					if (ceids.indexOf(email.id) >= 0) 
						auemails.push(email.email);
				});
			});
			return auemails.join(', ');
		}

		const transfer_ues = data.transfer_ues;
		if (transfer_ues) {
			return <div>
			{Object.keys(transfer_ues).map(uid => {
				const item = transfer_ues[uid];
return <div className="p-grid" key={uid} style={{border:'1px solid #666', margin:'1em 0'}}>
	<div className="p-col-2">
		Room:
	</div>
	<div className="p-col-10">
		{this.get_room_link(item.rid)}
	</div>
	<div className="p-col-2">
		UID:
	</div>
	<div className="p-col-10">
		{uid}
	</div>
	<div className="p-col-2">
		Date:
	</div>
	<div className="p-col-10">
		{edate(item.date)}
	</div>
	<div className="p-col-2">
		Msgid:
	</div>
	<div className="p-col-10" title={item.msgid}>
		{item.msgid.slice(0,12) + '...' + item.msgid.slice(-12)}
	</div>
</div>;
			})}
			</div>;
		}

		const uid2rid = data.info;
		if (uid2rid) {
			return <div>
			{Object.keys(uid2rid).map(uid => {
				const item = uid2rid[uid];
return <div className="p-grid" key={uid} style={{border:'1px solid #666', margin:'1em 0'}}>
	<div className="p-col-2">
		Action:
	</div>
	<div className="p-col-10">
		{item.action}
	</div>
	<div className="p-col-2">
		Room:
	</div>
	<div className="p-col-10">
		{this.get_room_link(item.rid)}
	</div>
	<div className="p-col-2">
		UID:
	</div>
	<div className="p-col-10">
		{uid}
	</div>
	<div className="p-col-2">
		Subject:
	</div>
	<div className="p-col-10">
		{item.subj}
	</div>
	<div className="p-col-2">
		Date:
	</div>
	<div className="p-col-10">
		{edate(item.date)}
	</div>
	<div className="p-col-2">
		Msgid:
	</div>
	<div className="p-col-10" title={item.msgid}>
		{item.msgid.slice(0,12) + '...' + item.msgid.slice(-12)}
	</div>
</div>;
			})}
			</div>;
		}
	}

	staff_name = sid => {
		const staff = get_staff(sid);
		const fname = staff.name.split(' ')[0];
		return <span title={staff.name}>/
			{fname}
		</span>;
	}

	show_logs = () => {
		const logs = this.log_root();
		return <div>
		{this.logs_legend()}
		{map_list(logs, entry => {
			const data = JSON.parse(entry.data_json);
			const room = get_room(data.rid) || {};
			log('logs', "Show Log", data, data.rid, room);
			const extra = this.get_extra(data, room);
			return <div className="p-grid" key={entry.id} >
				<div className="p-col-1">
					{entry.id}
			{this.state.org_logs && this.staff_name(entry.sid)}
				</div>
				<div className="p-col-2">
					{edate3(entry.dt_added)}
				</div>
				<div className="p-col-2">
		<Link to={go_url('room', room.id)}
			title={room.name}
			>
			{room.name}
		</Link>
				</div>
				<div className="p-col-7">
					{data.message}
					{' '}
					{extra}
				</div>
			</div>
			})}
		<hr/>
		</div>
	}

	log_sel = () => {
		if (!ldb.data.me.is_org_admin)
			return null;

		const options = [
			{label: 'My Logs', value: 'my'},
			{label: 'Org Logs', value: 'org'},
		];

		return <SelectButton 
			value={this.state.log_scope} 
			options={options} 
			onChange={(e) => {
				const log_scope = e.value;
				const org_logs = (log_scope == 'org');
				this.setState({log_scope, org_logs});
				this.init_data();
			}} ></SelectButton>
	}

	render() {

		log('logs', 'Logs');

		return (
<div>
	<Toolbar
	left={<Button label="Get Logs" 
		className="p-button-text p-button-raised" 
		onClick={this.get_logs} />}
	right={
		<div className="p-toolbar-group-right">
	{this.log_sel()}
		</div>
	}
	/>
	{this.state.working && <ProgressSpinner />}
	{/*  this.show_ues() */}
	{this.show_logs()}
</div>
		)
	}
}

export {Logs};
