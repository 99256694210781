import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';

import Tasks from './Tasks';
import SharedEmails from './SharedEmails';
import {UnsharedEmails, MailboxEmails} from './UnsharedEmails';
import {Contacts} from './Persons';
import {Chat} from "./Chat";
import {NewEmail} from "./NewEmail";
import {Summary, MailboxRoomSummary} from './Summary';

import {ldb, log, go, get_room, go_url, get_first_id, growl, pint,
	go_replace, drafts_tab, mbox_read_perm, mbox_write_perm,
	ridMRU, filter_list, api, show_new_flag, room_has_team_email,
	mark_summary_tab_as_read, get_new_flag_color, is_super_admin
} from './Lib';

let g_viewCount = 0;
let g_last_tab = 'summary';

// ViewRoom
//	Lays out all the tabs.
//   completes partial URLs:
//	/#/room/27 => 
//		/#/room/27/summary/0
//	/#/room/27/shared =>
//		/#/room/27/shared/-1	: if no pages fetched
//		/#/room/27/shared/0	: if fetched, but no items
//		/#/room/27/shared/321	: if fetched, and first item

class ViewRoom extends Component {
	constructor(props) {
		super(props);
		window.g_viewRoom = this;
		
		// properly initiliaze is_mailbox here.
		//	otherwise, ListView will be mounted,unmounted,
		//		remounted as Mailbox and APIs sent
		//		won't complete proplery on original obj.
		//	TBD: if ListView is remounted, should API completions
		//		transfer to new instance??
		//		Assumption: Only one ListView.
		let is_mailbox = false;
		const {rid} = this.props.match.params;
		if (rid) {
			const room = get_room(rid);
			if (room)
				is_mailbox = room.is_mailbox;
		}

		this.state = {shared_email_bulk_mode: false,
				mailbox_email_bulk_mode: false,
				is_mailbox};
	}

	componentDidMount() {
		// didUpdate doesn't get called first time..
		// 	we call it explicityly
		this.componentDidUpdate();
	}

	componentDidUpdate() {
		let {rid, tab, iid, command, cid} = this.props.match.params;
		const room = get_room(rid);
		let is_mailbox = this.state.is_mailbox;
		if (room) {
			if (room.is_mailbox != is_mailbox) {
				is_mailbox = room.is_mailbox;
				this.setState({is_mailbox});
			}
		}

		log("room", "didUpdate", {tab, iid});

		if (!tab)
			tab = is_mailbox ? 'unshared':g_last_tab;

		if (!iid) {
			// We switched to this tab without IID in url.
			let new_iid=0, command='';
			const dtab = drafts_tab(room);
			const rtab = room[dtab];

			// if (tab == 'compose' || tab == dtab) 
			if (tab == 'compose') {
				const pending_draft_iids = 
					rtab._flags.pending_draft_iids || [];
				new_iid = pending_draft_iids[0] || 0;
				if (new_iid && tab == 'compose')
					command = rtab.drafts[new_iid].command;
			}
			else {
				//	Grab first item, or -1, or 0
				new_iid = get_first_id(pint(rid), tab);
			}
			log("room", "didUpdate.new_iid", 
					{rtab, tab, new_iid, command});
			go_replace('room', rid, tab, new_iid, command);
		}
		else
			this.update_MRU();
	}

	update_MRU = () => {
		const {rid, tab, iid, command} = this.props.match.params;

		ridMRU.add(rid);
	}

	new_icon = show_new => show_new ? ' new' : ''

	get_new_flags = room => {
		return {
			unshared: this.new_icon(show_new_flag(room, 'unshared')),
			shared: this.new_icon(show_new_flag(room, 'shared')),
			task: this.new_icon(show_new_flag(room, 'task')),
			chat: this.new_icon(show_new_flag(room, 'chat')),
			summary: this.new_icon(show_new_flag(room, 'summary')),
		}
	}

	tabs = () => (this.state.is_mailbox ? 
		['unshared', 'compose', 'contact', 'summary', ] :
		['unshared', 'shared', 'compose', 
			'task', 'chat', 'contact', 'summary', ])

	t2i = url => this.tabs().indexOf(url)
	i2t = i => this.tabs()[i]

	goTab = i => {
		const tab = this.i2t(i);
		const {rid} = this.props.match.params;
		go('room', rid, tab);
	}

	render() {
		let {rid, tab, iid, command, cid} = this.props.match.params;
		const {is_mailbox} = this.state;

		log("room", "============= View Room====", 
			{rid, tab, iid, command, g_last_tab});

		if (!tab || !iid) 
			return null;	// didupdate will redirect.

		iid = pint(iid);
		rid = pint(rid);

		const room = get_room(rid);
		if (room === undefined)
			return null;

		command = command || '';	// if undefined, make it blank

		// default: cmd id (eg. summary/edit-client-user)
		cid = cid || 0;	

		g_viewCount++;
		g_last_tab = tab;

		const iTab = this.t2i(tab);
		const tnew = room._flags._tabs;

		const flags = this.get_new_flags(room);

		const args = {rid, iid, command, cid, tab, key:rid, g_viewCount};
		
		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		const has_team_email = room_has_team_email(room);
		const super_admin = is_super_admin();

		log("room", "View Room", rid, tab, iid, command, cid, flags);

		if (is_mailbox) {
			return (
			<TabView
				activeIndex={iTab}
				onTabChange={e => this.goTab(e.index)} >

			  <TabPanel header="My Emails"
				leftIcon={'fa fa-fw fa-envelope-o newtab-' + 
						tnew.unshared}
				>
				<MailboxEmails {...args} par={this}
				checkbox_col={this.state.mailbox_email_bulk_mode} />
			  </TabPanel>

			  <TabPanel header="Create Email"
				leftIcon={'pi pi-fw pi-plus'}
				>
				<NewEmail {...args} />
			  </TabPanel>

			  <TabPanel header="Contacts"
				leftIcon={'pi pi-fw pi-users'}
				  >
				<Contacts {...args} />
			  </TabPanel>

			  <TabPanel header="About"
				leftIcon={'pi pi-fw pi-info-circle newtab-' + 
						tnew.summary}
				  >
				<MailboxRoomSummary {...args} />
			  </TabPanel>
			</TabView>
			);
		}

		return (
		<TabView
			activeIndex={iTab}
			onTabChange={e => this.goTab(e.index)} >
		  <TabPanel header="My Unshared Emails"
				leftIcon={'fa fa-fw fa-envelope-o newtab-' + 
					tnew.unshared}
				headerClassName={read_perm ? "" : "hide-tab"}
		  	>
			<UnsharedEmails {...args} checkbox_col={true} />
		  </TabPanel>
		  <TabPanel header="Shared Emails"
				leftIcon={'fa fa-fw fa-envelope-open newtab-' + 
						tnew.shared}
		  	>
			<SharedEmails {...args} par={this}
				checkbox_col={this.state.shared_email_bulk_mode} />
		  </TabPanel>
		  <TabPanel header="Create Email"
				leftIcon={'fa fa-fw fa-plus'}
				headerClassName={(read_perm || ((!super_admin) && has_team_email)) ? "" : "hide-tab"}
		  	>
			<NewEmail {...args} />
		  </TabPanel>
		  <TabPanel header="Tasks"
				leftIcon={'fa fa-fw fa-calendar-check-o newtab-' + 
						tnew.task}
		  	>
			<Tasks {...args} />
		  </TabPanel>
		  <TabPanel header="Chat"
				leftIcon={'fa fa-fw fa-comments newtab-' + 
						tnew.chat}
			  >
			<Chat {...args} />
		  </TabPanel>
		  <TabPanel header="Contacts"
		  	leftIcon={'pi pi-fw pi-users'}
			  >
			<Contacts {...args} />
		  </TabPanel>
		  <TabPanel header="About"
				leftIcon={'pi pi-fw pi-info-circle newtab-' + 
					tnew.summary}
			  >
			<Summary {...args} />
		  </TabPanel>
		</TabView>
		)
	}
}

export default ViewRoom;
