import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Checkbox} from 'primereact/checkbox';

import sanitizeHtml from 'sanitize-html';

import {ModalCommand, Spacer, Focusable, lazyFocus, HelpInfo} from './Utils';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v, get_room_options, is_staff_a_recipient_of_shared_email,
	map_list, filter_list, gen_xid, get_item, em, escape_reg_exp,
	get_body_part, bulk_email_list_options, get_bulk_email_list, 
	html2plain, get_bulk_email_list_data, go, drafts_tab,
	} from './Lib';



const plain2html = plain => plain.split('\n').join('<br/>')



class SummarizeEmail extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, par} = this.props;
		
		const eid = item.id;
	
		const subject = item.subject;
		const room = get_room(rid);
		const working = false;
		const summary = null;
		
		if (!eid) {
			this.props.close();
		}
		
		const env = get_item(rid, 'shared', eid);
		if (!env) {
			this.props.close();
		}
		
		this.state = {rid, subject, working, summary};
		
		window.g_summarizeEmail = this;
	}
		
	has_errors = () => {
		if (!this.props.item) {
			growl('Error', 'No email selected. Please close the popup', 'error');
			return true;
		}
						
		return false;
	}
	
	cancel = () => {
		this.props.close();	
	}
		
	summarize_email = () => {
		if (this.has_errors())
			return;
		
		const {item} = this.props;
		
		const eid = item.id;
		
		this.setState({working: true});
		
		const args = {
			cmd: 'summarize_email', eid: eid
		}
		api( args, this.summarize_email_complete );
	}

	summarize_email_complete = (error, db) => {
		this.setState({working: false, summary: db.summary});
	}
		
	render() {
		const {item, rid} = this.props;
		
		return (
		<ModalCommand par={this}
			className="medium-modal-command"
			header="Summarize Email">
	
	<div className="p-grid">
		<div className="p-col-1">
		</div>
		<div className="p-col-11" style={{paddingTop: '30px'}}>
			{(this.state.summary) && <pre className="prewrap">
			{this.state.summary}
			</pre>}
			{(!this.state.summary) && <div>
			This feature will generate a summary of the current email and display it here.
			</div>}
		</div>
	</div>

	{!this.state.working && <div className="p-grid">
		<div className="p-col-2">
		</div>
		<div className="p-col-10" style={{paddingTop: '30px'}}>
			<br/>
			<Button label="Summarize Email"
				icon="pi pi-directions"
				onClick={this.summarize_email}
			/>
			{em(2)}
			<Button label="Cancel"
				className="bulk-email-secondary-button"
				icon="pi pi-times"
				onClick={this.cancel}
			/>
		</div>
	</div>}
	{this.state.working && <div style={{textAlign: 'center', marginTop: '30px'}}><ProgressSpinner/></div>}
		</ModalCommand>
		)
	}
}

class SummarizeThread extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, par} = this.props;
		
		const eid = item.id;
	
		const subject = item.subject;
		const room = get_room(rid);
		const working = false;
		const summary = null;
		
		if (!eid) {
			this.props.close();
		}
		
		const env = get_item(rid, 'shared', eid);
		if (!env) {
			this.props.close();
		}
		
		this.state = {rid, subject, working, summary};
		
		window.g_summarizeThread = this;
	}
		
	has_errors = () => {
		if (!this.props.item) {
			growl('Error', 'No email selected. Please close the popup', 'error');
			return true;
		}
						
		return false;
	}
	
	cancel = () => {
		this.props.close();	
	}
		
	summarize_thread = () => {
		if (this.has_errors())
			return;
		
		const {item} = this.props;
		
		const eid = item.id;
		
		this.setState({working: true});
		
		const args = {
			cmd: 'summarize_thread', eid: eid
		}
		api( args, this.summarize_thread_complete );
	}

	summarize_thread_complete = (error, db) => {
		this.setState({working: false, summary: db.summary});
	}
		
	render() {
		const {item, rid} = this.props;
		
		return (
		<ModalCommand par={this}
			className="medium-modal-command"
			header="Summarize Thread">
	
	<div className="p-grid">
		<div className="p-col-1">
		</div>
		<div className="p-col-11" style={{paddingTop: '30px'}}>
			{(this.state.summary) && <pre className="prewrap">
			{this.state.summary}
			</pre>}
			{(!this.state.summary) && <div>
			This feature will generate a summary of the current email and display it here.
			</div>}
		</div>
	</div>

	{!this.state.working && <div className="p-grid">
		<div className="p-col-2">
		</div>
		<div className="p-col-10" style={{paddingTop: '30px'}}>
			<br/>
			<Button label="Summarize Thread"
				icon="pi pi-directions"
				onClick={this.summarize_thread}
			/>
			{em(2)}
			<Button label="Cancel"
				className="bulk-email-secondary-button"
				icon="pi pi-times"
				onClick={this.cancel}
			/>
		</div>
	</div>}
	{this.state.working && <div style={{textAlign: 'center', marginTop: '30px'}}><ProgressSpinner/></div>}
		</ModalCommand>
		)
	}
}

class ExtractData extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, par} = this.props;
		
		const eid = item.id;
	
		const subject = item.subject;
		const room = get_room(rid);
		const working = false;
		const summary = null;
		
		if (!eid) {
			this.props.close();
		}
		
		const env = get_item(rid, 'shared', eid);
		if (!env) {
			this.props.close();
		}
		
		this.state = {rid, subject, working, summary};
		
		window.g_extractData = this;
	}
		
	has_errors = () => {
		if (!this.props.item) {
			growl('Error', 'No email selected. Please close the popup', 'error');
			return true;
		}
						
		return false;
	}
	
	cancel = () => {
		this.props.close();	
	}
		
	extract_data = () => {
		if (this.has_errors())
			return;
		
		const {item} = this.props;
		
		const eid = item.id;
		
		this.setState({working: true});
		
		const args = {
			cmd: 'extract_data', eid: eid
		}
		api( args, this.extract_data_complete );
	}

	extract_data_complete = (error, db) => {
		this.setState({working: false, summary: db.summary});
	}
		
	render() {
		const {item, rid} = this.props;
		
		return (
		<ModalCommand par={this}
			className="medium-modal-command"
			header="Action Items">
	
	<div className="p-grid">
		<div className="p-col-1">
		</div>
		<div className="p-col-11" style={{paddingTop: '30px'}}>
			{(this.state.summary) && <pre className="prewrap">
			{this.state.summary}
			</pre>}
			{(!this.state.summary) && <div>
			This feature will generate a list of actionable work items from the current email and display it here.
			</div>}
		</div>
	</div>

	{!this.state.working && <div className="p-grid">
		<div className="p-col-2">
		</div>
		<div className="p-col-10" style={{paddingTop: '30px'}}>
			<br/>
			<Button label="Create Action Items"
				icon="pi pi-directions"
				onClick={this.extract_data}
			/>
			{em(2)}
			<Button label="Cancel"
				className="bulk-email-secondary-button"
				icon="pi pi-times"
				onClick={this.cancel}
			/>
		</div>
	</div>}
	{this.state.working && <div style={{textAlign: 'center', marginTop: '30px'}}><ProgressSpinner/></div>}
		</ModalCommand>
		)
	}
}


export {SummarizeEmail, SummarizeThread, ExtractData};
