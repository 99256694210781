import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Menubar} from 'primereact/menubar';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {ProgressSpinner} from 'primereact/progressspinner';
import {PickList} from 'primereact/picklist';

import UserList from './UserList';
import AddStaff from './AddStaff';
import AddClient from './AddClient';
import EditClient from './EditClient';
import DeleteClient from './DeleteClient';
import {AutoMove, CategorizeRoom, RenameRoom, DeleteRoom} from './RenameRoom';
import {Commandable, lazyFocus, HelpInfo, AreYouSure} from './Utils';
import {EmailHeader} from './EmailUtil';

import {ldb, log, go, get_room, go_url, get_first_id, growl, 
	list_has_ele, list_toggle_ele, foreach_list,
	filter_list, api, edate3, get_room_activity, 
	set_order, map_list, resort, get_staff, init_list,
	mark_tab_as_read, 
} from './Lib';

//-- consts: Long Help Messages


function preview_preamble() {
	const {meta} = ldb.data;
	const count = meta ? meta.preview_count : 0;

	return <div className="p-col-12">
	<h3>Preview</h3>
		With the filters 
		above <b>{count}</b> emails
		will be added to Pending Emails in this room.
		<br/>
		Other staff members in the room may have fewer
		or more emails that match these triggers.
		<br/>
		Some of the matching emails may already be 
		in Pending Emails or were moved to Common.
		Those are not included in the count above.
		<br/>
		Here are some of your emails that match the triggers,
		whether they're new to Pending Emails or not:
		<br/><br/>
	</div>;
}

//------------

const confirm_set_message = <div style={{width:'50vw'}} >
	<h3>Set Triggers</h3>
	<ul>

	<li>
		Please be sure to have your triggers set as narrowly 
		as possible. If they're set improperly, matching email from
		one client may show up in another client's pending emails
		room, alarming everyone. Since they'll still be in 
		Pending, they won't do any harm, but can cause 
		alarm nevertheless.
	</li>

	<li>
		These triggers will be effective for all future incoming
		emails for all staff in this room.
	</li>

	<li>
		This operation will be performed on existing emails
		for all staff in this room, not just you. 
		That can take several minutes.
		Please wait until this operation completes.
	</li>

	<li>
		If you <i>change</i> triggers, any new emails that meet
		those triggers will be added to Pending Emails.
		However, if you remove any triggers, emails moved into
		Pending for this room from previously applicable triggered
		will <b>not</b> be unmarked and removed from Pending.
		In other words, undo, is not implemented at this point.
		It may be implemented, at a later stage, and
		retroactively applied if necessary.
	</li>

	<li>
		If you add other staff later to this room, their
		existing emails will not be matched for these triggers.
		However, their future emails will be matched for these triggers.
	</li>

	<li>
		For new incoming emails however, previous triggers
		don't matter. Only current triggers are applied.
	</li>

	</ul>
</div>

//------------

const confirm_reset_message = <div style={{width:'50vw'}} >
	<h3>Reset Triggers</h3>
	<ul>

	<li>
		Reset will remove all emails that were added to this room
		only because they matched the triggers from Pending Emails.
		They are not deleted, just removed from Pending Emails
		for this room.
	</li>

	<li>
		This action applies to all staff in this room.
	</li>

	<li>
		Triggers for Subject and From will be set to blanks.
	</li>

	<li>
		Future incoming emails will not be triggered for this
		room.
	</li>

	<li>
		This can take a while, to scan all Pending Emails
		for all staff for this room.
	</li>

	<li>
		Please wait until this completes.
	</li>

	</ul>
</div>

//-----------

const TriggerHelp = () => <HelpInfo header="About Triggers">
<div>
		<p>
	When a client in this room sends an email to you, 
	it becomes a Pending email in this room.
		</p>
		<p>
	If you'd like emails sent by someone else to become Pending Emails
	in this room, you can set up triggers. Triggers can be set
	for From or Subject fields of incoming emails. If any of the
	trigger phrases are present in the target fields, that email
	gets added to Pending Emails. Phrases are case insensitive.
		</p>
		<p>
	Examples: Any email from a specific domain: From: @myvendor.com
	<br/>
	Any email that contains a given loan number or order number in Subject line
		</p>
		<p>
	Caution: An incoming email can be added to the Pending Emails in
	multiple client rooms. If triggers are too broad, a client email
	can be marked as Pending in the wrong client room. 
	If that happens, there is still no harm as long as that email is 
	not moved to common.
		</p>
		<p>
	Setting triggers affects all future incoming mail.
	<br/>
	It also affects existing email. All emails not moved to common
	are re-examined to see if they match the triggers and if so
	they are added to or removed from Pending Emails in corresponding
	rooms. This operation can take several minutes.
		</p>
</div>
</HelpInfo>

//----------- code

function SetTriggers(props) {
	const {rid} = props;

	const room = get_room(rid);
	const init_from = () => (room.triggers.from || []).join(' ')
	const init_subject = () => (room.triggers.subject || []).join(' ')

	const [from, setFrom] = React.useState(init_from());
	const [subject, setSubject] = React.useState(init_subject());
	const [refresh, setRefresh] = React.useState(0);
	const [mode, setMode] = React.useState('');
	const reload =  () => setRefresh(refresh+1);

	// Mode: ''|setting|set_done|resetting|reset_done|
	//		previewing|preview_done

	const tolist = buf => {
		buf = buf.trim();
		if (!buf)
			return [];
		return buf.split(/\s+/).map(x => x.trim().toLowerCase());
	};

	const preview = e => {
		const triggers = {from : tolist(from), 
				subject: tolist(subject)};

		if (!triggers.from.length && !triggers.subject.length) {
			growl('Preview', 
				'Please choose triggers in subject or from',
				'error');
			return;
		}
		// Remove envs from previous Preview test
		room.preview_triggers_unshared = init_list();

		const args = {
			cmd: 'mod_room', 
			rid,
			op: 'preview_triggers',
			val: triggers,
		}
		api( args, (error, db) => {
			log('email', 'preview_triggers', db);
			setMode('preview_done');
			setTimeout(reload, 500);
		});

		if (room.preview_triggers_unshared)
			room.preview_triggers_unshared = init_list();

		setMode('previewing');
	}

	const really_set = e => {
		const triggers = {from : tolist(from), 
				subject: tolist(subject)};

		const args = {
			cmd: 'mod_room', 
			rid,
			op: 'set_triggers',
			val: triggers,
		}
		api( args, (error, db) => {
			setMode('set_done');
		});

		setMode('setting');
	}

	const really_reset = e => {
		const triggers = {from : [], subject: []};

		const args = {
			cmd: 'mod_room', 
			rid,
			op: 'reset_triggers',
			val: triggers,
		}
		api( args, (error, db) => {
			setMode('reset_done');
		});

		setSubject('');
		setFrom('');
		setMode('resetting');
	}

	const clear = e => {
		if (mode.indexOf('ing') >= 0) {
			growl('Operation in progress. Please wait')
			return;
		}
		setFrom(init_from());
		setSubject(init_subject());
		setMode('');

		growl('Cleared');
	}

	log('email', 'setTriggers', {from, subject, mode, room});

	return (
<div >
	<div className="p-grid backbar">
		<Link to="`/room/${rid}/summary/0`" className="p-col-2">
			&lt; Back
		</Link>

		<h3 className="p-col-3">
			Set Triggers
		</h3>
		<div className="p-col-7">
			<TriggerHelp />
		</div>
	</div>

	<div className="p-grid">
		<div className="p-col-2">
			Subject
		</div>
		<div className="p-col-8 wide-input">
			<InputText value={subject} 
				ref={lazyFocus}
				placeholder="Phrases in subject"
				onChange={e => setSubject(e.target.value)} />
		</div>
		<div className="p-col-2 helpinfo">
			eg. loan#  order#
		</div>

		<div className="p-col-2">
			From
		</div>
		<div className="p-col-8 wide-input">
			<InputText value={from} 
				placeholder="Parts of Sender's name or email"
				onChange={e => setFrom(e.target.value)} />
		</div>
		<div className="p-col-2 helpinfo">
			eg. @amazon.com @fedex.com
		</div>

		<div className="p-col-2">
			
		</div>
		<div className="p-col-2">
			<Button label="Set" onClick={e=>setMode('confirm')} />
		</div>
		<div className="p-col-2">
			<Button label="Preview" 
				className="p-button p-button-secondary"
				onClick={preview}
			/>
		</div>
		<div className="p-col-2">
			<Button label="Reset" 
				className="p-button p-button-secondary"
				onClick={e=>setMode('reset')}
			/>
		</div>
		<div className="p-col-4">
			<Button label="Clear" 
				className="p-button p-button-secondary"
				onClick={e=>clear('')}
			/>
		</div>

	</div>

	{mode == 'preview_done' && <div className="p-grid summary">
		{preview_preamble()}

		{map_list(room.preview_triggers_unshared, (env,i) => (
			<div className="p-col-12 preview-triggers" key={i}>
				<EmailHeader env={env} rid={rid} />
			</div>
		))}
	</div>}

	{mode == 'confirm' && <div className="p-grid summary">
		<AreYouSure callback={really_set} 
			close={e=>setMode('')}
			message={confirm_set_message}
		/>
	</div>}

	{mode == 'reset' && <div className="p-grid summary">
		<AreYouSure callback={really_reset} 
			close={e=>setMode('')}
			message={confirm_reset_message}
		/>
	</div>}

	{mode == 'previewing' && <div>
		Fetching preview emails...
		<br/>
		<ProgressSpinner/>
	</div>}

	{mode == 'setting' && <div>
		Scanning for triggers...
		<br/>
		<ProgressSpinner/>
	</div>}

	{mode == 'resetting' && <div>
		Resetting...
		<br/>
		<ProgressSpinner/>
	</div>}

	{mode == 'set_done' && <div className="result" >
		<i className="pi pi-check"></i> Set is done.
	</div>}

	{mode == 'reset_done' && <div className="result" >
		<i className="pi pi-check"></i> Reset is done.
	</div>}
</div>
	);
}

export {SetTriggers, TriggerHelp};

