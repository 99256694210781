import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {TaskSubject, TaskAttach, TaskNote, EditNote, TaskPriority, 
		TaskCommand, TaskSelectStaff,} from './EditTask';
import {Attach, Spacer, ModalCommand, Focusable} from './Utils';

import {ldb, log, get_room, get_list, growl, api, get_body_part, p2br, go,
	map_list, set_order, env_names, edate, regcomp} from './Lib';

class OrgCast extends Focusable {
	constructor(props) {
		super(props);
		this.state = {html:'', plain:'', creating: false};
		log('app', 'OrgCast', this.state);
		regcomp(this, 'orgCast');
	}

	has_errors = () => {
		const {plain} = this.state;

		return plain == '';
	}

	onSend = () => {
		if (this.has_errors())
			return;

		let {plain} = this.state;
		const args = {
			cmd: 'orgcast', 
			message:plain, 
		}
		api( args, this.sent, this.sent );

		this.setState({creating:true});
	}

	sent = (error) => {
		this.setState({creating:false});

		if (error) {
			const message = "An error occurred: " + error + 
			". Please notify TagInbox. Some operations may have been partially successful. Retry may not be successful."
			alert(message);
			return;
		}
		this.props.close();
	}

	render() {
	 	return (
                    <ModalCommand 
		    onHide={this.props.close}
		header="Send Alert To All Staff Online"
			className="mini-modal-command"
		    	>
  	<EditNote par={this} label="Alert" />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
  	<TaskCommand onClick={this.onSend} label="Send" />
	<Spacer />
	<Spacer />
	{this.state.creating && <ProgressSpinner/>}

                    </ModalCommand>
	
		)
	}
}

export default OrgCast;
