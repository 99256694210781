import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Fieldset} from 'primereact/fieldset';
import {Card} from 'primereact/card';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {TabView, TabPanel} from 'primereact/tabview';
import {Dropdown} from 'primereact/dropdown';
import {Toolbar} from 'primereact/toolbar';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Checkbox} from 'primereact/checkbox';
import {PickList} from 'primereact/picklist';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';

import {ldb, log, filter_list, ridMRU, go_url, map_list, clean_email, 
	growl, api, room_category_options, pid2person,
	list_has_ele, list_toggle_ele, em, gen_room_name, split_name,
	set_cluster_search_buf, gen_xid, deepcopy,
	room_category_default, get_room, get_staff} from './Lib';
import {FilteredPersons, email2person} from './Persons';
import {lazyFocus, ModalCommand, FakeLink} from './Utils';
import {AutoMoveHelp} from './Summary';

// Notes: Work in Progress... Transition
//	Moving to using Person, Cluster, away from local clients.
// TBD:
//	1. Refresh Person (remember time and get all changes past that date)
//	  to Person objects before creating a new room.
//	Not a huge deal, if another staff used up a client, room creation 
//	will get an error, but Person objects are not multicasted down,
//	since they are per staff and some staff not in the new room may
//	still have that Person that was marked away.
//	--Mark Time, fetch newer changes each time Add Room starts.
// 	2. Organize into smaller Components.. Too big

function EditEmails({item}) {
	const [refresh, setRefresh] = React.useState(0);
	const {emails, xid} = item;
	const options = [
		{label: 'P', value: 1},
		{label: 'O', value: 0},
		{label: 'U', value: -1},
	];

	if (item.email_kinds === undefined) {
		item.email_kinds = {};
	}
	emails.forEach(email => {
		if (item.email_kinds[email] === undefined)
			item.email_kinds[email] = 1;
	});

	return (
	<React.Fragment>
		{emails.map((email, i) => (
	<React.Fragment key={i}>
		<div className="p-col-2 x-small">
		<Dropdown 
			className="email-kinds-dropdown"
			value={item.email_kinds[email]}
			options={options}
			onChange={e => {
				item.email_kinds[email] = e.value;
				setRefresh(refresh+1);
			}}
		/>
		</div>
		<div className="p-col-10 edit-contact-email">
			{email}
		</div>
	</React.Fragment>
			
		))}
		<div className="p-col-2 x-small">
		</div>
		<div className="p-col-10 edit-emails-legend">
			P = Primary, O = Optional, U = Unused/old
		</div>
	</React.Fragment>
	)
}

function SelectMultipleStaff({sids, update, legend}) {
	const min_staff_toggle = 10;

	if (ldb.data.staffs._idlist.length >= min_staff_toggle) {
		let source_options = [];
		let target_options = [];
		ldb.data.staffs._idlist.forEach( sid => {
			const s = get_staff(sid);
			if (sids.includes(s.id)) {
				target_options.push({'name': s.name, 'id': s.id, 'email': s.email});
			} else {
				source_options.push({'name': s.name, 'id': s.id, 'email': s.email});
			}
		});
		
		return (<LargeSelectStaff source_options={source_options} target_options={target_options} update={update} legend={legend} />);
	} else {
		return (<SelectStaff sids={sids} update={update} legend={legend} />);
	}
}

function SelectStaff({sids, update, legend}) {
	return (
	<div className="p-grid select-staff">
		  <div className="p-col-1">
		  	{legend}
		  </div>
		  <div className="p-col-11">

	{map_list(ldb.data.staffs, (item, i) => <Button key={i}
		label={item.name}
		title={item.email}
		onClick={e => {
			e.preventDefault();
			list_toggle_ele(sids, item.id);
			update(sids);
		}}
		className={'p-button ' + (list_has_ele(sids, item.id) ?
			'p-button-outlined' : 'p-button-text')}
		/>)}
				
		  </div>
		  <div className="p-col-6">
		  </div>
		  <div className="p-col-6">
			<Button label="Reset Staff"
				icon="pi pi-fw pi-times"
				className="p-button-text p-button-raised"
				onClick={e => update([])}
				/>
			{em(2)}
			<Button label="Select All"
				icon="pi pi-fw pi-copy"
				className="p-button-text p-button-raised"
				onClick={e => {
					const sids = 
	map_list(ldb.data.staffs, item => item.id);
					update(sids);
				}}
				/>
		  </div>
	 </div>
	 );
}

function LargeSelectStaff({source_options, target_options, update, legend}) {
	const [source, setSource] = React.useState(source_options);
	const [target, setTarget] = React.useState(target_options);
	const [search, setSearch] = React.useState('');

	const onChange = (e) => {
		setSource(e.source);
		setTarget(e.target);
		
		const sids = e.target.map(item => item.id);
		update(sids);
	};
	
	const itemTemplate = (item) => {
		return (
<div className="flex flex-wrap p-2 align-items-center gap-3">
	<div className="flex-1 flex flex-column gap-2">
		<span className="font-bold" title={item.email}>{item.name}</span>
	</div>
</div>
		);
	};
	
	return (
	<PickList source={source} target={target} onChange={onChange} itemTemplate={itemTemplate} filter filterBy="name" breakpoint="1400px"
                sourceHeader="Available" targetHeader="Selected" sourceStyle={{ height: '20rem' }} targetStyle={{ height: '30rem' }}
		sourceFilterPlaceholder="Search by name" showTargetFilter={false}
	/>
	);
}

function SelectSettings({auto_move, update, legend}) {
	return (
	<div className="p-grid select-staff">
		  <div className="p-col-1">
		  	{legend}
		  </div>
		  <div className="p-col-11">

	{map_list(ldb.data.staffs, (item, i) => <Button key={i}
		label={item.name}
		onClick={e => {
			e.preventDefault();
			list_toggle_ele(sids, item.id);
			update(sids);
		}}
		className={'p-button ' + (list_has_ele(sids, item.id) ?
			'p-button-outlined' : 'p-button-text')}
		/>)}
				
		  </div>
		  <div className="p-col-6">
		  </div>
		  <div className="p-col-6">
			<Button label="Clear All"
				icon="pi pi-fw pi-times"
				className="p-button-text p-button-raised"
				onClick={e => update([])}
				/>
			{em(2)}
			<Button label="Select All"
				icon="pi pi-fw pi-copy"
				className="p-button-text p-button-raised"
				onClick={e => {
					const sids = 
	map_list(ldb.data.staffs, item => item.id);
					update(sids);
				}}
				/>
		  </div>
	 </div>
	 );
}

class CreateRoom extends Component {
	constructor(props) {
		super(props);
		this.draft = ldb.data.rooms.draft;

		/*
		//  Debug only
		this.draft.clients = [
		{csel: '', emails: ['raja@navaraga.com'], 
			name: 'Raja Abburi',
			pids: [33],
			xid:'efu999cwt',
			},
		{csel: '', emails: ['ashley.client.ria@cwraja.com',
			'extra@cwraja.com'], 
			name: 'Ashley Client',
			pids: [11],
			xid:'tkfegeh5m',
			},
		{csel: '', emails: ['raja@hypnoscape.com'], 
			name: '',
			pids: [64],
			xid:'jsqroj3h2',
			},
		];
		*/

		this.state = this.init_state();
		window.g_createRoom = this;

	}

	init_state = () => {
		const clients = this.draft.clients;
		const room_name = clients.length ? 
					this.mk_room_name(clients) : '';
		
		let newly_created_rooms_are_auto_move = ldb.data.org.settings.newly_created_rooms_are_auto_move;
		if (newly_created_rooms_are_auto_move === null) {
			newly_created_rooms_are_auto_move = true;
		}
		
		return {
			room_name,
			// defunct
			staffs : filter_list(ldb.data.staffs, 
				staff => (staff.id !== ldb.data.me.id)),
			// defunct.. sids replaces this
			targetStaffs: [ldb.data.me],

			sids: [ldb.data.me.id],
			edit_xid: null,
			merge_xid: null,
			categories : room_category_options(),
			category : room_category_default(),
			auto_move : newly_created_rooms_are_auto_move,
			iTab : 0,
			client_name: '',
			client_email: '',
			client_tel: '',
			search: '',
			clients,
			email2name : {},
			user_set_room_name: false,
			working: false,
			mode: 'prep', // prep|creating|created|ready
			choose_modal: false,
			nrefresh: 0,
			selxid: '',
			bid: 0,			// batch id
			
			rid: 0,
		}
	}

	componentDidMount() {
		// Login fetches contacts now...
		//	TBD: refresh?
		if (!ldb.data.contacts._flags.fetched) 
			this.get_contacts();

		// Clean up used clusters (from previous create room)
		set_cluster_search_buf();
	}

	refresh = () => this.setState({nrefesh: this.state.nrefresh+1})
	

	staffTemplate = item => item ? item.name : ''

	/*---- 1. Client User Selection Code ----*/

	/*- Actions -*/

	mk_room_name = clients => {
		clients.forEach(client => split_name(client));
		return gen_room_name(clients);
	}

	set_room_name = (clients) => this.setState(
			{room_name: this.mk_room_name(clients)}
				)

	select_person = person => {
		// cimr_update
		
		if (person.kind == 's') {
			log('mkroom', 'select_person: staff', person);
			growl('Error', person.email + ' is a staff member of the org', 'error');
			return;
		}

		const clients = this.state.clients;
		clients.push({name: person.name, emails: [person.email],
				tel: person.tel,
				csel:'', xid: gen_xid(), pids: [person.id] });
		person.kind = 'c';

		if (!this.state.user_set_room_name)
			this.set_room_name(clients);

		this.draft.clients = [...clients];
		this.setState({clients});
	}

	manual_add = () => {
		let {client_name, client_email, client_tel} = this.state;
		const email = clean_email(client_email);
		const person = email2person(email, client_name);
		person.tel = client_tel;
		this.select_person(person);
		this.setState({client_name: '', client_email: '',
			client_tel:''});
	}

	add_person = pid => {
		const person = pid2person(pid);
		this.select_person(person);
		log('mkroom', 'add_person', person);
	}

	add_pid = e => {
		const pid = e.currentTarget.dataset.pid;
		log('mkroom', 'add_pid', pid);
		this.add_person( pid );
	}

	add_group = e => {
		const clusters = ldb.data.contacts.clusters;
		const index = e.currentTarget.dataset.index;
		const cluster = clusters[index];
		log('mkroom', 'add_group', cluster);
		cluster.forEach( pid => {
			const person = pid2person(pid);
			if (person.kind == '')
				this.select_person(person);
		});
	}

	get_contacts = () => {
		const args = {cmd: 'get_contacts'};
		api( args, this.got_contacts )
		this.setState({working: true});
	}

	got_contacts = () => {
		log('mkroom', '+++got_contacts');
		ldb.data.contacts._flags.fetched = true;
		set_cluster_search_buf();
		this.setState({working: false});
	}

	/*- Display -*/

	do_merge = item => {
		let {merge_xid, clients} = this.state;
		const selitem = clients.filter(sitem => 
					sitem.xid == merge_xid)[0];
		log('mkroom', 'merge', item, selitem);
		if (selitem.name && !item.name)
			item.name = selitem.name;
		if (selitem.tel && !item.tel)
			item.tel = selitem.tel;
		item.emails = [...selitem.emails, ...item.emails];
		item.pids = [...selitem.pids, ...item.pids];

		this.clear_one_contact(null, merge_xid);
		merge_xid = null;
		this.setState({merge_xid});
	}

	merge_xid = xid => {
		let {merge_xid} = this.state;
	}
	
	toggle_edit = item => {
		let {edit_xid} = this.state;

		edit_xid = (edit_xid == item.xid) ?  null: item.xid;
		item.new_name = item.name;
		item.new_tel = item.tel;
		this.setState({edit_xid});
	}

	mini_contact = (item, i) => {
		const {name, tel, emails, xid} = item;
		let {edit_xid, merge_xid} = this.state;
		const email = emails.join(', ');
		const editing = edit_xid == xid;
		const cname = item.name || email;
		let kls = 'pointer ';
		if (merge_xid && merge_xid != xid)
			kls += 'merge-dest';

		return edit_xid == xid ? 
			this.edit_contact(item, i) :
			<div className={kls} key={i}
				onClick={e=> {
					if (merge_xid && merge_xid != xid) 
						this.do_merge(item);
					else 
						this.toggle_edit(item);
				}}
			>
				{this.show_contact(item, i, true)}
			</div>;

	}

	show_email = item => {
		const {emails, xid} = item;

		return (
		<React.Fragment>
			<div className="p-col-1">
				<i className="fa fi-fw fa-at" 
					title="Email Address"></i>
			</div>
			<div className="p-col-11 edit-contact-email">
				{emails[0]}
			</div>
		</React.Fragment>
		)
	}

	edit_contact = (item, i) => {
		const {name, tel, emails, xid} = item;
		let {edit_xid} = this.state;
		const email = emails.join(', ');
		const editing = edit_xid == xid;
		const cname = item.name || email;

		return (
		<div className="create-edit-contact p-grid" key={i}>
			<div className="p-col-1">
				<i className="fa fi-fw fa-address-card-o"
					title="Name"
					></i>
			</div>
			<div className="p-col-11 wide-input">
			<InputText ref={lazyFocus}
				value={item.new_name} 
				placeholder="Client Name"
				   onChange={e => {
					item.new_name = e.target.value;
					this.refresh();
				   }}
					/>
			</div>

			{emails.length > 1 ? 
					<EditEmails item={item} /> :
					this.show_email(item)}

			<div className="p-col-1">
				<i className="fa fi-fw fa-phone"
					title="Tel (for SMS) (optional)"
				></i>
			</div>
			<div className="p-col-11 wide-input">
			<InputText 
				value={item.new_tel} 
				placeholder="eg. 425-555-1212 (optional)"
				   onChange={e => {
					item.new_tel = e.target.value;
					this.refresh();
				   }}
					/>
			</div>

			<div className="p-col-1">
			</div>
			<div className="p-col-5">
			<Button label="Save"
				icon="fa fa-fw fa-check" 
				onClick={e => {
					item.name = item.new_name;
					item.tel = item.new_tel;
					item.new_name = item.new_tel = '';
					edit_xid = null;
					this.setState({edit_xid});
				}}
			/>
			</div>
			<div className="p-col-6">
			<Button label="Cancel"
				icon="fa fa-fw fa-times" 
				className="p-button-text p-button-raised"
				onClick={e => {
					item.new_name = item.new_tel = '';
					edit_xid = null;
					this.setState({edit_xid});
				}}	
			/>
			</div>

		</div>
		);
	}


	show_contact = (item, i, toolbar) => {
		const {name, emails, xid} = item;
		let {merge_xid} = this.state;

		// When in Merge mode, hide toolbar for all other contacts
		//	but the merge source. Reason: users are clicking
		//	on toolbar links and merge is not happening.
		if (toolbar && merge_xid && merge_xid != xid)
			toolbar = false;
		
		return (
		<div className="mkroom-contact" key={i + xid} >
			   <div className="name" >
				{item.name}
			   </div>
		   {emails.map(email => <div className="email" key={email}>
		   		{email} </div>)}
		    {item.tel && <div className="tel"> {item.tel}</div>}
		    {toolbar == true && <div className="editable">
		    	<FakeLink onClick={e=>this.toggle_edit(item)}>
				<i className="fa fa-fw fa-edit"></i>
				Edit
			</FakeLink>
		    	<FakeLink data-xid={xid}
					onClick={this.clear_one_contact} >
				<i className="fa fa-fw fa-times"></i>
				Remove
			</FakeLink>
		    	<FakeLink data-xid={xid}
					onClick={e => {
						if (merge_xid == xid)
							merge_xid = null;
						else
							merge_xid = xid;
						this.setState({merge_xid});
					}} 
					>
				<i className="fa fa-fw fa-random"></i>
				Merge
			</FakeLink>
		    </div>}
		    {merge_xid == xid && <div className="merge-src">
		    	Click on another contact to merge this into.
			<div className="xsmall">
			(Used when a client has multiple email addresses.)
			</div>
			<FakeLink onClick={e=>this.setState({merge_xid:null})}>
				<i className="fa fa-fw fa-times"></i>
				Cancel
			</FakeLink>
				
		    </div>}
		</div> 
		);
	}


	show_person = (person, i, cls) => (
		<div className="contact" key={i || 0}>
		   <div className="name" >{person.name}</div>
		   <div className="email" >{person.email}</div>
		</div> 
	)

	// Copied to AddClient.js. Keep them in sync.
	client_user_manual = () => (
		    <div className="p-grid">
		      <div className="p-col-6 wide-input">
		  	<div className="p-inputgroup">
			  <span className="p-inputgroup-addon">
			    Name
			  </span>
			<InputText value={this.state.client_name} 
				ref={lazyFocus}
				placeholder="First Last"
	   onChange={ e => this.setState({client_name: e.target.value}) } 
		   	/>
			</div>
			<br/>
		  	<div className="p-inputgroup wide-input">
			  <span className="p-inputgroup-addon">
			    Email
			  </span>
			<InputText value={this.state.client_email} 
				placeholder="user@domain.com"
	   onChange={ e => this.setState({client_email: e.target.value}) } 
		   	/>
			</div>
			<br/>
		  	<div className="p-inputgroup wide-input">
			  <span className="p-inputgroup-addon">
			    Tel
			    {em()}
			  </span>
			<InputText value={this.state.client_tel} 
				placeholder="eg. 425-555-1212"
	   onChange={ e => this.setState({client_tel: e.target.value}) } 
		   	/>
			</div>
			<br/>
			<Button label="Add" onClick={this.manual_add} />
		      </div>
		    </div>
	)

	clear_one_contact = (e, xid) => {
		xid = xid || e.currentTarget.dataset.xid;
		const clients = [];
		this.state.clients.forEach(item => {
			if (item.xid != xid)
				clients.push(item);
			else {
				item.pids.forEach(pid => (
					pid2person(pid).kind = ''
				));
			}
		});
		this.draft.clients = [...clients];
		this.setState({clients});
	}

	clear_contacts = e => {
		let {clients} = this.state;
		clients.forEach(item => {
			item.pids.forEach(pid => (pid2person(pid).kind = ''));
		});
		clients = [];
		this.draft.clients = [...clients];
		this.setState({clients});
		this.set_room_name(clients);
	}

	show_selected_client_users = () => (
			<div className="p-grid contact-list">
		{this.state.clients.map(this.show_contact)}

		{this.state.selxid && <div>
			Click alternate email.
		</div>}
			</div>
	)

	ask_load_contacts = () => (
		<div>
			<p>
				Check just the headers of last 30 days of 
				emails or last 100 emails,
				and generate contact list to choose
				Client users for this room.
			</p>
			<Button
				onClick={this.get_contacts}
				label="Get Contacts"
				/>
			{this.state.working && <ProgressSpinner/>}
		</div>
	)

	search_bar = btns => <Toolbar style={{margin:'0 0 1em 0'}} 
		left={<React.Fragment>
		<i className="pi pi-search"></i>
			{' '}
			<InputText value={this.state.search} 
				placeholder="Search"
	   onChange={ e => this.setState({search: e.target.value }) } />
	   		<Button icon="pi pi-times"
		onClick={e => this.setState({search:''})}
				className="p-button-text p-button-raised"
				/>
			{' '}
			{btns && btns}
		</React.Fragment>}
		/>

	show_kind = person => {
		let name = '';
		if (person.kind == 'v')
			name = 'Ext Contact';
		else if (person.kind == 's')
			name = 'Staff';
		else if (person.kind == 'c')
			name = 'Client';
		if (!name)
			return null;
		return <div className={'person-kind person-' + person.kind}>
			{name}
		</div>;
	}

	is_person_available = person => {
		let icon, klass;
		if (person.kind) {
			icon = 'pi-user-minus';
			klass = 'person-na';
		}
		else {
			icon = 'pi-user-plus';
			klass = 'person-available';
		}

		return <div className={'p-col-1 ' + klass} >
			<i className={'pi pi-fw ' + icon}></i>
		</div>
	}

	show_pid = (pid, i) => {
		const person = pid2person(pid);
		return this.show_person(person, i, 'bline');
	}

	regen = e => {
		const args = {
			cmd: 'regen_clusters', 
		}
		api( args, this.regened, this.regened);
		this.setState({working:true});
	}

	regened = (error, db) => {
		set_cluster_search_buf();
		this.setState({working:false});
		if (!error)
			growl('Smart Groups Refreshed')
		
	}

	client_user_groups = () => {
		//if (Object.keys(this.state.contacts).length === 0) 
			//return this.ask_load_contacts();

		const clusters = ldb.data.contacts.clusters;
		const {search} = this.state;
		const exp = RegExp(search, 'i');

		const refresh = <Button icon="pi pi-refresh"
		tooltip="Regenerate Smart Groups to include recent emails"
				label="Refresh"
				onClick={this.regen}
				className="p-button-text p-button-raised"
				/>

		return <div>
			{this.search_bar(refresh)}
			{this.state.working && <ProgressSpinner/>}
		<ScrollPanel style={{height:'50vh'}} >
			<div className="p-grid contact-groups">
	   {clusters.map( (cluster, i) => {
			// log('mkroom', '++++show_cluster', i, cluster);
		   	const csbuf = ldb.data.contacts.cluster_search_bufs[i];
		   	if (search && !csbuf.match(exp))
				return null;
		return <a key={i} onClick={this.add_group} data-index={i} 
		     	className="p-col-4"
		     >
		       <Card className="cluster">
		       	<div style={{textAlign:'center', color:'#999'}}>
				Group {i}
			</div>
		         {cluster.map(this.show_pid)}
		       </Card>
		     </a>
	     })}
		   {clusters.length ? null : <Card>
		   	Please choose contacts individually 
			from the Contacts tab. <br/><br/>
			No more groupings derived from recent emails.
			</Card>
		    }
		       </div>
		</ScrollPanel>
			</div>

	}

	hide_choose_modal = e => {
		const {clients, user_set_room_name} = this.state;
		if (!user_set_room_name)
			this.set_room_name(clients);
		this.setState({choose_modal:false})
	}

	choose_clients = () => {
		const hdr = <span> 1. Choose Clients
			{em(2)}
			<i className="pi pi-fw pi-users"></i> 
		</span>;
		return <ModalCommand header={hdr}
			className="choose-clients"
			onHide={this.hide_choose_modal}
		>
	<div className="p-grid">
		<div className="p-col-3">
			{this.state.clients.map(this.mini_contact)}
			{this.state.edit_xid == null && <div>
				<Button label="Done" 
					icon="pi pi-fw pi-check"
					onClick={this.hide_choose_modal}
				/>
				{em(3)}
			{this.state.clients.length > 0 && 
			<Button label="Clear All" 
					icon="pi pi-fw pi-times"
					className="p-button-text p-button-raised"
				onClick={e =>  this.clear_contacts()}
				/>}
			</div>}
		</div>
		<div className="p-col-9">
		<TabView
			activeIndex={this.state.iTab}
			onTabChange={e => this.setState({iTab: e.index})} >
		  <TabPanel header="Smart Groups">
		  	{this.client_user_groups()}
		  </TabPanel>
		  <TabPanel header="Contacts">
			<FilteredPersons setPid={this.add_person} />
		  </TabPanel>
		  <TabPanel header="Manual">
		  	{this.client_user_manual()}
		  </TabPanel>
		</TabView>
		</div>
	</div>
		</ModalCommand>

	}

	client_user_input = () => {
		const {clients} = this.state;

		return (

	  <div className="card card-w-title">
		<h1>1. Clients <span className="fader">for this Room</span> <span className="create-count">
				({clients.length})</span> </h1>
	<div className="p-grid p-align-center">
		<div className="p-col-1">
		</div>
		<div className="p-col-5">
		<Button label="Choose" icon="pi pi-fw pi-users"
			onClick={e => this.setState({choose_modal:true})} />
		</div>
		<div className="p-col-6">
			{clients.length > 0 && 
			<Button label="Clear All" icon="pi pi-fw pi-times"
					className="p-button-text p-button-raised"
				onClick={e =>  this.clear_contacts()} 
			/>}
		</div>
		<div className="p-col-1">
		</div>
		<div className="p-col-11">
			{this.show_selected_client_users()}
		</div>
		{this.state.choose_modal && this.choose_clients()}
	 </div>
	  </div>
		)
	}

	/*---- 2. Room Name ----*/

	category_input = () => {
		let content = '';

		if (!this.state.categories) 
			return <div className="p-col-5"></div>;
			
		return (
		<React.Fragment>
		<div className="p-col-1 right small">
			Category:
		</div>
		<div className="p-col-4">
			<Dropdown 
			className="room-category-dropdown"
			value={this.state.category}
			options={this.state.categories}
			onChange={ e => this.setState({category: e.value})}
			placeholder="No Category"
			/>
		</div>
		</React.Fragment>
		)
	}

	room_name_input = () => (
	  <div className="card card-w-title">
		<h1>2. Room Name</h1>
		<div className="p-grid p-align-center">
			  <div className="p-col-1">
			  </div>
			  <div className="p-col-6 wide-input">
			<InputText value={this.state.room_name} 
				placeholder="eg. Mary and Bob Adams"
		   onChange={ e => this.setState({room_name: e.target.value,
		   		user_set_room_name: true}) } />
			  </div>
			 { this.category_input() }
		 </div>
	  </div>
	)

	/*---- 3. Staff Selection ----*/

	staff_input = () => {
		const {sids} = this.state;
		
		let source_options = [];
		let target_options = [];
		ldb.data.staffs._idlist.forEach( sid => {
			const s = get_staff(sid);
			if (sids.includes(s.id)) {
				target_options.push({'name': s.name, 'id': s.id, 'email': s.email});
			} else {
				source_options.push({'name': s.name, 'id': s.id, 'email': s.email});
			}
		});


		return (
	  <div className="card card-w-title create-room-staffs">
		<h1>3. Staff <span className="fader">for this Room</span> <span className="create-count">
				({sids.length})</span> </h1>
			  
		<SelectMultipleStaff sids={sids} update={sids => this.setState({sids})} />
	  </div>
		)
	}

	/*---- 4. Settings ----*/

	room_settings_input = () => {
		const {auto_move} = this.state;

		return (
	  <div className="card card-w-title">
		<h1>4. Room Settings</h1>
		<div className="p-grid p-align-center">
			  <div className="p-col-1">
			  </div>
			  <div className="p-col-6 wide-input">
			  Auto Share:
			  {em(1)}
			  <Checkbox
				onChange={e=> {	
					const new_auto_move = (!auto_move);
					this.setState({auto_move: new_auto_move});
				}}
				checked={auto_move}
			  />
			  {em(1)}
			  <AutoMoveHelp />
			  </div>
		 </div>
	  </div>
	)
	}

	/*---- 5. Submit ----*/

	check_errors = () => {
		const state = this.state;

		if (!state.room_name) {
			growl('Error', 'Please choose Room Name', 'error');
			return true;
		}

		if (state.sids.length === 0) {
			growl('Error', 'Please select at least one staff member for the room', 'error');
			return true;
		}

		return false;
	}

	empty_list_index = list => {
		let index = -1;
		list.forEach( (sublist, i) => {
			if (sublist.length === 0)
				index = i;
		});
		return index;
	}

	remove_client_contacts = (clients, rid) => {
		clients.forEach( item => {
			item.pids.forEach(pid => {
				const person = pid2person(item.pid);
				if (person) {
					person.kind = 'c';
					person.objid = rid;
				}
			});
		});
	}

	cli_xform = orig_clients => {
		const clients = deepcopy(orig_clients);
		clients.forEach(cli => {
			if (cli.email_kinds === undefined)
				cli.email_kinds = {};
			cli.emails = cli.emails.map(email => {
				let priority = cli.email_kinds[email];
				// priority could be 0. so check for undefined
				if (priority === undefined)	
					priority = 1;
				return { email, priority };
			});
			cli.tels = [{tel: cli.tel}];
			delete cli.tel;
			delete cli.email_kinds;
		});

		return clients;
	}

	create = () => {
		if (this.check_errors())
			return;

		const {room_name, sids, clients, category, auto_move} = this.state;
		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {
			cmd: 'create_room', 
			name: room_name,
			staff_ids: sids,
			clients: this.cli_xform(clients),
			category,
			auto_move,
			bid,
		}
		api( args, this.created, this.created);

		this.setState({mode:'creating', bid});

		window.g_progCircle.add(bid, 
			"Creating Room " + room_name, 1);

		// Clean up clusters
		set_cluster_search_buf();
	}

	created = (error, db) => {
		if (error) {
			this.setState({mode:'prep'});
			return;
		}
		//const rid = parseInt(Object.keys(db.rooms._items)[0], 10);
		//ridMRU.add(rid);

		// Can't use these clients again. Close
		//this.remove_client_contacts(this.state.clients, rid);
		this.draft.clients = [];
		//this.setState({created:true, rid});
		this.setState({mode:'created'});
		window.g_app.refresh();
	}

	room_ready = (bid, rid, room_name) => {
		log('mkroom', 'room_ready', bid, rid);
		if (bid == this.state.bid) {
			this.setState({mode:'ready', rid, room_name});
		}
	}

	submit_input = () => (
	  <div className="card card-w-title">
		<div className="p-grid">
		  <div className="p-col">
			<Button label="Create" icon="pi pi-plus" 
				onClick={this.create}
			/>
			{this.state.creating && <ProgressSpinner/>}
		  </div>
		  <div className="p-col">
			<Button label="Cancel" icon="pi pi-times"
				onClick={ e => (window.location = '/#/') }
				className="p-button-text p-button-raised" />
		  </div>
		</div>
	  </div>
	)

	reset = () => (this.setState(this.init_state()) && false)

	show_created = () => (
	  <div className="card card-w-title">
		<h1>Success</h1>
		<div className="ui-g-12">
		<p>
		Client room <strong>{this.state.room_name}</strong> is being created.
		You will be notified when this is complete.
		</p>

		<Button label="Create Another Room" 
			icon="fa fa-fw fa-folder-open-o"
			onClick={this.reset}
			className="p-button-text p-button-raised" />

		 </div>
	  </div>
	)

	show_ready = () => (
	  <div className="card card-w-title">
		<h1>Ready</h1>
		<div className="ui-g-12">
		Your room is ready.
		<br/>
		<br/>
		Visit <Link to={go_url('room', this.state.rid)}>
			{this.state.room_name}
		</Link>

		<br/><br/>

		<Button label="Create Another Room" 
			icon="fa fa-fw fa-folder-open-o"
			onClick={this.reset}
			className="p-button-text p-button-raised" />
		 </div>
	  </div>
	)

	render() {
		const {mode, working} = this.state;
		log('mkroom', 'render', {working, mode});

		if (mode == 'created')
			return this.show_created();
		else if (mode == 'ready')
			return this.show_ready();

	return <div className="ui-g ui-fluid create-room">
		{this.client_user_input()}
		{this.room_name_input()}
		{this.staff_input()}
		{this.room_settings_input()}
		{this.submit_input()}
	</div>
	}
}

export {CreateRoom, SelectStaff, LargeSelectStaff, SelectMultipleStaff};
