import React, {useRef} from 'react'; 
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactDOM from 'react-dom';
import {jsPanel} from 'jspanel4/es6module/jspanel';
import 'jspanel4/dist/jspanel.min.css';

import {ldb, log, growl, get_room, get_list, api, gen_xid, go,
	set_order, resort, get_current_list_view_comp, is_super_admin,
	mbox_read_perm, mbox_write_perm, room_has_team_email, em} from './Lib';

import {ModalNewEmail, NewEmail} from './NewEmail';
import {NewSms} from './NewSms';
import {AddTag} from './Tag';
import {CreateTask} from './CreateTask';
import ReadEmailTask from './ReadEmailTask';
import BaseEmails from './BaseEmails';
import MarkTagsNotNeeded from './MarkTagsNotNeeded';
import {MoveEmail} from './MoveEmail';
import BulkEmail from './BulkEmail';
import {get_raw_env_content} from './EmailUtil';
import CopyToOtherRoom from './CopyToOtherRoom';
import {make_email_window} from './MakeEmailWindow';
import {SummarizeEmail, SummarizeThread, ExtractData} from './AiFeatures';

import {ModalCommand, MoreMenu, Focusable, AreYouSure} from './Utils';



class SmsReplyWarning extends Focusable {
	constructor(props) {
		super(props);
		const {par} = this.props;
		
		this.state = {};
		
		window.g_smsReplyWarning = this;
	}
	
	render() {
	 	return (
		<ModalCommand par={this}
			className="modal-35-command"
			header="SMS Reply">
		
		<p>
			Currently you cannot reply directly to an SMS message. Compose a new SMS message and select this recipient instead.
		</p>

		</ModalCommand>
		)
	}
}



class SharedEmails extends BaseEmails {

	onMount = () => {
		// When we fetch Shared, we also need to fetch Tasks,
		// otherwise, can't show tasks linked to these emails.
		// Tasks however, already have email links, 
		// so  no need to fetch shared along with tasks.
		const {rid}  = this.props;
		if (get_room(rid).task === undefined) {
			this._pre_fetch_tab = 'task';
		}
		const list = this.get_item_list();
		if (list && list._flags.is_threaded === undefined)
			list._flags.is_threaded = true;
	}

	//---- Render
	
	update_search_results = (search_text, prev_search_text) => {
		const {tab, rid} = this.props;
		
		const args = {cmd: 'search_email', rid: rid, tab: tab, search_text: search_text};
		api(args, this.email_search_results_received, this.email_search_results_received);
	}

	email_search_results_received = (error, full_data) => {
		const data = full_data.action_results;
		if (data.log) {
			growl(data.log);
		}
		
		log('search', 'RECEIVED EMAIL SEARCH RESULTS', data);

		if (data.rid && data.tab) {
			const rid = data.rid;
			const tab = data.tab;
			const search_text = data.search_text;
			
			const current_list = get_list(rid, tab);
			const room = ldb.data.rooms._items[rid];
			
			current_list._flags._remote_search_order = room.email_search_results;
			this.setState({remote_search: search_text});
			
			this.refresh();
		}
	}

	toggle_is_threaded = () => {
		const list = this.get_item_list();
		list._flags.is_threaded = !list._flags.is_threaded;
		resort(list);
		set_order(list);
		this.refresh();
	}

	get_thread_ids = id => {
		const {iid} = this.props;
		// Only if ID is selected.
		if (id != iid)
			return [];
		
		const items = this.get_item_list();
		const env = items._items[id];
		let gotall = true;
		env.thread_eids.ancestor_eids.map(aeid => {
			const aitem = items._items[aeid];
			if (aitem === undefined)
				gotall = false;
		});
		if (!gotall)
			return [];

		return env.thread_eids.ancestor_eids;
	}

	undone_transfer = () => {
		this.setState({busy:false});
	}
	
	do_undo_transfer = () => {
		const {rid} = this.props;

		const item = this.get_current_item();
		if (item) {
			const eids = [item.id];

			// batch id is ignored for single undo-transfers

			const args = {cmd: 'undo_transfer_email', eids, 
						rid, bid:0};
			this.setState({busy: true});
			item._being_moved = true;
			api( args, this.undone_transfer );
		}
	}

	undo_transfer_multiple_command = () => {
		const {rid} = this.props;
		
		const eids = this.get_checked_ids();
		if (!eids.length)
			return growl("Please select some items");

		const bid = gen_xid();	// batch id, used in progress bar.
		const args = {cmd: 'undo_transfer_email', eids, rid, bid};
		this.setState({busy: true});
		api( args, this.undone_transfer );
		
		window.g_progCircle.add(bid, 
			"Undo Move to Common in Room: " + get_room(rid).name,
				eids.length);
	}

	undo_transfer = () => {
		this.select_command('undo_transfer');
	}

	bulk_email = () => {
		this.select_command('bulk_email');
	}

	copy_to_other_room = () => {
		this.select_command('copy_to_other_room');
	}
	
	summarize_email = () => {
		this.select_command('summarize_email');
	}

	summarize_thread = () => {
		this.select_command('summarize_thread');
	}

	extract_data = () => {
		this.select_command('extract_data');
	}
	
	delete_email = () => {
		const {rid} = this.props;
		
		const item = this.get_current_item();
		if (item) {
			const eids = [item.id];

			const api_args = {
				cmd: 'delete_shared_envs', 
				eids: eids,
				rid: rid,
			}
			api( api_args );
		}
	}

	deleted_multiple = () => {
		this.setState({busy:false});
	}

	delete_multiple_emails = () => {
		const {iid, rid} = this.props;
		const ids = this.get_checked_ids();
		if (!ids.length)
			return growl("Please select some items");
		
		const api_args = {
			cmd: 'delete_envs', 
			eids: ids,
			rid: rid,
		}
		this.setState({busy: true});
		api( api_args, this.deleted_multiple );
	}
	
	mark_tags_not_needed = () => {
		this.select_command('mark_tags_not_needed');
	}

	move_email = () => {
		this.select_command('move_email');
	}

	move_email_multiple = () => {
		const eids = this.get_checked_ids({'mark_as_moved': false});
		if (!eids.length)
			return growl("Please select some items");
		
		this.select_command('move_email_multiple');
	}

	no_items_text = () => {
		return <div>There are no shared emails</div>
	}

	toggle_bulk_mode = () => {
		const par = this.props.par;
		const new_value = par.state.shared_email_bulk_mode ? false : true;
		par.setState({shared_email_bulk_mode: new_value});
	}
	
	list_toolbar = () => {
		const {rid, iid} = this.props;
		const list = this.get_item_list();
		const page = list._flags.page;
		
		const room = get_room(rid);

		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		const has_team_email = room_has_team_email(room);
		const super_admin = is_super_admin();
		
		const items = [
			//{
			//	label: 'Get Older Emails',
			//	icon:"pi pi-fw pi-angle-double-left",
			//	command: this.fetch_older,
			//},
			//{
			//	label: 'Refresh',
			//	icon:"fa fa-fw fa-refresh",
			//	command: this.fetch,
			//},
			//{
			//	label: 'Toggle Thread',
			//	icon: list._flags.is_threaded ? 
			//		"pi pi-check" :
			//		"pi pi-times",
			//	command: this.toggle_is_threaded,
			//},
			{
				label: 'Tags Not Needed',
				icon: "pi pi-tag",
				command: this.mark_tags_not_needed,
			},
			{
				label: 'Toggle Bulk Mode',
				icon: "pi pi-check",
				command: this.toggle_bulk_mode,
			},
		      ];
		
		if (this.props.par.state.shared_email_bulk_mode && write_perm) {
			items.push({
				separator:true,
			});
			items.push({
				label: 'Move Emails',
				icon:"pi pi-directions",
				command: this.move_email_multiple,
			});
			items.push({
				label: 'Undo Transfer',
				icon: "fa fa-fw fa-share-square-o fa-rotate-180",
				command: this.undo_transfer_multiple_command,
			});
			if (ldb.data.org.settings.allow_shared_env_delete) {
				items.push({
					label: 'Delete Emails',
					icon: "fa fa-fw fa-times",
					command: this.delete_multiple_emails,
				});
			}
		}

		log('email', '****Toolbar.list');

	const left = (
	<React.Fragment>
		<i className="fa fa-fw fa-envelope-o"></i> 
		{' '}
		({page.total}+)
		{this.page_size(page)}
	</React.Fragment>
	);

	const right = (
	<React.Fragment>
		{(read_perm || ((!super_admin) && has_team_email)) && <Button label="SMS"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-commenting-o" 
			tooltip="New SMS"
			onClick={() => this.select_command('new_sms')}
		/>}
		{page.has_more && <Button label="More"
			className="p-button-raised p-button-text"
			icon="pi pi-fw pi-angle-double-down"
			tooltip="Show More Emails"
			onClick={this.fetch_page}
		/>}
		{(read_perm || ((!super_admin) && has_team_email)) && <Button label="New"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-pencil-square-o" 
			tooltip="New Email"
			onClick={() => go('room', rid, 'compose', 0)}
		/>}
		{ <MoreMenu model={items} /> }
	</React.Fragment>
	);
		return <Toolbar left={left} right={right} />;
	}

	detail_toolbar = () => {
		const {rid, iid} = this.props;
		
		const room = get_room(rid);

		const read_perm = mbox_read_perm();
		const write_perm = mbox_write_perm();
		const has_team_email = room_has_team_email(room);
		const super_admin = is_super_admin();
		
		const env = this.get_current_item();
		const fetched_body = ((env) && (env.body));
		const is_sms = ((env) && (env.is_sms));
		
		const items = [
		/*
			{
				label: 'Reply',
				icon:"fa fa-fw fa-reply",
		command: () => this.select_command('reply'),
			},
			{
				label: 'Request Read',
				icon:"fa fa-fw fa-bell-o",
		command: () => this.select_command('read_email_task'),
			},
			{
				label: 'Forward',
				icon:"fa fa-fw fa-share",
		command: () => this.select_command('forward'),
			},
		*/
			{
				label: 'Create Task',
				icon:"pi pi-plus-circle",
			command: () => this.select_command('create_task'),
			},
			{
				separator:true,

			},
			{
				label: 'Print Email',
			icon:"pi pi-print",
				command: () => this.setState({print:true}),
			},
			{
				separator:true,

			},
			{
				label: 'Move Email',
			icon:"pi pi-directions",
				command: this.move_email,
				className: write_perm ? '' : 'hide',
			},
			{
				separator:true,
			},
			{
				label: 'Undo Transfer',
			icon:"fa fa-fw fa-share-square-o fa-rotate-180",
				command: this.undo_transfer,
				className: write_perm ? '' : 'hide',
			},
		      ];
		
		if (ldb.data.org.settings.allow_shared_env_delete && write_perm) {
			items.push({
				separator:true,
			});
			items.push({
				label: 'Delete',
				icon: "fa fa-fw fa-times",
				command: this.delete_email,
			});
		}
		
		if (ldb.data.me.is_org_admin) {
			items.push({
				separator:true,
			});
			items.push({
				label: 'Bulk Email',
				icon:"fa fa-fw fa-files-o",
				command: this.bulk_email,
			});
		}

		items.push({
			separator:true,
		});
		items.push({
			label: 'Copy To Room',
			icon: "fa fa-fw fa-files-o",
			command: this.copy_to_other_room,
		});
		
		if (ldb.data.org.use_ai_features && ldb.data.me.settings.show_ai_features) {
			items.push({
				separator:true,
			});
			items.push({
				label: 'Summarize Email',
				icon: "fa fa-fw fa-magic",
				command: this.summarize_email,
			});
			//items.push({
			//	label: 'Summarize Thread',
			//	icon: "fa fa-fw fa-magic",
			//	command: this.summarize_thread,
			//});
			items.push({
				label: 'Action Items',
				icon: "fa fa-fw fa-list",
				command: this.extract_data,
			});
		}
		
		log('email', '****Toolbar.detail');
		      
		// {command == 'tag' && <AddTag env={env} rid={rid} 
					// command={command} par={this} />}
	const left = (
	<React.Fragment>
		<i className="detail-pane-icon fa fa-fw fa-envelope-open-o"></i> 
		{' '}
		<strong><span className="toolbar-label"></span></strong>
	</React.Fragment>
	);
	
	const right = (iid == 0) ? null : (
	<React.Fragment>
		{(read_perm || ((!super_admin) && has_team_email)) && <Button label="Reply"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-reply"
			tooltip="Reply"
			onClick={() => {if (is_sms) {this.select_command('sms_reply')} else {go('room', rid, 'compose', iid)}}}
			disabled={(!fetched_body)}
		/>}
		{(read_perm || ((!super_admin) && has_team_email)) && <Button label="Reply All"
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-reply-all"
			tooltip="Reply All"
			onClick={() => {if (is_sms) {this.select_command('sms_reply')} else {go('room', rid, 'compose', iid, 'reply_all')}}}
			disabled={(!fetched_body)}
		/>}
		<Button label="Tag"
			className="p-button-raised p-button-text"
			icon="pi pi-fw pi-tag"
			tooltip="Add a Tag"
			onClick={() => this.select_command('tag')}
		/>
		{(read_perm || ((!super_admin) && has_team_email)) && <Button label=""
			className="p-button-raised p-button-text"
			icon="fa fa-fw fa-share"
			tooltip="Forward"
			onClick={() => go('room', rid, 'compose', iid, 
				'forward')}
			disabled={(!fetched_body)}
		/>}
		<Button label=""
			className="p-button-raised p-button-text"
			icon="pi pi-fw pi-clone"
			tooltip="Launch Window"
			onClick={() => this.mkwin()}
		/>
		&nbsp;

		{<MoreMenu model={items} />}
	</React.Fragment>
	);

		return <Toolbar left={left} right={right} />;
	}

	list_legend = (list) => {
		return (
		<div className="p-grid">
		    <div className="p-col-2 checkbox-col">
			{this.props.par.state.shared_email_bulk_mode && <Checkbox checked={list._flags.is_check_all} 
				onChange={e => this.check_all(e.checked)}
			/>}
		    </div>
		    <div className="p-col-10 list-search">
		    	{this.search_bar()}
			{em(1.5)}
			<i className="pi pi-fw pi-tag pointer" title="Show Tagged Emails"
			onClick={() => this.setSearchText('tagged')}></i>
			{em(0.5)}
			<i className="pi pi-fw pi-exclamation-triangle pointer" title="Show Untagged Emails"
			onClick={() => this.setSearchText('untagged')}></i>
		     </div>
		</div>
		)
	}

	create_task_command = args => <CreateTask {...args} />
	read_email_task_command = args => <ReadEmailTask {...args} />

	new_email_command = args => <NewEmail {...args} title="New Email"
					is_reply={false} />
	reply_command = args => <NewEmail {...args} title="Reply"
					is_reply={true} />
	forward_command = args => <NewEmail {...args} title="Forward"
					is_reply={true} is_forward={true} />
	new_sms_command = args => <NewSms {...args} title="New SMS"
					is_reply={false} />
	sms_reply_command = args => <SmsReplyWarning {...args} par={this} />
	tag_command = args => <AddTag {...args} par={this} />
	mark_tags_not_needed_command = args => <MarkTagsNotNeeded {...args} par={this} />
	move_email_command = args => <MoveEmail {...args} par={this} bulk_move={false} />
	move_email_multiple_command = args => <MoveEmail {...args} par={this} bulk_move={true} />
	bulk_email_command = args => <BulkEmail {...args} par={this} />
	copy_to_other_room_command = args => <CopyToOtherRoom {...args} par={this} />
	summarize_email_command = args => <SummarizeEmail {...args} par={this} />
	summarize_thread_command = args => <SummarizeThread {...args} par={this} />
	extract_data_command = args => <ExtractData {...args} par={this} />
	
	undo_transfer_command = () => <AreYouSure 
		close={e => this.select_command('')}
		callback={this.do_undo_transfer}
		message={<div>
			This action will remove this email from Shared Emails 
			in this room.
			<br/> 
			It will also send it back to Pending 
			for all the original recipients
		</div>}
		/>
		
	mkwin = () => {
		const {rid, iid} = this.props;
		
		const room = get_room(rid);
		
		const env = this.get_current_item();
		if (!env)
			return;

		const root = ReactDOM.findDOMNode(this);
		const detail = root.querySelector('.item-detail');
		const dwrap = detail.parentNode;
		let html = dwrap.innerHTML;

		let url = window.location;
		
		const raw_html = get_raw_env_content(env);

		make_email_window(env.subject, html, room, url, raw_html);
	}
}


export default SharedEmails;
