import React, { Component } from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {ScrollPanel} from 'primereact/scrollpanel';
import {ProgressSpinner} from 'primereact/progressspinner';

import {Focusable, Spacer, lazyFocus, HelpInfo, SanitizeHTML} from './Utils';
import {ldb, log, api, go, edate, edate3, map_list, go_url, regcomp,
	get_first_name, show_is_new, get_room, get_staff,
	set_order, resort, em, get_room_activity,
	remove_flag, server_remove_flag, show_new_flag,
	show_new_general_activity_flag, remove_general_activity_flag,
	get_room_staff_by_name_fragment,
	} from './Lib';

//------------------ Chat  ------------------------


class NewChatFlag extends Component {
	constructor() {
		super();
		this.state = {has_new:false, nrefresh:0};
		window.g_newChatFlag = this;
	}

	refresh = () => {
		this.setState({nrefresh: this.state.nrefresh + 1});
	}
	render() {
		return <span>
			{show_is_new(this.state.has_new, 'New Chat')}
		</span>
	}
}

function caught_up(rid) {
	if (rid) {
		const room_activity = get_room_activity(get_room(rid), 'chat');
		if (room_activity) {
			room_activity._flags.date_zero = Date.now();
		}
	}
}

class ChatWrite extends Focusable {
	constructor(props) {
		super(props);
		const room = get_room(props.rid);
		const to_sid = room.chat._flags._to_sid || 0;
		this.state = {note:'', working:false, to_sid};
		regcomp(this, 'chatWrite');
	}
	onChange = e => this.setState({note: e.target.value})
	
	check_for_at = (note) => {
		// Check for @[Name] at the beginning of a message to alert
		// that staff member
		const match = note.match(/@(\S+)\s(.*)/);
		if (!match)
			return null;
		
		const fragment = match[1];
		const room = get_room(this.props.rid);
		
		const found_staff = get_room_staff_by_name_fragment(room, fragment);
		
		return found_staff;
	}

	send = () => {
		let {note, to_sid} = this.state;
		const {rid} = this.props;
		if (!note)
			return;

		const found_staff = this.check_for_at(note);
		if (found_staff)
			to_sid = found_staff.id;
		
		log('chat', 'send', this.state.note);
		const args = {cmd: 'add_chat_note', note, rid, to_sid};
		api( args, this.sent );
		this.setState({working:true});
	}
	sent = () => {
		const note = '', working=false;
		this.setState({note, working});
		caught_up(this.props.rid);
	}
	keyDown = e => {
		if (e.key == 'Enter') {
			this.send();
			e.preventDefault();
		}
	}

	render() {
		const {rid, mobile} = this.props;
		if (mobile) 
			return (
	<div className="p-grid">
		<div className="p-col-2 chat-by">
			Me:
		</div>
		<div className="p-col-8 wide-input">
		<InputText
			className="mobile-chat-input"
			ref={inp => (this._focusEle = inp)}
			value={this.state.note} 
			placeholder=""
			onChange={this.onChange}
			onKeyDown={this.keyDown}
			/>
		</div>
		<div className="p-col-2">
			{em(1)}
		</div>
	</div>
			);

		const room = get_room(rid);
		const staffs = room.staffs;
		const to_choices = map_list(staffs, staff => (
			{value: staff.id, label: get_first_name(staff.name)}));
		to_choices.push({value: 0, label: 'All'});

		return <div className="p-grid">
		{false && this.state.working && <ProgressSpinner />}
		<div className="p-col-1">
			{mobile && <span className="chat-by">Me</span>}
		</div>
		<div className="p-col-8">
		<InputTextarea
			id="chat-input"
			className="chat-input"
			ref={inp => (this._focusEle = inp)}
			rows={1}
			autoResize={true}
			value={this.state.note} 
			placeholder=""
			onChange={this.onChange}
			onKeyDown={this.keyDown}
			/>
		</div>
		<div className="p-col-1">
			<Button 
				className="p-button"
				label="Send"
				onClick={ e => {
					this.send();
					lazyFocus(this._focusEle);
				}}
			/>
		</div>
		<div className="p-col-2 chat-to-div">
			<span className="chat-to-label">
				Alert:
			</span>
			<Dropdown value={this.state.to_sid}
				options={to_choices}
				onChange={ e => {
					this.setState({ to_sid: e.value }) 
					room.chat._flags._to_sid = e.value;
					lazyFocus(this._focusEle);
				}}
			/>
			{em(1)}
			<HelpInfo header="Chat Alerts">
			<div>
			<p>
			All messages sent here are visible to all staff,
			and all staff will receive a chat notification.
			The selected staff member(s) will receive a higher-priority
			alert that there is a new chat message for them.
			</p>
			</div>
			</HelpInfo>
		</div>
		</div>;
	}
}


function staff_name(sid) { 
	if (!sid)
		return <span className="chat-to-all"
			title="all staff in this room"
		>
			All
		</span>;

	const staff = get_staff(sid);
	let fname = staff.name.split(' ')[0];
	if (sid == ldb.data.me.id)
		fname = 'Me';
	return <span title={staff.name}>
		{fname}
	</span>;
}

function wrap_anchor_tags_around_urls(text) {
	const urlRegex = /[^"\/]((www\.|https?:\/\/)[^\s\<]+)/g;
	const txt = text.replace(urlRegex, (url) => {
		let starter = url.charAt(0);
		url = url.slice(1);
		let link_url = url;
		if (!url.startsWith('http')) {
			link_url = 'http://' + url;
		}
		return starter + '<a href="' + link_url + '" target="_blank">' + url + '</a>';
	});
	return txt;
}

function ChatMessage({entry, mobile, date_zero}) {
	let is_new = false;
	if (!mobile && date_zero && (Date.parse(entry.dt_added) > date_zero))
		is_new = true;

	let sklass = mobile ? 'p-col-2' : 'p-col-1';
	sklass += ' chat-by ';

	if (is_new)
		sklass += ' chat-new';

	let nklass = mobile ? 'p-col-8' : 'p-col-9';
	nklass += ' chat-note';

	//const note = wrap_anchor_tags_around_urls(entry.note);
	const note = entry.note;

	return (
	<div className={"p-grid " + 
		(entry.to_sid ? 'chat-private' : '')}
			key={entry.id} >
		<div className={sklass} >
			{staff_name(entry.by_sid)}:
		</div>
		<div className={nklass} >
			 <SanitizeHTML html={note} />
		</div>
		<div className="p-col-2 chat-date">
			{edate3(entry.dt_added)}
			{show_is_new(is_new)}
		</div>
	</div>
	);
}

class Chat extends Component {
	constructor(props) {
		super(props);
		const rid = props.rid || 0;
		this.state = {working:false, nrefresh:0, rid};
		regcomp(this, 'chat');
	}

	refresh = () => {
		this.setState({nrefresh: this.state.nrefresh + 1});
	}

	init_data = rid => {
		const chat = rid ? get_room(rid).chat : ldb.data.chat;
		const flags = chat._flags;
		if (flags._new) {
			flags._new = false;
			this.get_chat_history();
		}
	}

	scroll_down_chat = () => {
		const ele = document.getElementById('chat-history');
		if (ele)		
			ele.scrollIntoView(false);
	}

	componentDidMount() {
		this._isMounted = true;
		this.init_data( this.state.rid );

		this.scroll_down_chat();
		// log('chat', 'mount');
	}

	componentDidUpdate() {
		const {rid} = this.props;
		if (rid != this.state.rid) {
			this.setState({rid});
		}
		this.init_data( rid );

		this.scroll_down_chat();
		// window.g_newChatFlag.setState({has_new:false});

		let room = null;
		if (rid) {
			room = get_room(rid);
		}
		
		const tab = 'chat';
		
		let has_new_activity = false;
		if (rid) {
			has_new_activity = show_new_flag(room, tab);
			remove_flag(room, tab);
		} else {
			has_new_activity = show_new_general_activity_flag(tab);
			remove_general_activity_flag(tab);
		}
		
		if (has_new_activity)
			server_remove_flag(room, tab);
		
		if (room._flags._tabs[tab])
			this.clear_activity();

		caught_up(rid);
		// log('chat', 'update');

	}

	componentWillUnmount() {
		this._isMounted = false;
		// log('chat', 'unmount');
	}
	
	get_chat_history = () => {
		const {rid} = this.state;
		const args = {cmd: 'get_chat_history', rid};
		api( args, this.got_chat_history );
		this.setState({working: true});
	}

	sort_by_id = (a,b) => a-b

	got_chat_history = () => {
		// skip_post_proc.. so order will be empty
		// const root = this.log_root();
		// root._order = root._idlist.sort(this.sort_by_id).reverse();
		log('chat', 'Got history');
		this.setState({working: false});
	}


	clear_activity = () => {
		const {rid} = this.state;
		
		const args = {cmd: 'mod_room',
				op: 'clear_room_activity', 
				rid: rid, 
				val: 'chat'};
		api( args );
		
		log('chat', 'clear_activity', args);
	}

	show_chat = () => {
		const {rid} = this.state;
		const room = rid ? get_room(rid) : null;
		const chat_room_activity = get_room_activity(room, 'chat');
		const date_zero_flag = chat_room_activity ? chat_room_activity._flags.date_zero : 0;
		const date_zero = rid ? date_zero_flag : 0;
		
		let chat = room ? room.chat : ldb.data.chat;
		chat._flags.reverse = true;
		resort(chat);

		return <div id="chat-history">
		  {map_list(chat, entry => <ChatMessage key={entry.id}
				entry={entry} date_zero={date_zero} /> )}
		</div>;
	}

	show_online = (staff, i) => <span key={i}
		className={'chat-member-' + (staff._online ? 'on' : 'off')}
			title={staff.name + (staff._online ? ' (online)' : '')}
			>
				{get_first_name(staff.name)}
			</span>
	
	show_members = rid => {
		const staffs = get_room(rid).staffs;

		return (
		<ScrollPanel className="chat-members">
			<div className="p-grid" >
				<div className="p-col-1">
					From
				</div>
				<div className="p-col-9">
			<span className="chat-member-legend">
				 All = 
			</span>
			{map_list(staffs, this.show_online)}
				</div>
				<div className="p-col-2 chat-date">
					Date
				</div>
			</div>
		</ScrollPanel>
		);
	}

	render() {
		const {rid} = this.state;
		const klass = rid ? 'room-chat' : 'org-chat';

		
		return (
<div className={klass}>
	{this.state.working && <ProgressSpinner />}
	{this.show_members(rid)}
	<div className="chat-history">
		{this.show_chat()}
	</div>
	<div className="chat-write">
		<ChatWrite rid={rid} />
	</div>
</div>
		)
	}
}

export {Chat, ChatMessage, ChatWrite, NewChatFlag};
