import React, {Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Accordion, AccordionTab} from 'primereact/accordion';
import mime from 'mime-types'
import sanitizeHtml from 'sanitize-html';
import JSZip from 'jszip';
import ical from 'ical';

// import {decode} from 'base64-arraybuffer-es6';
// import {decode} from 'base64-arraybuffer';
import {Base64} from 'js-base64';
import * as qp from 'quoted-printable'
// import {decodeWords} from 'libmime'
import {mimeWordsDecode} from 'emailjs-mime-codec';

import {OtherHomeRooms} from './OtherHomeRooms';
import {LinkedTaskHeader} from './TaskUtil';
import {AddTag, ShowTags } from './Tag';
import {SanitizeHTML} from './Utils';

import {TaskSelectStaff, NudgeSubjectSelect} from './EditTask';

import {ldb, log, gen_email_summary, get_room, edate, regcomp,
	cap, go_url, show_is_new, get_body_part, api, get_task, get_item,
	get_body_parts_all, text2html,
	unset_priority, pri_v2n, get_file_icon, display_readable_bytes,
	myatob, pint, has_attachments, is_inline_attachment, env_tags,
	display_linked_images_for_env, set_display_linked_images_flag,
	enable_links_for_env, set_enable_links_flag, growl, is_object,
	} from './Lib';

import {env_name_spans, } from './Persons';

window.g_b64 = Base64;

function is_uue(enc) {
	return (enc == 'x-uue' || enc == 'x-uuencode');
}

function uud(buf) {
	const lines = buf.split('\n');
	let decodingError = false;
	let work = false;
	let decoded = [];

	for (let i=0; i<lines.length; i++) {
		let line = lines[i];
		// log('email', 'uud.line1', line);
		if (line.indexOf('begin') >= 0) {
			work = true;
			continue;
		}
		else if (line.indexOf('end') >= 0) {
			work = false;
			// log('email', 'uud.END', line);
			break;
		}
		else if (line.indexOf('`') == 0) {
			// log('email', 'uud.empty', line);
			continue;
		}

		if (!work)
			continue;

		const lineUUE = line;
		// log('email', 'uud.line2', line);

         var byteLength = (lineUUE.charCodeAt(0) - 32) % 64;
         if( byteLength === 0 ) {
		// log('email', 'uud.Empty', line);
		decoded.push(new Buffer(0));
	}

         var charLength = ( (byteLength / 3) |0 ) * 4;
         if( byteLength % 3 !== 0 ) charLength += 4;
         if( 1 + charLength > lineUUE.length ){
		// log('email', 'uud.ERROR', line);
		decoded.push(null);
         }
         var targetBuffer = new Buffer(byteLength);

         var step, total;
         var stringOffset = 1;
         var bufferOffset = 0;
         for( step = 0; step < ( (charLength / 4) |0 ); step++ ){
            total = 0;

            total += ((lineUUE.charCodeAt(stringOffset) - 32) % 64) << 18;
            stringOffset++;
            total += ((lineUUE.charCodeAt(stringOffset) - 32) % 64) << 12;
            stringOffset++;
            total += ((lineUUE.charCodeAt(stringOffset) - 32) % 64) << 6;
            stringOffset++;
            total +=  (lineUUE.charCodeAt(stringOffset) - 32) % 64;
            stringOffset++;

            targetBuffer.writeUInt8( total >>> 16, bufferOffset );
            bufferOffset++;
            if (bufferOffset >= byteLength) break;
            targetBuffer.writeUInt8( (total >>> 8) & 0xFF, bufferOffset );
            bufferOffset++;
            if (bufferOffset >= byteLength) break;
            targetBuffer.writeUInt8( total & 0xFF, bufferOffset );
            bufferOffset++;
            if (bufferOffset >= byteLength) break;
         }
		// log('email', 'uud.bin', targetBuffer);
         decoded.push(targetBuffer);
      }
	// log('email', 'uud.decoded', decoded);

      // now `decoded` is a valid array containing buffers,
      // because `null` could appear only in `decodingError` state
      return Buffer.concat(decoded);
}

function atch_dec(fname) {
	// Sometimes Attachment names are encoded in MIME Words like this:
	//	=?UTF-8?b?UGF5....g==?=
	// Use this to decode.
	if (!fname)
		return fname;

	const name = fname ? mimeWordsDecode(fname) : fname;
	// log('email', 'Attachment File Name Decode', fname, name);

	return name;
}

class EmailHeader extends Component {
	// 4 properties:
	//	email
	//	selected (true or false)
	//	onSelect (callback)  
	//	cls : additional class names
	show_names = (env, kind, full, rid) => {
		const names = env_name_spans(env, kind, full, rid);
		if (!names && (kind == 'cc' || kind == 'bcc'))
			return null;

		return (
		<span>
			<b>{cap(kind) + ': '}</b> 
			<span> {names} </span>
		</span>
		)
	}

	show_tag = env => {
		if (env.is_untagged)
			return <i 
		  className="pi pi-fw pi-exclamation-triangle notag"></i>;
		const mid = ldb.data.me.id;
		let pri = unset_priority;	

		env_tags(env).map(tag => {
			if (!tag.dt_done && tag.to_sid == mid && 
						tag.priority < pri)
				pri = tag.priority;
		});
		if (pri == unset_priority)
			return null;

		const kpri = ' tag-' + pri_v2n(pri);

		return <i className={"pi pi-fw pi-tag env-tag" + kpri}></i>;
	}

	icon_attach = env => {
		if (!env.rid)
			return null;	// only show for common, not pending

		if ((!env.attachmentinfo) || (env.attachmentinfo == {}))
			return null;

		if (env.attachmentinfo.length == 0)
			return null;
		
		return <i className="fa fa-fw fa-paperclip"></i>;
	}

	list_attachments = env => {
		if (!env.rid)
			return null;	// only show for common, not pending

		if ((!env.attachmentinfo) || (env.attachmentinfo == {}))
			return null;

		return <div className="env-attachment-list">
			{Array.from(env.attachmentinfo).map((attachment,i) => {
				const fname = atch_dec(attachment.file_name);
				return (
				<span key={i} className="env-attachment" 
				title={display_readable_bytes(attachment.size, 0, false, false)}
					>
					{get_file_icon(fname)} {fname}
				</span>
				);
			})}
		</div>
	}

	show_folder = env => {
		if (env.rid)
			return null;	// only show for pending, not common

		const folder = ldb.data.folders._items[ env.fid ];
		if (!folder)
			return null;

		if (folder.name.toLowerCase() == 'inbox')
			return null;

		return <div className="folder-name to-cc">
			<b>Folder:</b> {folder.name}
		</div>
	}

	is_triggered = (env, rid) => {
		const trigs = JSON.parse(env.triggers_json);
		const yesno = rid in trigs;
		// log('email', 'Triggers', yesno, env, rid, trigs);
		if (!yesno)
			return null;
		return <i className="fa fa-fw fa-bullseye"
			title="Matched room triggers"
			>
		</i>;
	}

	other_home_rooms = (env, rid) => {
		if (env.is_common)
			return null;
		
		return <OtherHomeRooms env={env} rid={rid} />;
	}
	
	is_sms = (env) => {
		if (!env.is_sms)
			return null;
		return <i className="fa fa-fw fa-commenting-o is-sms-icon"
			title="SMS message"
			>
		</i>;
	}

	is_sent_from_system = (env) => {
		return null;
		
		if ((!env.is_sent_from_system) || env.is_being_sent)
			return null;
		return <i className="fa fa-fw fa-pencil-square-o"
			title="Sent From TagInbox"
			>
		</i>;
	}

	is_private = (env) => {
		if (!env.is_private)
			return null;

		return <i className="fa fa-fw fa-lock"
			title="Private Email"
			>
		</i>;
	}

	show_move_error = (env) => {
		env._show_move_error = true;
	}

	hide_move_error = (env) => {
		env._show_move_error = false;
	}

	start_displaying_linked_images = (env) => {
		set_display_linked_images_flag(env, true);
		this.props.par.forceUpdate();
	}
	
	start_enabling_links = (env) => {
		set_enable_links_flag(env, true);
		this.props.par.forceUpdate();
	}

	show_images_links = (env,full) => {
		if (!full)
			return null;

		const body = env.body;
		if (!body)
			return null;

		let has_images = false;
		let has_links = false;

		body.forEach(item => {
			if (item.content_type == 'text/calendar')
				has_links = true;

			if (item.content_type != 'text/html' ||
					!item.data)
				return;
			
			const data = wrap_urls(item.data.toLowerCase());
			
			if (data.includes('<a'))
				has_links = true;

			if (data.includes('<img'))
				has_images = true;
		});

		if (!has_images && !has_links)
			return null;

		return <div className="p-grid">
		<div className="p-col-6">
		</div>

		<div className="p-col-6 display-attached-images-div">
		{has_images && (!display_linked_images_for_env(env)) &&
			<Button label="Show Images"
				className="p-button-secondary enable-links-button"
				tooltip="Externally linked images in emails are hidden by default for safety"
				tooltipOptions={{position: 'left'}}
				onClick={() => this.start_displaying_linked_images(env)}
			/>
		}
		{has_links && (!enable_links_for_env(env)) &&
			<Button label="Enable Links"
				className="p-button-secondary enable-links-button"
				tooltip="Links in emails are disabled by default for safety"
				tooltipOptions={{position: 'left'}}
				onClick={() => this.start_enabling_links(env)}
			/>
		}
		</div>
		</div>
	}
	
	empty = () => {}

	render() { 
		window.g_emailHeader = this;

		const {env, is_new, cls, noindent, full, rid} = this.props;
		const body = env.body;
		const se_error = get_se_error(env);
		
		if (env.is_common && env.pinfo === undefined)
			env.pinfo = JSON.parse(env.pinfo_json);

		if (env.is_common && env.attachmentinfo === undefined)
			env.attachmentinfo = JSON.parse(env.attachmentinfo_json);

		let sel = cls || '';	// if cls is undefined
		if (this.props.selected)
			sel += ' selected';

		// if (env.has_thread_child)
		//	sel += ' subthread';
		//  		skip if being shown in body.
		if (!noindent && env.tsindent) {
			// stop nesting after 10 levels;
			if (env.tsindent < 10)
				sel += ' nest-' + env.tsindent;
			else
				sel += ' nest-9';
		}

		let onSelect = this.props.onSelect || this.empty;
		
		if (env._being_moved) {
			sel = 'being-moved';
			onSelect = this.empty;
		} else if (env.is_being_sent) {
			sel = 'being-sent';
		}
		if ((se_error) || (env._move_error)) {
			sel += ' se_error';
		}
		
		// log('email', 'EmailHeader', env, rid);

		return (
		<div key={env.id} data-email_id={env.id}
			onClick={onSelect} 
			className={sel + " summary"} >
<div>
	{env._being_moved && <div className="legend">
			<i className="fa fa-fw fa-share-square-o"></i>
		...   
		Being moved...
	</div>}
	{env.is_being_sent && !se_error && <div className="legend">
			<i className="fa fa-fw fa-share-square-o"></i>
		...   
		Being sent...
	</div>}
	{se_error && <div className="legend">
			<i className="fa fa-fw fa-warning"></i>
		{se_error}
	</div>}
	{(false) && (!env.saved_sent_copy) && (!env.is_being_sent) && <div className="legend">
			<i className="fa fa-fw fa-share-square-o"></i>   
		(We were unable to save a copy of this email in your sent folder)
	</div>}		
	{(env._move_error) && <div className="legend">
			<i className="fa fa-fw fa-warning"></i>
		There was an error sharing this email. Retry?
		
		{false && (!env._show_move_error) && <div>
			<span onClick={function(e) {alert('a');}}>
			Display Full Error
			</span>
		</div>}
		
		{false && (env._show_move_error) && <div>
			Error: <br/>
			{env._move_error}
		</div>}
	</div>}
	<div className="p-grid from-date">
		<div className="p-col-6 from">
			{env_name_spans(env, 'from', full, rid)}
		</div>
		<div className="p-col-6 date">
			{full && this.other_home_rooms(env, rid)}
			{this.is_triggered(env, rid)}
			{show_is_new(is_new)}
			{this.is_sms(env)}
			{this.is_sent_from_system(env)}
			{this.show_tag(env)}
			{this.is_private(env)}
			{this.icon_attach(env)}
			{edate(env.dt_sent)}
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-12 to-cc">
			{this.show_names(env, 'to', full, rid)}
			{this.show_names(env, 'cc', full, rid)}
			{this.show_names(env, 'bcc', full, rid)}
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-12 subject">
			{env.subject}
		</div>
	</div>
	{this.show_images_links(env, full)}
	{this.list_attachments(env)}
	{this.show_folder(env)}
</div>
		</div>
		)
	}
}

const EmailTasks = props => {
	const {rid, task_ids} = props;
	const tids = task_ids.split(',').map(pint);

	return <div className="linked-tasks">
	{tids.map(tid => {
	if (!tid)
		return '';
	
	return <Link to={go_url('room', rid, 'task', tid)} key={tid} >
		Task #{tid}
		{' '}
	</Link>
	})}

	: Linked Tasks

	</div>
}

function get_cid(src) {
	// Example: <img id="Picture_x0020_7" src="cid:image001.jpg@01D6.4D0">
	if (src && src.slice(0,4) == 'cid:')
		return '<' + src.slice(4) + '>';
	return null;
}

function rm_nl(b64) {
	return b64.replace(/[\r\n]/, ' ');
}

function gen_cid_url(item) {
	const enc = item.encoding.toLowerCase();

	if (false && is_uue(enc)) {
		/*
		TBD: 1. Guess content type based on file name.
		2. uudecode and re-encode with Base64.
		3. Below is not working yet.
		const url = 'data:image/png;base64, ' +
			Base64.encode(uud(item.data));
		return url;
		*/
		return '';
	}
	
	if (enc == 'quoted-printable') {
		try {
			let alt_data = qp.decode(item.data);
			alt_data = Base64.btoa(alt_data);
			item.data = alt_data;
			item.encoding = 'base64';
		} catch (e) {
		}
	}

	const url = 'data:' + 
		item.content_type + ';' + 
		item.encoding + ', ' + 
		item.data;

	return url;
}

function map_inline_attachments(body, display_linked_images, enable_links) {
	// return options dictionary to be used in Sanitize HTML.
	let count = 0;
	let cids = {};	// content ids.

	body.forEach(item => {
		if (item.is_inline || item.cid) {
			count++;
			cids[item.cid] = item;
		}
	});

	let allowedTags = [ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'ul', 'ol',
	  'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'code', 'hr', 'br', 'div',
	  'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'img', 'span' ];
	
	if (enable_links) {
		allowedTags.push('a');
	}

	return {
	allowedTags,
	allowedAttributes: {
	  	'a': ['href', 'name', 'target'],
	  // We don't currently allow img itself by default, but this
	  // would make sense if we did
		'img' : ['src', 'id', 'width', 'height', 'style', 'alt',],
		//'div' : ['height', 'width', 'style'],
		'table' : ['border', 'cellpadding', 'cellspacing', 'align', 'width', 'height', 'style'],
		'tr' : ['style'],
		'td' : ['style', 'width', 'height', 'colspan', 'rowspan'],
		'th' : ['style'],
		'p' : ['style'],
		'div' : ['style'],
		'span' : ['style'],
	},
	//allowedStyles: {
        //  'div': {
        //    'height': [/^.*$/],
	//    'width': [/^.*$/],
        //  }
        //},
	allowedSchemes: ['data', 'http', 'https'],
	  transformTags: {
	  	'img' : function(tagName, attribs) {
			const cid = get_cid(attribs.src);
			// log("email", "+++img src cid+++", 
				// attribs.src, {cid, cids});
			if (cid) {
				const item = cids[cid];
				if (item !== undefined) 
					attribs.src = gen_cid_url(item);
				
				log("email", "img updated", attribs);
			} else if ((!display_linked_images) && attribs.src && attribs.src.startsWith('http')) {
				return {tagName: 'img', attribs: {src: 'data:,'}};
			}
			return {tagName: 'img', attribs: {...attribs}};
		},
		'a' : function(tagName, attribs) {
			attribs.target = '_blank';
			return {tagName: 'a', attribs: {...attribs}};
		},
	  }
	};
}

// Recursively traverse this to find vevent.
// Typical icalev:
//  {2234234234.234234 : {2342342.234234 : {type: 'ALARM', ...}, 234234.234324: {type: 'VEVENT, ...}, 234234234.234234: {}, ...}
//
function get_vevent(data) {
	if (data.type == 'VEVENT')
		return data;

	for (let k in data) {
		let val = data[k];
		if (is_object(val)) {
			var vev = get_vevent(val);
			if (vev)
				return vev;
		}
	}
	return null;
}

const ical_name = (item) => {
	if (!item)
		return null;
	log('email', 'ical_name', item);
	let name = (item.params && item.params.CN) || '';
	name = name.replaceAll('"', '');
	let email = item.val;
	if (email && (email.slice(0,7) == 'mailto:'))
		email = email.slice(7);
	
	if (name.toLowerCase() == email.toLowerCase())
		email = '';
	
	return <span className="ical-name">
		{name}
		<span className="ical-email">
			{email}
		</span>
	</span>;
}

const ical_date = (item) => {
	if (!item)
		return null;

			// {item.toLocaleString()}
	
	return <React.Fragment>
		<span className="ical-time">
			{ window.g_moment(item).format('dddd, MMMM Do YYYY, h:mm a') }
		</span>
		<span className="ical-tz">{item.tz}</span>
	</React.Fragment>
}

const ShowCalEvent = ({icalev, options}) => {
  try {
	const ev = ical.parseICS(icalev);
	const vev = get_vevent(ev);
	let attendee = vev.attendee;
	// log('email', 'iCal Event', icalev, ev);

	log('email', 'iCal Event', ev, vev);
	window.g_vevent = vev;

	const altdesc = vev['ALT-DESC'];
	let althtml = '';

	if (altdesc && altdesc.params && altdesc.params.FMTTYPE == 'text/html')
		althtml = altdesc.val;
	
	if (attendee === undefined)
		attendee = [];
	else if (!Array.isArray(attendee)) // if 1 attendee, not array, make it.
		attendee = [attendee];

	return (
<div className="ical">
	<div className="ical-hint">
		Please click calendar.ics attachment above to process this
	</div>
	<dl>
	<dt>Event</dt>
	<dd>{vev.summary && vev.summary.val}</dd>
	<dt>Start:</dt>
	<dd>{ical_date(vev.start)}</dd>
	<dt>End:</dt>
	<dd>{ical_date(vev.end)}</dd>
	<dt>From:</dt>
	<dd>{ical_name(vev.organizer)}</dd>
	<dt>To:</dt>
	<dd>
	<ul>
		{attendee.map( (vto, i) => <li key={i}>
				{ical_name(vto)}
			</li>)}
	</ul>
	</dd>
	<dt>Details:</dt>
	<dd>
	{althtml ? 
	<SanitizeHTML html={althtml} options={options} />
		:
		<pre>
			{vev.description && vev.description.val}
		</pre>
	}
	</dd>
	</dl>
</div>
	);
  }
  catch (e) {
	log('email', 'ical display failed', e);
	return <div>Calendar Event</div>;
  }
}

const wrap_urls = (text) => {
	//const urlRegex = /((?<!=")((?<!\/)www\.|https?:\/\/)[^\s\<]+)/g;
	const urlRegex = /[^"\/]((www\.|https?:\/\/)[^\s\<]+)/g;
	const txt = text.replace(urlRegex, (url) => {
		let starter = url.charAt(0);
		url = url.slice(1);
		let link_url = url;
		if (!url.startsWith('http')) {
			link_url = 'http://' + url;
		}
		return starter + '<a href="' + link_url + '">' + url + '</a>';
	});
	return txt;
}

const remove_meta_tags = function (all) {
	const return_all = [];

	all.forEach(function(text) {
		let return_text = text.replace(/(<meta[\s\S]*?>)/g, '');
		return_all.push(return_text);
	});
	
	return return_all;
}

const add_anchor_tags_to_urls = function (all) {
	const return_all = [];

	all.forEach(function(text) {
		let return_text = '';
		const parts = text.split(/(<a.*?<\/a>)/g);
		
		parts.forEach(function(part) {
			//console.log('PART', part);
			if (part.search('<a') < 0) {
				part = wrap_urls(part);
			}
			return_text = return_text + part;
		});

		return_all.push(return_text);
	});
	
	return return_all;
}

const ShowBody = ({body, display_linked_images=false, enable_links=false}) => {
	if (body === undefined)
		return <ProgressSpinner/>
	
	if (body == null)
		return <div><div className="lightYellow">Note: Looks like you deleted this email in your inbox, or moved it to a different folder.</div><br/><br/>This is not an error and no action is needed. In an upcoming version of TagInbox, this email reference will be removed from the pending list.</div>;

	let all = get_body_parts_all(body, 'text/html');
	if (all.length == 0) {
		// if html part is not here, try plain.
		// if neither exist, sometimes, SMS replies (from email gateway)
		//	from Verizon, come as plain text attachments.
		const plain = get_body_part(body, 'text/plain') || 
			       get_body_part(body, 'text/plain', true) || 
			        '';
		let html = '<pre>' + plain + '</pre>\n';

		// Some MMS messages have no text/html but include inline imgs
		//	Nilam reported. 
		body.forEach(item => {
			if (item.cid && item.content_type && 
			item.content_type.substring(0,5) == 'image') {
				html += '<br/><img src="data:image/png;' +
				item.encoding + ', ' + item.data + 
				'" alt="' + item.cid + '" width="100%" />';
			}
		});

		all = [html];
	}
	let icals = get_body_parts_all(body, 'text/calendar');
	// log('email', 'iCals', icals);
	
	//console.log('SHOWBODY 1', all);
	all = remove_meta_tags(all);

	//console.log('SHOWBODY 2', all);

	const options = map_inline_attachments(body, display_linked_images, enable_links);
	log('email', 'ShowBody', body, all, options);

	//console.log('SHOWBODY 3', options);
	
	all = add_anchor_tags_to_urls(all);

	//console.log('SHOWBODY 4', all);

	return <React.Fragment>
		{icals.map((icalev,i) => <ShowCalEvent key={i} 
					icalev={icalev} options={options}
						/>)}
		{all.map((html,i) => <SanitizeHTML key={i} 
					html={html} options={options}
						/>)}
	</React.Fragment>
}

const SmsWrap = props => <Card className="sms-wrap" 
	header={<div className="sms-wrap-hdr"><i className="fa fa-fw fa-commenting-o"></i> SMS</div>}
	>
		<ShowBody body={props.body} display_linked_images={props.display_linked_images} enable_links={props.enable_links} />
	</Card>
	

function b64toArrayBuffer(b64) {
	const binbuf = myatob(b64);
	const len = binbuf.length;
	const bytes = new Uint8Array(len);
	for (let i=0; i<len; i++)
		bytes[i] = binbuf.charCodeAt(i);
	return bytes;
}

function decode_mime(item) {
	const enc = item.encoding.toLowerCase();

	if (enc == 'base64')
		return Base64.toUint8Array(item.data);	

	if (enc == '')
		return item.data;

	if (enc == '7bit')
		return item.data;

	if (enc == '8bit')
		return item.data;

	if (enc == 'quoted-printable')
		return qp.decode(item.data);

	if (enc == 'x-uue' || enc == 'x-uuencode') {
		const uuimg = uud(item.data, item.file_name);
		// log("email", "x-uue attachment ", uuimg);
		item.is_inline = true;
		item.cid = '<' + item.file_name + '>' ;
		return uuimg;
	}

	growl('Error', 'Unknown encoding for attachment: ' + enc + 
		'. Please report it to TagInbox.',
		'error', true);
	return '';
}

function get_content_type(part) {
	const ctype = part.content_type;

	// application/octet-stream does NOT launch app when it is
	//	pages, office docs etc. Choose the right content type
	//	based on file name in those cases.
	if (ctype != 'application/octet-stream')
		return ctype;
	
	const better_type = mime.lookup(part.file_name);
	if (better_type)
		return better_type;
	
	return ctype;
}

class ShowAttachment extends Component {
	get_url = () => {
		const {item} = this.props;
		const ab = item.content_type == 'text/calendar' ?
			item.data : 
			decode_mime(item);

		const blob = new Blob([ab], {type: get_content_type(item)});
		// const url = URL.createObjectURL(blob);
		const url = URL.createObjectURL(blob);

		window.g_attach = {item, ab, blob, url};
		
		// log("email", "attachment ", item, ab, blob);

		return url;
	}

	show = e => {
		const url = this.get_url();
		// window.open('http://www.google.com');
		window.open(url, '_blank', );
	}

	render = () => {
		window.g_showAttachment = this;

		const {item} = this.props;
		let {file_name, is_attachment} = item;
		let fname = atch_dec(file_name);

		if (item.content_type == 'text/calendar') {
			is_attachment = true;
			fname = 'calendar.ics';
			// log("email", "+++attachment: calendar ", item);
		}

		log("email", "attachment ", item);

		if (!is_attachment && !is_inline_attachment(item))
			return null;

		return <div className="attachment">
			<a href={this.get_url()} download={fname}>
			<Button label={fname}
				className="p-button-secondary"
				icon="fa fa-fw fa-paperclip"
				title={display_readable_bytes(item.data.length, 0, false, false)}
				/>
			</a>
		</div>
	}
}

class DownloadAllLink extends Component {
	constructor(props) {
		super(props);
		
		this.state = {clicked: false, generated: false};
	}

	get_num_attachments = () => {
		const {body} = this.props;
		
		let count = 0;
		
		body.forEach((item, i) => {
			const {file_name, is_attachment} = item;

			if (!is_attachment && !is_inline_attachment(item))
				return;

			count++;
		});

		return count;
	}
	
	generate = e => {
		if (this.state.generated) {
			return true;
		}

		if (!this.state.clicked) {
			e.preventDefault();
		}
		
		const {body} = this.props;
		
		const zip = new JSZip();
		const folder = zip.folder('attachments');
		
		body.forEach((item, i) => {
			const {file_name, is_attachment} = item;
			let fname = atch_dec(file_name)
			
			if (!is_attachment && !is_inline_attachment(item))
				return null;

			fname = fname.replace(/[\n\r]/g, '');
			
			const ab = decode_mime(item);
			
			const blob = new Blob([ab], {type: get_content_type(item)});
			
			folder.file(fname, blob)
		});
		
		zip.generateAsync({type : "blob"}).then(zip_blob => {
			if (!dl_all_link) {
				return false;
			}
			
			this.setState({generated: true});

			dl_all_link.href = URL.createObjectURL(zip_blob);
			dl_all_link.download = 'attachments.zip';
			dl_all_link.click();
		});
		
		this.setState({clicked: true});
		
		return false;
	}

	render = () => {
		const num_attachments = this.get_num_attachments();
		
		if (num_attachments < 2) {
			return null;
		}
		
		return <div className="attachments_download_all" style={{marginTop: '1em', marginBottom: '0.5em'}}>
			<a id="dl_all_link" href="#" onClick={this.generate}>
			<Button label="Download All"
				className="p-button-secondary"
				icon="pi pi-fw pi-download"
				/>
			</a>
		</div>
	}
}

function ShowAttachmentArray(props) { 
/*
 Sometimes, when attachment is another email, <name>.eml file,
 item.data is an array of MIME parts, instead of just the mime buffer.
 In which case, parse each part and treat it as through it is a separate file
 with the same name/encoding etc. in the wrapper.
 In the error case that triggered this, array had only one item,
 an empty MIME plain segment.
 */
	const {item} = props;
	const nitem = {...item};

	return <div>
	{item.data.map((part, i) => {
		nitem.data = part;
		return <ShowAttachment key={i} item={nitem}  />;
	})}
	</div>;
}



// DEFUNCT. No longer used. Threaded parent envs are shown in List, indented
//	No need to also show it in body.
//	TBD: Remove
class ThreadEmails extends Component {
	constructor(props) {
		super(props);
		const {env} = this.props;
		env.thread_eids = JSON.parse(env.thread_eids_json);

		let working = false;
		this.state = {working, rid:env.rid, tab:'shared'};
	}

	show_env = (eid, i) => {
		const {rid, tab} = this.state;
		const env = get_item(rid, tab, eid);
		return <Link key={i} to={go_url('room', rid, tab, eid)}
			data-iid={eid} >
		<EmailHeader env={env} cls='thread-header' />
		    </Link>
	}

	show_parent_envs = () => {
		if (this.state.working)
			return <ProgressSpinner/>;
		const {env} = this.props;

		return <React.Fragment>
			{env.thread_eids.ancestor_eids.map(this.show_env)}
		</React.Fragment>;
	}

	render() {
		return <Accordion>
		<AccordionTab header="Previous emails in this thread">
			{this.show_parent_envs()}
		</AccordionTab>
		</Accordion>;
	}
}

const get_se_error = env => {
	if (!env.error)
		return '';
	
	const lines = env.error.split('\n');
	for (var i=0; i<lines.length; i++)
		if (lines[i].slice(0,2) == 'SE')
			return lines[i];
	return '';
}

function get_raw_env_content(env) {
	const body = env.body;
	
	if (body === undefined)
		return '';
	
	if (body == null)
		return '';
	
	let all = get_body_parts_all(body, 'text/html');
	if (all.length == 0) {
		// if html part is not here, try plain.
		// if neither exist, sometimes, SMS replies (from email gateway)
		//	from Verizon, come as plain text attachments.
		const plain = get_body_part(body, 'text/plain') || 
			       get_body_part(body, 'text/plain', true) || 
			        '';
		let html = '<pre>' + plain + '</pre>\n';

		// Some MMS messages have no text/html but include inline imgs
		//	Nilam reported. 
		body.forEach(item => {
			if (item.cid && item.content_type && 
			item.content_type.substring(0,5) == 'image') {
				html += '<br/><img src="data:image/png;' +
				item.encoding + ', ' + item.data + 
				'" alt="' + item.cid + '" width="100%" />';
			}
		});

		all = [html];
	}

	let raw_html = all[0];

	let cids = {};	// content ids.

	body.forEach(item => {
		if (item.is_inline || item.cid) {
			cids[item.cid] = item;
		}
	});
	
	let options = {
		allowedTags: false,
		allowedAttributes: false,
		allowedSchemes: ['data', 'http', 'https'],
		transformTags: {
	  		'img' : function(tagName, attribs) {
				const cid = get_cid(attribs.src);
				if (cid) {
					const item = cids[cid];
					if (item !== undefined) 
						attribs.src = gen_cid_url(item);
				}
				return {tagName: 'img', attribs: {...attribs}};
			}
		}
	};
	
	let clean_raw_html = sanitizeHtml(raw_html, options);
	
	return clean_raw_html;
}

class FullEmail extends Component {
	constructor(props) {
		super(props);
		
		const {rid, env} = this.props;
		const room = get_room(rid);

		regcomp(this, 'fullEmail');

		this.state = {};
	}

	// One property
	//	email
	text2html = (text) => {
		if (text === undefined)
			return <ProgressSpinner/>
		return <div>
			{text.split('\n').map((item, key) => {
			  return <Fragment key={key}>{item}<br/></Fragment>
			})}
		</div>
	}
	
	render = () => {
		const {env, tab, rid, mobile, par} = this.props;
		const body = env.body;

		if (body)
			env._seen = Date.now();

		const display_linked_images = 
			display_linked_images_for_env(env);
		const enable_links = enable_links_for_env(env);


		// log("email", "full_email ", env.message_id, env);

		return (
	<div className="item-detail full-email" 
		ref={el => (par.printEl = el)} >

		<ShowTags env={env} rid={rid} mobile={mobile} par={this} />

		{tab === 'shared' && env.task_ids && 
			<EmailTasks rid={rid} task_ids={env.task_ids} />}

		<EmailHeader env={env} noindent={true} full={true} rid={rid}
			par={this}
		/>

		<div id="display-full-email-body" className="email-body">
			{has_attachments(body) ? 
				<div className="attachments">
				<h5>Attachments:</h5>
				{body.map((part, i) => Array.isArray(part.data)?
				 <ShowAttachmentArray key={i} item={part} /> :
				 <ShowAttachment key={i} item={part} />
				)}
				<DownloadAllLink body={body} />
				</div>
				: null }
			{env.is_sms ? <SmsWrap body={body} display_linked_images={display_linked_images} /> :
				<ShowBody body={body} display_linked_images={display_linked_images} enable_links={enable_links} />}
		</div>
	</div>
		)
	}
}

export {EmailHeader, FullEmail, ShowBody, decode_mime, map_inline_attachments, get_raw_env_content}; 
