import React from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {ModalCommand, Spacer, Focusable} from './Utils';

import {ldb, log, gen_email_summary, task_category_default, regcomp,
	task_due_date_delta_default, growl, api, pri_n2v} from './Lib';

let saved_to_sid = null;
let saved_priority = null;
let saved_dt_due = null;

class CreateTask extends Focusable {
	constructor(props) {
		super(props);
		const {item} = this.props;
		const subject = item.env ? item.env.subject : '';
		const priority = pri_n2v('Normal');
		const to_sid = saved_to_sid ? saved_to_sid : 0;
		const note = '';
		const category = task_category_default();
		const delta = task_due_date_delta_default();
		const dt_due = saved_dt_due ? saved_dt_due : window.g_moment().add(delta, 'days').toDate();
		this.state = {subject, category, to_sid, priority, 
				note, dt_due};

		window.g_createTask = this;
	}

	has_errors = () => {
		if (this.state.to_sid === 0) {
			growl('Error', 
		'Please choose staff for To:', 'error');
			return true;
		}

		if (this.state.priority === null) {
			growl('Error', 
		'Please choose the priority', 'error');
			return true;
		}
		
		return false;
	}

	onCreate = () => {
		if (this.has_errors())
			return;

		const {to_sid, subject, category, note, 
				priority, dt_due} = this.state;
		const {rid, item} = this.props;
		const email = item;
		let eid = 0; 
		let email_summary = '';
		if (email.id) {	// has attached email
			eid = email.id;
			email_summary = gen_email_summary(email);
		}

		const args = {
			cmd: 'create_task', 
			subject, category, note, to_sid, dt_due,
			priority, rid, eid, email_summary,
		}
		api( args, this.created );
		
		saved_to_sid = to_sid;
		saved_priority = priority;
		saved_dt_due = dt_due;
	}

	created = (error) => {
		/*
		if (!error)
			growl("Created Task");
		*/
		this.props.close();
	}

	render() {
		const {rid} = this.props;
	 	return (
		<ModalCommand par={this} header="Create Task">
	<Spacer />
	<Spacer />
	<Spacer />
	<Spacer />
  	<TaskSubject par={this} autofocus={true} />
  	<TaskCategory par={this} />
	<Spacer />
	<Spacer />
  	<TaskSelectStaff par={this} rid={rid} />
	<Spacer />
	<Spacer />
	<TaskPriority par={this} />
	<Spacer />
	<Spacer />
  	<TaskNote par={this} />
	<Spacer />
	<Spacer />
  	<TaskDueDate par={this} />
	<Spacer />
	<Spacer />
  	<TaskCommand onClick={this.onCreate} label="Create" />
	{this.state.creating && <ProgressSpinner/>}
	<TaskAttach par={this} email={this.props.item} />
		</ModalCommand>
	
		)
	}
}

export {CreateTask};
