import React, {Component} from 'react';
import {Button} from 'primereact/button';

import UserList from './UserList';
import OrgCast from './OrgCast';
import {ldb, log, regcomp} from './Lib';

class StaffList extends Component {
	constructor() {
		super();
		this.state = {nrefresh:0, show_orgcast: false};
		regcomp(this, 'staffList');
	}

	refresh = () => {
		if (this._isMounted)
			this.setState({nrefresh: this.state.nrefresh + 1});
	}

	componentDidMount() {
		this._isMounted = true;
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	close = () => this.setState({show_orgcast:false})

	render() {
		const root = ldb.data.staffs;
		const label = ldb.data.org ? 
					ldb.data.org.name + ' Staff' : 
					'Staff';
		const items = [{label}];
		const {mobile} = this.props;
		const cols = mobile ? ['', 'p-col-12', ''] :
				['p-col-1', 'p-col-6', 'p-col-5'];

	return <div className="p-grid ">
		{this.state.show_orgcast && 
			<OrgCast close={this.close} title="Send Alert" />}
		<div className={cols[0]}></div>
		<div className={cols[1] + ' staff-list'}>

		<h3 style={{textAlign:'center'}}>{label}</h3>
		<div className="staff-alert">
			<Button label="Send Alert"
				icon="pi pi-exclamation-triangle"
			className="p-button p-button-outlined p-button-raised"
			onClick={e => this.setState({show_orgcast: true})} />
		</div>

		<UserList root={root} items={items} mobile={mobile} />
		</div>
		<div className={cols[2]}>
		</div>
	</div>
	}
}

export default StaffList;
