import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';
import {Password} from 'primereact/password';

import {ModalCommand, HelpInfo} from './Utils';
import {PushNotif} from './Notif';
import {EditSig, AddAltSig, EditAltSig, DeleteAltSig} from './EditSig';
import {SanitizeHTML} from './Utils';

import {ldb, log, settings_url, go, p2br, api, growl, go_url,
	get_first_name, foreach_list, map_list, get_staff, list_rm_ele,
	list_has_ele, task_due_date_delta_default, em, 
	mbox_read_perm, display_account_type, is_favorite_room} from './Lib';

//------------------ Session ------------------------

const Session = props =>  {
	const delta = window.g_moment.duration(
			window.g_moment().diff(ldb.data.sys.dt_login )
			);
	const me = ldb.data.me;
	return <Card title="This Session">
		<div className="p-grid">
			<div className="p-col-2 colname">
				Action
			</div>
			<div className="p-col-10">

			    <a href="/">
			    <i className="fa fa-fw fa-sign-out"></i>
				    Logout
			    </a>
				
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-2 colname">
				Since
			</div>
			<div className="p-col-10">
				{ldb.data.sys.dt_login.format('h:mm a')}
				{' '}
				({delta.humanize()})
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-2 colname">
				You
			</div>
			<div className="p-col-10 hilite">
				{me.name}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-2 colname">
				Email
			</div>
			<div className="p-col-10">
				{me.email}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-2 colname">
				Role
			</div>
			<div className="p-col-10">
			{me.is_org_admin ? 'Admin' : 'Staff'}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-2 colname">
				Org
			</div>
			<div className="p-col-10">
				{ldb.data.org.name}
			</div>
		</div>
	</Card>;
}

//------------------ Mail Settings  ------------------------

const MailSettings = props => <Card title="Mail Settings">
		<div className="p-grid">
			<div className="p-col-4 colname">
				Account Type
			</div>
			<div className="p-col-8">
				{display_account_type(ldb.data.me, false)}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 colname">
				Mail-In
			</div>
			<div className="p-col-8">
				{ldb.data.mailbox.in_server}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 colname">
				Mail-Out
			</div>
			<div className="p-col-8">
				{ldb.data.mailbox.out_server}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 colname">
				Username
			</div>
			<div className="p-col-8">
				{ldb.data.mailbox.username}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 colname">
				Link
			</div>
			<div className="p-col-8">
			    <Button label="Mail Settings"
			    	icon="pi pi-fw pi-cog"
			    	onClick={props.par.toggleMailSettingsModal}
				/>
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 colname">
				Note
			</div>
			<div className="p-col-8">
For now, mail settings on this server are managed with a different UI.
You'll need to login again.
			</div>
		</div>
		
	</Card>

//----------------- My Settings ------------------------

class MySettings extends Component {
	constructor() {
		super();

		const company_name_color = ldb.data.me.settings.company_name_color || '';
		const email_editor_font = ldb.data.me.settings.email_editor_font || 'Arial';
		const email_editor_font_size = ldb.data.me.settings.email_editor_font_size || '';
		const email_editor_line_format = ldb.data.me.settings.email_editor_line_format || '';
		
//font-family: cursive; font-size: 10px;

		this.state = { company_name_color, email_editor_font, email_editor_font_size, email_editor_line_format, updating: false, };
	}
	
	error_check = () => {
		return false;
	}

	update = () => {
		if (this.error_check()) {
			return;
		}

		const company_name_color = this.state.company_name_color;
		const email_editor_font = this.state.email_editor_font;
		const email_editor_font_size = this.state.email_editor_font_size;
		const email_editor_line_format = this.state.email_editor_line_format;

		const new_settings = {company_name_color, email_editor_font, email_editor_font_size, email_editor_line_format};
		const args = {
			cmd: 'staff_settings_update', 
			new_settings,
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}
	
	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;
		
		const font_options = [{label: 'Arial', value: 'Arial'},
			{label: 'Comic Sans MS', value: 'Comic Sans MS'},
			{label: 'Courier New', value: 'Courier New'},
			{label: 'Impact', value: 'Impact'},
			{label: 'Georgia', value: 'Georgia'},
			{label: 'tahoma', value: 'tahoma'},
			{label: 'Trebuchet MS', value: 'Trebuchet MS'},
			{label: 'Verdana', value: 'Verdana'}
		];

		const font_size_options = [{label: '13', value: ''},
			{label: '8', value: '8'},
			{label: '9', value: '9'},
			{label: '10', value: '10'},
			{label: '11', value: '11'},
			{label: '12', value: '12'},
			{label: '14', value: '14'},
			{label: '16', value: '16'},
			{label: '18', value: '18'},
			{label: '20', value: '20'},
			{label: '22', value: '22'},
			{label: '24', value: '24'},
			{label: '26', value: '26'},
			{label: '28', value: '28'},
			{label: '36', value: '36'},
			{label: '48', value: '48'},
			{label: '72', value: '72'}
		];

		const line_format_options = [{label: 'Paragraph', value: ''}, 
				 {label: 'Normal (DIV)', value: 'div'}];

		return (
	<Card title="My Settings" footer={footer}>

		<div style={{margin:'5px 0px'}}>
		Company Name Color:
		{em(1.5)}
		<InputText type="text" placeholder="Enter a color"
			value={this.state.company_name_color} 
			onChange={e => {
				this.setState({company_name_color: e.target.value});
			}}
		/>
		<div style={{fontSize:'x-small'}}>
		This will change the color of the company name displayed in the upper-left of the page
		</div>
		</div>

		<div style={{margin:'5px 0px'}}>
		Email Editor Font:
		{em(3.7)}
		
		<Dropdown value={this.state.email_editor_font}
				options={font_options}
				onChange={ e => this.setState({email_editor_font: e.value}) } 
			/>
		
		<div style={{fontSize:'x-small'}}>
		This will change the default font for the compose email editor
		</div>
		</div>

		<div style={{margin:'5px 0px'}}>
		Email Editor Font Size:
		{em(1.7)}
		
		<Dropdown value={this.state.email_editor_font_size}
				options={font_size_options}
				onChange={ e => this.setState({email_editor_font_size: e.value}) } 
			/>
		
		<div style={{fontSize:'x-small'}}>
		This will change the default font size for the compose email editor
		</div>
		</div>

		<div style={{margin:'5px 0px'}}>
		Email Editor Line Format:
		{em(0.5)}
		
		<Dropdown value={this.state.email_editor_line_format}
				options={line_format_options}
				onChange={ e => this.setState({email_editor_line_format: e.value}) } 
			/>
		
		<div style={{fontSize:'x-small'}}>
		This will change the default line format for the compose email editor
		</div>
		</div>


	{this.state.updating && <ProgressSpinner/>}
	</Card>);
	}
}

//------------------ Signature  ------------------------

class Signature extends Component {
	edit_sig = () => go('settings', 'profile', 'edit_sig')

	add_alt_sig = () => go('settings', 'profile', 'add_alt_sig')

	edit_alt_sig = (num) => go('settings', 'profile', 'edit_alt_sig', num)

	delete_alt_sig = (num) => go('settings', 'profile', 'delete_alt_sig', num)

	close = () => go('settings', 'profile')
	
	help_popup = () => {
		return (<HelpInfo header="Email Signatures" className="normal">
		<div>
		<p>
			You can set multiple email signatures for yourself, and assign a particular email signature to each room. When you send emails from each room, the email signature you have selected for that room will be used.
		</p>
		<p>
			You can use the provided tools to add, edit, and delete email signatures. You can then go to a room and assign the email signature for that room under the "Settings" section of the "About" tab. The default signature will be used in any room you have not selected an alternative email signature for.
		</p>
		<p>
			As an example: If you have 50 rooms (one for each state), you could have 50 distinct email signatures with one for each room (eg, a Florida signature, a New York signature). Or you could have two email signatures, with each covering a percentage of your rooms (eg, 20 of the 50 rooms could have signature #1, and 30 of the 50 rooms could have signature #2). Or you can just have a single default email signature that is used for all 50 of your rooms.
		</p>
		</div>
		</HelpInfo>)
	}

	render() {
		const me = ldb.data.me;
		
		const alt_sigs = me.settings.alt_sigs;
		
		// TBD Improve this
		let alt_command = null;
		let alt_num = null;
		if (this.props.command) {
			const parts = this.props.command.split('/');
			if (parts.length == 2) {
				alt_command = parts[0];
				alt_num = parseInt(parts[1]);
			}
		}
		
		return (
	<Card title="Email Signatures" className="profile-signatures">
	{(this.props.command == 'edit_sig') && <EditSig par={this} />}
	{(this.props.command == 'add_alt_sig') && <AddAltSig par={this} />}
	{(alt_command == 'edit_alt_sig') && <EditAltSig par={this} num={alt_num} />}
	{(alt_command == 'delete_alt_sig') && <DeleteAltSig par={this} num={alt_num} />}

	<Fieldset legend="Default">
		<div dangerouslySetInnerHTML={{
			__html: me.html_signature}} />
		<Button label="Edit" onClick={this.edit_sig}
			className="p-button-outlined p-button-raised"
		/>
	</Fieldset>
	
	{alt_sigs && alt_sigs.map((sig, i) =>
	<Fieldset legend={sig.name} className="profile-alt-signature" key={i}>
		<div dangerouslySetInnerHTML={{
			__html: sig.html}} />
		<Button label="Edit" onClick={() => this.edit_alt_sig(i)}
			className="p-button-outlined p-button-raised"
		/>
		{em(1)}
		<Button label="Delete" onClick={() => this.delete_alt_sig(i)}
			className="p-button-outlined p-button-raised"
		/>
	</Fieldset>
	)}
	
	<div className="p-grid profile-signature-buttons">
		<div className="p-col-12">
			<Button label="Additional Signature" onClick={this.add_alt_sig}
				icon="pi pi-fw pi-plus"
				className="p-button-outlined p-button-raised"
			/>
			{em(0.5)}
			{this.help_popup()}
		</div>
	</div>

	</Card> );
	}
}

// ----------- Untagged Email Notifications -------------

class UntaggedEmailNotifications extends Component {
	constructor() {
		super();
		const untagged_email_notifications = ldb.data.me.settings.untagged_email_notifications || false;
		const untagged_email_notification_method = ldb.data.me.settings.untagged_email_notification_method || 'email';
		this.state = { untagged_email_notifications, untagged_email_notification_method, updating:false, };
	}
		
	update = () => {
		const untagged_email_notifications = this.state.untagged_email_notifications;
		const untagged_email_notification_method = this.state.untagged_email_notification_method;
		const new_settings = {untagged_email_notifications, untagged_email_notification_method};
		const args = {
			cmd: 'staff_settings_update', 
			new_settings,
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;
		
		const options = [{label: 'Email', value: 'email'}, 
				 {label: 'SMS Message', value: 'sms'}];
		
		return (
	<Card title="Untagged Email Notifications" footer={footer}>
		<p>
			When this setting is on you will recieve text messages notifying you of new untagged emails in your rooms. These texts will be sent out at most once per hour between 9am - 6pm.
		</p>
		<div className="p-grid">
		<div className="p-col-4">
			Receive untagged email notifications:
		</div>
		<div className="p-col-8">
			<Checkbox onChange={e=> {
					const untagged_email_notifications = e.checked;
					this.setState({untagged_email_notifications});
				}}
				checked={this.state.untagged_email_notifications}
			/>
		</div>
		</div>
		<div className="p-grid">
		<div className="p-col-4">
			Notification method:
		</div>
		<div className="p-col-8">
			<Dropdown value={this.state.untagged_email_notification_method}
				options={options}
				onChange={ e => this.setState({untagged_email_notification_method: e.value}) } 
			/>
		</div>
		</div>
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

// ----------- Update Password -------------

class UpdatePassword extends Component {
	constructor() {
		super();
		this.state = { show_modal: false, new_password: '', confirm_new_password: '', updating: false };
	}
	
	display_modal = () => {
		this.setState({show_modal: true})
	}

	hide_modal = () => {
		this.setState({show_modal: false, new_password: '', confirm_new_password: '', updating: false})
	}
	
	showPasswordModal = () => <ModalCommand
		onHide={this.hide_modal}
		className="medium-modal-command"
		extra={{maximizable:false}}
		header="Update Password"
		>
			<b>Update your TagInbox Account Password</b>
			<br/><br/>
			<div className="p-grid">
				<div className="p-col-3">
					New Password: 
				</div>
				<div className="p-col-3">
					<Password value={this.state.new_password} onChange={(e) => this.setState({new_password: e.target.value})} feedback={false} />
				</div>
			</div>
			<div className="p-grid">
				<div className="p-col-3">
					Confirm Password: 
				</div>
				<div className="p-col-3">
					<Password value={this.state.confirm_new_password} onChange={(e) => this.setState({confirm_new_password: e.target.value})} feedback={false} />
				</div>
			</div>
			<br/><br/>
			
			{(!this.state.updating) && <Button label="Set New Password" onClick={this.update} className="p-button-outlined p-button-raised" />}
			
			{this.state.updating && <ProgressSpinner/>}
	</ModalCommand>
		
	update = () => {
		const new_password = this.state.new_password;
		const confirm_new_password = this.state.confirm_new_password;
		
		if (new_password != confirm_new_password) {
			growl('Password not updated', 'New password and confirmation password do not match', 'error');
			return false;
		}

		const args = {
			cmd: 'set_new_password', 
			new_password,
		}
		api( args, this.updated );
		
		this.setState({updating: true});
	}

	updated = (error) => {
		this.hide_modal();
		growl('Updated password');
	}
	
	render() {
		const footer = <span><Button label="Update Password" onClick={this.display_modal} className="p-button-outlined p-button-raised" /></span>;
		
		return (
	<Card title="Update Password" footer={footer}>
		{this.state.show_modal && this.showPasswordModal()}
	</Card> );
	}
}

class UseNewEditor extends Component {
	constructor() {
		super();
		const use_new_editor = ldb.data.me.settings.use_new_editor || false;
		this.state = { use_new_editor, updating:false, };
	}
		
	update = () => {
		const new_settings = {use_new_editor: this.state.use_new_editor};
		const args = {
			cmd: 'staff_settings_update', 
			new_settings,
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;
		
		const options = [{label: 'Email', value: 'email'}, 
				 {label: 'SMS Message', value: 'sms'}];
		
		return (
	<Card title="New Editor for Composing Emails " footer={footer}>
		<p>
	A new editor for composing emails is being tested.
	This editor has a lot more features than the current editor,
	including tables, font size, undo/redo, full screen.
		</p>
		<div className="p-grid">
		<div className="p-col-4">
			Use New Editor:
		</div>
		<div className="p-col-8">
			<Checkbox onChange={e=> {
			this.setState({use_new_editor: e.checked});
				}}
			checked={this.state.use_new_editor}
			/>
		</div>
		</div>
	{this.state.updating && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ SMS Number  ------------------------

class SMSAlertsNumber extends Component {
	constructor() {
		super();
		
		let sms_number = '';
		if (ldb.data.me.settings.alerts && ldb.data.me.settings.alerts.sms_number) {
			sms_number = ldb.data.me.settings.alerts.sms_number;
		}
		this.state = { sms_number, updating:false, };
	}
		
	update = () => {
		const sms_number = this.state.sms_number;
		const new_settings = {'alerts' : {'sms_number' : sms_number}};
		const args = {
			cmd: 'staff_settings_update', 
			new_settings,
		}
		api( args, this.updated );

		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
	}

	render() {
		const subTitle = "Use T-Mobile: phonenumber@tmomail.net, Virgin Mobile: phonenumber@vmobl.com, Cingular: phonenumber@cingularme.com, Sprint: phonenumber@messaging.sprintpcs.com, Verizon: phonenumber@vtext.com, Nextel: phonenumber@messaging.nextel.com, where phonenumber = your 10 digit phone number";
		
		const footer = <span><Button label="Update" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		return (
	<Card title="Text Alerts Number" subTitle={subTitle} footer={footer}>
		<InputTextarea 
			style={{height:'4em', width:'100%',}}
			autoResize={true}
			value={this.state.sms_number} 
			onChange={ e => this.setState({sms_number: 
						e.target.value }) }
			/>
	{this.state.creating && <ProgressSpinner/>}
	</Card> );
	}
}

//------------------ Clear Notification Flags  ------------------------

class ClearNotificationFlags extends Component {
	constructor() {
		super();
		
		this.state = {updating:false};
	}

	help_popup = () => {
		return (<HelpInfo header="Clear Notification Flags" className="normal">
		<div>
		<p>
			This will clear all the red and green flag icons for all the rooms in My Rooms view for you.
		</p>
		<p>
			This can be helpful when you first create several rooms and share a lot of emails in each client room. They are likely all emails you've already processed, so you don't need to view them as new.
		</p>
		<p>
			Once you create all the rooms and clear the flags, you'll be alerted to all new activity: new unshared emails, newly shared emails, newly assigned tags or tasks and such.
		</p>
		<p>
			Please note that when you clear flags it will only clear them for you, and not for your coworkers.
		</p>
		</div>
		</HelpInfo>)
	}
		
	update_all = () => {
		let rids = [];
		
		for (let rid in ldb.data.rooms._items) {
			rids.push(rid);
		}
		
		const args = {
			cmd: 'staff_clear_activity_flags',
			rids: rids
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}
	
	update_non_favorites = () => {
		let rids = [];
		
		for (let rid in ldb.data.rooms._items) {
			const room = ldb.data.rooms._items[rid];
			if (!is_favorite_room(room)) {
				rids.push(rid);
			}
		}
		
		const args = {
			cmd: 'staff_clear_activity_flags',
			rids: rids
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}
	
	updated = (error) => {
		this.setState({updating:false});
	}
	
	render() {
		const footer = <span><Button label="Clear Notification Flags" onClick={this.update_all} className="p-button-outlined p-button-raised" /><br/><br/><Button label="Clear Notification Flags for Non-Favorite Rooms" onClick={this.update_non_favorites} className="p-button-outlined p-button-raised" /></span>;

		return (
	<Card title="Clear Notifications Flags">
	This will clear all the red and green flag icons for all the rooms in My Rooms view for you.
	{em(0.5)}
	{this.help_popup()}
	<br/><br/>
	{this.state.updating && <ProgressSpinner/>}
	{(!this.state.updating) && footer}
	</Card> );
	}
}

//------------------ Clear Email Drafts  ------------------------

class ClearEmailDrafts extends Component {
	constructor() {
		super();
		
		this.state = {updating:false};
	}
		
	update = () => {
		const args = {
			cmd: 'staff_clear_drafts', 
		}
		api( args, this.updated );

		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
	}

	render() {
		const footer = <span><Button label="Clear Email Drafts" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;

		return (
	<Card title="Clear Email Drafts">
	This will clear all your in-progress email drafts that you been working on.<br/><br/>This will not affect any emails you have received or sent.
	<br/><br/>
	{this.state.updating && <ProgressSpinner/>}
	{(!this.state.updating) && footer}
	</Card> );
	}
}
//------------------ Update Account Type ------------------------

class UpdateAccountType extends Component {
	constructor() {
		super();
		
		this.state = {account_type:ldb.data.me.account_type, updating:false};
	}
		
	update = () => {
		const new_account_type = this.state.account_type;

		const args = {
			cmd: 'staff_update_account_type',
			new_account_type: new_account_type,
		}
		api( args, this.updated );
		
		this.setState({updating:true});
	}

	updated = (error) => {
		this.setState({updating:false});
		
		window.location = '/';
	}

	render() {
		const footer = <span><Button label="Update Mailbox Monitoring" onClick={this.update} className="p-button-outlined p-button-raised" /></span>;
		
		const options = [{label: 'Real-Time', value: 'n'}, 
				 {label: 'Partial', value: 'r'}, 
				 {label: 'None', value: 'g'}];
		
		return (
	<Card title="Update Mailbox Monitoring">
	<span style={{fontSize:'small'}}><b>For TagInbox to be effective all staff accounts need to have real time mailbox monitoring. Occasionally, you can have one or two staff members in Partial or None modes. In Partial mode, the email in their inbox will NOT be automatically organized, but they can view activity in client rooms, respond to tags and tasks, and send emails out from client rooms. In None mode, the staff member will NOT get any TagInbox benefits, other than the ability to supervise the work of other staff members and see activity in client rooms, without participating in any of it.</b></span>
	<br/><br/>
	<Dropdown value={this.state.account_type}
			placeholder="Real-Time"
			options={options}
			onChange={ e => this.setState({account_type: e.value}) } 
		/>
	<br/><br/>
	{this.state.updating && <ProgressSpinner/>}
	{(!this.state.updating) && footer}
	</Card> );
	}
}

//------------------ Profile  ------------------------

class Profile extends Component {
	constructor() {
		super();
		this.state = {showms: false};
	}

	toggleMailSettingsModal = e => {
		const showms = ! this.state.showms;
		if (showms) {
			const args = { cmd: 'nonce', }
			api( args, this.got_nonce );
		}
		else
			this.setState({showms});
	}

	got_nonce = error => {
		this.setState({showms:true});
	}

	showMailSettingsModal = () => <ModalCommand
		onHide={this.toggleMailSettingsModal}
		header="Mail Settings"
		extra={{width:"30%", minY: 70, className: ''}}
		>
			This will launch in a separate tab.
			<br/><br/>
		    <a href={settings_url()} 
			onClick={this.toggleMailSettingsModal}
				target="_blank" >
		    <i className="pi pi-fw pi-cog"></i>
			    Launch Mail Settings
		    </a>
	</ModalCommand>
	
	render() {
		const me = ldb.data.me;
		const {command} = this.props.match.params;
		const {showms} = this.state;
		
		const read_perm = mbox_read_perm();

		return (
<div className='profile'>
<div className="p-grid">
	<div className="p-col-12">
		<Card title="My Profile" className="profile-settings-card">
			<div className="p-grid">
				<div className="p-col-12 p-lg-6">
					<Session />
				</div>
				<div className="p-col-12 p-lg-6">
					{read_perm && <MailSettings par={this} />}
				</div>
			</div>
			<div className="p-grid">
				<div className="p-col-12">
					<Signature command={command} />
				</div>
			</div>
			<div className="p-grid">
				<div className="p-col-6">
					<ClearNotificationFlags />
				</div>
				<div className="p-col-1">
				</div>
				<div className="p-col-5">
					<PushNotif />
				</div>
			</div>
			<div className="p-grid">
				<div className="p-col-6">
					<ClearEmailDrafts />
				</div>
				<div className="p-col-1">
				</div>
				<div className="p-col-5">
					<MySettings />
				</div>
			</div>
			<div className="p-grid">
				<div className="p-col-6">
					<UntaggedEmailNotifications />
				</div>
				<div className="p-col-1">
				</div>
				<div className="p-col-5">
					<UpdatePassword />
				</div>
			</div>
		</Card>
	</div>
</div>
<br/>
		{showms && this.showMailSettingsModal()}
</div>
		)
	}
}

export {Profile};
