import React, {useRef} from 'react'; 
import {Button} from 'primereact/button';
import {Toolbar} from 'primereact/toolbar';
import {Dialog} from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import {ProgressSpinner} from 'primereact/progressspinner';
import ReactDOM from 'react-dom';
import {jsPanel} from 'jspanel4/es6module/jspanel';
import 'jspanel4/dist/jspanel.min.css';

import {ldb, log, growl, get_room, get_list, api, gen_xid, go,
	set_order, resort, get_current_list_view_comp, is_super_admin,
	mbox_read_perm, mbox_write_perm, room_has_team_email, em,
	static_img_url} from './Lib';

import {ModalNewEmail, NewEmail} from './NewEmail';
import {NewSms} from './NewSms';
import {AddTag} from './Tag';
import {CreateTask} from './CreateTask';
import ReadEmailTask from './ReadEmailTask';
import BaseEmails from './BaseEmails';
import MarkTagsNotNeeded from './MarkTagsNotNeeded';
import {MoveEmail} from './MoveEmail';
import BulkEmail from './BulkEmail';
import CopyToOtherRoom from './CopyToOtherRoom';
import {SummarizeEmail, SummarizeThread, ExtractData} from './AiFeatures';

import {ModalCommand, MoreMenu, Focusable, AreYouSure} from './Utils';



let new_win_css = `

body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: normal;
    background: #f5f5f5;
}

.top-header {
    background: #fffff2;
    padding: 10px 10px 10px 10px;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14);
    margin-bottom: 10px;
    overflow: hidden;
    display: flex;
}

.logo-img {
    width: 100px;
    display: inline-flex;
}

.top-header-info {
    display: inline-flex;
    vertical-align: top;
    padding-top: 3px;
    margin-left: 1em;
}

.center {
    flex: auto;
    justify-content: center;
}

.item-detail {
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.14);
    background: #fff;
}

.item-detail .summary {
    background: #eef;
    cursor: pointer;
    padding: 0px 10px;
}

.tags {
   display: none;
}

.p-grid {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
}

.from-date {
    margin-top: 0;
}

.from {
    font-weight: bold;
}

.p-col-6 {
    width: 50%;
    flex: 0 0 auto;
    padding: 0.5rem;
    box-sizing: border-box;
}

.date {
    text-align: right;
    font-size: small;
}

.to-cc {
    font-size: small;
}

.p-col-12 {
    width: 100%;
    flex: 0 0 auto;
    padding: 0.5rem;
    box-sizing: border-box;
}

.legend {
    display: none;
}

.display-attached-images-div {
    display: none;
}

.env-attachment-list {
    font-size: small;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
    display: none;
}

.env-attachment {
    margin-right: 0.5em;
}

.email-body {
    padding: 0 1em 1em 1em;
}

.attachments {
    border-bottom: 1px solid #999;
    padding-left: 4em;
    padding-bottom: 0.5em;
}

.attachments h5 {
    margin: 0 0 0 -4em !important;
    padding: 0;
}

.attachment {
    margin: 0.2em 1em 0em 0em;
    display: inline-block;
}

.attachments_download_all {
    display: none;
}

.smart-name {
    display: inline;
    margin-right: 1em;
    position: relative;
}

.sname {
    display: none;
}

.semail {
    display: inline;
}

.smart-detail {
    display: none;
}

.smart-hover {
    display: inline;
}

.smart-hover:before {
    content: '\u00A0<';
}

.smart-hover:after {
    content: '> ';
}

.untagged {
    display: none;
}

.other-rooms-panel {
    display: none;
}

.fa-paperclip:before {
    content: '\uD83D\uDCCE\u00A0';
}

.env-attachment:before {
    content: '\uD83D\uDDCB';
}

.p-button {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    padding: 0.429rem 1rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-button.p-button-secondary {
    color: #ffffff;
    background: #607D8B;
    border: 1px solid #607D8B;
}

.return-button-div {
    margin-top: 5px;
}

.return-button {
    cursor: pointer;
}

.room-info {
    color: #666;
}

.room-name {
    font-weight: bold;
    margin-left: 5px;
    margin-right: 5px;
}

.instructions {
    font-size: x-small;
    color: #666;
    padding-top: 4px;
    margin-left: 20px;
}

`;



function make_email_window(subject, html, room, url, raw_html) {
	let logo_url = static_img_url('logo.png');

	html = '<html><head><script type="text/javascript">function show_full_email_content() {document.getElementById("return-button").style.display = "none";let ele = document.getElementById("display-full-email-body"); let ele2 = document.getElementById("full-email-content"); ele.innerHTML = ele2.innerHTML;}</script><style type="text/css">' + new_win_css + '</style></head><body><div class="top-header"><img src="' + logo_url + '" class="logo-img" /><div class="top-header-info center room-info">Email from <span class="room-name">' + room.name + '</span> room <span class="instructions">View Only. Actions in Main Tab.</span></div><div class="top-header-info right"><button id="return-button" class="return-button" onClick="show_full_email_content();">Load Remote Content</button>&nbsp;<button class="return-button" onClick="window.open(' + "'" + url + "'" + ');">View Email in Room</button></div></div>' + html + '<div id="full-email-content" style="display:none;">' + raw_html + '</div></body></html>';
	
	let newWindow = window.open('', '_blank');
	newWindow.document.write(html);
	newWindow.document.title = 'View Email: ' + subject;
	newWindow.document.close();
}



export {make_email_window};
