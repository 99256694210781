import React, {Component, useCallback} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Fieldset} from 'primereact/fieldset';
import {Card} from 'primereact/card';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Checkbox} from 'primereact/checkbox';
import {PickList} from 'primereact/picklist';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {TabView, TabPanel} from 'primereact/tabview';
import {Dropdown} from 'primereact/dropdown';
import {Toolbar} from 'primereact/toolbar';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';
import {FileUpload} from 'primereact/fileupload';

import parse from 'csv-parse/lib/sync';

import {ldb, log, filter_list, ridMRU, go_url, map_list, trimsplit,
	growl, api, room_category_options, pid2person, cap,
	list_has_ele, em, validate_email, clean_email, validate_tel,
	set_cluster_search_buf, gen_xid, split_name, gen_room_name,
	room_category_default, get_room, escape_reg_exp, go,
	get_bulk_email_list, get_bulk_email_list_data,
	get_my_mailbox_room, get_values_for_room_field} from './Lib';
import {FilteredPersons, email2person} from './Persons';
import {SelectStaff} from './CreateRoom';
import {lazyFocus, HelpInfo} from './Utils';
import {CcBccRows} from './NewEmail';



class DisplayBulkEmailList extends Component {
	constructor(props) {
		super(props);
		const {bid} = this.props;
		
		const refresh_count = 0;
		
		const bulk_email_list = get_bulk_email_list(bid);
		
		const index = 0;
		const working = false;
		
		const [rid_options, excluded_rids, error_buf] = this.get_rid_options('');
		let rids_prep = [...rid_options].sort(function(a, b) {
			const aroom = get_room(a);
			const broom = get_room(b);
			
			return (aroom.name.localeCompare(broom.name));
		});
		
		const search_text = '';
		const search_emails_text = '';

		const name = bulk_email_list.name;
		const sids = bulk_email_list.staffs._idlist;
		const list_rids = bulk_email_list.data.rids || [];
		const included_eids = bulk_email_list.data.included_eids || [];
		
		list_rids.forEach(function(rid) {
			if (rids_prep.includes(rid)) {
				rids_prep.splice(rids_prep.indexOf(rid), 1);
			}
		});
		
		const rids = rids_prep;
		
		const draft = bulk_email_list.data.draft || {'rid': get_my_mailbox_room().id, 'to_emails': [], 'ccs': [], 'cc_emails': [], 'bccs': [], 'bcc_emails': [], 'to_look': {'text': '', 'pids_left': ldb.data.contacts.persons._idlist}};

		const filter1_on = false;
		const filter1_field = '';
		const filter1_specificity = 'contains';
		const filter1_text = '';
		
		const filter2_on = false;
		const filter2_field = '';
		const filter2_specificity = 'contains';
		const filter2_text = '';
		
		const filter3_on = false;
		const filter3_field = '';
		const filter3_specificity = 'contains';
		const filter3_text = '';

		const notify_rooms = bulk_email_list.data.notify_rooms || false;

		this.state = {refresh_count, index, working, search_text, search_emails_text, rids, name, sids, list_rids, included_eids, draft, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text, notify_rooms};
	}

	reload_comp = () => {
		this.setState({refresh_count: this.state.refresh_count+1});
	}
	
	update_list_complete = (error) => {
		if (!error) {
			growl('Saved Bulk Email List');
		}
		
		this.setState({working: false});
	}
	
	update_list = () => {
		this.setState({working: true});
		
		const {bid} = this.props;
		const {name, sids, list_rids, included_eids, notify_rooms, draft} = this.state;
		
		const args = {
			cmd: 'update_bulk_email_list',
			bid: bid,
			name: name,
			sids: sids,
			list_rids: list_rids,
			included_eids: included_eids,
			ccs_list: draft.cc_emails,
			bccs_list: draft.bcc_emails,
			notify_rooms, notify_rooms,
			draft: draft,
		}
		api( args, this.update_list_complete );
	}

	generate_search_params = (search_text, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text) => {
		let name_params = [];
		let category_params = [];
		let field_params = [];
		
		search_text = search_text.trim();
		
		const components = search_text.split(' ');
		components.forEach( component => {
			if (!component.trim())
				return;

			let match = component.match(/(\S+?):(\S+)/g);
			if (match) {
				const parts = component.split(":");
				const key = parts[0];
				const fvalue = parts[1];
				
				if (key.toLowerCase() == 'category') {
					category_params.push(fvalue);
				} else {
					field_params.push([key, fvalue]);
				}
			} else {
				name_params.push(component);
			}
		});

		if ((filter1_on) && (filter1_field != '')) {
			if (filter1_field.toLowerCase() == 'category') {
				category_params.push([filter1_text.trim(), filter1_specificity]);
			} else {
				field_params.push([filter1_field, filter1_text.trim(), filter1_specificity]);
			}
		}
		
		if ((filter2_on) && (filter2_field != '')) {
			if (filter2_field.toLowerCase() == 'category') {
				category_params.push([filter2_text.trim(), filter2_specificity]);
			} else {
				field_params.push([filter2_field, filter2_text.trim(), filter2_specificity]);
			}
		}
		
		if ((filter3_on) && (filter3_field != '')) {
			if (filter3_field.toLowerCase() == 'category') {
				category_params.push([filter3_text.trim(), filter3_specificity]);
			} else {
				field_params.push([filter3_field, filter3_text.trim(), filter3_specificity]);
			}
		}
		
		const params = {'name': name_params, 'category': category_params, 'field': field_params};
		
		return params;
	}
	
	search_rooms = (search_text, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text) => {
		const [rid_options, excluded_rids, error_buf] = this.get_rid_options(search_text, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text);
		
		this.setState({search_text, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text, rids: [...rid_options]});
	}

	search_emails = (value) => {
		this.setState({search_emails_text: value});
	}

	get_rid_options = (search_text, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text) => {
		const params = this.generate_search_params(search_text, filter1_on, filter1_field, filter1_specificity, filter1_text, filter2_on, filter2_field, filter2_specificity, filter2_text, filter3_on, filter3_field, filter3_specificity, filter3_text);

		let rid_options = [];
		let excluded_rids = [];
		
		let rooms_without_fields = {};
		let error_buf = '';

		params['field'].forEach( field_check => {
			const key = field_check[0];
			rooms_without_fields[key] = 0;
		})

		ldb.data.rooms._idlist.forEach( rid => {
			const room = ldb.data.rooms._items[rid];
			if (room.is_mailbox)
				return;
			
			if (room.hidden)
				return;

			let include = true;
			
			params['field'].forEach( field_check => {
				const key = field_check[0];
				const fvalue = field_check[1];
				const fspec = field_check[2];
				
				// Check if this is a checkbox object
				// TBD Add syntax and support for checking if checkboxes are false
				if ((room.info[key]) && (room.info[key].value.constructor == Object)) {
					if (!room.info[key].value[fvalue]) {
						include = false;
					}
				} else if ((!room.info[key]) || ((fspec == 'is_exactly') && (room.info[key].value != fvalue)) || ((fspec != 'is_exactly') && (!RegExp(escape_reg_exp(fvalue), 'i').test(room.info[key].value)))) {
					include = false;
				}
				
				if (!room.info[key]) {
					rooms_without_fields[key] = rooms_without_fields[key] + 1;
				}
			});

			params['name'].forEach( name_check => {
				const re = RegExp(escape_reg_exp(name_check), 'i');
				const res = re.test(room.name) 
				if (!res)
					include = false;
			});

			params['category'].forEach( category_check => {
				const fvalue = category_check[0];
				const fspec = category_check[1];
				
				if (fspec == 'is_exactly') {
					if (fvalue != room.category) {
						include = false;
					}
				} else {
					const re = RegExp(escape_reg_exp(fvalue), 'i');
					const res = re.test(room.category) 
					if (!res)
						include = false;
				}
			});
						
			if (include)
				rid_options.push(room.id);
			else
				excluded_rids.push(room.id);
		});

		params['field'].forEach( field_check => {
			const key = field_check[0];
			const num_rooms_without_field = rooms_without_fields[key];
			if (num_rooms_without_field  > 0) {
				error_buf = error_buf + num_rooms_without_field + ' room(s) do not have the field "' + key + '" set\n';
			}
		})

		let rids_prep = [...rid_options].sort(function(a, b) {
			const aroom = get_room(a);
			const broom = get_room(b);
			
			return (aroom.name.localeCompare(broom.name));
		});

		rid_options = rids_prep;
		
		return [rid_options, excluded_rids, error_buf];
	}
	
	room_template(item) {
		const room = get_room(item);

		return (
			<div>
				{room.name}
			</div>
		);
	}

	email_in_list = (eid) => {
		return this.state.included_eids.includes(eid);
	}

	toggle_list_email = (eid) => {
		let included_eids = this.state.included_eids;
		
		if (this.email_in_list(eid)) {
			included_eids.splice(included_eids.indexOf(eid), 1);
		} else {
			included_eids.push(eid);
		}
		
		this.setState({included_eids: included_eids});
	}

	delete_list_complete = (error) => {
		if (!error)
			growl("Deleted Bulk Email List");
		
		this.props.parwrap('list');
	}

	delete_list = () => {
		if (!confirm('Delete this bulk email list?')) {
			return false;
		}

		this.setState({working: true});
		
		const args = {
			cmd: 'delete_bulk_email_list', 
			bid: this.props.bid,
		}
		api( args, this.delete_list_complete );
	}

	view_list_tab = () => {
		const {bid, parwrap} = this.props;
		
		const bulk_email_list = get_bulk_email_list(bid);
		
		const bulk_email_list_data = get_bulk_email_list_data(bid);
		
		return (<div>
			{(bulk_email_list.data_json == '{}') && <div className="bulk-email-list-tab">
				There is no data set for this bulk email list yet.
			</div>}
			
			{(bulk_email_list.data_json != '{}') && <div className="bulk-email-list-tab">
			<div style={{marginBottom: '10px'}}>
				{bulk_email_list_data.rcount + ' Room' + ((bulk_email_list_data.rcount == 1) ? '' : 's') + ', ' + bulk_email_list_data.ccount + ' Client' + ((bulk_email_list_data.ccount == 1) ? '' : 's') + ', and ' + bulk_email_list_data.ecount + ' Email' + ((bulk_email_list_data.ecount == 1) ? '' : 's')}
			</div>
			{bulk_email_list.data.rids.map( rid => {
				const room = get_room(rid);
				return (<div className="bulk-email-room-item" key={"selected_bulk_room_key_" + rid} style={{padding: '15px', marginBottom: '5px', border: '1px solid #ccc'}}>
					<div className="p-grid">
					<div className="p-col-12 bulk-email-room-name">
						<i className="fa fa-fw fa-home"></i>
						{em(0.5)}
						{room.name}
					</div>
					</div>
					{room.clients._idlist.map( cid => {
						const client = room.clients._items[cid];
						const iemails = this.state.included_eids;

						let email_selected = false;
						client.emails._idlist.forEach(function(eid) {
							if (iemails.includes(eid)) {
								email_selected = true;
							}
						});
						
						if (!email_selected) {
							return null;
						}
						
						return (<div key={"selected_bulk_room_client_key_" + client.id}>
							<div className="p-grid">
							<div className="p-col-12 bulk-email-room-name">
								{em(3)}
								<i className="fa fa-fw fa-user-o"></i>
								{em(0.5)}
								{client.name}
							</div>
							</div>
							{client.emails._idlist.map( eid => {
								if (!this.state.included_eids.includes(eid)) {
									return null;
								}
								
								const email = client.emails._items[eid];
								
								return (<div key={"selected_bulk_room_client_email_key_" + email.id}>
									<div className="p-grid">
									<div className="p-col-12 bulk-email-room-name" style={{color: '#999'}}>
										{em(6)}
										{email.email}
									</div>
									</div>
									</div>)
							})}
						</div>);
					})}
				</div>);
			})}
			</div>}
		</div>);
	}

	name_tab = () => {
		return (<div className="bulk-email-list-tab">

			<div className="p-grid">
			<div className="p-col-1" style={{paddingTop: '15px'}}>List Name:</div>
			<div className="p-col-6">
			<InputText type="text" placeholder="Bulk Email List Name"
				value={this.state.name} 
				onChange={e => {
					this.setState({name: e.target.value});
				}}
				className="bulk-email-input"
			/>
			</div>
			</div>

		</div>);
	}
	
	staff_tab = () => {
		return (<div className="bulk-email-list-tab">
			<SelectStaff sids={this.state.sids} update={sids => this.setState({sids})} legend="Staff Access:" />	
		</div>);
	}
	
	select_rooms_tab = () => {
		let room_info_fields = {category: {name: 'Category', kind: 'text'},
					_caption: {name: 'Caption', kind: 'text'},
					_flag: {name: 'Flag', kind: 'text'},
					_notes: {name: 'Notes', kind: 'textarea'},
		};
		Object.assign(room_info_fields, (ldb.data.org.settings.room_info_fields || {}) );
		
		const field_options = Object.keys(room_info_fields).map(key => ({label:room_info_fields[key].name, value:key}));
		
		const specificity_options = [{label:'contains', value:'contains'}, {label:'is exactly', value:'is_exactly'}];
		
		const filter1_field_values = get_values_for_room_field(this.state.filter1_field);
		const filter2_field_values = get_values_for_room_field(this.state.filter2_field);
		const filter3_field_values = get_values_for_room_field(this.state.filter3_field);
		
		return (<div className="bulk-email-list-tab">
			
			<div className="p-grid" style={{marginBottom: '20px', marginLeft: '64px'}}>
			<div className="p-col-1" style={{paddingTop: '15px'}}>
				Search Rooms:
			</div>
			<div className="p-col-6">
				<InputText type="text" placeholder="Search"
					value={this.state.search_text} 
					onChange={e => {
						this.search_rooms(e.target.value, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					className="bulk-email-input-extra-small"
				/>
				{em(0.5)}
				<span className="pi pi-search"/>
			</div>
			<div className="p-col-5"></div>

			<div className="p-col-1" style={{paddingTop: '15px', fontSize: 'small'}}>
				<Checkbox 
					onChange={e => {
						this.search_rooms(this.state.search_text, (!this.state.filter1_on), this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					checked={this.state.filter1_on}
				/>
				{em(1)}
				{(this.state.filter1_on) && <span>
				And filter by:
				</span>}
				{(!this.state.filter1_on) && <span style={{color: '#a6a6a6'}}>
				And filter by:
				</span>}
			</div>
			<div className="p-col-2">
				<Dropdown value={this.state.filter1_field}
					placeholder="Choose Field"
					options={field_options}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, e.value, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					disabled={(!this.state.filter1_on)}
				/>
			</div>
			<div className="p-col-2">
				<Dropdown value={this.state.filter1_specificity}
					options={specificity_options}
					onChange={e => {
						let filter_text = '';
						if (e.value == 'exact') {

						} else {
							
						}
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, e.value, '', this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					disabled={(!this.state.filter1_on)}
				/>
			</div>
			<div className="p-col-4">
				{(this.state.filter1_specificity != 'is_exactly') && <InputText type="text" placeholder="Enter Value"
					value={this.state.filter1_text} 
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, e.target.value, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					className="bulk-email-input-extra-small"
					disabled={(!this.state.filter1_on)}
				/>}
				{(this.state.filter1_specificity == 'is_exactly') && <Dropdown value={this.state.filter1_text}
					placeholder="Choose Value"
					options={filter1_field_values}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, e.value, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					style={{width: '335px'}}
					disabled={(!this.state.filter1_on)}
				/>}
			</div>
			<div className="p-col-3"></div>

			<div className="p-col-1" style={{paddingTop: '15px', fontSize: 'small'}}>
				<Checkbox 
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, (!this.state.filter2_on), this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					checked={this.state.filter2_on}
				/>
				{em(1)}
				{(this.state.filter2_on) && <span>
				And filter by:
				</span>}
				{(!this.state.filter2_on) && <span style={{color: '#a6a6a6'}}>
				And filter by:
				</span>}
			</div>
			<div className="p-col-2">
				<Dropdown value={this.state.filter2_field}
					placeholder="Choose Field"
					options={field_options}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, e.value, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					disabled={(!this.state.filter2_on)}
				/>
			</div>
			<div className="p-col-2">
				<Dropdown value={this.state.filter2_specificity}
					options={specificity_options}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, e.value, '', this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					disabled={(!this.state.filter2_on)}
				/>
			</div>
			<div className="p-col-4">
				{(this.state.filter2_specificity != 'is_exactly') && <InputText type="text" placeholder="Enter Value"
					value={this.state.filter2_text} 
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, e.target.value, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					className="bulk-email-input-extra-small"
					disabled={(!this.state.filter2_on)}
				/>}
				{(this.state.filter2_specificity == 'is_exactly') && <Dropdown value={this.state.filter2_text}
					placeholder="Choose Value"
					options={filter2_field_values}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, e.value, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					style={{width: '335px'}}
					disabled={(!this.state.filter2_on)}
				/>}
			</div>
			<div className="p-col-3"></div>

			<div className="p-col-1" style={{paddingTop: '15px', fontSize: 'small'}}>
				<Checkbox 
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, (!this.state.filter3_on), this.state.filter3_field, this.state.filter3_specificity, this.state.filter3_text);
					}}
					checked={this.state.filter3_on}
				/>
				{em(1)}
				{(this.state.filter3_on) && <span>
				And filter by:
				</span>}
				{(!this.state.filter3_on) && <span style={{color: '#a6a6a6'}}>
				And filter by:
				</span>}
			</div>
			<div className="p-col-2">
				<Dropdown value={this.state.filter3_field}
					placeholder="Choose Field"
					options={field_options}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, e.value, this.state.filter3_specificity, this.state.filter3_text);
					}}
					disabled={(!this.state.filter3_on)}
				/>
			</div>
			<div className="p-col-2">
				<Dropdown value={this.state.filter3_specificity}
					options={specificity_options}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, e.value, '');
					}}
					disabled={(!this.state.filter3_on)}
				/>
			</div>
			<div className="p-col-4">
				{(this.state.filter3_specificity != 'is_exactly') && <InputText type="text" placeholder="Enter Value"
					value={this.state.filter3_text} 
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, e.target.value);
					}}
					className="bulk-email-input-extra-small"
					disabled={(!this.state.filter3_on)}
				/>}
				{(this.state.filter3_specificity == 'is_exactly') && <Dropdown value={this.state.filter3_text}
					placeholder="Choose Value"
					options={filter3_field_values}
					onChange={e => {
						this.search_rooms(this.state.search_text, this.state.filter1_on, this.state.filter1_field, this.state.filter1_specificity, this.state.filter1_text, this.state.filter2_on, this.state.filter2_field, this.state.filter2_specificity, this.state.filter2_text, this.state.filter3_on, this.state.filter3_field, this.state.filter3_specificity, e.value);
					}}
					style={{width: '335px'}}
					disabled={(!this.state.filter3_on)}
				/>}
			</div>
			<div className="p-col-3"></div>

			</div>
			
			<PickList source={this.state.rids} target={this.state.list_rids} itemTemplate={this.room_template}
				sourceHeader="Available Rooms" targetHeader="Selected Rooms"
				sourceStyle={{ height: '342px' }} targetStyle={{ height: '342px' }}
				onChange={e => {
					let orooms = this.state.rids;
					let crooms = this.state.list_rids;
					let iemails = this.state.included_eids;
					
					e.source.forEach(function(rid) {
						if (orooms.includes(rid)) {
							return;
						}
						
						const room = get_room(rid);
						room.clients._idlist.forEach(function(cid) {
							const client = room.clients._items[cid];
							client.emails._idlist.forEach(function(eid) {
								if (iemails.includes(eid)) {
									iemails.splice(iemails.indexOf(eid), 1);
								}
							});
						});
					});
					
					e.target.forEach(function(rid) {
						if (crooms.includes(rid)) {
							return;
						}

						const room = get_room(rid);
						room.clients._idlist.forEach(function(cid) {
							const client = room.clients._items[cid];
							client.emails._idlist.forEach(function(eid) {
								const email = client.emails._items[eid];
								if (email.priority < 1) {
									return;
								}
								
								if (!iemails.includes(eid)) {
									iemails.push(eid);
								}
							});
						});
					});
					
					this.setState({
						rids: e.source,
						list_rids: e.target,
						included_eids: iemails,
					});
				}}
			>
			</PickList>
		
		</div>);
	}
	
	search_email_check = (value) => {
		let tsearch = this.state.search_emails_text.trim();
		
		if (tsearch == '') {
			return false;
		}
		
		return value.toLowerCase().includes(tsearch); 
	}
	
	select_emails_tab = () => {
		const {bid, parwrap} = this.props;
		
		const bulk_email_list = get_bulk_email_list(bid);
		
		const bulk_email_list_data = get_bulk_email_list_data(bid);
		
		return (<div className="bulk-email-list-tab">

	<div className="bulk-email-section">
		<div style={{marginBottom: '10px'}}>
			<InputText type="text" placeholder="Search"
				value={this.state.search_emails_text} 
				onChange={e => {
					this.search_emails(e.target.value);
				}}
				className="bulk-email-input-extra-small"
			/>
			{em(0.5)}
			<span className="pi pi-search"/>
		</div>
		<div style={{marginBottom: '10px'}}>
			{bulk_email_list_data.rcount + ' Room' + ((bulk_email_list_data.rcount == 1) ? '' : 's') + ', ' + bulk_email_list_data.ccount + ' Client' + ((bulk_email_list_data.ccount == 1) ? '' : 's') + ', and ' + bulk_email_list_data.ecount + ' Email' + ((bulk_email_list_data.ecount == 1) ? '' : 's')}
		</div>
		
		<div className="bulk-email-room-list-div">			
			<ScrollPanel className="bulk-email-selected-room-list">
				{(this.state.list_rids.length > 0) && this.state.list_rids.map( rid => {
					const room = get_room(rid);
					if (!room)
						return null;
					
					return (<div className="bulk-email-room-item" key={"selected_bulk_room_key_" + rid} style={{padding: '15px', marginBottom: '5px', border: '1px solid #ccc'}}>
							<div className="p-grid">
							<div className={"p-col-12 bulk-email-room-name" + (this.search_email_check(room.name) ? ' bulk-email-search-match' : '')}>
								<i className="fa fa-fw fa-home"></i>
								{em(0.5)}
								{room.name}
							</div>
							</div>
							{room.clients._idlist.map( cid => {
								const client = room.clients._items[cid];
								
								return (<div key={"selected_bulk_room_client_key_" + client.id}>
								<div className="p-grid">
								<div className={"p-col-12 bulk-email-room-name" + (this.search_email_check(client.name) ? ' bulk-email-search-match' : '')}>
									{em(3)}
									<i className="fa fa-fw fa-user-o"></i>
									{em(0.5)}
									{client.name}
								</div>
								</div>
								{client.emails._idlist.map( eid => {
									const email = client.emails._items[eid];
									if (email.priority < 0) {
										return null;
									}
									
									return (<div key={"selected_bulk_room_client_email_key_" + email.id}>
									<div className="p-grid">
									<div className={"p-col-12 bulk-email-room-name" + (this.search_email_check(email.email) ? ' bulk-email-search-match' : '')} style={{color: '#999'}}>
										{em(6)}
										<Checkbox onChange={e => {this.toggle_list_email(email.id);}} checked={(this.email_in_list(email.id))} />
										{em(0.5)}
										{email.email}
										{em(0.5)}
										{(email.priority == 1) && <i className="pi pi-fw pi-check-circle" title="Primary Email (Active)" />}
										{(email.priority == 0) && <i className="pi pi-fw pi-check" title="Secondary Email (Active)" />}
										<div className="cemail-comment">
										{em(9.5)}
										{email.comment}
										</div>
									</div>
									</div>
	
									</div>)
								})}
								</div>)
							})}
						</div>);

				})}
				{(this.state.list_rids.length == 0) && <div>No Rooms in Bulk Email List</div>}
			</ScrollPanel>
		</div>
	</div>

		</div>);
	}
	
	select_cc_bcc = () => {
		return (<div className="bulk-email-list-tab">
			
			<div style={{marginBottom: '20px'}}>
			<CcBccRows draft={this.state.draft} reload={this.reload_comp} />
			</div>

		</div>);
	}
	
	admin_tab = () => {
		return (<div className="bulk-email-list-tab">
			<div style={{marginBottom: '20px'}}>
			Add notifications for other staff for client rooms that receive bulk emails:
			{em(1)}
			<Checkbox 
				onChange={e => {
						this.setState({notify_rooms: (!this.state.notify_rooms)});
					}}
				checked={this.state.notify_rooms}
			/>
			</div>
			<div style={{marginBottom: '20px'}}>
			<Button label="Delete List"
				icon="pi pi-fw pi-times"
				className="p-button-primary"
				onClick={this.delete_list}
			/>
			</div>

		</div>);
	}

	render() {
		const {bid, parwrap} = this.props;
		
		const bulk_email_list = get_bulk_email_list(bid);
		
		const bulk_email_list_data = get_bulk_email_list_data(bid);
		
		return (<div>

			<div style={{marginBottom: '20px'}}>
				<Link to="#" onClick={function() {parwrap('list');}}>
					&lt; Return to bulk email lists
				</Link>
				
				{em(10)}
				
				<span className="bulk-email-list-title">
				{bulk_email_list.name}
				
				{em(10)}
				
				<Button label="Save"
					icon="pi pi-fw pi-save"
					className="p-button-primary"
					onClick={this.update_list}
				/>
				
				</span>
			</div>

			<TabView activeIndex={this.state.index} onTabChange={e=>this.setState({index: e.index})}>

			<TabPanel header="View List">
			
			{this.view_list_tab()}
			
			</TabPanel>
			
			<TabPanel header="List Name">
			
			{this.name_tab()}
						
			</TabPanel>
			
			<TabPanel header="Staff Access">
			
			{this.staff_tab()}
			
			</TabPanel>
			
			<TabPanel header="Select Rooms">

			{this.select_rooms_tab()}
			
			</TabPanel>
			
			<TabPanel header="Select Clients/Emails">
			
			{this.select_emails_tab()}
			
			</TabPanel>

			<TabPanel header="CC/BCC">
			
			{this.select_cc_bcc()}
			
			</TabPanel>

			<TabPanel header="Admin">
			
			{this.admin_tab()}
			
			</TabPanel>
			
			</TabView>
			
		</div>);
	}
}

function BulkEmailLists(props) {
	const [refresh, setRefresh] = React.useState(0);
	const reload = () => {
		setRefresh(refresh+1);
	}
	const [state, setState] = React.useState('list');
	const [viewing, setViewing] = React.useState(0);

	const wrapperSetParentState = useCallback(val => {
		setState(val);
	}, [setState]);

	let bulk_email_lists = ldb.data.bulk_email_lists;

	const update = buf => {
		reload();
	}

	const view_bulk_lists = buf => {
		setState('list');
		reload();

		return false;
	}

	const add_bulk_list = buf => {
		setState('add');
		reload();

		return false;
	}

	const update_bulk_list = buf => {
		setState('update');
		reload();

		return false;
	}

	const create_list_complete = (error) => {
		if (!error)
			growl("Created New Bulk Email List");

		setState('list');
	}

	const create_list = () => {
		if (!confirm('Create a new bulk email list?')) {
			return false;
		}

		setState('working');
		
		const args = {
			cmd: 'add_bulk_email_list', 
		}
		api( args, create_list_complete );
	}

	return (
	<div className="bulk-org">
		<h3 className="p-grid">
		<div className="p-col-2 back">
			{(state != 'view') && 
			<Link to="/settings/org" >
				&lt; Manage
			</Link>}
		</div>

		<div className="p-col-3">
			Bulk Email Lists
		</div>
			
		<div className="p-col-7">
		</div>

		</h3>

		<div className="bulk-sep"></div>
		
		<div className="p-grid">
		
		{(state == 'list') && <div className="p-col-12" style={{backgroundColor: '#FFF'}}>
		
		<div style={{marginBottom: '20px'}}>
			<Button label="Create New Bulk Email List"
				icon="pi pi-fw pi-plus"
				className="p-button-primary"
				onClick={create_list}
			/>
		</div>

		{(bulk_email_lists._idlist.length == 0) &&
			<div>
				You currently have no bulk email lists
			</div>
		}

		{(bulk_email_lists._idlist.length > 0) &&
			<ul>
				{bulk_email_lists._idlist.map( bid => {
					const blist = bulk_email_lists._items[bid];
					console.log(bid, blist);
					
					return (<li key={"bulk_email_list_" + bid}><Link to="#" onClick={function() {setViewing(bid);setState('view');return false;}}>{blist.name}</Link></li>)
				})}
			</ul>
		}

		</div>}
		
		{(state == 'view') && <div className="p-col-12" style={{backgroundColor: '#FFF'}}>
		
		<div>
			<DisplayBulkEmailList bid={viewing} parwrap={wrapperSetParentState} />
		</div>
		
		</div>}

		</div>
	</div>
	);
}



export default BulkEmailLists;

