import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Dropdown} from 'primereact/dropdown';
import {ScrollPanel} from 'primereact/scrollpanel';
import {Checkbox} from 'primereact/checkbox';

import sanitizeHtml from 'sanitize-html';

//**-------- SunEditor
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import './suneditor-override.css';

import {EditBody} from './EditTask';
import {decode_mime, map_inline_attachments} from './EmailUtil';
import {ModalCommand, Spacer, Focusable, lazyFocus, HelpInfo} from './Utils';
import {CcBccRows, init_draft} from './NewEmail';
import {ChooseRoom} from './MoveEmail';

import {ldb, log, gen_email_summary, growl, api, get_room, list_rm_ele,
	pri_n2v, get_room_options, is_staff_a_recipient_of_shared_email,
	map_list, filter_list, gen_xid, get_item, em, escape_reg_exp,
	get_body_part, bulk_email_list_options, get_bulk_email_list, 
	html2plain, get_bulk_email_list_data, go, drafts_tab,
	} from './Lib';



const attach_files = env => env.body.filter(part => part.is_attachment).map(
    part => {
	const ab = decode_mime(part);
	const blob = new Blob([ab], {type: part.content_type});
	const url = URL.createObjectURL(blob);
	
	return {
		url,
		payload: {
			name: part.file_name,
			size: part.data.length,
			kind: part.content_type,
			b64data: part.data,
		},
	};
})

function CopyEmailConfirm(props) {
	const {onHide, onConfirm, subject, par} = props;
	
	const selected_rid = par.state.selected_rid;
	const room = get_room(selected_rid);
	
	return (
	<ModalCommand header="Confirm Copy To Room"
		onHide={onHide} className="medium-modal-command"
		extra={{maximizable:false}}
	>
		Are you sure you want to send a copy of the email
		"{subject}" to {room.name}? 
		<br/><br/>
		You will be taken to the create email page for this room with a copy of this email prefilling the editor. You can make any changes you desire before sending it to the room.
		<br/><br/>
		<Button label="Confirm Room"
			icon="pi pi-fw pi-plus"
			onClick={e=> {
				onConfirm();
				onHide();
			}} />
		{em(2)}
		<Button label="Cancel"
			className="bulk-email-secondary-button"
			icon="pi pi-times"
			onClick={e=> {
				onHide();
			}} />
	
	</ModalCommand>
	);
}

const plain2html = plain => plain.split('\n').join('<br/>')

class CopyToOtherRoom extends Focusable {
	constructor(props) {
		super(props);
		const {item, rid, par} = this.props;
		
		const eid = item.id;
	
		const subject = item.subject;
		const room = get_room(rid);
		const working = false;
		const show_step1_confirm = false;
		const rid_options = ldb.data.rooms._idlist;
		const step = 1;
		const selected_rid = null;
		
		if (!eid) {
			this.props.close();
		}

		const env = get_item(rid, 'shared', eid);
		if (!env) {
			this.props.close();
		}
		
		let plain = '';
		let html = '';
		
		if (env) {
			plain = get_body_part(env.body, 'text/plain');
			html = get_body_part(env.body, 'text/html') || plain2html(plain);
			const options = map_inline_attachments(env.body);
			html = sanitizeHtml(html, options);
		}

		this.state = {html, plain, rid:0, selected_rid, step, rid_options, subject, show_step1_confirm, working};
		
		window.g_copyToOtherRoom = this;
	}
	
	on_body = e => {
		this.state.html = e.htmlValue;
		this.state.plain = e.textValue;
	}
	
	has_errors = () => {
		if (!this.props.item) {
			growl('Error', 'No email selected. Please close the popup', 'error');
			return true;
		}

		if (!this.state.selected_rid) {
			growl('Error', 'Please select a room', 'error');
			return true;
		}
				
		return false;
	}
	
	cancel = () => {
		this.props.close();	
	}
	
	prep_room_selected = () => {
		if (this.has_errors())
			return;
		
		this.setState({show_step1_confirm: true});
	}
	
	confirm_room = () => {
		if (this.has_errors())
			return;
		
		const {subject, plain, html, selected_rid} = this.state;
		const {item} = this.props;
		
		const eid = item.id;
		
		this.setState({show_step1_confirm: false, working: true});
		
		const selected_room = get_room(selected_rid);
		const tab = drafts_tab(selected_room);
		const rtab = selected_room[tab];
		delete rtab.drafts[0];
		
		let draftobj = init_draft({'rid': selected_rid, 'tab': 'shared', 'iid': 0, 'command': null});
		
		draftobj.html = html;
		draftobj.plain = plain;
		draftobj.subject = subject;
		draftobj.files = attach_files(item);

		const version_number = 1;

		const args = {
			cmd: 'save_draft', 
			rid: selected_rid, iid: 0, draft: draftobj,
			version_number
		}
		api( args, this.confirm_room_complete );
	}

	confirm_room_complete = (error) => {
		this.props.close();
		
		go('room', this.state.selected_rid, 'compose', '0');
	}
		
	render() {
		const {item, rid} = this.props;

		const room_options = get_room_options();
		
		return (
		<ModalCommand par={this}
			className="medium-modal-command"
			header="Copy Email To Other Room">
	
	<div className="p-grid">
		<div className="p-col-2">Subject:</div>
		<div className="p-col-10">
			<InputText value={this.state.subject} 
				ref={lazyFocus}
				disabled={true}
		  		onChange={e => {
		  			this.setState({subject: e.target.value});
				}}
				className="bulk-email-input"
			/>
		</div>
	</div>
	<div className="p-grid">
		<div className="p-col-2">Room to Copy To:</div>
		<div className="p-col-10">
			<ChooseRoom rid={this.state.selected_rid}
				frid={rid}
				same_room={true}
				onChoose={ rid => this.setState({selected_rid: rid})  }
			/>
		</div>
	</div>

	{this.state.error_buf && <div className="p-grid">
		<div className="p-col-2">
		</div>
		<div className="p-col-10 bulk-email-error-div">
			{this.state.error_buf}
		</div>
	</div>}
	{!this.state.working && <div className="p-grid">
		<div className="p-col-2">
			{this.state.show_step1_confirm && <div><CopyEmailConfirm par={this} subject={this.state.subject} onConfirm={this.confirm_room} onHide={e=>this.setState({show_step1_confirm: false})} /></div>}
		</div>
		<div className="p-col-10">
			<br/>
			<Button label="Select Room"
				icon="pi pi-directions"
				onClick={this.prep_room_selected}
			/>
			{em(2)}
			<Button label="Cancel"
				className="bulk-email-secondary-button"
				icon="pi pi-times"
				onClick={this.cancel}
			/>
		</div>
	</div>}
	{this.state.working && <ProgressSpinner/>}
		</ModalCommand>
		)
	}
}

export default CopyToOtherRoom;
