import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {InputText} from 'primereact/inputtext';
import {Menubar} from 'primereact/menubar';
import {ProgressSpinner} from 'primereact/progressspinner';
import PropTypes from 'prop-types';
import {ldb, log, settings_url, go, get_current_list_view_rid, get_current_list_view_tab, get_current_list_view_comp, api, get_list, get_current_room, is_favorite_room, is_return_later_room, is_room_a_cimr_room} from './cw/Lib';
import {Button} from 'primereact/button';
import {Menu} from 'primereact/menu';

import {Onlines, RoomPanel, RecentRoomsPanel} from './cw/Onlines';
import {ProgCircle} from './cw/ProgCircle';



const RoomBadges = props => {
	const par = props.par;
	const room = props.room;

	const is_favorite = is_favorite_room(room);
	const return_later = is_return_later_room(room);
	const is_cimr = is_room_a_cimr_room(room);
	
	const toggle_favorite_badge = () => {
		const new_value = is_favorite ? false : true;
		const args = { cmd: 'mod_badge', rid: room.id, field: 'favorite', value: new_value };

		api( args, (error, db) => {
			par.reload();
		});
	};
	
	const toggle_return_later_badge = () => {
		const new_value = return_later ? false : true;
		const args = { cmd: 'mod_badge', rid: room.id, field: 'return_later', value: new_value };

		api( args, (error, db) => {
			par.reload();
		});
	};
	
	const favorite_icon = is_favorite ? 'pi-star' : 'pi-star-o';
	const return_later_icon = return_later ? 'fa-bookmark' : 'fa-bookmark-o';
	
	const f_action_word = is_favorite ? 'Unmark' : 'Mark';
	const r_action_word = return_later ? 'Unbookmark' : 'Bookmark';

	const auto_move_icon = room.auto_move ? <i className="pi pi-fw pi-replay" title="Auto Share is On" style={{cursor: 'default'}}></i> : null;
	
	return (<span style={{fontSize: 'medium', marginLeft: '1em', marginTop: '0.2em'}}>
		{auto_move_icon}
		{is_cimr && <i className="pi pi-fw pi-link"
			title="Clients are in multiple rooms">
		</i>}
		<i className={"pi pi-fw " + favorite_icon}
			title={f_action_word + " as a favorite room"}
			style={{cursor: 'pointer'}}
			onClick={toggle_favorite_badge}>
		</i>
		<i className={"fa fa-fw fa-lg " + return_later_icon}
			style={{fontSize: 'medium', paddingBottom: '1px', cursor: 'pointer'}}
			title={r_action_word + " as a room to return to later"}
			onClick={toggle_return_later_badge}>
		</i>
	</span>);
}

class SearchBar extends Component {
	constructor(props) {
		super(props);
		this.state = {search_text: '', working:false};
		this._search_timer = null;
		this._target_comp = null;
		window.g_searchBar = this;
	}

	componentDidUpdate() {
		const {working, search_text} = this.state;
		if (working) {
			console.log('STARTING SEARCH');
			console.log(this._target_comp);
			
			if ((this._target_comp) && (this._target_comp.update_search)) {
				console.log('SEARCHING');
				console.log(this._target_comp);
				console.log(this._target_comp.update_search);
				this._target_comp.update_search( search_text );
			}
			this.setState({working:false});
		}
	}

	// Comps such as ListView, etc. need to register by
	//	g_searchBar.set_target_comp(this);
	// They also need to have
	//	a. update_search(search_text) method
	//	b. have search_text in its state

	set_target_comp = (comp) => {
		// log('search', 'set_target_comp', comp, this._target_comp);
		if (this._target_comp != comp) {
			// User clicked elsewhere in the middle of search.
			//	Reset search_text for new comp.
			// If new comp was in the middle of search,
			//	resume.
			this._target_comp = comp;
			const {search_text} = comp.state;
			if (search_text != this.state.search_text)
				this.setState({search_text});
		}
	}

	stop_timer = () => {
		if (this._search_timer) {
			clearTimeout(this._search_timer);
			this._search_timer = null;
		}
	}

	start_timer = () => {
		this.stop_timer();
		this._search_timer = setTimeout(() => this.reSearch(), 500)
	}

	reset = e => {
		const search_text = '';
		this.setState({search_text});
		this.reSearch(search_text);
	}

	keyDown = e => {
		let done = false;

		if (e.key === 'Enter') 
			done = true;
		else if (e.key === 'Escape') {
			this.setState({search_text: ''});
			done = true;
		}
		if (done)
			this.reSearch();
	}
	
	onChange = e => {
		this.setState({search_text:e.target.value});
		
		this.start_timer();
	}

	
	reSearch = () => {
		this.stop_timer();
		this.setState({working:true});
	}

	render() {
		const {search_text, working} = this.state;

		return <span className="layout-topbar-search">
			<span className={working? '' : 'invisible'}>
				<ProgressSpinner 
					style={{width:'1em', height:'1em'}}
					strokeWidth='8'
					fill='#CCCCCC'
				/>
			</span>

			<span className={search_text ? '' : 'invisible'}>
				<span className="pi pi-times pointer"
					onClick={this.reset}
				/>
			</span>
			
			<InputText type="text" placeholder="Search" 
				ref={inp => this._searchEle = inp}
				value={this.state.search_text} 
				onChange={this.onChange}
				onKeyDown={this.keyDown}
			/>
			<span className="layout-topbar-search-icon pi pi-search"/>
		</span>
	}
}

window.g_setTitle = () => null;
window.g_currentRoom = null;

/***
function MainTitle(props) {
	const [title, setTitle] = React.useState('');
	window.g_setTitle = ntitle => {
		if (ntitle != title) {
			log('lib', 'set.title', ntitle);
			setTitle(ntitle);
		}
	}
	log('lib', '****set.title********:', title, ':********');

	return (
	<div className="layout-topbar-title" title={title} >
		{title}
	</div>
	);
}
**/


export class AppTopbar extends Component {
	constructor(props) {
		super(props);
		this.state = {activeRoomCount: 0, title: '', refresh: 0};
		window.g_appTopbar = this;
		window.g_setTitle = title => {
			if (title != this.state.title) {
				log('lib', 'set.title', title);
				this.setState({title});
			}
		}
	}

	static defaultProps = {
		onToggleMenu: null
	}

	static propTypes = {
		onToggleMenu: PropTypes.func.isRequired
	}

	set_active_room_count = activeRoomCount => 
		this.setState({activeRoomCount})

	old_showUserMenu = e => {
		e.preventDefault();
		window.g_appInlineProfile.onClick(e);
	}
	
	is_my_tasks() {
		const parts = window.location.hash.split('/');
		if (parts[1] == 'task') {
			return true;
		}
		return false;
	}

	reload() {
		this.setState({refresh: this.state.refresh + 1});
	}
	
	render() {
		const {activeRoomCount, title} = this.state;
		const rooms_url = '/#/myrooms/';
		const count = activeRoomCount;
		
		let is_favorite = false;
		let return_later = false;
		//const current_room = get_current_room();
		const current_room = window.g_currentRoom;
				
		const old_items = [
	{label: '',
	// icon: 'layout-topbar-icon pi pi-fw pi-user usermenuicon',
	icon: 'pi pi-fw pi-user',
	items: [
		{label: 'Profile',
		icon: 'pi pi-fw pi-cog',
		command: () => go('settings/profile'),
		},
		{label: 'Logout',
		icon: 'fa fa-fw fa-sign-out',
		url: '/',
		},
	]
	}
			];
		const items = [
			{label: 'Profile',
			icon: 'pi pi-fw pi-cog',
			command: () => go('settings/profile'),
			},
			{label: 'Logout',
			icon: 'fa fa-fw fa-sign-out',
			url: '/',
			},
		];

		const user_items = [
			{label: 'User Profile', icon: 'pi pi-fw pi-user', command: () => { window.location.hash = '/settings/profile'; }},
			{label: 'Logout', icon: 'pi pi-fw pi-sign-in', command: () => { window.location = '/'; }}
		];

		return (
<div className="layout-topbar clearfix cwtopbar">
<div className="p-d-flex p-jc-between">
	<a className="p-mr-2 layout-menu-button" 
			style={{width:'3em'}} 
			onClick={this.props.onToggleMenu} 
			>
		<span className="pi pi-bars lmbtn"/>
	</a>
	<div className="p-mr-2 ">
		<div className="layout-topbar-title" title={title}>
			{title}
		</div>
	</div>

{ ldb.data.me && 

	<div className="layout-topbar-icons p-mr-2 p-d-flex" >
		{false && <SearchBar />}
		{current_room && <RoomBadges room={current_room} par={this} />}
		<RoomPanel count={count} />
		<a href={settings_url()} target="_blank" 
			style={{display:'none'}}
			title="Mail Server Settings"
			>
			<span className="layout-topbar-item-text">Settings</span>
			<span className="layout-topbar-icon pi pi-cog"/>
		</a>
		<Onlines />
		<ProgCircle />
		<Menu model={items} popup ref={el => this.menu = el} 
						id="topbar_popup" />
		<Button label="↓" icon="pi pi-fw pi-user"
			className="p-button p-button-text p-button-raised user-dropdown"
			onClick={ev => this.menu.toggle(ev)}
			aria-controls="topbar_popup"
			aria-haspopup />
	</div>
}
</div>
</div>
		);
	}
}
