import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {SelectButton} from 'primereact/selectbutton';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {Checkbox} from 'primereact/checkbox';
import {OverlayPanel} from 'primereact/overlaypanel';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, 
		TaskCommand, TaskSelectStaff,} from './EditTask';
import {ModalCommand} from './Utils';

import {ldb, log, get_room, map_list, set_order, get_list, 
	validate_email, growl, api} from './Lib';

class EditTo extends Component {
	constructor(props) {
		super(props);
		window.g_editTo = this;
	}

	close = () => this.props.par.setState({editing:false})

	get_items = () => this.props.par.props.par.state.tos

	no_blank_rows = items => {
		const blanks = items.filter(
			item => item.kind == 'Ext' && item.email == ''
		);
		return blanks.length == 0;
	}
	setVal = (id, prop, val) => {
		const items = this.get_items();
		const item = items[id];
		item[prop] = val;

		if (prop == 'email' && validate_email(val)) {
			item.checked = true;
			if (this.no_blank_rows(items))
				this.props.par.props.par.add_blank(items);
		}

		this.props.par.props.par.setState({tos:items}); 
		this.props.par.setState({show:true});
		// log('email', 'setVal', id, prop, val);
	}

	email = (item, id) => {
		if (item.kind != 'Ext')
			return item.email;
		return <InputText
			value={item.email}
			onChange={ e => this.setVal(id, 'email', 
					e.target.value) }
		/>
	}

	name = (item, id) => {
		const comment = item.comment ? 
				<div className="cemail-comment">
					{item.comment}
				</div> : null;
		if (item.kind != 'Ext')
			return <div>{item.name}{comment}</div>;

		return <InputText
			value={item.name}
			placeholder="Name (optional)"
			onChange={ e => this.setVal(id, 'name', 
					e.target.value) }
		/>
	}

	toggle_selall = checked => {
		const items = this.get_items();

		items.forEach(item => {
			item.checked = checked;
			if (item.kind == 'Ext' && item.email == '')
				item.checked = false;
		});

		this.props.par.props.par.setState({tos:items}); 
		this.props.par.setState({selall:checked, show: true});
	}

	render() {
		const items = this.get_items();
return <div>
	{items.map( (item,id) => <div className="p-grid" key={id}>
		<div className="p-col-1">
			<Checkbox checked={item.checked} 
				onChange={e => this.setVal(id, 'checked', 
							e.checked)}
				/>
		</div>
		<div className="p-col-5 small fade">
			{this.email(item, id)}
		</div>
		<div className="p-col-5">
			{this.name(item, id)}
		</div>
		<div className="p-col-1 xsmall">
			{item.kind}
		</div>
	</div>)}
	<div className="p-grid">
		<div className="p-col-1">
			<Checkbox checked={this.props.par.state.selall}
				onChange={e => this.toggle_selall(
					e.checked)}
				/>
		</div>

		<div className="p-col-11">
		<Button 
			label="Close" 
			icon="pi pi-fw pi-chevron-up" 
			onClick={this.close} />
		</div>
	</div>
</div>
	}
}

class EmailTo extends Component {
	constructor(props) {
		super(props);

		this.state = {show: true, editing: false, selall: true,
			mode: 'clients'};

		window.g_emailTo = this;
		/* TaskSubject uses this global to reset editing
		 * when user moves to subject
		 * Otherwise SendMail button is invisible when ToList
		 *	is expanded
		 */
	}

	toggle_edit = () => this.setState({editing: !this.state.editing})

	ignore = e => e.preventDefault()

	show_name = item => 
	<span key={item.id} 
			title={item.email} className="sep"
		>{item.name}</span>

	show_names = () => {
		const items = this.props.par.state.tos;

		return <div>
			{items.filter(item => item.checked).map((item,id) =>
				<span key={id} title={item.name} 
					className="sep xsmall"
					>{item.email} </span>
			)}
		<Button 
			label="Change" 
			icon="fa fa-fw fa-edit" 
			onClick={this.toggle_edit}
			className="p-button-secondary" 
		/>
		{' '}
		<Button 
			label="" 
			icon="pi pi-fw pi-info" 
			tooltip="Staff does NOT need to be copied in this email. They can see all emails in Common whether they are copied or not."
			tooltipOptions={{position:'left'}}
			onClick={this.ignore}
			className="p-button-secondary" 
		/>

		</div>;
	}

	show_mode = () => {
		const modes = [
			{label: 'Clients', value:'clients'},
			{label: 'Custom', value:'custom'},
			{label: 'Internal', value:'internal'},
		];
			
		return (
	<div className="p-grid email-to-mode">
		<div className="p-col-11">
		<SelectButton value={this.state.mode}
			options={modes}
			onChange={e=>this.setState({mode:e.value})}
			/>
		</div>
		<div className="p-col-1">
		<Button 
			label="" 
			icon="pi pi-fw pi-info" 
			tooltip="Staff does NOT need to be copied in this email. They can see all emails in Common whether they are copied or not."
			tooltipOptions={{position:'left'}}
			onClick={this.ignore}
			className="p-button-secondary" 
		/>
		</div>
	</div>
		);
	}

	blurb = () => <span>
		All Clients and Staff in this room.
		<Button 
			label="Change" 
			icon="fa fa-fw fa-edit" 
			onClick={this.toggle_edit}
			className="p-button-secondary" 
		/>
		</span>

	render() {
		const {show,editing, mode} = this.state;
		let content;

		if (editing)
			content = <EditTo par={this} />;
		else if (show)
			content = this.show_names();
		else
			content = this.blurb();

		return (
<div className="p-grid">
	<label className="p-col-2">To:</label>
	<div className="p-col-10">
		{content}
	</div>
</div>
		)
	}
}

export default EmailTo;
