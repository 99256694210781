import React, {Component} from 'react'; 
import {ProgressSpinner} from 'primereact/progressspinner';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';

import {TaskSubject, TaskAttach, TaskNote, TaskPriority, TaskDone, TaskDueDate,
	TaskCategory, TaskCommand, TaskSelectStaff,} from './EditTask';
import {PriorTaskNotes} from './TaskUtil';
import {ModalCommand, Spacer, Focusable} from './Utils';

import {ldb, log, gen_email_summary, growl, api} from './Lib';

class UpdateTask extends Focusable {
	// props = {item, close}
	constructor(props) {
		super(props);
		this.state = this.load();
		window.g_updateTask = this;
	}

	componentDidUpdate() {
		if (this.state.tid != this.props.item.id)
			this.setState( this.load() );
	}

	load = () => { 
		const task = this.props.item;
		// We might lose some accuracy in this conversion.
		//	When we send it out even without changes,
		//	server may see it as a new date. So track if 
		// 	date is modified and send null if not.
		const dt_due = window.g_moment(task.dt_due).toDate();

		// log('task', 'load', task);

		return {
			tid : task.id,
			subject: task.name, 
			to_sid: task.to_sid, 
			priority: task.priority, 
			category: task.category,
			dt_due,
			dt_due_mod: false,	// see comment above
			note: '',
			done: task.dt_done ? true : false,
		};
	}


	onUpdate = () => {
		let {note, subject, category, to_sid, done, priority,
			dt_due, dt_due_mod} = this.state;
		const task = this.props.item;
		const tid = task.id;

		// see comment in constructor
		if (!dt_due_mod)
			dt_due = null;

		const args = {
			cmd: 'update_task', 
			dt_due, note, tid, subject, category, note, 
			to_sid, priority, done};

		// log('task', 'update', args, done, this.state);

		api( args, this.updated );
	}

	updated = (error) => {
		this.props.close();
	}

	render() {
		const {item} = this.props;
		const rid = item.rid;
		if (!rid)
			return null;	// task not loaded

		return (
		<ModalCommand par={this} header="Update Task" >

	<Spacer />
  	<TaskSubject par={this} />
  	<TaskCategory par={this} />
	<Spacer />
  	<TaskSelectStaff par={this} rid={rid} />
	<Spacer />
  	<TaskNote par={this} autofocus={true} />
	<Spacer />
	<PriorTaskNotes task={item} />
	<Spacer />
  	<TaskDueDate par={this} show_done={true} />
	<Spacer />
  	<TaskPriority par={this} />
	<Spacer />
  	<TaskCommand onClick={this.onUpdate} label="Update" />
		</ModalCommand>
		)
	}
}

export default UpdateTask;
