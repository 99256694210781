import React, { Component } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import {Dialog} from 'primereact/dialog';
import 'react-circular-progressbar/dist/styles.css';

import {Focusable, Spacer} from './Utils';
import {ldb, log, api, go, edate, edate3, map_list, go_url, regcomp,
	set_order, resort,
	get_first_name, show_is_new, get_room, get_staff, } from './Lib';

//------------------ Circular Progressbar on Topbar  ------------------------

window.g_progCircle = null;

function ProgCircle(props) {
	const [ items, setItems ] = React.useState({});
	const [ refresh, setRefresh ] = React.useState(0);
	const [ attn, setAttn ] = React.useState('');

	window.g_progCircle = {
		// bid = batch id, op name, tasks in batch
		add : (bid, name, count) => {
			items[bid] = {name, count, done:0, errors:[],
					active:true};
			setItems(items);
			setAttn(bid);
			setRefresh(refresh+1);
			return items;
		},
		done : (bid, ndone, error) => {
			ndone = ndone === undefined ? 1 : ndone;
			const item = items[bid];
			if (item === undefined) {
				// Not our batch id. another staff sharing room
				return null;
			}
			item.done += 1;
			if (item.done >= item.count)
				item.active = false;
			if (error)
				item.errors.push(error);
			setItems(items);
			setRefresh(refresh+1);
			if (attn == bid)
				setAttn('');
			return items;
		},
		list: () => items,
		attn: attn,
	};

	// log('online', 'svg', url);
	let count = 0, done = 0, percent = 0, msg='', dmsg='', pmsg = '';
	for (const bid in items) {
		const item = items[bid];
		if (item.active) {
			count += item.count;
			done += item.done;
			msg += item.name + ` (${item.done} of ${item.count})\n`;
		}
		else {
			dmsg += item.name + ': ' + item.count + ' emails\n';
		}
	}
	if (dmsg)
		msg += '\nCompleted: ' + dmsg;

	if (count) {
		percent = Math.floor(done * 100.0 / count);
		pmsg = percent + '%';
	}
	else {
		percent = 0;
		pmsg = '';
	}

	if (!msg)
		msg = 'Background Activity Progress Bar\n\nNothing going on right now.';

	// log('online', 'prog_circle', count, done, percent, msg, pmsg, items);

	return (
	<div className="circ-prog-wrap" >
		<CircularProgressbar 
			className="circ-prog"
			value={percent} 
			text={pmsg}
		/>
		<div className="circ-prog-panel">
			<pre>
				{msg}
			</pre>
		</div>
		<Dialog header="In Progress..." visible={attn != ''}
			onHide={e => setAttn('')}>
			Operations are in progress in the background.
			<br/>
			You can do other things, no need to wait.
			<br/>
			You can check progress at circular progress 
			bar icon 
			<br/>
			in the top right of the screen.
		</Dialog>
	</div>
	);
}

export {ProgCircle};
