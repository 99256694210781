import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {PickList} from 'primereact/picklist';

import {ldb, log, go, get_room, go_url, get_first_id, growl,
	filter_list, api,
} from './Lib';

//-------------- Add Staff ---------------

class AddStaff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rid: null,
			staffs: [],
			targetStaffs: [],
		};
		window._addStaff = this;
	}

	componentDidMount() {
		this.loadOptions();
	}
	
	componentDidUpdate() {
		this.loadOptions();
	}

	loadOptions = () => {
		const {rid} = this.props;
		if (rid != this.state.rid) {
			const room = get_room(rid);
			const order = room.staffs._order;
			const staffs = filter_list(ldb.data.staffs, 
				staff => (order.indexOf(staff.id) < 0));

			const targetStaffs = filter_list(room.staffs,
				staff => true);
			this.setState({rid, staffs, targetStaffs});
		}
	}

	save = e => {
		let added = [];
		let removed = [];
		const {rid} = this.props;
		const room = get_room(rid);
		const {targetStaffs} = this.state;
		const curSids = room.staffs._order;
		const targetSids = targetStaffs.map(staff => staff.id);

		if (targetStaffs.length == 0) {
			growl('Error', 'You need at least one staff member');
			return;
		}

		targetSids.forEach(sid => {
			if (curSids.indexOf(sid) < 0)
				added.push(sid);
		});

		curSids.forEach(id => {
			if (targetSids.indexOf(id) < 0)
				removed.push(id);
		});

		log("room", "save", added, removed, curSids, targetSids);

		const args = {
			cmd: 'mod_staff', 
			rid,
			added,
			removed,
		}
		api( args, this.saved );
	}

	saved = e => {
		this.props.close(e);
	}

	//staffTemplate = item => item ? item.name : ''

	staffTemplate = (item) => {
		const name = item ? item.name : '';
		const email = item ? item.email : '';
		
		return (
			<div title={email}>
                		{name}
			</div>
		);
	}
	
	render() {
	 	return (
                    <Dialog header="Add Staff" 
		    visible={true}
		    width="50%" minY={70} draggable={true}
			modal={true}
		    onHide={this.props.close}
		    maximizable={true}
		    	>
		<PickList source={this.state.staffs}
			target={this.state.targetStaffs}
			itemTemplate={this.staffTemplate}
			onChange={ e => this.setState({
				staffs: e.source,
				targetStaffs: e.target})} 
			sourceHeader="Available"
			targetHeader="Selected"
			showSourceControls={false}
			showTargetControls={false}
					/>

					<br/>
		<Button label="Save" onClick={this.save} />
		<br/>
                    </Dialog>
	
		)
	}
}

export default AddStaff;
