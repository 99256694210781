import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {Card} from 'primereact/card';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Checkbox} from 'primereact/checkbox';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Dropdown} from 'primereact/dropdown';
import {Fieldset} from 'primereact/fieldset';

import {ModalCommand, HelpInfo} from './Utils';
import {EditSig, AddAltSig, EditAltSig, DeleteAltSig} from './EditSig';
import {SanitizeHTML} from './Utils';

import {ldb, log, 
	} from './Lib';

//------------------ Push Notifications ------------------------

function PushNotif() {
	const wn = ldb.data.webnotify;
	const [mode, setMode] = React.useState('uninited');
	const [result, setResult] = React.useState('Initializing...');
	const swjs = '/pushnotif.js';
	let output = result;
	log('user', 'PushNotif======================', mode);

	const notif_permission = () => {
		Notification.requestPermission(st => {
			log('user', 'requestPermission', st);
			wn.permission = st;
			if (st == 'granted') {
				setMode('enabled');
				wn.active = !wn.suspended;
			}
			else if (st == 'default') {
				setMode('default');
				wn.active = false;
			}
			else {
				setMode('denied');
				wn.active = false;
			}
		});
	}

	const notif_test = () => {
		wn.webpushreg.showNotification(
			'Notification Test from TagInbox');
	}
	const notif_denied = () => {
		output = (
	<div>
		You blocked notifications from TagInbox.
		<br/><br/>
		Browser won&rsquo;t let TagInbox change that setting.
		You will have to enable notifications yourself.
		<br/><br/>
		To do so, for Chrome browser:
		<ol>
		<li> 
			Click on Chrome menu (three vertical dots in top right)
		</li>
		<li> 
			Choose <i>Settings</i>
		</li>
		<li> 
			Choose <i>Privacy and Security</i>
		</li>
		<li> 
			Choose <i>Site Settings</i>
		</li>
		<li> 
			Choose <i>Notifications</i>
		</li>
		<li> 
			If you find TagInbox in Block, click three dots
		</li>
		<li> 
			Click <i>Allow</i>
		</li>
		<li> 
			You could also choose arrow next to TagInbox 
			and select <i>Reset Permissions</i>
		</li>

		</ol>
	</div>
		);
	}

	const notif_enabled = () => {
		output = (
	<div>
		Web Push Notifications are enabled.
		<br/><br/>
		When you switch to another tab or another program,
		TagInbox will send a notification you when you are assigned
		a task, tag or receive new email.
		<br/><br/>

	    <div className="p-grid">
		<div className="p-col-4">
		<Button label="Test"
			icon="fa fa-fw fa-bell-o"
			className="p-button-secondary"
			onClick={notif_test}
			/>
		</div>
		<div className="p-col-8">
		Expect a notification from system, not from this browser.
		It typically shows up in a sidebar in Windows, OS/X
		or Ubuntu.
		</div>
	    </div>
	</div>
		);
	}

	const do_enable_notif = e => {
	}

	const notif_default = () => {
		output = (
	<div>
		Web Push Notifications are not enabled yet.
		<br/><br/>

	    <div className="p-grid">
		<div className="p-col-4">

		<Button label="Enable Notifications"
			icon="pi pi-fw pi-cog"
			className="p-button-secondary"
			onClick={notif_permission}
			/>
		</div>

		<div className="p-col-8">

		Please select <b>Allow</b>.

		<br/><br/>
		If you block notifications, TagInbox will not be
		able to request permission again. You will need
		to change browser settings to enable it.
		</div>
	    </div>
	</div>
		);
	}

	// Mode decision tree
	switch(mode) {
	case 'uninited':
		// Lib post_login would have tried to initialize 
		//	web push notifications
		if (wn.active)
			setMode('enabled');
		else if (wn.error == 'unsupported') {
			setMode('error');
			setResult(
		'Error: Web Push Messaging is not supported by your browser');
		}
		else if (wn.error == 'noserviceworker') {
			setMode('error');
			setResult( 'Error: service worker script is missing');
		}
		else if (wn.permission == 'denied')
			setMode('denied');
		else if (wn.permission == 'default')
			setMode('default');
		else if (wn.permission == 'granted')
			setMode('enabled');

		break;

	case 'denied':
		notif_denied();
		break;
	
	case 'enabled':
		notif_enabled();
		break;

	case 'default':
		notif_default();
		break;
	}

	return (
	<Card title="Browser Push Notifications">
		{output}
	<div>
	</div>
	</Card>
	);
}

export {PushNotif};
