import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom';
import {Panel} from 'primereact/panel';
import {TabView, TabPanel} from 'primereact/tabview';
import {Menu} from 'primereact/menu';
import {Menubar} from 'primereact/menubar';
import {Dialog} from 'primereact/dialog';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import {Card} from 'primereact/card';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {PickList} from 'primereact/picklist';
import {InputTextarea} from 'primereact/inputtextarea';
import {ProgressSpinner} from 'primereact/progressspinner';
import {Inplace, InplaceDisplay, InplaceContent} from 'primereact/inplace';

import UserList from './UserList';
import AddStaff from './AddStaff';
import AddClient from './AddClient';
import EditClient from './EditClient';
import DeleteClient from './DeleteClient';
import {MergeClients} from './Merge';
import {RoomInfo} from './RoomInfo';
import {SetTriggers, TriggerHelp} from './Triggers';
import {AutoMove, EmailNotifications, CategorizeRoom, SelectRoomSignature,
	RenameRoom, DeleteRoom, CloseTicket, ArchiveEmails, ChangeVisibility,
	DefaultEmailRecipients} from './RenameRoom';
import {Commandable, Spacer2, LinkButton, HelpInfo, lazyFocus} from './Utils';

import {ldb, log, go, get_room, go_url, get_first_id, growl, 
	list_has_ele, list_toggle_ele, foreach_list,
	filter_list, api, edate3, get_room_activity, 
	set_order, map_list, resort, get_staff, edate, 
	mark_tab_as_read, is_test_server, is_local_server, em,
	get_room_my_notes, remove_flag, server_remove_flag, show_new_flag,
	is_favorite_room, is_return_later_room, get_signature_of_room,
	get_archive_rooms, is_ticket_org, dv_default_email_recipients
} from './Lib';

//-------------- Tab: Summary ---------------

class RoomWriteActivityList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rid: props.par.props.rid,
			expanded_activity: false
		};	
	}
	
	render() {
	const rid = this.state.rid;
	
	const last_write_activity = null;
	const last_write_activity_date = last_write_activity ? last_write_activity.dt_updated : '';
	
	const last_unshared_activity = get_room_activity(rid, 'unshared');
	const last_shared_activity = get_room_activity(rid, 'shared');
	const last_task_activity = get_room_activity(rid, 'task');
	const last_summary_activity = get_room_activity(rid, 'summary');
	
	const last_unshared_activity_date = last_unshared_activity ? last_unshared_activity.dt_last_read : '';
	const last_shared_activity_date = last_shared_activity ? last_shared_activity.dt_last_read : '';
	const last_task_activity_date = last_task_activity ? last_task_activity.dt_last_read : '';
	const last_summary_activity_date = last_summary_activity ? last_summary_activity.dt_last_read : '';
	
	const last_activity_date = [last_unshared_activity_date, last_shared_activity_date, last_task_activity_date, last_summary_activity_date].reduce(function (a, b) { return a > b ? a : b; });
	
	const last_unshared_activity_by = last_unshared_activity ? 'by ' + get_staff(last_unshared_activity.sid).name : '';
	const last_shared_activity_by = last_shared_activity ? 'by ' + get_staff(last_shared_activity.sid).name : '';
	const last_task_activity_by = last_task_activity ? 'by ' + get_staff(last_task_activity.sid).name : '';
	const last_summary_activity_by = last_summary_activity ? 'by ' + get_staff(last_summary_activity.sid).name : '';
	
	const expand_icon_class = this.state.expanded_activity ? 'minus' : 'plus';
		
	if (!last_activity_date) {
		return <div className="last-activity-div">
			<div className="p-grid">
				<div className="p-col-6">
					No Write Activity
				</div>
			</div>
			</div>
	}
	
	return <div>
		<div className="activity-list-div-header">
			Room Write Activity
		</div>
		<div className="last-activity-div">
		<Link key="last-room-write-activity-div-{this.state.rid}"
			to="#" 
			onClick={e => {
				e.preventDefault();
				const new_value = this.state.expanded_activity ? false : true;
				this.setState({expanded_activity: new_value});
		}}>
			<div className="p-grid">
				<div className="p-col-4">
					<i className={"pi pi-" + expand_icon_class}></i> Last Write Activity:
				</div>
				<div className="p-col-6">
					{edate3(last_activity_date)}
				</div>
			</div>
		</Link>
		{this.state.expanded_activity && <div>
		<div className="p-grid">
			<div className="p-col-4 last-activity-div-hidden-item">
				Unshared Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_unshared_activity_date)} {last_unshared_activity_by}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 last-activity-div-hidden-item">
				Shared Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_shared_activity_date)} {last_shared_activity_by}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 last-activity-div-hidden-item">
				Task Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_task_activity_date)} {last_task_activity_by}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-4 last-activity-div-hidden-item">
				Summary Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_summary_activity_date)} {last_summary_activity_by}
			</div>
		</div>
		</div>
		}
		</div>
		</div>;
	}
}

class StaffActivity extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rid: props.par.props.rid,
			sid: props.item.id,
			expanded_staff_activity: false
		};	
	}
	
	render() {
	const rid = this.state.rid;
	const sid = this.state.sid;

	const last_unshared_activity = null;
	const last_shared_activity = null;
	const last_task_activity = null;
	const last_summary_activity = null;
	
	const last_unshared_activity_date = last_unshared_activity ? last_unshared_activity.dt_updated : '';
	const last_shared_activity_date = last_shared_activity ? last_shared_activity.dt_updated : '';
	const last_task_activity_date = last_task_activity ? last_task_activity.dt_updated : '';
	const last_summary_activity_date = last_summary_activity ? last_summary_activity.dt_updated : '';
	
	const last_activity_date = [last_unshared_activity_date, last_shared_activity_date, last_task_activity_date, last_summary_activity_date].reduce(function (a, b) { return a > b ? a : b; });
	
	const expand_icon_class = this.state.expanded_staff_activity ? 'minus' : 'plus';
	
	if (!last_activity_date) {
		return <div className="last-user-activity-div">
			<div className="p-grid">
				<div className="p-col-6">
					No Room Activity
				</div>
			</div>
			</div>
	}
	
	return <div className="last-user-activity-div">
		<Link key="last-activity-div-{this.state.sid}"
			to="#" 
			onClick={e => {
				e.preventDefault();
				const new_value = this.state.expanded_staff_activity ? false : true;
				this.setState({expanded_staff_activity: new_value});
		}}>
			<div className="p-grid">
				<div className="p-col-6">
					<i className={"pi pi-" + expand_icon_class}></i> Last Activity:
				</div>
				<div className="p-col-6">
					{edate3(last_activity_date)}
				</div>
			</div>
		</Link>
		{this.state.expanded_staff_activity && <div>
		<div className="p-grid">
			<div className="p-col-6 last-activity-div-hidden-item">
				Unshared Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_unshared_activity_date)}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-6 last-activity-div-hidden-item">
				Shared Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_shared_activity_date)}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-6 last-activity-div-hidden-item">
				Task Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_task_activity_date)}
			</div>
		</div>
		<div className="p-grid">
			<div className="p-col-6 last-activity-div-hidden-item">
				Summary Tab:
			</div>
			<div className="p-col-6">
				{edate3(last_summary_activity_date)}
			</div>
		</div>
		</div>
		}
		</div>;
	}
}

class UserActivityList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	
	show_item = (item, i) => {
	return <div className="p-grid" key={i}>
		<div className="p-col-8">
			<div className="name">
				{item.name}
			</div>
			<StaffActivity par={this.props.par} item={item} /> 
		</div>
		</div>
		}

	render() {
		const {root, items} = this.props;

		set_order(root);
		resort(root);

		return (
		<div className="user-activity-list-div">
			<div className="activity-list-div-header">
				Staff Member Activity
			</div>
	    		{map_list(root, this.show_item)}
	    	</div>
		)
	}
}

const AutoMoveHelp = () => <HelpInfo header="About Auto Share">
<div>
		<p>
	If an email belongs to this room it will be automatically
	be transferred to Shared.
		</p>
		<p>
	If an email belongs to multiple rooms, it will NOT be 
	automatically transferred. Also, this applies only to new
	emails arriving in Inbox (or Sent Items), not past emails.
		</p>
		<p>
	This applies to all staff in this room.
		</p>
</div>
</HelpInfo>

const EmailNotificationsHelp = () => <HelpInfo header="About Email Alerts">
<div>
		<p>
	When this setting is on for a room, when an email from a client gets
	automatically moved to the shared emails for the room, an SMS notification
	will go out to all staff members of the room who have an SMS number setup.
		</p>
		<p>
	These SMS messages will only be sent out to a staff member between 8am-6pm
	for the time zone that is set for that staff member. Shared emails that come
	in outside of these hours will not initiate an SMS message for that staff member.
		</p>
</div>
</HelpInfo>

const RoomHelp = props => <HelpInfo label={<b>First Steps</b>} 
			header="About this Room">
	<Card >
<p>
	Welcome to <strong>{props.room.name}</strong> client room.
</p>
<p>
	Staff members that have access to this room are listed below.
	This room contains emails and tasks related to the client 
	users listed below. Client users do NOT have access to this room.
</p>
<p>
	Please check <strong>Pending Emails</strong> tab to
	transfer emails you received from these client users to this room.
</p>
<p>
	Please use <strong>Common Emails</strong> tab to see
	emails to/from these client users from the staff members.
</p>
	</Card>
</HelpInfo>

function MyNotes({room, setEditMyNotes}) {
	const [savingMyNotes, setSavingMyNotes] = React.useState(false);
	const [myNotes, setMyNotes] = React.useState(get_room_my_notes(room));

	const save_my_notes = () => {
		setSavingMyNotes(true);
		const new_value = myNotes;
		const args = { cmd: 'mod_badge', rid: room.id, 
			field: 'my_notes', value: new_value };

		api( args, (error, db) => {
			setEditMyNotes(false);
			setSavingMyNotes(false);
			growl('Updated My Notes');
		});
	}

	log('room', 'MyNotes', room);

	return (
	<Dialog header="My Notes" 
		visible={true}
		style={{width:'32em', textAlign:'left'}}
		draggable={true}
		modal={true}
		onHide={e => setEditMyNotes(false)}
		maximizable={true}
	>
		<InputTextarea 
			style={{height:'16em', width:'100%',}}
			autoResize={false}
			value={myNotes} 
			ref={lazyFocus}
			onChange={ e => setMyNotes(e.target.value) }
		/>
		<br/>
		<br/>
		{(!savingMyNotes) && <Button label="Save"
			icon="pi pi-fw pi-check"
			onClick={save_my_notes}
		/>}
		{savingMyNotes && <ProgressSpinner/>}
		
	</Dialog>
	);
}

class Summary extends Commandable {
	constructor(props) {
		super(props);
		this.state = { miniProfile: true, editMyNotes: false, refresh: 0 };
		window.g_Summary = this;
	}

	toggle_mini_profile = e => {
		const on = this.state.miniProfile;

		const icon = on ? 'down' : 'up';

		return <i className={'pointer fa fa-fw fa-chevron-circle-' 
						+ icon}
			onClick={e => this.setState({miniProfile: !on})}
		></i>;
	}
	
	AddStaff_command = args => <AddStaff {...args} />
	AddClient_command = args => <AddClient {...args} />
	EditClient_command = args => <EditClient {...args} />
	DeleteClient_command = args => <DeleteClient {...args} />
	CategorizeRoom_command = args => <CategorizeRoom {...args} />
	SelectRoomSignature_command = args => <SelectRoomSignature {...args} />
	DefaultEmailRecipients_command = args => <DefaultEmailRecipients {...args} />
	ChangeRoomHiddenStatus_command = args => <ChangeRoomHiddenStatus {...args} />
	AutoMove_command = args => <AutoMove {...args} />
	EmailNotifications_command = args => <EmailNotifications {...args} />
	RenameRoom_command = args => <RenameRoom {...args} />
	DeleteRoom_command = args => <DeleteRoom {...args} />
	CloseTicket_command = args => <CloseTicket {...args} />
	ArchiveEmails_command = args => <ArchiveEmails {...args} />
	ChangeVisibility_command = args => <ChangeVisibility {...args} />
	
	componentDidMount() {
		this._isMounted = true;
		
		this.check_and_remove_alerts();
	}

	componentDidUpdate() {
		this.check_and_remove_alerts();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	reload() {
		this.setState({refresh: this.state.refresh + 1});
	}

	check_and_remove_alerts = () => {
		const {rid} = this.props;
		
		const room = get_room(rid);
		
		const tab = 'summary';
		
		const has_new_activity = show_new_flag(room, tab);
		remove_flag(room, tab);
		
		if (has_new_activity)
			server_remove_flag(room, tab);
		
		if (room._flags._tabs[tab])
			this.clear_activity();
	}

	clear_activity = () => {
		const {rid} = this.props;
		
		const args = {cmd: 'mod_room',
				op: 'clear_room_activity', 
				rid: rid, 
				val: 'summary'};
		api( args );
		
		log('summary', 'clear_activity', args);
	}

	render() {
		const {rid, tab, command} = this.props;
		const room = get_room(rid);

		if (command == 'MergeClients')
			return <MergeClients rid={rid} />
		else if (command == 'SetTriggers')
			return <SetTriggers rid={rid} />
		
		mark_tab_as_read(room, 'summary');

		const aitems = [{
				label:'About this room', 
				icon: 'pi pi-fw pi-info-circle',
			},
		      	{
				label: 'Menu',
				// icon: 'pi pi-fw pi-user',
				items: [
				{
					label: 'Rename',
					icon: 'pi pi-fw pi-tags',
					command: () => {
					this.select_command('RenameRoom');
					}
				},
				{
					label: 'Categorize',
					icon: 'pi pi-fw pi-tags',
					command: () => {
					this.select_command('CategorizeRoom');
					}
				},
				],
			},
		      ];
		if (ldb.data.me.is_org_admin && is_local_server()) {
			aitems[1].items.push({
				label: 'Delete',
				icon: 'pi pi-fw pi-tags',
				command: () => {
				this.select_command('DeleteRoom');
				}
			});
		}

		const clients_header = <Toolbar 
			left={<strong>Clients</strong>}
			right={
	<div className="p-toolbar-group-right">
		<Button label="Merge Clients"
			className="p-button-raised p-button-text"
			icon='pi pi-fw pi-users'
			tooltip="Merge Alternate Emails Account"
			onClick={() => this.select_command('MergeClients')}
		/>
		<Button label="Add Client"
			className="p-button-raised p-button-text"
			icon='pi pi-fw pi-user-plus'
			tooltip="Add Client"
			onClick={() => this.select_command('AddClient')}
		/>
	</div>
			}
			/>;
	

		const staffs_header = <Toolbar
			left={<strong>Staff</strong>}
			right={
	<div className="p-toolbar-group-right">
		<Button label="Add/Remove Staff"
			className="p-button-raised p-button-text"
			icon='pi pi-fw pi-user-plus'
			tooltip="Add/Remove Staff"
			onClick={() => this.select_command('AddStaff')}
		/>
	</div>
			}
			/>;

		const command_output = this.show_command(null);
		const header = <Menubar model={aitems} 
			style={{border:0}} />
		let room_category = room.category;
		if (room_category) {
			room_category = '(' + room_category + ')';
		} else {
			room_category = '';
		}

		const signature = get_signature_of_room(rid);
		const signature_name = signature ? signature.name : 'Default';
		
		const settings = JSON.parse(room.settings_json);
		
		const email_notifications = settings.email_notifications ? 'On' : 'Off';

		const ticket_org = is_ticket_org();

		const default_email_recipients = dv_default_email_recipients(room.default_email_recipients);

		const archive_rooms_list = get_archive_rooms(room);
				
	 	return (
<div className="p-grid">
	{command_output}
	<div className="p-col-12">
<Toolbar
	left={<React.Fragment>
		<i className="fa fa-fw fa-folder-open-o"></i> 
		&nbsp;
		<strong>{room.name} </strong>
		{room.auto_move && <i 
			title="Auto Share: Unshared to Shared"
			className="pi pi-fw pi-replay auto-move-summary"
			>
			</i>}
		{em(0.5)}
		{room_category}
	</React.Fragment>}
		
		right={<RoomHelp room={room} />}
	/>
	</div>
	
	{room.archive_of && <div className="p-col-12">
	<Toolbar
		style={{backgroundColor: '#eef'}}
		left={<React.Fragment>
			Archive Room for&nbsp;<Link to={go_url('room', room.archive_of)}>
			{get_room(room.archive_of).name}
			</Link>
		</React.Fragment>}
	/>
	<Card>
		<div>
		{room.hidden && <span>
			This room is currently <b>Hidden</b>
		</span>}
		{(!room.hidden) && <span>
			This room is currently <b>Visible</b>
		</span>}
		</div>
		<div>
		<br/>
		<LinkButton label="Change Visibility"
			tooltip="Change Visibility"
			onClick={() => this.select_command('ChangeVisibility')}
		/>
		</div>

	</Card>
	</div>}

	<div className="p-col-6">
		<Toolbar
			left={<React.Fragment>
			{this.toggle_mini_profile()}
			{em()}
			Profile 
			</React.Fragment>}
		/>

	<Card>
	<div className={"p-grid room-profile " + 
			  (this.state.miniProfile ? 'room-profile-mini' : '')}
			style={{'minHeight': '250px'}}>

		<div className="p-col-3">
			<i className="fa fa-fw fa-edit" style={{'marginRight': '5px'}}></i>
			Name
		</div>
		<div className="p-col-7">
			<b>{room.name}</b>
		</div>
		<div className="p-col-2 small">
		<LinkButton
			tooltip="Rename Room"
			onClick={e => this.select_command('RenameRoom')}
			label="Rename"
		/>
		</div>

		<div className="p-col-3">
			<i className='fa fa-fw fa-list' style={{'marginRight': '5px'}}></i>
			Category
		</div>
		<div className="p-col-7">
			<b>{room.category}</b>
		</div>
		<div className="p-col-2 small">
		<LinkButton label="Change"
			tooltip="Categorize Room"
			onClick={() => this.select_command('CategorizeRoom')}
		/>
		</div>

		<Spacer2 />
		<Spacer2 />

		<RoomInfo room={room} />
	</div>
	</Card>

	</div>

	<div className="p-col-6">
		<Toolbar left="Settings" />
	<Card>
	<div className="p-grid room-settings">

		<div className="p-col-4">
			<i className='fa fa-fw fa-dot-circle-o small-sep'></i>
			Triggers
		</div>
		<div className="p-col-5">
			{room.triggers_json == '{}' ?
				'Not Set' :
				<div>
			Subject: <b>
				{room.triggers.subject.join(' ')}
				</b>
			<br/>
			From: <b>
				{room.triggers.from.join(' ')}
				</b>
				</div>}
		</div>
		<div className="p-col-3 small">
			{ldb.data.me.is_org_admin && 
			<LinkButton label="Set"
				style={{marginRight:'0.6em'}}
				tooltip="Set Triggers"
				onClick={() => 
					this.select_command('SetTriggers')}
			/>}
			<TriggerHelp />
		</div>

		<div className="p-col-4">
			<i className='pi pi-fw pi-replay small-sep'></i>
			Auto Share
		</div>
		<div className="p-col-5">
			<b>{room.auto_move ? 'On' : 'Off'} </b>
		</div>
		<div className="p-col-3 small">
			{ldb.data.me.is_org_admin && 
			<LinkButton label="Change"
				style={{marginRight:'0.6em'}}
				tooltip="Toggle Auto Share"
				onClick={() => this.select_command('AutoMove')}
			/>}
			<AutoMoveHelp />
		</div>

		<div className="p-col-4">
			<i className='fa fa-fw fa-mobile fa-lg'></i>
			Email Alerts
		</div>
		<div className="p-col-5">
			<b>{email_notifications}</b>
		</div>
		<div className="p-col-3 small">
		<LinkButton label="Change"
			style={{marginRight:'0.6em'}}
			tooltip="Change Email Notifications Settings"
			onClick={() => this.select_command('EmailNotifications')}
		/>
		<EmailNotificationsHelp />
		</div>

		<div className="p-col-4" title="Email Signature">
			<i className='fa fa-fw fa-envelope-o small-sep' style={{'marginLeft': '2px'}}></i>
			Signature
		</div>
		<div className="p-col-5">
			<b>{signature_name}</b>
		</div>
		<div className="p-col-3 small">
		<LinkButton label="Change"
			tooltip="Select Room Email Signature"
			onClick={() => this.select_command('SelectRoomSignature')}
		/>
		</div>

		<div className="p-col-4" title="Default Email Recipients">
			<i className='fa fa-fw fa-users small-sep' style={{'marginLeft': '2px'}}></i>
			Email Recipients
		</div>
		<div className="p-col-5">
			<b>{default_email_recipients}</b>
		</div>
		<div className="p-col-3 small">
		<LinkButton label="Change"
			tooltip="Change Default Email Recipients"
			onClick={() => this.select_command('DefaultEmailRecipients')}
		/>
		</div>

		{(archive_rooms_list.length > 0) && <React.Fragment>
		{archive_rooms_list.map(function(ar, index) {
			const is_hidden = ar.hidden ? 'Hidden' : 'Visible';
			
                        return (<React.Fragment key={"archive_room_" + ar.id}>
				<div className="p-col-4">
					{(index == 0) && <React.Fragment><i className='fa fa-fw fa-archive'></i> Archive Rooms</React.Fragment>}
				</div>
				<div className="p-col-5 small">
					{ar.name}<br/><b>{is_hidden}</b>
				</div>
				<div className="p-col-3 small">
					<LinkButton label="Go to Archive"
					style={{marginRight:'0.6em'}}
					tooltip="Go to Archive"
					onClick={() => {go('room', ar.id, 'summary', 0)}}
					/>
				</div>
				</React.Fragment>);
		})}
		</React.Fragment>}

		<div className="p-col-4">
			<Link to='#' 
				onClick={e=>this.setState({editMyNotes:true})}>
				<i className='fa fa-fw fa-file-o small-sep'></i>
				My Notes:
			</Link>
		</div>

		<div className="p-col-8 my-notes">
			{get_room_my_notes(room)}
			{this.state.editMyNotes && <MyNotes room={room} 
				setEditMyNotes={
				  val => this.setState({editMyNotes: val})}
				  />
			}
		</div>

		{ldb.data.me.is_org_admin &&
		<React.Fragment>
		<Spacer2 />

		<div className="p-col-12 right">
		<Button label="Delete Room"
			className="p-button-raised p-button-text small"
			icon='fa fa-trash-o'
			tooltip="Delete Room"
			tooltipOptions={{position: 'left'}}
			onClick={() => this.select_command('DeleteRoom')}
		/>
		&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
		<Button label="Create Archive"
			className="p-button-raised p-button-text small"
			icon='fa fa-archive'
			tooltip="Create Email Archive"
			tooltipOptions={{position: 'left'}}
			onClick={() => this.select_command('ArchiveEmails')}
		/>
		</div>
		</React.Fragment>
		}

		{ticket_org &&
		<React.Fragment>
		<Spacer2 />

		<div className="p-col-12 right">
		<Button label="Close Ticket"
			className="p-button-raised p-button-text small"
			icon='fa fa-ticket'
			tooltip="Close Ticket"
			tooltipOptions={{position: 'left'}}
			onClick={() => this.select_command('CloseTicket')}
		/>
		</div>
		</React.Fragment>
		}

	</div>
	</Card>

	</div>

	
	<div className="p-col-12 p-md-6 ">
	<UserList root={room.clients} header={clients_header} par={this} 
		/>
	</div>
	<div className="p-col-12 p-md-6 ">
	<UserList root={room.staffs} header={staffs_header} par={this} 
		/>
	</div>'
</div>


		)
	}
}

function MailboxRoomSummary(props) {
	const {rid, tab} = props;
	const room = get_room(rid);

	return (
<Accordion activeIndex={0} >
<AccordionTab header="Overview">
	Here you can access all of your emails in your mailbox.
	<br/><br/>
	You can do basic operations here itself intsead of having 
	to switch to your email program, such as Outlook, Thunderbird, 
	Mac Mail etc.
	<br/><br/>
	Only you have access to this room, not your coworkers.
</AccordionTab>
</Accordion>
	);
}

export {Summary, MailboxRoomSummary, MyNotes, AutoMoveHelp};
